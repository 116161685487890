import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@nutrien/uet-react/styles/makeStyles'
import Dialog from '@nutrien/uet-react/Dialog'
import Button from '@nutrien/uet-react/Button'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

const useStyles = makeStyles(theme => ({
  message: {
    fontFamily: 'Open Sans',
    fontSize: '16px',
    margin: theme.spacing(0, 0, 1, 0)
  },
  continueButton: {
    fontFamily: 'Open Sans',
    fontSize: '16px'
  },
  country: {
    margin: theme.spacing(1, 0, 0, 0),
    width: '40%',
    fontSize: '16px'
  },
  placeholder: {
    color: theme.palette.grey.A400,
    fontSize: '16px',
    fontWeight: 400
  }
}))

const SelectCountryDialog = ({ open, handleSelect }) => {
  const { t } = useTranslation()
  const styles = useStyles()
  const [country, setCountry] = useState('')
  const countries = [
    { value: 'CA', label: 'Canada' },
    { value: 'US', label: 'United States' }
  ]

  return (
    <div>
      <Dialog
        title={t('select_country_dialog_title')}
        open={open}
        onClose={() => undefined}
        actions={
          <>
            <Button
              data-testid="continue-button"
              className={styles.continueButton}
              disabled={!country}
              variant="contained"
              onClick={() => handleSelect(country)}>
              {t('continue')}
            </Button>
          </>
        }>
        <p className={styles.message}>{t('select_country_dialog_message')}</p>
        <Select
          inputProps={{ 'data-testid': 'country-select' }}
          className={styles.country}
          name="country"
          displayEmpty
          renderValue={
            !country
              ? () => (
                  <div className={styles.placeholder}>
                    {t('select_country')}
                  </div>
                )
              : undefined
          }
          value={country}
          onChange={e => setCountry(e.target.value)}>
          {countries.map(country => (
            <MenuItem key={country.value} value={country.value}>
              {country.label}
            </MenuItem>
          ))}
        </Select>
      </Dialog>
    </div>
  )
}

export default SelectCountryDialog
