import React, { useCallback, useMemo, useState } from 'react'
import { Link, useHistory, Redirect } from 'react-router-dom'
import styled from 'styled-components'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'graphql-hooks'
import * as yup from 'yup'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Tooltip from '@material-ui/core/Tooltip'
import InputAdornment from '@nutrien/uet-react/InputAdornment'
import Button from '@nutrien/uet-react/Button'
import Box from '@nutrien/uet-react/Box'
import Checkbox from '@nutrien/uet-react/Checkbox'
import CircularProgress from '@nutrien/uet-react/CircularProgress'
import FormControlLabel from '@nutrien/uet-react/FormControlLabel'
import MailIcon from '@nutrien/uet-react/icons/Mail'
import Notification from '@nutrien/uet-react/Notification'
import TextField from '@nutrien/uet-react/TextField'
import PropTypes from 'prop-types'
import { getGraphQLError } from 'helpers'
import useFlags from 'hooks/useFlags'
import useQueryParams from 'hooks/useQueryParams'
import useDashboardLink from 'hooks/useDashboardLink'
import { InformationIcon, LockIcon } from 'components/Icons'
import SvgIcon from 'components/SvgIcon'
import useInput from 'hooks/useInput'
import useToggle from 'hooks/useToggle'
import th from 'theme/helpers'
import { AuthConsumer } from 'components/Auth/authentication-context'

const PlaceholderWrapper = styled.div`
  color: ${th.palette('grey.A400')};
  font-weight: 400;
  font-size: 16px;
`

const Placeholder = ({ children }) => {
  return <PlaceholderWrapper>{children}</PlaceholderWrapper>
}

const TCLink = styled(Link)`
  color: ${th.palette('green.800')};
`

const FormControlWrapper = styled(FormControl)`
  margin: ${th.spacing(0, 0, 2, 0)};
  width: 100%;
`

const Error = styled(Notification)`
  margin-top: ${th.spacing(1)};
  margin-bottom: ${th.spacing(1)};
  font-family: 'Open Sans', sans-serif;
`

const Row = styled.div`
  margin: 16px 0;
  text-align: center;
`

const EligibilityButton = styled(Button)`
  margin-top: 17px;
  border-radius: 4px;
  padding: 8px, 16px;
  width: 100%;
`

const StyledTextField = styled(TextField)`
  margin-bottom: ${th.spacing(2)};

  .MuiFormHelperText-contained {
    margin: 0;
  }
`

const VALIDATE_EMAIL_MUTATION = `
  mutation ValidateEmailInUse(
    $email: String!
  ) {
    validateEmailInUse(
      email: $email
    )
  }
`

const CREATE_ACCOUNT = `
  mutation CreateAccount(
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $code: String
    $country: String
    $language: String
    $phoneNumber: String
    $agribleTermsAndConditions: String!
  ) {
    createAccount(
      email: $email
      firstName: $firstName
      lastName: $lastName
      password: $password
      code: $code
      country: $country
      language: $language
      phoneNumber: $phoneNumber
      agribleTermsAndConditions: $agribleTermsAndConditions
    ){
      org_uuid
    }
  }
`

const STORE_SCREENING_RESPONSES = `
  mutation StoreScreeningResponses(
    $screeningResponsesInput: ScreeningResponsesInput!
  ) {
    storeScreeningResponses(
      screeningResponsesInput: $screeningResponsesInput
    )
  }
`

const VALIDATE_PROMO_CODE = `
  mutation ValidatePromoCode(
    $code: String!
  ) {
    validatePromoCode(
      code: $code
    ) {
      valid
      international
      country
      community {
        name
        termsAndConditions {
          text
          version
          modified
        }
        kind
      }
      campaign {
        name
        termsAndConditions {
          text
          version
          modified
        }
        kind
        enrollmentEndDate
        campaignStartDate
      }
    }
  }
`
const StyledFormControlLabel = styled(FormControlLabel)`
  margin-right: 0;
  padding-left: 34px;
`
const TermsAndConditionsWrapper = styled.div`
  color: #666666;
  font-size: 12px;
  line-height: 22px;
`

const ButtonWrapper = styled(Button)`
  width: 100%;
`

const StyledIcon = styled(SvgIcon)`
  color: ${th.palette('grey.600')};
  margin-top: ${th.spacing(1)};
  margin-left: ${th.spacing(0.5)};
  margin-right: ${th.spacing(-1)};
`

const StyledCheckbox = styled(Checkbox)`
  padding: 7px;
`
const ErrorLoginSpan = styled.span`
  border-bottom: 1px solid;
`

// THIS WILL BE REMOVED SOON
const Signup = ({ screeningAnswers, handleIsNutrienUser, switchtab }) => {
  const history = useHistory()
  const flags = useFlags()
  const { t, i18n } = useTranslation()
  const [isSubmitting, setSubmitting] = useState(false)
  const [errors, setError] = useState(null)
  const [promoCodeAreaErrorText] = useState(false)
  const [errorKey, setErrorKey] = useState('')
  const { redirect, membershipCountry, membershipEmail } = useQueryParams()
  const dashboardLink = useDashboardLink()
  const countries = [
    { value: 'CA', label: 'Canada' },
    { value: 'US', label: 'United States' }
  ]
  const { appEnv } = window.uiConf
  const firstNameSchema = useMemo(
    () =>
      yup
        .string()
        .trim()
        .required(t('enter_first_name')),
    [t]
  )
  const firstName = useInput('', firstNameSchema)

  const lastNameSchema = useMemo(
    () =>
      yup
        .string()
        .trim()
        .required(t('enter_last_name')),
    [t]
  )
  const lastName = useInput('', lastNameSchema)

  const emailSchema = useMemo(
    () =>
      yup
        .string()
        .trim()
        .email(t('email_error'))
        .required(t('enter_email')),
    [t]
  )
  const email = useInput(membershipEmail, emailSchema)

  const passwordSchema = useMemo(
    () =>
      yup
        .string()
        .trim()
        .required(t('password_is_required'))
        .min(6, t('password1_error')),
    [t]
  )
  const password = useInput('', passwordSchema)

  const phoneNumberSchema = useMemo(
    () =>
      yup
        .string()
        .trim()
        .required(t('phone_number_is_required'))
        .max(32, t('phone_number_max_32')),
    [t]
  )
  const phoneNumber = useInput('', phoneNumberSchema)

  const projectCode = useInput('')

  const countrySchema = useMemo(
    () =>
      yup
        .string()
        .trim()
        .required(t('country_is_required')),
    [t]
  )

  const country = useInput(
    membershipCountry || (screeningAnswers ? screeningAnswers.country : ''),
    countrySchema
  )

  const initialValues = {
    firstName: firstName.value,
    lastName: lastName.value,
    email: email.value,
    password: password.value,
    phoneNumber: phoneNumber.value,
    projectCode: projectCode.value,
    country: country.value
  }

  const translateServerError = error => {
    let key
    switch (getGraphQLError(error)) {
      case 'CommunityCode matching query does not exist.':
        key = 'failed_to_enroll_in_campaign'
        break
      case 'Please provide a valid promo code.':
        key = 'invalid_code_text'
        break
      case 'Not found.':
        key = 'invalid_code'
        break
      case 'This email address is already in use.':
        key = 'used_email_text_or_login'
        break
      case 'invalid code area':
        key = 'invalid_code_area'
        break
      case 'This email address is already in use for nutrien user.':
        key = 'error_already_have_nutrien_account'
        break
      default:
        key = 'an_unexpected_error_occurred'
        break
    }
    setErrorKey(key)
    return t(key)
  }
  const [agribleTerms, toggleTerms] = useToggle(false)
  const [validateEmail] = useMutation(VALIDATE_EMAIL_MUTATION)
  const [validateCode] = useMutation(VALIDATE_PROMO_CODE)
  const [createAccount] = useMutation(CREATE_ACCOUNT)
  const [storeScreeningResponses] = useMutation(STORE_SCREENING_RESPONSES)

  const handleStoreScreeningResponses = useCallback(
    async orgUuid => {
      if (screeningAnswers) {
        await storeScreeningResponses({
          variables: {
            screeningResponsesInput: {
              organizationUuid: orgUuid,
              locations: screeningAnswers.locations,
              crops: screeningAnswers.crops,
              specificCrops: screeningAnswers.specificCrops,
              screenerQuestions: screeningAnswers.screenerQuestions
            }
          }
        })
      }
    },
    [screeningAnswers, storeScreeningResponses]
  )

  const submitData = useCallback(
    async login => {
      try {
        const { data, error } = await createAccount({
          variables: {
            firstName: firstName.value,
            lastName: lastName.value,
            email: email.value,
            password: password.value,
            phoneNumber: phoneNumber.value,
            code: projectCode.value,
            country: country.value,
            language: i18n.language,
            agribleTermsAndConditions: flags.tcVersion[appEnv]
          }
        })
        if (!error) {
          const loggedIn = await login({
            username: email.value,
            password: password.value
          })

          if (loggedIn) {
            await handleStoreScreeningResponses(data.createAccount.org_uuid)
            setSubmitting(false)
            sessionStorage.setItem('firstLogin', 'true')
            sessionStorage.setItem('projectCode', projectCode.value)
            history.push(redirect || dashboardLink)
          }
        } else {
          setSubmitting(false)
        }
      } catch (err) {
        setSubmitting(false)
        setError(err)
      }
    },
    [
      createAccount,
      firstName.value,
      lastName.value,
      email.value,
      password.value,
      phoneNumber.value,
      projectCode.value,
      country.value,
      i18n.language,
      flags.tcVersion,
      handleStoreScreeningResponses,
      history,
      redirect,
      dashboardLink,
      appEnv
    ]
  )

  const handleSubmit = useCallback(
    async login => {
      setSubmitting(true)
      setError(null)
      let codeError = false

      const { error } = await validateEmail({
        variables: {
          email: email.value
        }
      })

      if (!error) {
        if (projectCode.value) {
          const { data, error } = await validateCode({
            variables: {
              code: projectCode.value
            }
          })

          if (error) {
            setError(error)
            setSubmitting(false)
            codeError = true
          } else {
            if (!data.validatePromoCode.country.includes(country.value)) {
              setSubmitting(false)
              setError('invalid code area')
              codeError = true
            } else {
              sessionStorage.setItem(
                'validatePromoCode',
                JSON.stringify(data.validatePromoCode)
              )
            }
          }
        }
      } else {
        setError(error)
        setSubmitting(false)
      }

      if (!error && !codeError) {
        submitData(login)
      }
    },
    [
      validateEmail,
      validateCode,
      email.value,
      projectCode.value,
      country.value,
      submitData
    ]
  )

  const canSave = () => {
    return (
      firstName.value &&
      lastName.value &&
      email.value &&
      password.value &&
      phoneNumber.value &&
      country.value &&
      !email.hasError &&
      !password.hasError &&
      !phoneNumber.hasError &&
      !projectCode.hasError &&
      !country.hasError &&
      agribleTerms
    )
  }

  const handleSelectedCountry = value => {
    country.setValue(value)
  }

  const switchToLogin = () => {
    switchtab({}, 'login')
  }

  const handleSetNutrienUser = () => {
    handleIsNutrienUser(true)
  }

  return (
    <AuthConsumer>
      {({ login, user }) => (
        <Formik initialValues={initialValues} enableReinitialize>
          {() =>
            user ? (
              <Redirect to={dashboardLink} />
            ) : (
              <Box maxWidth={430}>
                <Box mt={2}>
                  {!!errors && (
                    <Error variant="error">
                      <span>{translateServerError(errors)}</span>
                      {errorKey === 'used_email_text_or_login' && (
                        <ErrorLoginSpan onClick={switchToLogin}>
                          {t('login')}
                        </ErrorLoginSpan>
                      )}
                      {errorKey === 'error_already_have_nutrien_account' && (
                        <Box textAlign="end">
                          <Link onClick={handleSetNutrienUser}>
                            {' '}
                            {t('continue')}{' '}
                          </Link>
                        </Box>
                      )}
                    </Error>
                  )}

                  <StyledTextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon
                            color="grey.600"
                            fontSize="small"
                            component={MailIcon}
                          />
                        </InputAdornment>
                      )
                    }}
                    placeholder={t('email')}
                    {...email.bind}
                    autoComplete="email"
                    value={email.value ? email.value : ''}
                    disabled={membershipEmail}
                    required
                    autoFocus
                  />
                  <StyledTextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <StyledIcon component={LockIcon} />
                        </InputAdornment>
                      )
                    }}
                    placeholder={t('password')}
                    type="password"
                    {...password.bind}
                    required
                  />
                  <StyledTextField
                    inputProps={{
                      'data-testid': 'firstName'
                    }}
                    placeholder={t('first_name')}
                    {...firstName.bind}
                    autoComplete="given-name"
                    required
                  />
                  <StyledTextField
                    inputProps={{
                      'data-testid': 'lastName'
                    }}
                    placeholder={t('last_name')}
                    {...lastName.bind}
                    autoComplete="family-name"
                    required
                  />
                  <StyledTextField
                    inputProps={{
                      'data-testid': 'phoneNumber'
                    }}
                    placeholder={t('phone_number')}
                    {...phoneNumber.bind}
                    required
                  />
                  <FormControlWrapper>
                    <Select
                      name="country"
                      displayEmpty
                      renderValue={
                        !country.value
                          ? () => (
                              <Placeholder>{t('select_country')}</Placeholder>
                            )
                          : undefined
                      }
                      value={country.value ? country.value : ''}
                      onChange={e => {
                        handleSelectedCountry(e.target.value)
                      }}
                      disabled={membershipCountry || screeningAnswers}>
                      {countries.map((country, index) => (
                        <MenuItem key={index} value={country.value}>
                          {country.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControlWrapper>
                  {!membershipEmail && (
                    <StyledTextField
                      InputProps={{
                        endAdornment: (
                          <InputAdornment>
                            <Tooltip
                              title={t('project_code_hint')}
                              placement="top">
                              <span>
                                <InformationIcon />
                              </span>
                            </Tooltip>
                          </InputAdornment>
                        )
                      }}
                      placeholder={t('project_code')}
                      {...projectCode.bind}
                    />
                  )}
                  {promoCodeAreaErrorText && (
                    <>
                      <Error variant="error">{t('invalid_code_area')}</Error>
                    </>
                  )}
                  <StyledFormControlLabel
                    control={
                      <StyledCheckbox
                        checked={agribleTerms}
                        onChange={toggleTerms}
                        color="primary"
                      />
                    }
                    label={
                      <TermsAndConditionsWrapper>
                        <span>{t('agrible_agree_terms_label')}</span>
                        &nbsp;
                        <TCLink target="_blank" to="/termsandconditions">
                          {t('terms_and_conditions')}
                        </TCLink>
                      </TermsAndConditionsWrapper>
                    }
                  />
                </Box>
                <Row>
                  {isSubmitting ? (
                    <CircularProgress size={33} />
                  ) : (
                    <ButtonWrapper
                      data-testid="submit-button"
                      variant="contained"
                      color="primary"
                      onClick={() => handleSubmit(login)}
                      disabled={!canSave()}
                      type="submit">
                      {t('signup')}
                    </ButtonWrapper>
                  )}
                  {!screeningAnswers && !membershipEmail && (
                    <EligibilityButton
                      variant="outlined"
                      color="primary"
                      href="/eligibility">
                      {t('check_sustainability_eligibility')}
                    </EligibilityButton>
                  )}
                </Row>
              </Box>
            )
          }
        </Formik>
      )}
    </AuthConsumer>
  )
}

Signup.propTypes = {
  screeningAnswers: PropTypes.object
}

export default Signup
