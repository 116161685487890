import _get from 'lodash/get'
import _filter from 'lodash/filter'
import EditIcon from '@nutrien/uet-react/icons/Edit'
import AddIcon from '@nutrien/uet-react/icons/Add'
import CheckCircle from '@nutrien/uet-react/icons/CheckCircle'
import Cancel from '@nutrien/uet-react/icons/Cancel'
import theme from 'theme'

import { PlantingCompletionIcon } from 'components/Icons'

export const createPlantingCard = (seasonId, event, t, createUrl) => ({
  title: t('planting_date'),
  value: t('_date_monthShortDYear', {
    date: _get(event, 'activityDatetime')
  }),
  LinkIcon: EditIcon,
  ContentIcon: PlantingCompletionIcon,
  color: theme.palette.cardColors.barleyQuality.neutral,
  link: createUrl({
    page: 'field-story',
    queryParams: {
      seasonId: seasonId,
      eventId: event.id,
      panel: 'event_panel',
      formType: 'edit',
      eventType: 'plantingcompletion'
    }
  })
})

export const createEventCard = (
  seasonId,
  eventType,
  event,
  title,
  t,
  createUrl
) => {
  const queryParams = {
    seasonId: seasonId,
    panel: 'event_panel',
    formType: event ? 'edit' : 'create',
    eventType: eventType
  }
  if (event) {
    queryParams.eventId = event.id
  }

  return {
    value: title,
    title: event ? t('edit') : t('add'),
    LinkIcon: event ? EditIcon : AddIcon,
    ContentIcon: event ? CheckCircle : Cancel,
    color: theme.palette.cardColors.barleyQuality[event ? 'green' : 'red'],
    link: createUrl({
      page: 'field-story',
      queryParams
    })
  }
}

export const getEvent = (events, eventName) =>
  _get(
    _filter(events, event => event.__typename === eventName),
    '[0]'
  )
