export const SYNC_ECHELON_DATA_MUTATION = `
mutation SyncEchelonData(
  $accountId: String!
) {
  syncEchelonData(
    accountId: $accountId
  )
}
`

export const LINK_ACCOUNT_MUTATION = `
mutation LinkPaymentAccount(
  $accountId: String!
  $accountToken: String!
  $nickname: String

) {
  linkPaymentAccount(
    accountId: $accountId
    accountToken: $accountToken
    nickname: $nickname
  )
}
`
export const EDIT_ACCOUNT_NICKNAME_MUTATION = `
mutation EditAccountNickname(
  $accountId: String!
  $nickname: String

) {
  editAccountNickname(
    accountId: $accountId
    nickname: $nickname
  )
}
`
