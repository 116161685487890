/**
 * This function checks if value is a plain object
 * which means an object created by the Object constructor
 *
 * Tests:
 * isPlainObject({})                    // true
 * isPlainObject(Object.create({}))     // true
 * isPlainObject(undefined)             // false
 * isPlainObject(null)                  // false
 * isPlainObject(NaN)                   // false
 * isPlainObject([])                    // false
 * isPlainObject(true)                  // false
 * isPlainObject(12345)                 // false
 * isPlainObject("Foo Bar")             // false
 * isPlainObject(new Date())            // false
 * isPlainObject(Object.create(null))   // false
 * isPlainObject(() => {})              // false
 * isPlainObject(Object)                // false
 *
 * @param {*} obj Value to check
 * @returns Boolean
 */
export const isPlainObject = obj =>
  (obj ?? false)?.constructor?.name === 'Object'
