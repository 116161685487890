import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import _get from 'lodash/get'
import _set from 'lodash/set'
import { useAuth0 } from '@auth0/auth0-react'
import { withGlobalConfig } from 'components/GlobalConfig'
import {
  AUTH_TYPE_SSO,
  AUTH_TYPE_SSO_PENDING,
  AUTH_TYPE_LOCAL
} from './constants/authTypes'

/**
 * @typedef {{
 * login: (data: any) => Promise<any>;
 * logout: () => Promise<void>;
 * ssoLogin: (options?: RedirectLoginOptions) => Promise<void>;
 * isSSOAuthenticated: boolean;
 * isSSOUser: boolean;
 * getSSOAccessToken: (options?: GetTokenSilentlyOptions) => Promise<string>;
 * createSSOSession: () => Promise<void>;
 * loginError: any;
 * setLoginError: React.Dispatch<any>;
 * setAuthType: (type: AUTH_TYPE_SSO | AUTH_TYPE_SSO_PENDING | AUTH_TYPE_LOCAL ) => void;
 * logoutError: any;
 * isAuthenticated: any;
 * user: any;
 * setUser: React.Dispatch<any>;
 * resetSSOPassword: () => Promise<Response>;
 * component: any;
 * fetchUserData: () => Promise<void>;
 * authentication: any;
 * isEmployee: boolean;
 * }} AuthContext
 */

/**
 * @type {AuthContext}
 */
const initialContext = {}
export const AuthContext = React.createContext(initialContext)

export const AuthConsumer = AuthContext.Consumer

export const AuthProvider = withGlobalConfig(
  ({ authentication, children, component, globalConfig }) => {
    const [loginError, setLoginError] = useState(null)
    const [logoutError, setLogoutError] = useState(null)
    const [user, setUserContext] = useState(authentication.user)
    const [isAuthenticated, setAuthenticated] = useState(
      authentication.isAuthenticated()
    )
    const [isUserLoading, setIsUserLoading] = useState(false)
    const [membership, setMembership] = useState(null)

    const language = _get(user, ['profile', 'language'])
    const { i18n } = useTranslation()
    const auth0 = useAuth0()
    const {
      loginWithRedirect: ssoLogin,
      getAccessTokenSilently: getSSOAccessToken,
      isAuthenticated: isSSOAuthenticated,
      isLoading: isSSOLoading,
      logout: ssoLogout
    } = auth0

    const getAuthType = useCallback(() => localStorage.getItem('authType'), [])
    const setAuthType = useCallback(
      type => localStorage.setItem('authType', type),
      []
    )
    const isSSOUser = getAuthType() === AUTH_TYPE_SSO
    const isEmployee = isSSOUser && user?.email?.includes('@nutrien.com')

    const setUser = useCallback(
      userData => {
        if (user?.profile?.agribleTermsAndConditions) {
          // this is to keep the agribleTermsAndConditions
          // after updating user data
          _set(
            userData,
            ['profile', 'agribleTermsAndConditions'],
            user?.profile?.agribleTermsAndConditions
          )
        }

        setUserContext(userData)
      },
      [user, setUserContext]
    )

    const fetchUserData = useCallback(async () => {
      const authorshipGrowerExists = sessionStorage.getItem('authorshipGrower')
      const authorshipGrower = authorshipGrowerExists
        ? JSON.parse(authorshipGrowerExists)
        : false
      const user = await authentication.getCurrentUser()
      const address = _get(user, ['profile', 'address']) || {}

      _set(user, ['profile', 'address'], address)
      setUserContext(authorshipGrowerExists ? authorshipGrower : user)
    }, [authentication])

    const login = async data => {
      let username
      const authorshipGrowerExists = sessionStorage.getItem('authorshipGrower')
      try {
        setLoginError(null)
        if (authorshipGrowerExists) {
          sessionStorage.removeItem('authorshipGrower')
          sessionStorage.removeItem('authorshipSession')
          sessionStorage.removeItem('currentLocation')
        }
        username = await authentication.login(data)
        if (username && authentication.isAuthenticated()) {
          setAuthType(AUTH_TYPE_LOCAL)
          setAuthenticated(true)
        }
      } catch (err) {
        setLoginError(err.message)
      }
      return username
    }

    const logout = useCallback(async () => {
      try {
        setLogoutError(null)
        await authentication.logout()
        if (isSSOAuthenticated) ssoLogout({ returnTo: window.location.origin })
        // TODO: handle logout error
      } catch (err) {
        setLogoutError(err.message)
      }
      if (!isSSOAuthenticated) window.location.assign('/legacy-login')
    }, [authentication, isSSOAuthenticated, ssoLogout])

    const createSSOSession = useCallback(async () => {
      setAuthenticated(true)
      setAuthType(AUTH_TYPE_SSO)
      await fetchUserData()
    }, [fetchUserData, setAuthType])

    const checkAuthStatus = useCallback(async () => {
      const isAuthenticated = authentication.isAuthenticated()

      switch (getAuthType()) {
        case AUTH_TYPE_LOCAL:
          if (!isAuthenticated) {
            return await logout()
          }
          return
        case AUTH_TYPE_SSO:
          if (!isSSOAuthenticated && !isAuthenticated) {
            return await logout()
          } else if (isAuthenticated && !isSSOAuthenticated) {
            // TEMPORARY: This is a temporary fix for auth0 getting blocked byt the browser
            // we will use the auth0 to validate the user instead of using the SDK.
            // SDK is being blocked when the browser doesn't allow 3rd party cookies
            // or if the user blocks 3rd party cookies.
            return ssoLogin({
              redirectUri: `${window.location.origin}/auth?redirect=${window.location.pathname}`
            })
          }
          return
        case AUTH_TYPE_SSO_PENDING:
          if (!isSSOAuthenticated) {
            return await logout()
          }

        // no default
      }
    }, [authentication, getAuthType, isSSOAuthenticated, logout, ssoLogin])

    const refreshSSOAccessToken = useCallback(() => getSSOAccessToken(), [
      getSSOAccessToken
    ])

    const checkSSOSessionInterval = useCallback(
      () =>
        setInterval(
          () => refreshSSOAccessToken().catch(e => {}),
          1000 * 60 * globalConfig.auth0RefreshTokenTimeout
        ),
      [refreshSSOAccessToken, globalConfig]
    )

    const resetSSOPassword = useCallback(async () => {
      const { auth0Domain, auth0ClientID, auth0Connection } = window.uiConf
      return fetch(`https://${auth0Domain}/dbconnections/change_password/`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify({
          email: user.email,
          client_id: auth0ClientID,
          connection: auth0Connection
        })
      })
    }, [user])

    useEffect(() => {
      let ssoChecker
      if (!isSSOLoading) {
        checkAuthStatus()
        if (isSSOAuthenticated) ssoChecker = checkSSOSessionInterval()
      }

      if (language) i18n.changeLanguage(language)
      if (isAuthenticated && !authentication.user && !isUserLoading) {
        setIsUserLoading(true)
        fetchUserData()
      }

      return () => {
        if (ssoChecker) clearInterval(ssoChecker)
      }
    }, [
      i18n,
      language,
      isSSOLoading,
      fetchUserData,
      authentication,
      checkAuthStatus,
      isAuthenticated,
      checkSSOSessionInterval,
      isSSOAuthenticated,
      isUserLoading
    ])

    authentication.onExpiredAuth = () => {
      const { location } = window
      location.assign(
        `/login?redirect=${encodeURIComponent(
          location.pathname + location.search
        )}`
      )
    }

    const context = {
      login,
      logout,
      ssoLogin,
      isSSOLoading,
      isSSOAuthenticated,
      isSSOUser,
      getSSOAccessToken,
      createSSOSession,
      loginError,
      setLoginError,
      getAuthType,
      setAuthType,
      logoutError,
      isAuthenticated,
      user,
      setUser,
      resetSSOPassword,
      component,
      fetchUserData,
      authentication,
      isEmployee,
      membership,
      setMembership
    }

    return (
      <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
    )
  }
)

AuthProvider.propTypes = {
  children: PropTypes.node,
  authentication: PropTypes.object,
  component: PropTypes.func
}
