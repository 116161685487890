import React from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@nutrien/uet-react/Box'
import Typography from '@nutrien/uet-react/Typography'
import InfoIcon from '@nutrien/uet-react/icons/Info'
import makeStyles from '@nutrien/uet-react/styles/makeStyles'
import SvgIcon from 'components/SvgIcon'

const useStyles = makeStyles(theme => ({
  infoIcon: {
    color: '#003C6A'
  },
  resetPasswordMessageBox: {
    backgroundColor: '#B2CDE1',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    columnGap: 5,
    paddingTop: 16
  },
  resetPasswordMessage: {
    color: '#003C6A',
    lineHeight: '24px'
  }
}))

export function ResetEmailSentMessage() {
  const styles = useStyles()
  const { t } = useTranslation()
  return (
    <Box
      paddingX={3}
      paddingY={1.25}
      textAlign="center"
      color="common.white"
      mb={5}
      className={styles.resetPasswordMessageBox}>
      <SvgIcon component={InfoIcon} className={styles.infoIcon} />
      <Typography variant="subtitle1" className={styles.resetPasswordMessage}>
        {t('If_an_account_exists')}
      </Typography>
    </Box>
  )
}
