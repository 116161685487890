import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useMutation } from 'graphql-hooks'
import { ENTITY_TYPE_ENTERPRISE_GROUPS } from '@nutrien/farm-tree-navigation/dist/constants/entities'

import useJohnDeereCreds from 'hooks/useJohnDeereCreds'
import useToggle from 'hooks/useToggle'
import useCreateUrl from 'hooks/useCreateUrl'
import withRequest from 'HOCs/withRequest'

import JohnDeereOrgSelector from '../JohnDeereOrgSelector'
import JohnDeereImport from '../JohnDeereImport'

const AUTHORIZE_JD = `
  mutation AuthorizeJD($code: String!, $state: String!) {
    authorizeJohnDeereImport(code: $code, state: $state)
  }
`

const JohnDeereWizard = ({
  noRedirect,
  onClose,
  onCloseAll,
  setRequestPending,
  setRequestError,
  fromJD,
  setShowDialog
}) => {
  const history = useHistory()
  const jdCreds = useJohnDeereCreds()
  const createUrl = useCreateUrl()
  const [selectedOrg, setSelectedOrg] = useState(null)
  const [showOrgSelector, toggleShowOrgSelector] = useToggle(!jdCreds)
  const [authorizeJD] = useMutation(AUTHORIZE_JD)

  useEffect(() => {
    if (!jdCreds) return

    const callAuthorizeJD = async () => {
      const { data } = await authorizeJD({ variables: jdCreds })

      if (data.authorizeJohnDeereImport) {
        toggleShowOrgSelector()
        // Clear the local state so that it doesn't repeat on page refresh
        history.replace({
          pathname: history.location.pathname,
          search: history.location.search,
          state: null
        })
      }
    }

    callAuthorizeJD()
  }, [authorizeJD, history, jdCreds, toggleShowOrgSelector])

  const handleDone = useCallback(
    groupUuid => {
      // if the import fails, just close JD wizard and stay on Add Field
      if (!groupUuid) return onClose()

      // if we don't want to redirect to field story,
      // close the whole AddFieldWizard
      if (noRedirect) return onCloseAll()

      history.push(
        createUrl(
          {
            farmItemLevel: ENTITY_TYPE_ENTERPRISE_GROUPS,
            farmItemId: groupUuid,
            page: 'field-story'
          },
          { clearPageParams: true }
        )
      )
    },
    [createUrl, history, noRedirect, onClose, onCloseAll]
  )

  const handleClose = useCallback(() => onClose(), [onClose])

  const handleOrgAuthClose = () => {
    setShowDialog(false)
    sessionStorage.removeItem('orgAuthorization')
  }

  if (jdCreds) return null

  if (selectedOrg)
    return (
      <JohnDeereImport
        orgId={selectedOrg}
        onDone={handleDone}
        setRequestPending={setRequestPending}
        setRequestError={setRequestError}
      />
    )

  if (showOrgSelector)
    return (
      <JohnDeereOrgSelector
        onClose={fromJD ? handleOrgAuthClose : handleClose}
        onImportFields={setSelectedOrg}
        setRequestPending={setRequestPending}
        setRequestError={setRequestError}
      />
    )
}

JohnDeereWizard.propTypes = {
  noRedirect: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onCloseAll: PropTypes.func,
  setRequestPending: PropTypes.func.isRequired,
  setRequestError: PropTypes.func.isRequired
}

JohnDeereWizard.defaultProps = {
  noRedirect: false,
  onCloseAll: () => {}
}

export default React.memo(withRequest(JohnDeereWizard))
