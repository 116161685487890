import _camelCase from 'lodash/camelCase'
import _snakeCase from 'lodash/snakeCase'
import _get from 'lodash/get'
import _isNil from 'lodash/isNil'
import _truncate from 'lodash/truncate'
import { transform } from 'ol/proj'
import theme from 'theme'
import { GeoJSON } from '@nutrien/geospatial'

import isArray from 'lodash/isArray'
import isNumber from 'lodash/isNumber'
import { unitMap } from 'helpers/units'
import { getMoistureStatusColor } from 'pages/Yield/helpers'

const roundDisplayedValue = (value, tooltip, metric) => {
  const decimals = metric === 'yield' ? 0 : tooltip ? 1 : 2
  return parseFloat(value).toFixed(decimals)
}

export const generateLabel = (
  field,
  value,
  metric,
  timeframe,
  t,
  tooltip = false
) => {
  if (!value) {
    return tooltip ? _truncate(field.name, { length: 10 }) : field.name
  }

  if (transformMetric(metric) === 'tractortime') {
    const { style } = value
    const translatedStyle = t(tractorTimeValue(style))
    return tooltip ? translatedStyle : `${field.name}\n${translatedStyle}`
  }

  const { actual, units } = value
  let displayed = ''

  if (!_isNil(actual)) {
    if (isNaN(parseFloat(actual)) && metric === 'yield') {
      displayed = actual
    } else {
      displayed = roundDisplayedValue(actual, tooltip, metric)
    }
  } else {
    if (metric === 'yield') {
      displayed = 0
    }
  }

  if (units) {
    const formattedUnit = unitMap[units] || units
    displayed =
      displayed +
      (displayed.length + formattedUnit.length > 6 && tooltip ? '\n' : ' ') +
      formattedUnit
  } else {
    if (metric === 'yield' && timeframe === 'WaterPercent') {
      displayed = `${displayed} %`
    }
  }
  return tooltip ? displayed : `${field.name}\n${displayed}`
}

export const transformMetric = metric => {
  if (['primary', 'wet'].includes(metric.toLowerCase())) {
    return 'tractortime'
  }

  return metric
}

export const tractorTimeValue = value => `${_snakeCase(value)}_short`

export const getColorForMetric = (metric, timeframe, value, cardColors) => {
  if (metric === 'yield' && timeframe === 'MoistureStatus') {
    return getMoistureStatusColor(value.actual, theme)
  }

  return _get(
    cardColors[_camelCase(metric)],
    _get(value, 'style'),
    cardColors.default
  )
}

export const getPolygonColor = (metric, timeframe, value, cardColors) => {
  if (metric === 'yield' && timeframe === 'MoistureStatus') {
    return getMoistureStatusColor(value.actual, theme)
  }

  if (!cardColors[_camelCase(metric)]) {
    return cardColors.default
  }

  return _get(cardColors, value ? [_camelCase(metric), value.style] : 'default')
}

export const getForField = (fieldId, fieldsData) =>
  fieldsData && fieldsData.find(d => d.fieldUuid === fieldId)

export const getFieldsLayerData = (
  fields,
  cardColors,
  t,
  metric = null,
  timeframe = null,
  fieldsData = [],
  showAll = true
) => {
  const fieldsPointData = []
  const fieldsPolygonData = []

  const getPoint = (field, value, index) => ({
    id: field.uuid,
    label: generateLabel(field, value, metric, timeframe, t, true),
    centroid: field.centroid,
    color: getColorForMetric(metric, timeframe, value, cardColors),
    zIndex: index + 100
  })

  const getPolygon = (field, value) => ({
    id: field.uuid,
    label: generateLabel(field, value, metric, timeframe, t),
    poly: field.poly,
    style: {
      fill: getPolygonColor(metric, timeframe, value, cardColors)
    }
  })

  fields.forEach((field, index) => {
    const { value } =
      (fieldsPolygonData && getForField(field.uuid, fieldsData)) || {}

    // hide fields that don't have data is showAll is true
    if (!showAll && !_get(value, 'actual')) return null

    fieldsPointData.push(getPoint(field, value, index))
    fieldsPolygonData.push(getPolygon(field, value))
  })

  return { fieldsPointData, fieldsPolygonData }
}

export const driftWatchColors = {
  B: {
    plain: '#635F00',
    border: '#C5BD00'
  },
  FV: {
    plain: '#6B0C0F',
    border: '#D7191D'
  },
  GS: {
    plain: '#16415D',
    border: '#2C83BA'
  },
  COO: {
    plain: '#1F5110',
    border: '#3EA120'
  },
  CPO: {
    plain: '#684C00',
    border: '#D09900'
  },
  UKN: {
    plain: '#3C3C3C',
    border: '#797979'
  }
}

export const to3857 = coordinates =>
  transform(coordinates, 'EPSG:4326', 'EPSG:3857')

export const to4326 = coordinates =>
  transform(coordinates, 'EPSG:3857', 'EPSG:4326')

export const geometryTo3857 = geometry =>
  geometry.clone().transform('EPSG:4326', 'EPSG:3857')

export const geometryTo4326 = geometry =>
  geometry.clone().transform('EPSG:3857', 'EPSG:4326')

export const geometryToGeoJSON = geometry =>
  new GeoJSON().writeFeatureObject(geometry)

export const validateLonLat = ([lon, lat]) => {
  return Math.abs(lon) <= 180 && Math.abs(lat) <= 90
}
export const findAllValidCoordinates = arrayOfCoordinates =>
  arrayOfCoordinates.reduce((acc, curr) => {
    if (isArray(curr)) {
      return validateLonLat(curr)
        ? [...acc, curr]
        : [...acc, ...findAllValidCoordinates(curr)]
    }
    return acc
  }, [])

export const coordinatesAreValid = arr =>
  isArray(arr) &&
  arr.every(elem => {
    if (isArray(elem)) {
      if (elem.length === 2 && isNumber(elem[0]) && isNumber(elem[1])) {
        return validateLonLat(elem)
      }
      return coordinatesAreValid(elem)
    }
    return false
  })
