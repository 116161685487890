import React, { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import propTypes from 'propTypes'
import makeStyles from '@nutrien/uet-react/styles/makeStyles'
import Box from '@nutrien/uet-react/Box'
import Tooltip from '@nutrien/uet-react/Tooltip'
import ClickAwayListener from '@nutrien/uet-react/ClickAwayListener'
import useTheme from '@nutrien/uet-react/styles/useTheme'
import useAuthorshipSession from 'hooks/useAuthorshipSession'
import { AuthContext } from 'components/Auth/authentication-context'

const useClasses = makeStyles(theme => ({
  overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1000,
    cursor: 'default',
    opacity: 0.5
  },
  tooltip: {
    background: theme.palette.common.white,
    color: theme.palette.common.black,
    boxShadow: '0px 0px 7px rgba(0, 0, 0, 0.25)',
    borderRadius: 4
  }
}))
export const AuthorshipPermissionDisabledOverlay = ({
  tooltipMessage,
  alwaysDisableInAuthorship,
  overlayBackgroundColor
}) => {
  const classes = useClasses()
  const theme = useTheme()

  const { t } = useTranslation()
  const { user } = useContext(AuthContext)
  const { authorshipSessionActive } = useAuthorshipSession()
  const [open, setOpen] = useState(false)
  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
  }

  if (
    authorshipSessionActive &&
    (alwaysDisableInAuthorship ||
      (user?.authorshipPermissions?.canViewGrowerData &&
        !user?.authorshipPermissions?.canEditGrowerData))
  ) {
    return (
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Box>
          <Tooltip
            PopperProps={{
              disablePortal: true
            }}
            placement="top"
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            arrow={false}
            classes={{ tooltip: classes.tooltip }}
            title={t(tooltipMessage)}>
            <Box
              className={classes.overlay}
              onClick={handleTooltipOpen}
              style={{
                backgroundColor:
                  overlayBackgroundColor || theme.palette.common.white
              }}></Box>
          </Tooltip>
        </Box>
      </ClickAwayListener>
    )
  } else return <></>
}

AuthorshipPermissionDisabledOverlay.propTypes = {
  tooltipMessage: propTypes.string,
  alwaysDisableInAuthorship: propTypes.bool
}

AuthorshipPermissionDisabledOverlay.defaultProps = {
  tooltipMessage: '',
  alwaysDisableInAuthorship: false
}

export default AuthorshipPermissionDisabledOverlay
