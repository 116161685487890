import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import useMediaQuery from '@nutrien/uet-react/useMediaQuery'
import Tabs from '@nutrien/uet-react/Tabs'
import Tab from '@nutrien/uet-react/Tab'

import th from 'theme/helpers'

const StyledTabs = styled(Tabs)`
  border-bottom: solid 1px ${th.palette('grey.200')};
  min-height: 37px;
`

const StyledTab = styled(Tab)`
  padding-left: 0;
  padding-right: 0;
  margin-right: ${th.spacing(2)};
  min-height: 37px;
  color: ${th.palette('grey.600')};
  min-width: inherit !important; /* !important required to override uet important */

  &.Mui-selected {
    color: ${th.palette('grey.850')};
  }

  .MuiTab-wrapper {
    align-items: flex-start;
    /* Pseudo-element prevents bold text from expanding the tabs */
    &::after {
      content: '${({ label }) => label}';
      display: block;
      line-height: 0;
      color: transparent;
      font-weight: 600;
      color: 'transparent'
    }
  }
`

function InPageTabs({ tabs, selected, onChange, width }) {
  const isXs = useMediaQuery(theme => theme.breakpoints.down('xs'))
  const orientation = isXs ? 'vertical' : 'horizontal'

  return (
    <StyledTabs value={selected} onChange={onChange} orientation={orientation}>
      {tabs.map((tab, index) => (
        <StyledTab key={index} data-testid={tab.testId} {...tab} />
      ))}
    </StyledTabs>
  )
}

InPageTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    }).isRequired
  ),
  selected: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
}

export default InPageTabs
