export const countriesList = t => {
  return [
    { value: 'AR', label: t('ar'), adjective: t('Argentinian') },
    { value: 'BR', label: t('br'), adjective: t('Brazilian') },
    { value: 'CA', label: t('ca'), adjective: t('Canadian') },
    { value: 'MX', label: t('mx'), adjective: t('Mexican') },
    { value: 'ZA', label: t('za'), adjective: t('South African') },
    { value: 'US', label: t('us'), adjective: t('American') },
    { value: 'UY', label: t('uy'), adjective: t('Uruguayan') }
  ]
}
