import React from 'react'

export const XLSIcon = ({ height = 24, width = 24 }) => (
  <svg
    style={{ verticalAlign: 'middle' }}
    width={width}
    height={height}
    viewBox="0 0 364 364"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M291.2 45.5H86.45C60.6667 45.5 45.5 60.6667 45.5 86.45V291.2C45.5 303.333 60.6667 318.5 86.45 318.5H291.2C303.333 318.5 318.5 303.333 318.5 291.2V86.45C318.5 60.6667 303.333 45.5 291.2 45.5Z"
      fill="#666666"
    />
    <path
      d="M233.886 219.248C238.675 222.37 246.658 223.931 253.044 223.931C262.623 223.931 265.816 219.248 265.816 214.564C265.816 208.319 261.026 205.196 251.447 198.951C233.886 189.583 230.693 177.093 230.693 169.287C230.693 152.113 241.868 136.5 267.412 136.5C275.395 136.5 281.781 138.061 284.974 139.623L281.781 159.919C278.588 158.358 273.798 156.797 267.412 156.797C259.43 156.797 254.64 161.48 254.64 166.164C254.64 170.848 256.237 173.971 269.009 180.216C283.377 188.022 288.167 198.951 288.167 209.88C288.167 230.176 272.202 242.667 249.851 242.667C240.272 242.667 232.289 241.105 227.5 237.983L233.886 219.248Z"
      fill="white"
    />
    <path
      d="M166.833 242.667V136.5H186.535V222.642H212.333V242.667H166.833Z"
      fill="white"
    />
    <path
      d="M97.4997 136.5H75.833L102.916 186.461L75.833 242.667H97.4997L113.75 205.196L130 242.667H151.666L124.583 186.461L151.666 136.5H130L113.75 167.725L97.4997 136.5Z"
      fill="white"
    />
  </svg>
)
