import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { ENTITY_TYPE_FIELDS } from '@nutrien/farm-tree-navigation/dist/constants/entities'
import Snackbar from '@nutrien/uet-react/Snackbar'
import useCreateUrl from 'hooks/useCreateUrl'
import useLoadFarmTree from 'hooks/useLoadFarmTree'
import withRequest from 'HOCs/withRequest'

import MapEntry from '../MapEntry'
import FieldDetailsDialog from '../FieldDetailsDialog'
import FieldSuccessDialog from '../FieldSuccessDialog'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
`

const MapEntryWizard = ({
  selectedEntity,
  onDrawStart,
  onDrawEnd,
  setRequestError,
  setRequestPending,
  onAddFieldSuccess
}) => {
  const [fieldInfo, setFieldInfo] = useState(null)
  const [fieldUuid, setFieldUuid] = useState(null)
  const [snackbarData, setSnackBarData] = useState(null)

  const history = useHistory()
  const createUrl = useCreateUrl()
  const [, loadFarmTree] = useLoadFarmTree()

  const handleSaveSuccess = async (fieldUuid, savedData) => {
    if (onAddFieldSuccess) {
      onAddFieldSuccess(savedData)
    } else {
      setRequestPending(true)
      await loadFarmTree()
      setRequestPending(false)

      setSnackBarData(savedData)
    }
    setFieldUuid(fieldUuid)
  }

  const handleAddMore = useCallback(() => {
    setFieldInfo(null)
    setFieldUuid(null)
  }, [])

  const handleDone = useCallback(
    () =>
      history.push(
        createUrl(
          {
            farmItemLevel: ENTITY_TYPE_FIELDS,
            farmItemId: fieldUuid,
            page: 'field-story'
          },
          { clearPageParams: true }
        )
      ),
    [createUrl, fieldUuid, history]
  )

  const handleCreateCropSeason = useCallback(
    () =>
      history.push(
        createUrl(
          {
            farmItemLevel: ENTITY_TYPE_FIELDS,
            farmItemId: fieldUuid,
            page: 'field-story/new-season'
          },
          { clearPageParams: true }
        )
      ),
    [createUrl, fieldUuid, history]
  )

  const handleCloseFieldsDetails = useCallback(() => setFieldInfo(null), [])

  return (
    <Wrapper>
      <MapEntry
        entity={fieldInfo}
        onSelect={setFieldInfo}
        selectedEntity={selectedEntity}
        onDrawStart={onDrawStart}
        onDrawEnd={onDrawEnd}
      />
      {fieldInfo && !fieldUuid && (
        <FieldDetailsDialog
          entity={fieldInfo}
          onClose={handleCloseFieldsDetails}
          onSuccess={handleSaveSuccess}
          selectedEntity={selectedEntity}
          setRequestError={setRequestError}
          setRequestPending={setRequestPending}
        />
      )}
      {fieldUuid && (
        <FieldSuccessDialog
          onAddMore={handleAddMore}
          onDone={handleDone}
          croppingSeason={handleCreateCropSeason}
        />
      )}
      <Snackbar
        open={!!snackbarData?.fieldName}
        style={{ top: 92, right: 8 }}
        onClose={() => setSnackBarData(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        message={
          <div style={{ fontSize: 16 }}>
            <span>{`Success! ${
              snackbarData?.fieldName ? snackbarData.fieldName : ''
            } has been added to Agrible.`}</span>
          </div>
        }
      />
    </Wrapper>
  )
}

MapEntryWizard.propTypes = {
  selectedEntity: PropTypes.object,
  onDrawStart: PropTypes.func.isRequired,
  onDrawEnd: PropTypes.func.isRequired,
  setRequestError: PropTypes.func.isRequired,
  setRequestPending: PropTypes.func.isRequired,
  onAddFieldSuccess: PropTypes.func
}

export default withRequest(MapEntryWizard)
