import { useContext } from 'react'
import _get from 'lodash/get'

import { AuthContext } from 'components/Auth/authentication-context'

/**
 * @returns {boolean} true when current user has access to community features
 */
export default function useHasCommunityAccess() {
  const { user } = useContext(AuthContext)
  return _get(user, 'communityAdminAccess', false)
}
