import { ENTITY_TYPE_FARMS } from '@nutrien/farm-tree-navigation/dist/constants/entities'

import { findRoots, pruneGroups, splitOrgs } from 'helpers'

export const UPDATE_BUILD_CROP_ROTATIONS_STATUS =
  'UPDATE_BUILD_CROP_ROTATIONS_STATUS'
export function updateBuildCropRotationsStatus(enrolledFieldsInCampaign) {
  return {
    type: UPDATE_BUILD_CROP_ROTATIONS_STATUS,
    payload: enrolledFieldsInCampaign
  }
}

export const UPDATE_ENTITY_BASELINE_STATUS = 'UPDATE_ENTITY_STATUS'
export function updateEntityBaselineStatus(info) {
  return {
    type: UPDATE_ENTITY_BASELINE_STATUS,
    payload: info
  }
}

export const REFETCH_STEPPER_FARM_TREE_COMPLETE =
  'REFETCH_STEPPER_FARM_TREE_COMPLETE'
export function refetchStepperFarmTreeComplete(payload) {
  return {
    type: REFETCH_STEPPER_FARM_TREE_COMPLETE,
    payload
  }
}

export const RENAME_ENTITY = 'RENAME_ENTITY'
export function renameEntity({ uuid, name }) {
  return {
    type: RENAME_ENTITY,
    payload: {
      uuid,
      name
    }
  }
}

export const MOVE_ENTITY = 'MOVE_ENTITY'
export function moveEntity({ uuid, parentUUID }) {
  return {
    type: MOVE_ENTITY,
    payload: {
      uuid,
      parentUUID
    }
  }
}

export const DELETE_ENTITY = 'DELETE_ENTITY'
export function deleteEntity({ uuid, manuallySetSelectedField }) {
  return {
    type: DELETE_ENTITY,
    payload: {
      uuid,
      manuallySetSelectedField
    }
  }
}

export const FARM_TREE_IS_LOADING = 'FARM_TREE_IS_LOADING'
export function setFarmTreeIsLoading() {
  return {
    type: FARM_TREE_IS_LOADING
  }
}

export const LOAD_BUILD_ROTATIONS_COMPLETE = 'LOAD_BUILD_ROTATIONS_COMPLETE'
export function loadBuildRotationsComplete(payload) {
  return {
    type: LOAD_BUILD_ROTATIONS_COMPLETE,
    payload
  }
}

export const LOAD_LOG_FIELD_ACTIVITY_COMPLETE =
  'LOAD_LOG_FIELD_ACTIVITY_COMPLETE'
export function loadLogFieldActivityComplete(payload) {
  return {
    type: LOAD_LOG_FIELD_ACTIVITY_COMPLETE,
    payload
  }
}

export const LOAD_DOCUMENT_BASELINE_COMPLETE = 'LOAD_DOCUMENT_BASELINE_COMPLETE'
export function loadDocumentBaselineComplete(payload) {
  return {
    type: LOAD_DOCUMENT_BASELINE_COMPLETE,
    payload
  }
}

export const GET_BUILD_ROTATIONS_FARM_TREE_COMPLETE =
  'GET_BUILD_ROTATIONS_FARM_TREE_COMPLETE'
export function getBuildRotationsFarmTreeComplete(payload) {
  return {
    type: GET_BUILD_ROTATIONS_FARM_TREE_COMPLETE,
    payload
  }
}

export const LOAD_CREATE_PLAN_COMPLETE = 'LOAD_CREATE_PLAN_COMPLETE'
export function loadCreatePlanComplete(payload) {
  return {
    type: LOAD_CREATE_PLAN_COMPLETE,
    payload
  }
}

export const GET_ENROLLED_FIELDS_NAVIGATION_TREE_COMPLETE =
  'GET_ENROLLED_FIELDS_NAVIGATION_TREE_COMPLETE'
export function getEnrolledFieldsNavigationTreeComplete(payload) {
  return {
    type: GET_ENROLLED_FIELDS_NAVIGATION_TREE_COMPLETE,
    payload
  }
}

export const SET_SELECTED_ID_SIMPLE = 'SET_SELECTED_ID_SIMPLE'
export function setSelectedIdSimple(payload) {
  return {
    type: SET_SELECTED_ID_SIMPLE,
    payload
  }
}

const ADD_FARM_QUERY = `
  mutation AddFarm($name: String!, $parentUuid: String!, $profileUuid: String!) {
    addTreeNode(
      type: farms
      parentUuid: $parentUuid
      name: $name
      profileUuid: $profileUuid
    )
  }
`

export const addFarm = async (client, fieldData, profileUuid) => {
  const { data = {} } = await client.request({
    query: ADD_FARM_QUERY,
    variables: {
      parentUuid: fieldData.groupUuid,
      name: fieldData.farmName,
      profileUuid: profileUuid
    }
  })

  return data.addTreeNode
}

const ADD_FIELD_QUERY = `
  mutation AddField($name: String!, $parentUuid: String!, $poly: Geometry!, $planted: [PlantedCrops], $landOwner: LandRightsOwner, $profileUuid: String!) {
    addTreeNode(
      type: fields
      landOwner: $landOwner
      parentUuid: $parentUuid
      profileUuid: $profileUuid
      name: $name
      poly: $poly
      planted: $planted
    )
  }
`

export const addField = (client, fieldData, profileUuid) => async dispatch => {
  let farmUuid = fieldData.farmUuid

  // Add farm first
  if (!farmUuid && fieldData.farmName) {
    farmUuid = await addFarm(
      client,
      {
        farmName: fieldData.farmName,
        groupUuid: fieldData.groupUuid
      },
      profileUuid
    )
  }

  // Shouldn't proceed if still no farmUuid
  if (!farmUuid) return

  const { data = {}, error } = await client.request({
    query: ADD_FIELD_QUERY,
    variables: {
      parentUuid: farmUuid,
      landOwner: fieldData.landOwner,
      name: fieldData.fieldName,
      poly: fieldData.poly,
      planted: fieldData.planted,
      profileUuid: profileUuid
    }
  })

  const fieldUuid = data.addTreeNode

  if (!fieldUuid || error) {
    await dispatch(
      deleteTreeNode(client, ENTITY_TYPE_FARMS, farmUuid, profileUuid)
    )
    return
  }

  return fieldUuid
}

const DELETE_TREE_NODE = `
  mutation NavigationTree($type: TreeType!, $uuid: String!, $profileUuid: String!) {
    deleteNode(type: $type, uuid: $uuid, profileUuid: $profileUuid)
  }
`

export const deleteTreeNode = (
  client,
  type,
  uuid,
  profileUuid
) => async dispatch => {
  const { data = {} } = await client.request({
    query: DELETE_TREE_NODE,
    variables: {
      type: type,
      uuid: uuid,
      profileUuid: profileUuid
    }
  })

  // Deleted
  if (data.deleteNode === true) {
  }
}

export const RELOAD_ENTITIES_SUCCESS = 'RELOAD_ENTITIES_SUCCESS'
export const reloadEntitiesSuccess = payload => ({
  type: RELOAD_ENTITIES_SUCCESS,
  payload
})

export function processTree(tree) {
  const entitiesById = tree.reduce(
    (acc, next) => ({ ...acc, [next.uuid]: next }),
    {}
  )

  // TODO: Move these pre-processing functions into helpers or here, in action
  const orgs = findRoots(entitiesById)
  return pruneGroups(splitOrgs(orgs, entitiesById))
}
