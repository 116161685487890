import React, { useState, useCallback, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import makeStyles from '@nutrien/uet-react/styles/makeStyles'
import InfoIcon from '@nutrien/uet-react/icons/Info'
import { useTheme } from '@nutrien/uet-react/styles'
import { Box, Button, Divider, Paper, Typography } from '@nutrien/uet-react'
import Chip from '@nutrien/uet-react/Chip'
import Alert from '@material-ui/lab/Alert'
import { useMutation } from 'graphql-hooks'
import { AuthConsumer } from 'components/Auth/authentication-context'
import SvgIcon from 'components/SvgIcon'

const RESEND_VERIFICATION_MUTATION = `
    mutation ResendVerification($email:String!){
      resendVerification(email: $email)
    }
`

const useReloginPageStyles = makeStyles(theme => ({
  pageBox: {
    height: '100%',
    backgroundImage: `url('https://static.nutrienagsolutions.com/backgrounds/farmers_pointing.jpg')`,
    backgroundPositionX: '-1100px',
    transition: 'background-position 1s',
    overflow: 'hidden',
    [theme.breakpoints.up('lg')]: {
      backgroundPositionX: 0,
      backgroundSize: 'cover'
    }
  },
  alertInfo: {
    backgroundColor: theme.palette.info.secondary,
    borderRadius: 0,
    padding: theme.spacing(0.75, 3)
  },
  boxItem: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  buttonsBox: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: 5
  },
  link: {
    fontFamily: 'Open Sans',
    fontSize: 16,
    fontWeight: '400',
    color: theme.palette.primary.main,
    lineHeight: '24px',
    padding: 0
  },
  header: {
    fontFamily: 'Open Sans',
    fontSize: 18,
    fontWeight: '600',
    lineHeight: '28px'
  },
  typography: {
    fontFamily: 'Open Sans',
    fontSize: 16,
    fontWeight: '400',
    lineHeight: '24px'
  },
  mainContent: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(18),
    minHeight: '100%',
    boxSizing: 'border-box',

    [theme.breakpoints.down('xs')]: {
      alignItems: 'initial'
    }
  },
  paperRoot: {
    [theme.breakpoints.down('xs')]: {
      height: '100%'
    }
  },
  mainBox: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    rowGap: 24
  },
  chip: {
    marginRight: theme.spacing(1),
    padding: theme.spacing(0, 0.5, 0.1),
    marginTop: '4px',
    height: '18px',
    color: 'white',
    fontFamily: 'Open Sans',
    fontSize: 12,
    fontWeight: '600',
    lineHeight: '16px',
    backgroundColor: theme.palette.primary.main
  },
  chipDiv: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '10px'
  }
}))

const Relogin = () => {
  const reloginpageStyles = useReloginPageStyles()
  const { t } = useTranslation()
  const { palette } = useTheme()

  const location = useLocation()
  const history = useHistory()
  const [showChip, setShowChip] = useState(false)
  const [resendVerification] = useMutation(RESEND_VERIFICATION_MUTATION)

  const email = location.state?.email

  useEffect(() => {
    // calling the page directly from the browser
    if (!email) history.replace('/legacy-login')
  }, [history, email])

  const handleContactUsOnclick = async logout => {
    await logout()
    history.replace('/legacy-login')
    history.push('/contact-us')
  }

  const handleGoToAuth0 = async logout => {
    await logout()
  }

  const [isResendLoading, setIsResendLoading] = useState(false)
  const handleResendVerification = useCallback(async () => {
    try {
      setIsResendLoading(true)
      const { error } = await resendVerification({
        variables: {
          email: email
        }
      })
      if (!error) setShowChip(true)
      else throw Error('an_unexpected_error_occurred')
    } catch (e) {
    } finally {
      setIsResendLoading(false)
    }
    const timeoutHandle = setTimeout(() => {
      setShowChip(false)
    }, 5000)
    return () => clearTimeout(timeoutHandle)
  }, [email, resendVerification])

  return (
    <AuthConsumer>
      {({ logout }) => (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          className={reloginpageStyles.pageBox}>
          <Box flex={0} width="100%">
            <Alert
              severity="info"
              classes={{ standardInfo: reloginpageStyles.alertInfo }}
              icon={<SvgIcon color="info.text" component={InfoIcon} />}
              action={
                <Button
                  size="small"
                  href="https://nutrienagsolutions.com/agrible">
                  <Box mr={4} color={palette?.info?.text} fontSize="16px">
                    {t('view')}
                  </Box>
                </Button>
              }>
              <Box color={palette?.info?.text} fontSize="16px">
                {t('authentication_page_banner_msg')}
              </Box>
            </Alert>
          </Box>

          <Box className={reloginpageStyles.mainContent}>
            <Box maxWidth="391px">
              <Paper
                classes={{ root: reloginpageStyles.paperRoot }}
                elevation={2}>
                <Box padding={3} className={reloginpageStyles.mainBox}>
                  <Typography className={reloginpageStyles.header}>
                    {t('Almost done, check your email')}
                  </Typography>

                  <Typography className={reloginpageStyles.typography}>
                    {t(
                      'Click on the verification link in the email we sent you.'
                    )}
                  </Typography>
                  <div className={reloginpageStyles.boxItem}>
                    <Typography className={reloginpageStyles.typography}>
                      {t(`Didn't get the email?`)}
                    </Typography>
                    <div className={reloginpageStyles.chipDiv}>
                      <Button
                        disabled={isResendLoading || showChip}
                        className={reloginpageStyles.link}
                        onClick={() => handleResendVerification()}>
                        {t('Resend')}
                      </Button>
                      {showChip ? (
                        <Chip
                          className={reloginpageStyles.chip}
                          label={t('Sent')}
                          size={'small'}
                        />
                      ) : null}
                    </div>
                  </div>
                </Box>
                <Divider />

                <Box padding={2} height="100%">
                  <div
                    className={reloginpageStyles.buttonsBox}
                    style={{ justifyContent: 'end' }}>
                    <Button
                      variant="outlined"
                      onClick={() => handleContactUsOnclick(logout)}>
                      {t('Contact us')}
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => handleGoToAuth0(logout)}>
                      {t('login')}
                    </Button>
                  </div>
                </Box>
              </Paper>
            </Box>
          </Box>
        </Box>
      )}
    </AuthConsumer>
  )
}
export default Relogin
