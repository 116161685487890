const initialStore = () => ({
  farmTree: {
    activeId: null,
    entities: {},
    cropRotations: null,
    allCroppingSeasons: [],
    croppingSeasonsForCleanup: null,
    permissions: null,
    error: null,
    isLoading: false,
    isEditing: false,
    isEditDialogOpen: false,
    isOpen: false,
    searchString: '',
    // URL sync depends on this to be falsy
    selectedId: null,
    // Allows us to catch internal FarmTree events at component level
    previousSelectedId: null,
    openedIds: []
  }
})

export default initialStore
