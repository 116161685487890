import React from 'react'
import makeStyles from '@nutrien/uet-react/styles/makeStyles'
import Tooltip from '@nutrien/uet-react/Tooltip'
import PropTypes from 'prop-types'
import Chip from '@nutrien/uet-react/Chip'

const useStyles = makeStyles(theme => ({
  chip: {
    marginRight: theme.spacing(1),
    padding: theme.spacing(0, 0.5, 0.1),
    fontSize: '12px',
    fontWeight: '600',
    height: '18px',
    color: props => props?.textColor || theme.palette.grey.A900
  },
  tooltip: {
    maxWidth: 'none'
  }
}))

const BonsaiStatusChip = ({ size, tooltipText, textColor, ...props }) => {
  const classes = useStyles({ textColor })
  const chip = (
    <Chip
      className={classes.chip}
      size={size}
      data-testid="bonsaiStatusChip"
      {...props}
    />
  )

  if (tooltipText) {
    return (
      <Tooltip
        classes={{ tooltip: classes.tooltip }}
        placement="bottom"
        title={tooltipText}>
        {chip}
      </Tooltip>
    )
  }

  return chip
}

BonsaiStatusChip.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  disabled: PropTypes.bool,
  color: PropTypes.string,
  tooltipText: PropTypes.string,
  textColor: PropTypes.string
}

BonsaiStatusChip.defaultProps = {
  disabled: false,
  size: 'small'
}

export default BonsaiStatusChip
