import React from 'react'
import { withTranslation } from 'react-i18next'
import Typography from '@nutrien/uet-react/Typography'
import Box from '@nutrien/uet-react/Box'

const isLocalStorageAvailable = () => {
  const storageTest = '__storage_test__'
  try {
    window.localStorage.setItem(storageTest, storageTest)
    window.localStorage.removeItem(storageTest)
    return true
  } catch (e) {
    return false
  }
}

class ChunkErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, reloading: false }
  }

  static getDerivedStateFromError(error) {
    if (
      error.name === 'ChunkLoadError' &&
      isLocalStorageAvailable() &&
      (!window.localStorage.reloadTimestamp ||
        Number(window.localStorage.reloadTimestamp) < Date.now() - 60 * 1000)
    ) {
      window.localStorage.reloadTimestamp = Date.now()
      window.location.reload(true)
      return { reloading: true }
    }
    return { hasError: error.name === 'ChunkLoadError' }
  }

  reloadPage(e) {
    e.preventDefault()
    window.location.reload(true)
  }

  render() {
    const { t } = this.props
    return this.state.reloading ? (
      <Typography>{t('please_wait')}</Typography>
    ) : this.state.hasError ? (
      <Box component={Typography} p={2} variant="h1">
        {t('agrible_updated')}{' '}
        <a href="/" onClick={this.reloadPage}>
          {t('reload')}
        </a>
      </Box>
    ) : (
      this.props.children
    )
  }
}

export default withTranslation()(ChunkErrorBoundary)
