import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Typography from '@nutrien/uet-react/Typography'
import Switch from '@nutrien/uet-react/Switch'
import Grid from '@nutrien/uet-react/Grid'
import Divider from '@nutrien/uet-react/Divider'
import RadioGroup from '@nutrien/uet-react/RadioGroup'
import Radio from '@nutrien/uet-react/Radio'
import FormControlLabel from '@nutrien/uet-react/FormControlLabel'
import SvgIcon from '@nutrien/uet-react/SvgIcon'
import EmailIcon from '@nutrien/uet-react/icons/Email'
import RegionIcon from '@nutrien/uet-react/icons/Public'
import { useMutation } from 'graphql-hooks'

import th from 'theme/helpers'
import useInput from 'hooks/useInput'
import useCheckbox from 'hooks/useCheckbox'
import { USER_OBJECT } from 'components/auth-providers/AgribleBackend/AgribleBackend'

const SectionRow = styled(Grid)`
  margin-bottom: ${th.spacing(3)};
`

const StyledIcon = styled(SvgIcon)`
  color: ${th.palette('grey.600')};
  margin-right: ${th.spacing(1)};
  vertical-align: bottom;
`

const StyledDivider = styled(Divider)`
  margin: ${th.spacing(3, 0)};
`

const StyledTypography = styled(Typography)`
  margin-bottom: ${th.spacing(1)};
`

const USER_PREFS_MUTATION = `
mutation EditUser(
  $dailyEmailReport: Boolean!
  $unit: Units!
  $language: String!
) {
  editUser(
    profile: {
      dailyEmailReport: $dailyEmailReport
      unit: $unit
      language: $language
    }
  ) ${USER_OBJECT}
}
`

const languages = ['en-us', 'pt-br', 'es']

const UserPrefs = ({
  profile,
  onChange,
  setRequestPending,
  setRequestError
}) => {
  const { t } = useTranslation()
  const [saveUserPrefs] = useMutation(USER_PREFS_MUTATION)
  const unit = useInput(profile.unit)
  const dailyEmailReport = useCheckbox(profile.dailyEmailReport)
  const language = useInput(profile.language)

  const updateUserPrefs = useCallback(
    async changed => {
      setRequestPending(true)
      const { data, error } = await saveUserPrefs({
        variables: {
          dailyEmailReport: dailyEmailReport.checked,
          unit: unit.value,
          language: language.value,
          ...changed
        }
      })

      setRequestPending(false)

      if (error) return setRequestError(t('api_error_title'))

      if (data && data.editUser) onChange(data.editUser)
    },
    [
      setRequestPending,
      saveUserPrefs,
      dailyEmailReport.checked,
      unit.value,
      language.value,
      setRequestError,
      t,
      onChange
    ]
  )

  dailyEmailReport.watch(val => updateUserPrefs({ dailyEmailReport: val }))
  unit.watch(val => updateUserPrefs({ unit: val }))
  language.watch(val => updateUserPrefs({ language: val }))

  return (
    <React.Fragment>
      <SectionRow container alignItems="center" justify="space-between">
        <Typography variant="h3">
          <StyledIcon component={EmailIcon} />
          {t('email_preferences')}
        </Typography>
      </SectionRow>

      <StyledTypography variant="body1">
        {t('email_preferences_text')}

        <Switch
          {...dailyEmailReport.bind}
          color="primary"
          inputProps={{
            'aria-label': t('email_preferences'),
            'data-testid': 'dailyEmailReport'
          }}
        />
      </StyledTypography>

      <StyledDivider />

      <SectionRow container alignItems="center" justify="space-between">
        <Typography variant="h3">
          <StyledIcon component={RegionIcon} />
          {t('Region')}
        </Typography>
      </SectionRow>

      <StyledTypography variant="h5">{t('Units')}</StyledTypography>
      <RadioGroup aria-label="unit" name="unit" {...unit.bind}>
        <FormControlLabel
          value="Imperial"
          control={<Radio inputProps={{ 'data-testid': 'unit-imperial' }} />}
          label={t('US')}
        />
        <FormControlLabel
          value="Metric"
          control={<Radio inputProps={{ 'data-testid': 'unit-metric' }} />}
          label={t('Metric')}
        />
      </RadioGroup>

      <br />

      <StyledTypography variant="h5">{t('language')}</StyledTypography>
      <RadioGroup aria-label="language" name="language" {...language.bind}>
        {languages.map(lang => (
          <FormControlLabel
            key={lang}
            value={lang}
            control={
              <Radio inputProps={{ 'data-testid': `language-${lang}` }} />
            }
            label={t(lang)}
          />
        ))}
      </RadioGroup>
    </React.Fragment>
  )
}

UserPrefs.propTypes = {
  profile: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  setRequestPending: PropTypes.func.isRequired,
  setRequestError: PropTypes.func.isRequired
}

export default React.memo(UserPrefs)
