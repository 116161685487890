import React from 'react'
import { func, string } from 'prop-types'
import { useTranslation } from 'react-i18next'
import TextField from '@material-ui/core/TextField'
import EditSection from '../EditSection'

const RenameSection = ({ name, setName, type }) => {
  const { t } = useTranslation()

  return (
    <EditSection name={`${type} ${t('name')}`}>
      <TextField
        variant="outlined"
        defaultValue={name}
        onChange={({ target }) => setName(target.value)}
      />
    </EditSection>
  )
}

RenameSection.propTypes = {
  name: string,
  type: string,
  setName: func
}

export default RenameSection
