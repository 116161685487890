import React, { Suspense, useEffect } from 'react'
import Box from '@nutrien/uet-react/Box'
import Footer from 'components/Footer'
import Loading from 'components/Loading'
import LinkHeader from 'components/Header/LinkHeader'
import usePendo from 'hooks/usePendo'
import { gqlHooksCache } from '../../../graphql'

const BasicLayout = ({ children, linkHeaderProps, withFooter }) => {
  const { initialize: initializePendo } = usePendo()

  useEffect(() => {
    initializePendo()
    gqlHooksCache.clear()

    // disabling this rule since we only want this to run on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box
      position="fixed"
      top={0}
      bottom={0}
      left={0}
      right={0}
      overflow="hidden"
      center="xy"
      flexDirection="column">
      <LinkHeader
        tabType="community"
        {...linkHeaderProps}
        position={'static'}
      />
      <Suspense fallback={<Loading />}>
        {children}

        {withFooter && <Footer />}
      </Suspense>
    </Box>
  )
}

export default BasicLayout
