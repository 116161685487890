/**
 * This SimplePopover component is just the MUI component re-exported
 * so we can save on the popover's config code. One addition is the
 * useImperativeHandle which allows the parent component access to the
 * click handler that opens the popover so the handler can be placed
 * on whatever clickable element in the parent.
 *
 * Update props in this component to make its configuration
 * more dynamic.
 */
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import PropTypes from 'propTypes'
import Popover from '@nutrien/uet-react/Popover'

const SimplePopover = forwardRef(({ children }, ref) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = event => {
    // Open handler
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  useImperativeHandle(ref, () => {
    // Open handler is passed up to the parent
    // so the handler can be applied to a clickable element
    return { handleClick }
  })

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}>
      {children}
    </Popover>
  )
})

export default SimplePopover

SimplePopover.propTypes = {
  children: PropTypes.node,
  ref: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.object })
  ])
}
