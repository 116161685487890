import React, { useState, useMemo, useCallback } from 'react'
import { Formik, Field, ErrorMessage } from 'formik'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { Link, Redirect } from 'react-router-dom'
import makeStyles from '@nutrien/uet-react/styles/makeStyles'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import TextField from '@nutrien/uet-react/TextField'
import Notification from '@nutrien/uet-react/Notification'
import Button from '@nutrien/uet-react/Button'
import CircularProgress from '@nutrien/uet-react/CircularProgress'
import InputAdornment from '@nutrien/uet-react/InputAdornment'
import MailIcon from '@nutrien/uet-react/icons/Mail'
import useFlags from 'hooks/useFlags'
import useDashboardLink from 'hooks/useDashboardLink'
import usePendo from 'hooks/usePendo'
import useQueryParams from 'hooks/useQueryParams'
import SvgIcon from 'components/SvgIcon'
import { LockIcon, NutrienN } from 'components/Icons'
import { useAuthContext } from 'pages/Authentication/Authentication'

const useLoginPageStyles = makeStyles(theme => ({
  rowStyle: {
    margin: '16px 0',
    textAlign: 'center'
  },
  testFieldStyle: {
    marginBottom: theme.spacing(2),
    '& span': {
      width: '100%'
    }
  },
  error: {
    marginTop: theme.spacing(1),
    marginBottom: 8,
    fontFamily: 'Open Sans'
  },
  icon: {
    color: theme.palette.grey[600],
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(-1)
  },
  loginButton: {
    borderRadius: 4,
    padding: '8px 16px',
    width: '100%'
  },
  resetPassword: {
    fontFamily: 'Open Sans',

    fontWeight: 'normal',
    fontSize: 12,
    textAlign: 'center',
    color: theme.palette.primary.main
  },
  signupHere: {
    textAlign: 'center',
    padding: '24px 0'
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: 24
  },
  divider: {
    display: 'flex',
    flexDirection: 'row',
    color: theme.palette.grey[600],
    fontFamily: 'Open Sans',
    fontWeight: 'normal',
    fontSize: 12,
    paddingTop: 10,
    paddingBottom: 30,
    '&::before, &::after': {
      content: '""',
      flex: '1 1',
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
      margin: 'auto'
    },
    '&::before': {
      marginRight: 10
    },
    '&::after': {
      marginLeft: 10
    }
  },
  nutrienAgLink: {
    fontFamily: 'Open Sans',
    fontSize: 16,
    fontWeight: '600',
    paddingLeft: 8,
    color: theme.palette.primary.main
  }
}))

const getLoginSchema = t =>
  yup.object().shape({
    username: yup
      .string()
      .email(t('email_error'))
      .required(t('username_is_required')),
    password: yup.string().required(t('password_is_required'))
  })

const translateServerError = (error, t) => {
  const key =
    error === 'Invalid credentials given.'
      ? 'Invalid email address or password'
      : 'an_unexpected_error_occurred'
  return t(key)
}
const Login = ({
  isSSOLoading,
  getSSOLoginRedirectUri,
  redirectToAuth0Signup
}) => {
  const { t } = useTranslation()
  const { redirect } = useQueryParams()
  const loginpageStyles = useLoginPageStyles()
  const [isPasswordShown, setPasswordShown] = useState(false)
  const dashboardLink = useDashboardLink()
  const { initialize: initializePendo } = usePendo()

  const loginSchema = useMemo(() => getLoginSchema(t), [t])

  const handleClickShowPassword = useCallback(() => {
    setPasswordShown(isPasswordShown => !isPasswordShown)
  }, [])

  const handleMouseDownPassword = useCallback(event => {
    event.preventDefault()
  }, [])

  const initialValues = useMemo(() => {
    return {
      username: '',
      password: ''
    }
  }, [])

  const flags = useFlags()

  const handleLoginRedirect = useCallback(() => {
    // initialize pendo before redirecting
    // to ensure that no anonymous users
    // will be logged in authenticated pages.
    initializePendo()

    if (redirect?.length) {
      return redirect
    }

    return dashboardLink
  }, [dashboardLink, initializePendo, redirect])

  const {
    login,
    ssoLogin,
    loginError,
    user,
    isAuthenticated,
    authentication
  } = useAuthContext()
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={false}
      validateOnChange={true}
      validationSchema={loginSchema}
      validateOnMount={false}
      onSubmit={async (values, { setSubmitting, setStatus }) => {
        const { username, password } = values
        setSubmitting(true)
        const user = await login({ username, password })
        if (!user) {
          setSubmitting(false)
        } else {
          setStatus('authenticated')
        }
      }}>
      {({ values, errors, isValid, handleSubmit, isSubmitting }) =>
        user ? (
          // redirect to specified url, or to field forecast (we do not know yet the org uuid)
          <Redirect to={handleLoginRedirect()} />
        ) : (
          <>
            <form noValidate onSubmit={handleSubmit} data-testid="login-form">
              {errors &&
                Object.keys(errors).map(error => (
                  <ErrorMessage
                    key={error}
                    name={error}
                    variant="error"
                    component="div">
                    {msg => (
                      <Notification
                        className={loginpageStyles.error}
                        variant="error">
                        {msg}
                      </Notification>
                    )}
                  </ErrorMessage>
                ))}
              {!!loginError && (
                <Notification className={loginpageStyles.error} variant="error">
                  {translateServerError(loginError, t)}
                  <ErrorMessage name={loginError} component="div" />
                </Notification>
              )}
              <div className={loginpageStyles.rowStyle}>
                <Field
                  as={TextField}
                  className={loginpageStyles.testFieldStyle}
                  placeholder={t('email')}
                  type="text"
                  name="username"
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon
                          color="grey.600"
                          fontSize="small"
                          component={MailIcon}
                        />
                      </InputAdornment>
                    )
                  }}
                />

                <Field
                  as={TextField}
                  className={loginpageStyles.testFieldStyle}
                  placeholder={t('password')}
                  type={isPasswordShown ? 'text' : 'password'}
                  name="password"
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon
                          className={loginpageStyles.SvgIcon}
                          component={LockIcon}
                        />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}>
                        {isPasswordShown ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    )
                  }}
                />
              </div>
              <div className={loginpageStyles.rowStyle}>
                {!isSSOLoading && (
                  <div className={loginpageStyles.rowStyle}>
                    <Link
                      className={loginpageStyles.resetPassword}
                      to={`/legacy-login/resetpassword?email=${values.username}`}
                      data-testid="reset_password">
                      {t('forgot_your_password')}
                    </Link>
                  </div>
                )}
                <div className={loginpageStyles.rowStyle}>
                  {isSSOLoading ||
                  isSubmitting ||
                  (isAuthenticated && !authentication.user) ? (
                    <CircularProgress size={33} />
                  ) : (
                    <Button
                      className={loginpageStyles.loginButton}
                      data-testid="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSSOLoading || isSubmitting || !isValid}
                      type="submit">
                      {t('login')}
                    </Button>
                  )}
                </div>
              </div>
            </form>
            {flags?.ssoEnabled && !isSSOLoading && (
              <div data-testid="SSO_Login">
                <div className={loginpageStyles.signupHere}>
                  <Link
                    to="#"
                    className={loginpageStyles.nutrienAgLink}
                    onClick={e => {
                      e.preventDefault()
                      redirectToAuth0Signup()
                    }}>
                    {t('New to agrible? Sign up here')}
                  </Link>
                </div>
                <div className={loginpageStyles.divider}>{t('OR')}</div>
                <div className={loginpageStyles.wrapper}>
                  <NutrienN />
                  <Link
                    to="#"
                    className={loginpageStyles.nutrienAgLink}
                    onClick={e => {
                      e.preventDefault()
                      ssoLogin({
                        redirectUri: getSSOLoginRedirectUri(redirect)
                      })
                    }}>
                    {t('Log_in_with_Nutrien_credentials')}
                  </Link>
                </div>
              </div>
            )}
          </>
        )
      }
    </Formik>
  )
}

export default Login
