import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'

import rootReducer from './reducers'
import init from './init'

// FarmTree actions sometimes require us to return the promise and then do nothing with it
// e.g. updateEntity does this: return dispatch(action).then(response => () => {}, err => () => {});
const promiseSupport = () => next => action => {
  if (typeof action.then === 'function') {
    action.then(next)
    return action
  }
  return next(action)
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const createPopulatedStore = (initState = init()) => {
  const store = createStore(
    rootReducer,
    initState,
    composeEnhancers(applyMiddleware(thunk, promiseSupport))
  )

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(require('./reducers').default)
    })
  }

  return store
}

export default createPopulatedStore
