export const FIELD_FORECAST_PRODUCT = 'field-forecast'
export const HAIL_EVENTS_PRODUCT = 'hail-alert'
export const WEATHER_STORY_PRODUCT = 'weather-story'
export const FIELD_STORY_PRODUCT = 'field-story-timeline'
export const SUSTAINABILITY_PRODUCT = 'sustainable-sourcing'
export const YIELD_ENGINE_PRODUCT = 'yield'
export const NUTRIENT_ENGINE_PRODUCT = 'nutrient-engine'
export const CRITICAL_PERIODS_PRODUCT = 'critical-periods'
export const TRACTOR_TIME_PRODUCT = 'tractor-time-daily'
export const TRACTOR_TIME_HOURLY_PRODUCT = 'tractor-time-hourly'
export const SPRAY_SMART_PRODUCT = 'spray-smart'
export const BARLEY_QUALITY_ENGINE_PRODUCT = 'barley-quality-engine'
export const TOTALS_PRODUCT = 'my-summary'
export const FARM_TREE_PRODUCT = 'user-field-entry'
export const ORG_MANAGEMENT_PRODUCT = 'manage-organization'
