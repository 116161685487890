import React from 'react'
import { useTranslation } from 'react-i18next'
import Alert from '@material-ui/lab/Alert'
import Box from '@nutrien/uet-react/Box'
import Button from '@nutrien/uet-react/Button'
import InfoIcon from '@nutrien/uet-react/icons/Info'
import { useTheme } from '@nutrien/uet-react/styles'
import makeStyles from '@nutrien/uet-react/styles/makeStyles'
import SvgIcon from 'components/SvgIcon'

const useSameAgribleBannerStyles = makeStyles(theme => ({
  alertInfo: {
    backgroundColor: theme.palette.info.secondary,
    borderRadius: 0,
    padding: theme.spacing(0.75, 3)
  }
}))
export function SameAgribleBanner() {
  const { palette } = useTheme()
  const { t } = useTranslation()
  const styles = useSameAgribleBannerStyles()
  return (
    <Box flex={0} width="100%">
      <Alert
        severity="info"
        classes={{
          standardInfo: styles.alertInfo
        }}
        icon={<SvgIcon color="info.text" component={InfoIcon} />}
        action={
          <Button size="small" href="https://nutrienagsolutions.com/agrible">
            <Box mr={4} color={palette?.info?.text} fontSize="16px">
              {t('view')}
            </Box>
          </Button>
        }>
        <Box color={palette?.info?.text} fontSize="16px">
          {t('authentication_page_banner_msg')}
        </Box>
      </Alert>
    </Box>
  )
}
