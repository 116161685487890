import React from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@nutrien/uet-react/Box'
import Tab from '@nutrien/uet-react/Tab'
import Tabs from '@nutrien/uet-react/Tabs'
import TabContext from '@nutrien/uet-lab/TabContext'
import TabPanel from '@nutrien/uet-lab/TabPanel'
import Typography from '@nutrien/uet-react/Typography'
import makeStyles from '@nutrien/uet-react/styles/makeStyles'
import Signup from './components/Signup'
import Login from './components/Login'
import { LoadingAuthForm } from './Authentication'

const useLoginAndSignupStyles = makeStyles(theme => ({
  tabs: {
    color: theme.palette.gray.A400,
    borderBottom: `1px solid ${theme.palette.gray.A400}`,
    ...theme.typography.h5
  },
  tabSelected: {
    color: theme.palette.text.primary
  },
  tabPanel: {
    padding: theme.spacing(0)
  }
}))
export function LoginAndSignupTaps({
  handleTabChange,
  activeTab,
  isSSOLoading,
  getSSOLoginRedirectUri,
  agribleSSOSignup,
  setNutrienUserExists,
  screeningAnswers,
  redirectToAuth0Signup
}) {
  const { t } = useTranslation()
  const styles = useLoginAndSignupStyles()

  return (
    <Box padding={0} flex={1}>
      {agribleSSOSignup ? (
        <Login
          isSSOLoading={isSSOLoading}
          getSSOLoginRedirectUri={getSSOLoginRedirectUri}
          redirectToAuth0Signup={redirectToAuth0Signup}
        />
      ) : (
        <TabContext value={activeTab}>
          <Tabs
            classes={{
              root: styles.tabs
            }}
            onChange={handleTabChange}
            value={activeTab}
            variant="fullWidth"
            indicatorColor="primary">
            <Tab
              classes={{
                selected: styles.tabSelected
              }}
              label={<Typography variant="h5">{t('login')}</Typography>}
              value="login"
            />
            <Tab
              classes={{
                selected: styles.tabSelected
              }}
              label={<Typography variant="h5">{t('signup')}</Typography>}
              value="signup"
            />
          </Tabs>
          <TabPanel
            classes={{
              root: styles.tabPanel
            }}
            value="login">
            <Login
              isSSOLoading={isSSOLoading}
              getSSOLoginRedirectUri={getSSOLoginRedirectUri}
              redirectToAuth0Signup={redirectToAuth0Signup}
            />
          </TabPanel>
          <TabPanel
            classes={{
              root: styles.tabPanel
            }}
            value="signup">
            {agribleSSOSignup ? (
              <LoadingAuthForm></LoadingAuthForm>
            ) : (
              <Signup
                switchtab={handleTabChange}
                handleIsNutrienUser={setNutrienUserExists}
                screeningAnswers={screeningAnswers}
              />
            )}
          </TabPanel>
        </TabContext>
      )}
    </Box>
  )
}
