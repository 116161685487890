import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Typography from '@nutrien/uet-react/Typography'
import Grid from '@nutrien/uet-react/Grid'
import Button from '@nutrien/uet-react/Button'
import SvgIcon from '@nutrien/uet-react/SvgIcon'
import InfoIcon from '@nutrien/uet-react/icons/Info'
import EditIcon from '@nutrien/uet-react/icons/Edit'

import th from 'theme/helpers'
import useToggle from 'hooks/useToggle'

import ChangeInfoDialog from '../ChangeInfoDialog'

const SectionRow = styled(Grid)`
  margin-bottom: ${th.spacing(3)};
`

const StyledIcon = styled(SvgIcon)`
  color: ${th.palette('grey.600')};
  margin-right: ${th.spacing(1)};
  vertical-align: bottom;
`

const StyledTypography = styled(Typography)`
  margin-bottom: ${th.spacing(1)};
`

const PlaceholderTypography = React.memo(({ value, name }) => {
  const { t } = useTranslation()
  const text = value || `${t(name)} (${t('none')})`
  const variant = value ? 'body1' : 'body2'

  return <StyledTypography variant={variant}>{text}</StyledTypography>
})

const UserInfo = ({ user, onChange, setRequestPending, setRequestError }) => {
  const { t } = useTranslation()
  const [showChangeInfo, toggleChangeInfo] = useToggle(false)
  const address = user.profile.address || {}

  const userInfo = useMemo(
    () => ({
      firstName: user.firstName,
      lastName: user.lastName,
      addressName: address.name ?? '',
      addressLine1: address.addressLine1 ?? '',
      addressLine2: address.addressLine2 ?? '',
      city: address.city ?? '',
      zipCode: address.zipCode ?? '',
      state: address.state ?? '',
      country: address.country || '',
      phoneNumber: address.phoneNumber ?? ''
    }),
    [user] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const handleChangeInfoSuccess = useCallback(
    userObj => {
      onChange(userObj)
      toggleChangeInfo()
    },
    [onChange, toggleChangeInfo]
  )

  return (
    <React.Fragment>
      <SectionRow container alignItems="center" justify="space-between">
        <Typography variant="h3">
          <StyledIcon component={InfoIcon} />
          {t('Information')}
        </Typography>
        <Button
          startIcon={<EditIcon />}
          variant="outlined"
          onClick={toggleChangeInfo}>
          {t('change_info')}
        </Button>
      </SectionRow>

      <StyledTypography variant="body1">{userInfo.firstName}</StyledTypography>
      <StyledTypography variant="body1">{userInfo.lastName}</StyledTypography>

      <PlaceholderTypography value={userInfo.addressName} name="address_name" />
      <PlaceholderTypography
        value={userInfo.addressLine1}
        name="address_line_1"
      />
      <PlaceholderTypography
        value={userInfo.addressLine2}
        name="address_line_2"
      />
      <PlaceholderTypography value={userInfo.city} name="city" />
      <PlaceholderTypography value={userInfo.zipCode} name="zip_code" />
      <PlaceholderTypography value={userInfo.state} name="state" />
      <PlaceholderTypography
        value={t(userInfo.country.toLowerCase())}
        name="country"
      />
      <PlaceholderTypography value={userInfo.phoneNumber} name="phone_number" />

      {showChangeInfo && (
        <ChangeInfoDialog
          onClose={toggleChangeInfo}
          onSuccess={handleChangeInfoSuccess}
          userInfo={userInfo}
          setRequestPending={setRequestPending}
          setRequestError={setRequestError}
        />
      )}
    </React.Fragment>
  )
}

UserInfo.propTypes = {
  user: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  setRequestPending: PropTypes.func.isRequired,
  setRequestError: PropTypes.func.isRequired
}

export default React.memo(UserInfo)
