import React from 'react'

export const GenericFileIcon = ({ height = 24, width = 24 }) => (
  <svg
    style={{ verticalAlign: 'middle' }}
    width={width}
    height={height}
    viewBox="0 0 350 350"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M96.25 44C81.8125 44 70.1313 55.8125 70.1313 70.25L70 280.25C70 294.688 81.6812 306.5 96.1187 306.5H253.75C268.187 306.5 280 294.688 280 280.25V122.75L201.25 44H96.25ZM188.125 135.875V63.6875L260.312 135.875H188.125Z"
      fill="#666666"
    />
  </svg>
)
