import React from 'react'
import Typography from '@nutrien/uet-react/Typography'
import Box from '@nutrien/uet-react/Box'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Logo } from 'components/Icons'

const WrappedLogoHeight = 60

const StyledLogo = styled(Logo)`
  width: 156px;
  height: 32px;
`

const WarningWrapper = styled(Box)`
  height: calc(100% - ${WrappedLogoHeight}px);
`

const Warning = styled(Box)`
  box-shadow: 0 1px 6px -1px rgba(0, 0, 0, 0.32);
`

const CookieWarning = ({ children }) => {
  const { t } = useTranslation()
  let localStorageAvailable
  try {
    const testLocalStorage = 'test-local-storage'
    localStorage.setItem(testLocalStorage, testLocalStorage)
    const test = localStorage.getItem(testLocalStorage)
    localStorage.removeItem(testLocalStorage)
    localStorageAvailable = test === testLocalStorage
  } catch (e) {
    localStorageAvailable = false
  }

  return localStorageAvailable ? (
    children
  ) : (
    <React.Fragment>
      <Box
        height={WrappedLogoHeight}
        display="flex"
        alignItems="center"
        justifyContent="center">
        <StyledLogo />
      </Box>
      <WarningWrapper
        display="flex"
        alignItems="center"
        justifyContent="center"
        bgcolor="grey.100">
        <Warning
          p={2}
          pb={6}
          maxWidth={380}
          margin={3}
          align="center"
          borderRadius={4}
          bgcolor="common.white">
          <Box p={2} component={Typography} variant="h1">
            {t('cookie_title')}
          </Box>
          <Typography variant="body1">{t('cookie_message')}</Typography>
        </Warning>
      </WarningWrapper>
    </React.Fragment>
  )
}

export default CookieWarning
