import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Grid from '@nutrien/uet-react/Grid'
import Warning from '@nutrien/uet-react/icons/Warning'
import Typography from '@nutrien/uet-react/Typography'
import Button from '@nutrien/uet-react/Button'

const LargeWarning = styled(Warning)`
  width: 48px;
  height: 48px;
`

const Retry = ({ handleRetry }) => {
  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="center"
      spacing={2}>
      <Grid item>
        <LargeWarning color="error" fontSize="large" />
      </Grid>
      <Grid item>
        <Typography variant="subtitle2">Failed to Load</Typography>
      </Grid>
      <Grid item>
        <Button>
          <Typography variant="subtitle2" color="primary" onClick={handleRetry}>
            Retry
          </Typography>
        </Button>
      </Grid>
    </Grid>
  )
}

Retry.propTypes = {
  /** Called when Retry button is clicked */
  handleRetry: PropTypes.func
}

export default Retry
