import _mapValues from 'lodash/mapValues'
import _some from 'lodash/some'
import { useCallback } from 'react'

import useInput from '../useInput'

export default (initialValues = {}, schema = {}) => {
  const inputs = _mapValues(initialValues, (v, k) => useInput(v, schema[k]))

  const getValues = useCallback(
    () => _mapValues(inputs, input => input.value),
    [inputs]
  )

  const isValid = useCallback(() => !_some(inputs, input => input.hasError), [
    inputs
  ])

  return { inputs, getValues, isValid }
}
