import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Box from '@nutrien/uet-react/Box'
import Button from '@nutrien/uet-react/Button'
import Hidden from '@nutrien/uet-react/Hidden'
import ExpandMore from '@nutrien/uet-react/icons/ExpandMore'
import makeStyles from '@nutrien/uet-react/styles/makeStyles'

import SvgIcon from 'components/SvgIcon'

import TabTypography from '../TabTypography'
import DropdownPopover from './components/DropdownPopover'

const useStyles = makeStyles(theme => ({
  buttonRoot: {
    // these weird spacings are there to make sure
    // the AppBar is ~64px
    // sorry. :(
    margin: theme.spacing(0, 1.5),
    padding: theme.spacing(2.5, 0, 2),
    borderRadius: 0,

    // override the hover of the button component
    '&:hover': {
      background: 'none'
    }
  },
  buttonSelected: {
    margin: theme.spacing(0, 1.5),
    borderLeft: 'none',
    borderBottom: `solid 4px ${theme.palette.brand.green}`
  },
  icon: {
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.short
    }),
    transform: 'rotate(0)'
  },
  iconOpen: {
    transform: 'rotate(-180deg)'
  }
}))

const DropDownLinkTab = ({ children, id, tabLabel }) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = event => {
    event.preventDefault()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  /*
   * if the children is an array
   * filter the invalid element from children.
   * this is only used for conditions.
   */
  const validChildren =
    children?.filter?.(child => React.isValidElement(child)) ?? []

  /*
   * this guard clause is to render null
   * when there are no children or if the children are invalid elements.
   * this is a possible case when the user doesn't have any permissions
   * to any of the children or all of the children are feature flagged.
   */
  if (!children || (Array.isArray(children) && !validChildren.length))
    return null

  /*
   * checks if any of the children is selected.
   * we have to cast this as boolean because if isSelected is false,
   * it will pass the child object to the isSelected props
   * of <TabTypography> which expects a boolean.
   */
  const hasChildSelected = !!(Array.isArray(children)
    ? validChildren.find(child => child.props.isSelected)
    : children.props.isSelected)

  return (
    <>
      <Hidden smDown>
        <Button
          data-testid={`nav-link-dropdown-trigger-${id}`}
          className={classnames(classes.buttonRoot, {
            [classes.buttonSelected]: hasChildSelected
          })}
          color="secondary"
          onClick={handleClick}
          disableRipple>
          <TabTypography isSelected={hasChildSelected} component="div">
            <Box display="flex">
              {tabLabel}
              <SvgIcon
                data-testid="dropdown-icon"
                color="gray.200"
                component={ExpandMore}
                className={classnames(classes.icon, {
                  [classes.iconOpen]: Boolean(anchorEl)
                })}
              />
            </Box>
          </TabTypography>
        </Button>

        <DropdownPopover
          data-testid={`dropdown-tab-popover-${id}`}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorEl={anchorEl}
          keepMounted
          PaperProps={{ onMouseLeave: handleClose }}>
          {children}
        </DropdownPopover>
      </Hidden>

      <Hidden mdUp>
        <Box ml={5}>
          <TabTypography>{tabLabel}</TabTypography>
        </Box>
        {children}
      </Hidden>
    </>
  )
}

DropDownLinkTab.propTypes = {
  id: PropTypes.string.isRequired,
  tabLabel: PropTypes.string.isRequired
}

export default DropDownLinkTab
