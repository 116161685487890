import React, { createContext, useState } from 'react'
import PropTypes from 'prop-types'

export const TopBannerContext = createContext({})

export const TopBannerProvider = ({ children }) => {
  // this is the main state that will determine if the
  // top banner is renedered or not.
  const [topBannerVisible, setTopBannerVisible] = useState(false)

  // this can be used by banners that have an option
  // to be dismissed. we can use this to have additional
  // logic for rendering a dismissed banner.
  const [dismissed, setDismissed] = useState(false)
  return (
    <TopBannerContext.Provider
      value={{
        dismissed,
        topBannerVisible,
        setDismissed,
        setTopBannerVisible
      }}>
      {children}
    </TopBannerContext.Provider>
  )
}

TopBannerProvider.propTypes = {
  children: PropTypes.object.isRequired
}

export default TopBannerProvider
