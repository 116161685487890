import React from 'react'

export const ZIPIcon = ({ height = 24, width = 24 }) => (
  <svg
    style={{ verticalAlign: 'middle' }}
    width={width}
    height={height}
    viewBox="0 0 364 364"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M291.2 45.5H86.45C60.6667 45.5 45.5 60.6667 45.5 86.45V291.2C45.5 303.333 60.6667 318.5 86.45 318.5H291.2C303.333 318.5 318.5 303.333 318.5 291.2V86.45C318.5 60.6667 303.333 45.5 291.2 45.5Z"
      fill="#666666"
    />
    <path
      d="M166.833 242.667V136.5H189.583V197.167V227.5V242.667H166.833Z"
      fill="white"
    />
    <path
      d="M151.666 136.5H75.833V151.667H117.197L75.833 242.667H151.666L151.666 227.5H110.303L151.666 136.5Z"
      fill="white"
    />
    <path
      d="M212.333 242.969V136.803C212.333 136.803 288.166 131.308 288.166 167.136C288.166 202.964 234 201.521 234 201.521V242.969H212.333Z"
      fill="white"
    />
    <path
      d="M234 182.304V151.97C234 151.97 265.417 151.667 265.417 166.834C265.417 182 234 182.304 234 182.304Z"
      fill="#666666"
    />
  </svg>
)
