import React, { useContext, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'graphql-hooks'
import styled, { css } from 'styled-components'
import Typography from '@nutrien/uet-react/Typography'
import Label from '@nutrien/uet-react/Label'
import Divider from '@nutrien/uet-react/Divider'
import TextField from '@nutrien/uet-react/TextField'
import Grid from '@nutrien/uet-react/Grid'
import Button from '@nutrien/uet-react/Button'
import { Link } from 'react-router-dom'

import useUserCountry from 'hooks/useUserCountry'
import { AuthContext } from 'components/Auth/authentication-context'
import th from 'theme/helpers'
import useInput from 'hooks/useInput'
import withRequest from 'HOCs/withRequest'
import useToggle from 'hooks/useToggle'
import CampaignAcceptanceDialog from './SubscriptionDialogs/CampaignAcceptanceDialog'
import EnrollmentSuccessDialog from './SubscriptionDialogs/EnrollmentSuccessDialog'

const StyledDivider = styled(Divider)`
  margin: ${th.spacing(2, 0)};
`

const PromoInput = styled(TextField)`
  .MuiFormHelperText-contained {
    margin: 0;
  }
`

const Title = styled(Typography).attrs({ variant: 'h3' })`
  margin: ${th.spacing(2, 0)};
`
const HelperText = styled(Typography)`
  padding: 8px;
  font-size: 12px;
`

const ErrorText = styled(Typography)`
  padding: 8px;
  font-size: 12px;
  color: red;
`

const StyledLabel = styled(Label)`
  margin-bottom: ${th.spacing(1)};

  .MuiFormLabel-root {
    min-width: 70px;
  }
`

const StyledLink = styled(Link)`
  font-size: 12px;
  color: red;
  font-weight: 600;
  text-decoration: underline;
`

const faintStyle = css`
  color: ${th.palette('grey.400')};
`

const DateText = styled(({ faint, ...rest }) => <Typography {...rest} />)`
  ${({ faint }) => (faint ? faintStyle : '')}
`

const VALIDATE_PROMO_CODE = `
  mutation ValidatePromoCode(
    $code: String!
  ) {
    validatePromoCode(
      code: $code
    ) {
      valid
      international
      country
      community {
        name
        termsAndConditions {
          text
          version
          modified
        }
        kind
      }
      campaign {
        name
        termsAndConditions {
          text
          version
          modified
        }
        kind
      }
    }
  }
`

const getFormattedDate = (date, t) =>
  date ? t('_local_date_full', { date }) : t('never')

const SubscriptionRow = ({ title, activatedOn, expiresOn }) => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <Title>{title}</Title>
      <StyledLabel label={`${t('activated')}:`} labelPlacement="left">
        <DateText>{getFormattedDate(activatedOn, t)}</DateText>
      </StyledLabel>
      <StyledLabel label={`${t('expires')}:`} labelPlacement="left">
        <DateText faint={!expiresOn}>{getFormattedDate(expiresOn, t)}</DateText>
      </StyledLabel>
      <StyledDivider />
    </React.Fragment>
  )
}

const Subscriptions = ({ setRequestPending }) => {
  const { t } = useTranslation()
  const promoCode = useInput('')
  const [error, setError] = useState(null)
  const {
    user: { subscriptions = [] }
  } = useContext(AuthContext)
  const [validatePromoCode] = useMutation(VALIDATE_PROMO_CODE)
  const [tncDialog, setTncDialog] = useState(false)
  const [campaignDetails, setCampaignDetails] = useState(null)
  const [communityDetails, setCommunityDetails] = useState(null)
  const [campaignCode, setCampaignCode] = useState(null)
  const [enrollmentSuccess, setEnrollmentSuccess] = useState(false)
  const [previouslyEnrolled, setPreviouslyEnrolled] = useState(false)
  const [countryMismatch, setCountryMismatch] = useState(false)
  const [showIntercom, toggleIntercom] = useToggle(false)
  const [campaignCountry, setCampaignCountry] = useState(null)
  const userCountry = useUserCountry()
  promoCode.watch(() => {
    error && setError(null)
    setCountryMismatch(false)
  })

  const contactSupport = () => {
    if (window.Intercom) {
      window.Intercom('showNewMessage', t('campaign_mismatch_error'))
      toggleIntercom()
    }
  }

  const handleAddPromo = useCallback(async () => {
    setRequestPending(true)
    setCampaignCode(promoCode.value)
    const { data, error } = await validatePromoCode({
      variables: { code: promoCode.value }
    })

    if (data && data.validatePromoCode) {
      setRequestPending(false)
      if (!data.validatePromoCode.country.includes(userCountry)) {
        setCampaignDetails('')
        setCommunityDetails('')
        setTncDialog(false)
        setCampaignCountry(data.validatePromoCode.country[0])
        setCountryMismatch(true)
      } else {
        setCampaignDetails(data.validatePromoCode.campaign)
        setCommunityDetails(data.validatePromoCode.community)
        setTncDialog(true)
      }
    }
    setRequestPending(false)
    promoCode.reset()

    if (error) {
      setError(t('invalid_code_text'))
    }
  }, [setRequestPending, promoCode, validatePromoCode, userCountry, t])

  if (tncDialog) {
    return (
      <CampaignAcceptanceDialog
        campaignDetails={campaignDetails}
        communityDetails={communityDetails}
        campaignCode={campaignCode}
        onClose={() => setTncDialog(false)}
        setEnrollmentSuccess={() => setEnrollmentSuccess(true)}
        setPreviouslyEnrolled={() => setPreviouslyEnrolled(true)}
      />
    )
  }

  if (enrollmentSuccess || previouslyEnrolled) {
    return (
      <EnrollmentSuccessDialog
        onClose={
          (() => setEnrollmentSuccess(false),
          () => setPreviouslyEnrolled(false))
        }
        name={campaignDetails.name}
        previouslyEnrolled={previouslyEnrolled}
      />
    )
  }
  return (
    <div>
      {showIntercom && contactSupport()}
      <Grid container spacing={2} alignItems="flex-end">
        <Grid item xs={12} sm={6} md={3}>
          <Label label={t('enter_promo')}>
            <PromoInput
              error={!!error || countryMismatch}
              {...promoCode.bind}
              autoFocus
            />
          </Label>
        </Grid>
        <Grid item xs={12} sm={6} md={9}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddPromo}
            size="large"
            disabled={!promoCode.value.trim()}>
            {t('check')}
          </Button>
        </Grid>
        {error ? (
          <ErrorText>{t('invalid_code_text')}</ErrorText>
        ) : countryMismatch ? (
          <ErrorText>
            {t('dynamic_mismatch_error', {
              user: t(`${userCountry.toLowerCase()}`),
              campaign: t(`${campaignCountry.toLowerCase()}`)
            })}
            <br />
            <StyledLink
              to=""
              onClick={e => {
                e.preventDefault()
                toggleIntercom(true)
              }}>
              {t('contact_support')}
            </StyledLink>
          </ErrorText>
        ) : (
          <HelperText>{t('join_campaign')}</HelperText>
        )}
      </Grid>
      <br />
      <StyledDivider />
      <Typography variant="body1">{t('subscriptions_text')}</Typography>
      <br />
      {subscriptions &&
        subscriptions.map(sub => (
          <SubscriptionRow
            key={sub.uuid}
            title={sub.planName}
            activatedOn={sub.activatedDate}
            expiresOn={sub.expirationDate}
          />
        ))}
    </div>
  )
}

Subscriptions.propTypes = {
  setRequestPending: PropTypes.func.isRequired
}

Subscriptions.defaultProps = {}

export default React.memo(withRequest(Subscriptions))
