import React, { useMemo, useCallback, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Dialog from '@nutrien/uet-react/Dialog'
import Box from '@nutrien/uet-react/Box'
import Button from '@nutrien/uet-react/Button'
import Link from '@nutrien/uet-react/Link'
import * as Yup from 'yup'
import Typography from '@nutrien/uet-react/Typography'
import TextField from '@nutrien/uet-react/TextField'
import makeStyles from '@nutrien/uet-react/styles/makeStyles'
import Notification from '@nutrien/uet-react/Notification'
import { useMutation, ClientContext } from 'graphql-hooks'
import { IntercomAPI } from 'components/Intercom'
import useInputs from 'hooks/useInputs'
import useFlags from 'hooks/useFlags'
import { AuthContext } from 'components/Auth/authentication-context'
import { callQueryWithAccessToken } from 'helpers/callQueryWithAccessToken'
import { SYNC_ECHELON_DATA_MUTATION, LINK_ACCOUNT_MUTATION } from '../utils'

const useStyles = makeStyles(theme => ({
  dialogContent: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxSizing: 'border-box',
    padding: theme.spacing(0, 0, 2, 0)
  },

  fieldContainer: {
    gap: theme.spacing(2)
  },
  styledNotification: {
    marginBottom: `${theme.spacing(1)}`,
    fontFamily: 'Open Sans'
  }
}))

const handleMessageCustomerSupport = event => {
  event.preventDefault()
  IntercomAPI('show')
}

const LinkAccountDialog = ({ onClose, onSuccess, setRequestPending }) => {
  const { t } = useTranslation()
  const styles = useStyles()
  const [submitError, setSubmitError] = useState(null)
  const flags = useFlags()

  const { getSSOAccessToken } = useContext(AuthContext)
  const graphqlClient = useContext(ClientContext)
  const validationSchema = useMemo(
    () => ({
      accountNickname: Yup.string().required(t('acct_nickname_required')),
      accountNumber: Yup.string().required(t('acct_number_required')),
      statementToken: Yup.string().required(t('statement_token_required'))
    }),
    [t]
  )

  const { inputs, getValues, isValid } = useInputs(
    { accountNickname: '', accountNumber: '', statementToken: '' },
    validationSchema
  )
  const { accountNumber, accountNickname, statementToken } = inputs
  const [syncEchelonData] = useMutation(SYNC_ECHELON_DATA_MUTATION)

  const handleSubmit = useCallback(async () => {
    const accessToken = await getSSOAccessToken()
    setRequestPending(true)
    const { accountNumber, accountNickname, statementToken } = getValues()
    const variables = {
      accountId: accountNumber,
      accountToken: statementToken,
      nickname: accountNickname
    }
    const { data, error } = await callQueryWithAccessToken(
      graphqlClient,
      LINK_ACCOUNT_MUTATION,
      accessToken,
      variables
    )

    if (error) {
      setRequestPending(false)
      return setSubmitError(t('failed_to_link_payment_account'))
    }

    if (data?.linkPaymentAccount) {
      if (flags.echelonDataSync) {
        await syncEchelonData({
          variables: {
            accountId: accountNumber
          }
        })
      }
      setRequestPending(false)
      onSuccess()
    }
  }, [
    graphqlClient,
    getSSOAccessToken,
    getValues,
    onSuccess,
    setRequestPending,
    syncEchelonData,
    flags,
    t
  ])

  return (
    <Dialog
      actions={
        <>
          <Button onClick={onClose} variant="outlined">
            {t('cancel')}
          </Button>
          <Button
            data-testid="submitBtn"
            disabled={!isValid()}
            onClick={handleSubmit}
            variant="contained">
            {t('save')}
          </Button>
        </>
      }
      maxWidth="xs"
      open
      title={t('Link Account')}>
      <div className={styles.dialogContent}>
        <Box
          className={styles.fieldContainer}
          display="flex"
          flexDirection="column">
          <div>
            {submitError && (
              <Notification
                className={styles.styledNotification}
                variant="error">
                {submitError}
              </Notification>
            )}
          </div>
          <Box>
            <TextField
              inputProps={{ 'data-testid': 'accountNumber' }}
              label={t('account_number')}
              {...accountNumber.bind}
              autoFocus
            />
            <Typography variant="caption">
              {t('Your account number is on your statement')}
            </Typography>
          </Box>
          <Box>
            <TextField
              inputProps={{ 'data-testid': 'accountNickname' }}
              label={t('account_nickname')}
              {...accountNickname.bind}
            />
            <Typography variant="caption">
              {t('Give your account a recognizable and easy-to-remember name')}
            </Typography>
          </Box>
          <Box>
            <TextField
              inputProps={{ 'data-testid': 'statementToken' }}
              label={t('statement_token')}
              {...statementToken.bind}
            />
            <Typography variant="caption">
              {t(
                'Token_bottom_right_your_statement_only_June2018_or_newer_statements'
              )}{' '}
              <Link
                onClick={handleMessageCustomerSupport}
                style={{ cursor: 'pointer' }}
                variant="caption">
                {t('Chat_with_us')}
              </Link>
            </Typography>
          </Box>
        </Box>
      </div>
    </Dialog>
  )
}

LinkAccountDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  setRequestPending: PropTypes.func.isRequired
}

export default LinkAccountDialog
