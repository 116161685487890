import _mapValues from 'lodash/mapValues'

const selectAll = perm => ({
  ...perm,
  canView: perm.hasView && true,
  canAdd: perm.hasAdd && true,
  canChange: perm.hasChange && true,
  canDelete: perm.hasDelete && true
})

const clearAll = perm => ({
  ...perm,
  canView: false,
  canAdd: false,
  canChange: false,
  canDelete: false
})

export const SET_PERM = 'SET_PERM'
export const SELECT_ALL = 'SELECT_ALL'
export const CLEAR_ALL = 'CLEAR_ALL'
export const RESET = 'RESET'

function reducer(state, { type, payload }) {
  switch (type) {
    case SET_PERM:
      return {
        ...state,
        [payload.slug]: {
          ...state[payload.slug],
          [payload.perm]: payload.value
        }
      }
    case SELECT_ALL:
      return _mapValues(state, selectAll)
    case CLEAR_ALL:
      return _mapValues(state, clearAll)
    case RESET:
      return payload
    default:
      return state
  }
}

export default reducer
