import React, {
  useCallback,
  useContext,
  useMemo,
  useEffect,
  useState
} from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useQuery, useMutation } from 'graphql-hooks'
import styled from 'styled-components'
import Divider from '@nutrien/uet-react/Divider'
import _get from 'lodash/get'
import _sortBy from 'lodash/sortBy'
import _orderBy from 'lodash/orderBy'

import usePermissions from 'hooks/usePermissions'
import th from 'theme/helpers'
import {
  selectAllOrgs,
  selectFarmsFor,
  selectOwnedOrg
} from 'store/selectors/farmTree'
import Retry from 'components/Retry'
import Loading from 'components/Loading'
import { AuthContext } from 'components/Auth/authentication-context'
import withRequest from 'HOCs/withRequest'
import { ORG_MANAGEMENT_PRODUCT } from 'helpers/products'

import PermUser from '../PermUser'
import PermSelector from '../PermSelector'

const StyledDivider = styled(Divider)`
  margin: ${th.spacing(3, 0)};
`

const PERM_QUERY = `
query Permissions($orgId: String!) {
  listPermissions(orgId: $orgId) {
    users {
      uuid
      firstName
      lastName
      email
      farms {
        uuid
        name
        permissions {
          slug
          name
          accessLevels
        }
      }
    }
    possiblePermissions {
      slug
      accessLevels
    }
  }
}`

const simplifyPossiblePerm = ({ name, slug, accessLevels }) => ({
  slug,
  name,
  hasView: accessLevels.includes('view'),
  hasAdd: accessLevels.includes('add'),
  hasChange: accessLevels.includes('change'),
  hasDelete: accessLevels.includes('delete')
})

const simplifyUserPerm = ({ name, slug, accessLevels }, possiblePerm) => ({
  ...possiblePerm,
  slug,
  name,
  canView: accessLevels.includes('view'),
  canAdd: accessLevels.includes('add'),
  canChange: accessLevels.includes('change'),
  canDelete: accessLevels.includes('delete')
})

const getUserPerms = (users, selectedUser, selectedFarm, allPerms) => {
  const user = users.find(u => u.uuid === selectedUser)
  const userFarm = _get(user, 'farms', []).find(f => f.uuid === selectedFarm)

  const userPerms = _get(userFarm, 'permissions', []).reduce(
    (acc, perm) => ({
      ...acc,
      [perm.slug]: simplifyUserPerm(perm, allPerms[perm.slug])
    }),
    {}
  )

  return allPerms.reduce(
    (acc, perm) => ({
      ...acc,
      [perm.slug]: {
        ...perm,
        ...userPerms[perm.slug]
      }
    }),
    {}
  )
}

const EDIT_PERM_MUTATION = `
  mutation EditPermissions($orgId: String!, $farmId: String!, $userId: String!, $permissions: [PermissionInput]!) {
    editPermissions(
      orgId: $orgId
      farmId: $farmId
      userId: $userId
      permissions: $permissions
    )
  }
`

const Permissions = ({ setRequestPending, setRequestError }) => {
  const { canView, canChange } = usePermissions(ORG_MANAGEMENT_PRODUCT)
  const orgs = useSelector(selectAllOrgs)
  const ownedOrg = useSelector(selectOwnedOrg)
  const allOrgs = useMemo(
    () =>
      _orderBy(
        orgs.filter(({ uuid }) => canView(uuid)),
        ['isShared', 'name'],
        ['desc', 'asc']
      ),
    [orgs, canView]
  )
  const { t } = useTranslation()
  const { user } = useContext(AuthContext)
  // `count` here is used in the `key` prop below to PermSelector, which
  // uses `useReducer` hook that initializes on mount. But on re-render
  // there's a risk of showing stale data.
  // Hence, we use this count variable to force react to re-mount the
  // component when needed.
  const [count, setCount] = useState(0)
  const [selectedOrg, setSelectedOrg] = useState(allOrgs[0] && allOrgs[0].uuid)
  const farms = useSelector(state => selectFarmsFor(state, selectedOrg))
  const allFarms = useMemo(() => farms.filter(({ uuid }) => canView(uuid)), [
    farms,
    canView
  ])
  const [selectedFarmUuid, setSelectedFarmUuid] = useState(
    allFarms[0] && allFarms[0].uuid
  )
  const [selectedUser, setSelectedUser] = useState('')
  const { data, error, refetch } = useQuery(PERM_QUERY, {
    variables: { orgId: selectedOrg }
  })
  const [savePermissions] = useMutation(EDIT_PERM_MUTATION)

  const users = useMemo(
    () =>
      _get(data, ['listPermissions', 'users'], []).filter(
        u => u.uuid !== user.id
      ),
    [data, user.id]
  )

  const allPerms = useMemo(
    () =>
      _sortBy(
        _get(data, ['listPermissions', 'possiblePermissions'], []).map(
          simplifyPossiblePerm
        ),
        [
          // products with most possible permssions will come first
          ({ hasView, hasAdd, hasChange, hasDelete }) => {
            return -(((hasChange << hasAdd) << hasDelete) << hasView)
          },
          ({ slug }) => t(slug)
        ]
      ),
    [data, t]
  )

  const userPerms = useMemo(
    () => getUserPerms(users, selectedUser, selectedFarmUuid, allPerms),
    [allPerms, selectedFarmUuid, selectedUser, users]
  )

  const canChangePerms = useMemo(
    () => selectedOrg === _get(ownedOrg, 'uuid') && canChange(selectedFarmUuid),
    [selectedFarmUuid, canChange, ownedOrg, selectedOrg]
  )

  const handleSelectOrg = useCallback(orgId => {
    setSelectedFarmUuid('')
    setSelectedUser('')
    setSelectedOrg(orgId)
  }, [])

  // Select first farm when new list loads for the first time
  useEffect(() => {
    if (allFarms.length && !selectedFarmUuid)
      setSelectedFarmUuid(allFarms[0].uuid)
  }, [allFarms, selectedFarmUuid])

  // Select first user when new list loads for the first time
  useEffect(() => {
    if (users.length && !selectedUser) setSelectedUser(users[0].uuid)
  }, [selectedUser, users])

  const handleSavePerms = useCallback(
    async newPerms => {
      setRequestPending(true)
      const { error } = await savePermissions({
        variables: {
          orgId: selectedOrg,
          farmId: selectedFarmUuid,
          userId: selectedUser,
          permissions: newPerms
        }
      })

      if (error) return setRequestError(t('api_error_title'))

      await refetch()
      setCount(c => c + 1)
      setRequestPending(false)
    },
    [
      refetch,
      savePermissions,
      selectedFarmUuid,
      selectedOrg,
      selectedUser,
      setRequestError,
      setRequestPending,
      t
    ]
  )

  const handleInviteSuccess = useCallback(
    async userId => {
      setRequestPending(true)
      await refetch()
      setCount(c => c + 1)
      setSelectedUser(userId)
      setRequestPending(false)
    },
    [refetch, setRequestPending]
  )

  if (!data) return <Loading />

  return (
    <div>
      <PermUser
        orgs={allOrgs}
        selectedOrg={selectedOrg}
        onSelectOrg={handleSelectOrg}
        farms={allFarms}
        selectedFarm={selectedFarmUuid}
        onSelectFarm={setSelectedFarmUuid}
        users={users}
        selectedUser={selectedUser}
        onSelectUser={setSelectedUser}
        setRequestPending={setRequestPending}
        setRequestError={setRequestError}
        onInviteSuccess={handleInviteSuccess}
        canEdit={canChangePerms}
      />
      <StyledDivider />

      {error && <Retry handleRetry={refetch} />}

      {selectedUser && (
        <PermSelector
          key={`${count}-${selectedFarmUuid}-${selectedUser}`}
          canEdit={canChangePerms}
          userPerms={userPerms}
          onSave={handleSavePerms}
        />
      )}
    </div>
  )
}

Permissions.propTypes = {
  setRequestPending: PropTypes.func.isRequired,
  setRequestError: PropTypes.func.isRequired
}

Permissions.defaultProps = {}

export default React.memo(withRequest(Permissions))
