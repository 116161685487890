import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

export default () => {
  const location = useLocation()

  return useMemo(() => {
    const { state: aliasedState } = location
    const { code, state } = aliasedState || {}
    if (!code || !state) return null

    return { code, state }
  }, [location])
}
