import React from 'react'
import { BonsaiDrawerContent } from 'components/BonsaiDrawer'
import Loading from 'components/Loading'

const LoadingDrawerContent = ({
  title,
  subtitle,
  footerButtonLabel,
  onClose,
  children
}) => (
  <BonsaiDrawerContent
    buttonType="reset"
    onClose={onClose}
    title={title}
    subtitle={subtitle}
    footerButtonLabel={footerButtonLabel}
    isFooterButtonDisabled>
    {children}
    <Loading />
  </BonsaiDrawerContent>
)

export default LoadingDrawerContent
