import React from 'react'
import UETLink from '@nutrien/uet-react/Link'
import Table from '@nutrien/uet-react/Table'
import theme from '@nutrien/uet-theme'
import TableHead from '@nutrien/uet-react/TableHead'
import TableRow from '@nutrien/uet-react/TableRow'
import TableBody from '@nutrien/uet-react/TableBody'
import TableContainer from '@nutrien/uet-react/TableContainer'
import Typography from '@nutrien/uet-react/Typography'
import Box from '@nutrien/uet-react/Box'
import ReactHtmlParser from 'react-html-parser'
import { TableCell } from '../../../../pages/Legal/SharedStyledComponents'
import termsAndConditionHtml from './TermsAndConditionsContent.json'
const TermsAndConditionsContent = () => {
  const createTableData = (col0, col1, col2, col3, col4, col5, col6) => ({
    col0,
    col1,
    col2,
    col3,
    col4,
    col5,
    col6
  })

  const dataTableRows = [
    createTableData(
      'Agrible Base',
      'YES',
      'YES',
      'NO',
      'YES',
      'YES',
      'Weather data.'
    ),
    createTableData(
      `Agrible Measurement
        (Growers in Sustainability Projects)`,
      'YES',
      'YES',
      'YES',
      'YES',
      'YES',
      'Base plus Sustainability reports – All tools and functionality related to project'
    ),
    createTableData(
      `Agrible Solutions
        (Growers in Ecosystem Service Projects)`,
      'YES',
      'YES',
      'YES',
      'YES',
      'YES',
      'Base plus Sustainability reports – All tools and functionality related to pilot'
    )
  ]
  const options = {
    // replace table tag to add the table with style
    transform: node => {
      if (node.name === 'table') {
        return (
          <TableContainer>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <TableCell removeBorders />
                  <TableCell removeBorders>
                    <strong>Multiple Users per License</strong>
                  </TableCell>
                  <TableCell removeBorders>
                    <strong>Agrible Daily Electronic Report</strong>
                  </TableCell>
                  <TableCell removeBorders>
                    <strong>Aggregated Reports</strong>
                  </TableCell>
                  <TableCell removeBorders>
                    <strong>User Inputs Data</strong>
                  </TableCell>
                  <TableCell removeBorders>
                    <strong>Agrible Inputs Data for User</strong>
                  </TableCell>
                  <TableCell removeBorders>
                    <strong>Agrible Tools</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataTableRows.map((row, index) => (
                  <TableRow key={`key_index${index}`}>
                    <TableCell removeBorders scope="row">
                      <strong>{row.col0}</strong>
                    </TableCell>
                    <TableCell removeBorders scope="row">
                      {row.col1}
                    </TableCell>
                    <TableCell removeBorders scope="row">
                      {row.col2}
                    </TableCell>
                    <TableCell removeBorders scope="row">
                      {row.col3}
                    </TableCell>
                    <TableCell removeBorders scope="row">
                      {row.col4}
                    </TableCell>
                    <TableCell removeBorders scope="row">
                      {row.col5}
                    </TableCell>
                    <TableCell removeBorders scope="row">
                      {row.col6}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )
      }
      if (node.name === 'a') {
        return (
          <UETLink
            style={{ cursor: 'pointer', fontWeight: 'normal' }}
            onClick={e => {
              e.preventDefault()
              window.location.href = node.attribs.href
            }}>
            {node.children[0].data}
          </UETLink>
        )
      }
    }
  }
  return (
    <Box
      alignItems="flex-start"
      style={{ fontFamily: theme.typography.fontFamily }}>
      <Box pb={2}>
        <Typography variant="subtitle2" component="p">
          Last Updated February 21, 2023
        </Typography>
      </Box>
      {ReactHtmlParser(termsAndConditionHtml.content, options)}
    </Box>
  )
}

export default TermsAndConditionsContent
