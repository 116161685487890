import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { Link, NavLink, useLocation, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import makeStyles from '@nutrien/uet-react/styles/makeStyles'
import Grid from '@nutrien/uet-react/Grid'
import Box from '@nutrien/uet-react/Box'
import Tooltip from '@nutrien/uet-react/Tooltip'
import Button from '@nutrien/uet-react/Button'
import Menu from '@nutrien/uet-react/Menu'
import MenuItem from '@nutrien/uet-react/MenuItem'
import BurstModeIcon from '@nutrien/uet-react/icons/BurstMode'
import SupervisorAccount from '@nutrien/uet-react/icons/SupervisorAccount'
import KeyboardArrowDownIcon from '@nutrien/uet-react/icons/KeyboardArrowDown'
import AccountCircleIcon from '@nutrien/uet-react/icons/AccountCircle'
import LockIcon from '@nutrien/uet-react/icons/Lock'
import Hidden from '@nutrien/uet-react/Hidden'
import useFlags from 'hooks/useFlags'
import th from 'theme/helpers'
import useDashboardLink from 'hooks/useDashboardLink'
import usePanel from 'hooks/usePanel'
import useAuthorshipSession from 'hooks/useAuthorshipSession'
import withRequest from 'HOCs/withRequest'
import { AuthContext } from 'components/Auth/authentication-context'
import { NutrienNGrey } from 'components/Icons'
import SvgIcon from 'components/SvgIcon'
import Divider from '../../components/Divider'
import MyAccount from '../MyAccount'

// Used to resolve a suspected material-ui bug.
// Safari renders a column without this.
const GridRowForSafari = styled(Grid)`
  width: calc(100% + 9px);
`

const activeClassName = 'active'
const StyledNavLink = styled(NavLink).attrs({ activeClassName })`
  &.${activeClassName} {
    background-color: ${th.palette('action.hover')};
  }
`

const StyledMenu = styled(Menu)`
  width: 190px;
`

const StyledMenuItem = styled(MenuItem)`
  padding: ${th.spacing(1, 2)};
  font-weight: 600;
  position: relative;

  &.Mui-disabled {
    opacity: 1;
  }
`

const StyledIcon = styled(SvgIcon)`
  padding-right: ${th.spacing(1)};
`

const menuIconMap = {
  community: <SvgIcon color="grey.600" component={SupervisorAccount} />
}

const useClasses = makeStyles(theme => ({
  overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.white,
    zIndex: 2000,
    cursor: 'default',
    opacity: 0.5
  },
  tooltip: {
    background: theme.palette.common.white,
    color: theme.palette.common.black,
    boxShadow: '0px 0px 7px rgba(0, 0, 0, 0.25)',
    borderRadius: 4,
    minWidth: 250
  }
}))

// TODO: Think about it more
const isMyFarmActive = (match, location) =>
  /\/(cards|map)/.test(location.pathname)

const AuthPanel = ({ setRequestPending }) => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState(null)
  const location = useLocation()
  const { isAuthenticated, logout, user } = useContext(AuthContext)
  const dashboardLink = useDashboardLink()
  const history = useHistory()
  const flags = useFlags()
  const { authorshipSessionActive } = useAuthorshipSession()
  const { isOpen: showMyAccount, setParams: changeMyAccountPanel } = usePanel(
    'my_account'
  )
  const classes = useClasses()

  const [open, setOpen] = useState(false)
  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
  }
  const activePage = location.pathname.substr(1)
  const pageIcon = menuIconMap[activePage] || (
    <SvgIcon color="grey.600" component={BurstModeIcon} />
  )

  const handleOpenAccount = () => {
    changeMyAccountPanel({ panel: 'my_account', accounttab: 'profile' })
    setAnchorEl(null)
  }

  const handleOpenDigitalHub = () => {
    history.push('/nutrien-digital-hub')
  }

  const handleLogout = () => {
    setRequestPending(true)
    logout()
  }

  return (
    <Box style={{ flexShrink: 0 }}>
      {isAuthenticated && (
        <>
          <Tooltip
            PopperProps={{
              disablePortal: true
            }}
            placement="left-start"
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            arrow={false}
            classes={{ tooltip: classes.tooltip }}
            title={t('action_not_available_employees')}>
            <Button
              color="inherit"
              startIcon={pageIcon}
              endIcon={
                <SvgIcon color="grey.600" component={KeyboardArrowDownIcon} />
              }
              onClick={event => setAnchorEl(event.currentTarget)}>
              <Hidden smDown>
                {t(
                  location.pathname.indexOf('/community') === 0
                    ? 'community'
                    : 'my_dashboard'
                )}
              </Hidden>
            </Button>
          </Tooltip>
          <StyledMenu
            PaperProps={{
              style: {
                minWidth: 197
              }
            }}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => {
              setAnchorEl(null)
              handleTooltipClose()
            }}>
            <StyledMenuItem disabled>
              {t('Hi')} {user ? user.firstName : ''}
            </StyledMenuItem>
            <Divider mx={2} my={1} />
            <StyledMenuItem
              disabled={authorshipSessionActive}
              component={StyledNavLink}
              to={dashboardLink}
              isActive={!authorshipSessionActive && isMyFarmActive}>
              <Grid container alignItems="center">
                <StyledIcon color="grey.600" component={BurstModeIcon} />
                {t('my_dashboard')}
              </Grid>
            </StyledMenuItem>
            {flags?.deprecatedCommunityFeature && (
              <StyledMenuItem
                disabled={authorshipSessionActive}
                component={StyledNavLink}
                to="/community">
                <Grid container alignItems="center">
                  <StyledIcon color="grey.600" component={SupervisorAccount} />
                  {t('community')}
                </Grid>
              </StyledMenuItem>
            )}
            <StyledMenuItem
              disabled={authorshipSessionActive}
              onClick={handleOpenDigitalHub}>
              <Grid container alignItems="flex-start" spacing={0}>
                <SvgIcon
                  style={{ paddingRight: '6px', paddingLeft: '2px' }}
                  component={NutrienNGrey}
                />
                {t('Nutrien Digital Hub')}
              </Grid>
            </StyledMenuItem>
            <StyledMenuItem
              disabled={authorshipSessionActive}
              onClick={handleOpenAccount}>
              <Grid container alignItems="center">
                <StyledIcon color="grey.600" component={AccountCircleIcon} />
                {t('my_account')}
              </Grid>
            </StyledMenuItem>
            <StyledMenuItem
              disabled={authorshipSessionActive}
              onClick={handleLogout}>
              <Grid container alignItems="center">
                <StyledIcon color="grey.600" component={LockIcon} />
                {t('logout')}
              </Grid>
            </StyledMenuItem>
            {authorshipSessionActive && (
              <Box className={classes.overlay} onClick={handleTooltipOpen} />
            )}
          </StyledMenu>
          {showMyAccount && <MyAccount />}
        </>
      )}
      {!isAuthenticated && (
        <GridRowForSafari
          container
          direction="row"
          alignItems="center"
          spacing={1}>
          <Grid item>
            <Link to="/legacy-login">
              <Button variant="outlined" size="small">
                {t('login')}
              </Button>
            </Link>
            <Box m={1} component="span" />
            <Link to="/signup">
              <Button variant="contained" size="small">
                {t('signup')}
              </Button>
            </Link>
          </Grid>
        </GridRowForSafari>
      )}
    </Box>
  )
}

AuthPanel.propTypes = {
  setRequestPending: PropTypes.func.isRequired
}

export default withRequest(AuthPanel)
