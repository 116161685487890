// This layer's responsibility is composing a mix of atoms based on field configuration
// Note: name prop may be using dot-notation, so it's important to always
//       use _get when retrieving the value. Avoid this: values[name]

import React, { useEffect, useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import styled, { css } from 'styled-components'
import _difference from 'lodash/difference'
import _get from 'lodash/get'
import _isNil from 'lodash/isNil'
import _uniq from 'lodash/uniq'
import _find from 'lodash/find'
import Grid from '@nutrien/uet-react/Grid'
import Box from '@nutrien/uet-react/Box'
import FormHelperText from '@nutrien/uet-react/FormHelperText'
import Divider from '@nutrien/uet-react/Divider'
import IconButton from '@nutrien/uet-react/IconButton'
import Link from '@nutrien/uet-react/Link'
import MenuItem from '@nutrien/uet-react/MenuItem'
import NutrienRadioGroup from '@nutrien/uet-react/RadioGroup'
import InfoIcon from '@nutrien/uet-react/icons/Info'
import makeStyles from '@nutrien/uet-react/styles/makeStyles'
import AccordionBlock from 'components/AccordionBlock'
import Typography from 'components/Typography'
import SvgIcon from 'components/SvgIcon'
import th from 'theme/helpers'

import { Checkbox, Select, Radio } from './atoms'
import { StyledSingleChoice } from './organisms'
import { generateIdFromName } from './utils'

const useSelectStyles = makeStyles({
  select: {
    whiteSpace: 'normal'
  }
})

const useMenuItemStyles = makeStyles({
  root: {
    whiteSpace: 'normal'
  }
})

const StyledNutrienRadioGroup = styled(({ alignOptions, ...rest }) => (
  <NutrienRadioGroup {...rest} />
))`
  .MuiFormHelperText-root {
    margin: ${th.spacing(-1, 0, 0, 4)};
    font-size: ${({ theme }) => theme.typography.body2.fontSize};
  }

  ${({ alignOptions }) =>
    alignOptions &&
    css`
      .MuiFormControlLabel-root {
        align-items: ${alignOptions};
      }

      ${alignOptions === 'flex-start' &&
        css`
          /* necessary to align single or first line of label with checkbox similarly to centered default */
          .MuiFormControlLabel-label {
            margin-top: 2px;
          }
        `}
    `}
`

const InfoButton = styled(IconButton)`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`

export const useNoneOption = noneLabel => {
  const { t } = useTranslation()
  return useMemo(
    () => ({
      value: null,
      label: !_isNil(noneLabel) ? noneLabel : t('None')
    }),
    [noneLabel, t]
  )
}

// Adds support for null options in <Select> by
// managing disparity in data layer and display layer
// Formik and Yup should work with null, whereas
// <Select> requires a (mostly) non-empty string
// This is also used by <RadioGroup> for consistency
export const INTERIM_NONE_VALUE = 'none'
export const useNullableOptions = (name, options) => {
  const { setFieldValue, values } = useFormikContext()

  const hasNulls = useMemo(() => options.some(({ value }) => _isNil(value)), [
    options
  ])
  const nullableOptions = useMemo(() => {
    return options.map(option => {
      return !_isNil(option.value)
        ? option
        : { ...option, value: INTERIM_NONE_VALUE }
    })
  }, [options])

  // Immediately unpollute data layer from display layer values
  // For now useEffect is lower complexity than a custom onChange,
  // but if interim values ever cause issues, this logic should be
  // promoted to onChange. For now assumed to be safe
  useEffect(() => {
    if (hasNulls && _get(values, name) === INTERIM_NONE_VALUE) {
      setFieldValue(name, null)
    }
  }, [hasNulls, name, setFieldValue, values])

  const currentValue = useMemo(() => {
    const value = _get(values, name)
    if (!hasNulls) {
      // In case when nothing is selected AND
      // nullable option is not present,
      // <Select> demands empty string instead of null
      return _isNil(value) || value === INTERIM_NONE_VALUE ? '' : value
    }
    // In case when there is a selectable null option,
    // it must be fed as a non-empty string
    return _isNil(value) || value === '' ? INTERIM_NONE_VALUE : value
  }, [values, name, hasNulls])

  return {
    nullableOptions,
    currentValue
  }
}

export const RadioGroup = ({
  name,
  'data-testid': dataTestId,
  options,
  onChange,
  TypographyVariant,
  alignOptions,
  isRow,
  spaceRight,
  disabled,
  condensedRadioSpacing
}) => {
  // Radio groups with 0 options should still show at least one
  const noneOption = useNoneOption()
  const ensuredOptions = options.length ? options : [noneOption]
  const { nullableOptions, currentValue } = useNullableOptions(
    name,
    ensuredOptions
  )
  return (
    <StyledNutrienRadioGroup
      id={generateIdFromName(name)}
      data-testid={dataTestId}
      aria-label={name}
      name={name}
      {...(!!onChange && { onChange })}
      value={currentValue}
      alignOptions={alignOptions}
      row={isRow}>
      {nullableOptions.map(
        ({
          value,
          label,
          helperText,
          infoIcon,
          infoIconClick,
          childFields
        }) => (
          <Box key={value}>
            <Box
              position="relative"
              pr={spaceRight || 0}
              mt={condensedRadioSpacing && -1.5}>
              <Radio
                name={name}
                value={value}
                label={
                  helperText ? (
                    <Typography variant="h6">{label}</Typography>
                  ) : TypographyVariant ? (
                    <Box mt={0.5}>
                      <Typography variant={TypographyVariant}>
                        {label}
                      </Typography>
                    </Box>
                  ) : (
                    label
                  )
                }
                disabled={disabled}
              />
              {infoIcon && (
                <InfoButton onClick={infoIconClick}>
                  <SvgIcon color="grey.600" component={InfoIcon} />
                </InfoButton>
              )}
            </Box>
            {helperText && (
              <FormHelperText focused>{helperText}</FormHelperText>
            )}
            {childFields && currentValue === value && (
              <Box ml={4}>
                {childFields.map(({ name, options, label }) => (
                  <StyledSingleChoice
                    key={name}
                    size="small"
                    width="100%"
                    name={name}
                    options={options}
                    label={label}
                    variant="dropdown"
                  />
                ))}
              </Box>
            )}
          </Box>
        )
      )}
    </StyledNutrienRadioGroup>
  )
}

// Checkboxes populate a common array with their values
// It is imperative that we cast checked to a boolean to avoid bugs
export const CheckboxGroupWithTitles = React.forwardRef(
  (
    {
      name,
      'data-testid': dataTestId,
      options,
      disabled,
      boldItemText,
      setOpen
    },
    ref
  ) => {
    const { values, setFieldValue } = useFormikContext()
    return (
      <Grid id={generateIdFromName(name)} container direction="column">
        {options.map(
          (
            { key, value, label, helperText, helperLink, secondaryOptions },
            index
          ) => {
            return (
              <Box
                display="flex"
                m={1}
                flexDirection="column"
                key={key || value}>
                <Typography variant="h4">{label}</Typography>
                {secondaryOptions
                  ? secondaryOptions.map(({ value, label }, index) => {
                      const handleChange = event => {
                        // Make an array of all values selected
                        // Ignore event.target.value, because
                        // it forces values into strings
                        // setOpen(true)
                        const currentValue = _get(values, name)
                        if (Array.isArray(currentValue)) {
                          const arr = currentValue
                          const itemIndex = arr.findIndex(
                            itemValue => itemValue === value
                          )
                          const updatedArray =
                            itemIndex > -1
                              ? arr
                                  .slice(0, itemIndex)
                                  .concat(arr.slice(itemIndex + 1))
                              : arr.concat([value])
                          setFieldValue(name, updatedArray)
                          return
                        }
                        setFieldValue(name, !currentValue)
                      }
                      return (
                        <Box key={`${value}-${index}`}>
                          <Checkbox
                            key={`${value}-${index}`}
                            id={generateIdFromName(value || label)}
                            onChange={handleChange}
                            data-testid={`${dataTestId}-${index}`}
                            name={name}
                            value={value}
                            disabled={disabled}
                            checked={
                              !!(_get(values, name) || []).includes(value)
                            }
                            label={
                              <span
                                style={{
                                  fontWeight: boldItemText ? 600 : 'normal'
                                }}>
                                {label}
                              </span>
                            }
                          />
                          {helperText && (
                            <Typography
                              style={{
                                paddingLeft: '32px',
                                marginTop: -10,
                                marginBottom: 10,
                                color: '#222222'
                              }}>
                              {helperText}
                              {helperLink && (
                                <Link
                                  target="_blank"
                                  style={{ paddingLeft: '5px', color: 'grey' }}
                                  href={helperLink}>{`(${helperLink})`}</Link>
                              )}
                            </Typography>
                          )}
                        </Box>
                      )
                    })
                  : null}
              </Box>
            )
          }
        )}
      </Grid>
    )
  }
)

// Checkboxes populate a common array with their values
// It is imperative that we cast checked to a boolean to avoid bugs
export const CheckboxGroup = ({
  name,
  'data-testid': dataTestId,
  options,
  disabled,
  boldItemText,
  textWidth,
  classes,
  condensedRadioSpacing,
  includeNoneOfTheAboveOption,
  ...rest
}) => {
  const { values, setFieldValue } = useFormikContext()
  const [boxDirection, setBoxDirection] = useState('column')

  // Add 'None of the above' to the options if 'includeNoneOfTheAboveOption' is true
  if (includeNoneOfTheAboveOption && options.length) {
    const lastItem = options?.at(-1)
    lastItem.value !== -1 &&
      options.push({ value: -1, label: 'None of the above' })
  }

  useEffect(() => {
    if (rest?.direction === 'row') {
      setBoxDirection('row')
    }
  }, [rest])
  return (
    <Grid
      id={generateIdFromName(name)}
      container
      direction={boxDirection}
      data-testid={`${dataTestId}_grid`}>
      {options.map(
        (
          {
            key,
            value,
            label,
            helperText,
            helperLink,
            disabled: disableOption
          },
          index
        ) => {
          const handleChange = event => {
            if (value !== -1) {
              // Ignore event.target.value, because
              // it forces values into strings
              const currentValue = _get(values, name)
              if (Array.isArray(currentValue)) {
                const arr = currentValue
                // Clear 'None of the above' if other options are selected
                arr?.[0] === -1 && arr.shift()
                const itemIndex = arr.findIndex(
                  itemValue => itemValue === value
                )

                const updatedArray =
                  itemIndex > -1
                    ? arr.slice(0, itemIndex).concat(arr.slice(itemIndex + 1))
                    : arr.concat([value])
                setFieldValue(name, updatedArray)
                return
              }
              setFieldValue(name, !currentValue)
              // Reset other selections if 'None of the above' option is selected
            } else setFieldValue(name, [value])
          }
          return (
            <Box
              justifyContent={textWidth ? 'flex-start' : ' '}
              width={textWidth}
              key={key || value}>
              <Checkbox
                id={generateIdFromName(value || label)}
                onChange={handleChange}
                data-testid={`${dataTestId}-${index}`}
                name={name}
                value={value}
                disabled={disabled || disableOption}
                checked={!!(_get(values, name) || []).includes(value)}
                label={
                  <span
                    style={{
                      fontSize: classes && 16,
                      marginBottom: !condensedRadioSpacing && '.5em',
                      display: 'flex',
                      paddingTop: !condensedRadioSpacing && '.5em',
                      flexWrap: 'wrap',
                      fontWeight: boldItemText ? 600 : 'normal'
                    }}>
                    {label}
                  </span>
                }
              />
              {helperText && (
                <Typography
                  style={{
                    paddingLeft: '32px',
                    marginTop: -10,
                    marginBottom: 10,
                    color: '#222222'
                  }}>
                  {helperText}
                  {helperLink && (
                    <Link
                      target="_blank"
                      style={{ paddingLeft: '5px', color: 'grey' }}
                      href={helperLink}>{`(${helperLink})`}</Link>
                  )}
                </Typography>
              )}
            </Box>
          )
        }
      )}
    </Grid>
  )
}
CheckboxGroup.propTypes = {
  name: PropTypes.string,
  'data-testid': PropTypes.string,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  classes: PropTypes.object,
  boldItemText: PropTypes.string,
  includeNoneOfTheAboveOption: PropTypes.bool,
  condensedRadioSpacing: PropTypes.bool
}

CheckboxGroup.defaultProps = {
  classes: {},
  includeNoneOfTheAboveOption: false,
  condensedRadioSpacing: false
}

export const CheckboxGroupDropDown = ({
  name,
  'data-testid': dataTestId,
  options,
  disabled,
  boldItemText,
  textWidth,
  ...rest
}) => {
  const { values, setFieldValue } = useFormikContext()
  const { t } = useTranslation()
  const [boxDirection, setBoxDirection] = useState('column')
  useEffect(() => {
    if (rest?.direction === 'row') {
      setBoxDirection('row')
    }
  }, [rest])
  return (
    <Grid id={generateIdFromName(name)} container direction={boxDirection}>
      {options.map(({ key, value, label, helperText }) => {
        const selectedOption = values?.[name]?.map(option => option.name) ?? []
        const handleChange = () => {
          const currentValue = _get(values, name)
          if (Array.isArray(currentValue)) {
            const arr = currentValue
            const itemIndex = selectedOption.findIndex(
              itemValue => itemValue === label
            )
            const updatedArray =
              itemIndex > -1
                ? arr.slice(0, itemIndex).concat(arr.slice(itemIndex + 1))
                : arr.concat([{ id: value, name: label }])
            setFieldValue(name, updatedArray)
            return
          }
          setFieldValue(name, !currentValue)
        }
        return (
          <React.Fragment key={key || value}>
            <Box
              justifyContent={textWidth ? 'flex-start' : ' '}
              width={textWidth}>
              <Box>
                <AccordionBlock
                  complexHeader={true}
                  title=" "
                  accordianHeaderStyles={{
                    marginRight: '8px',
                    padding: 0,
                    contentMargin: 0,
                    expandedMargin: '0 !important',
                    minHeight: '48px !important'
                  }}
                  accordionDetailsStyles={{ paddingLeft: 0 }}
                  accordionStyles={{ expandedMargin: '0 !important' }}
                  header={
                    <Box pl={1}>
                      <Checkbox
                        id={generateIdFromName(value || label)}
                        onChange={handleChange}
                        data-testid={`test_${value}`}
                        name={name}
                        value={value}
                        disabled={disabled}
                        checked={selectedOption.includes(label)}
                        label={
                          <Box display="flex" flexWrap="wrap" ml={-0.5}>
                            <Typography variant="subtitle1">
                              {t(`field-characteristics:${label}`)}
                            </Typography>
                          </Box>
                        }
                      />
                    </Box>
                  }
                  children={
                    <>
                      {helperText && (
                        <Box ml={3} bgcolor="grey.A15">
                          <Box ml={3} mb={3} mr={1} mt={2}>
                            <Typography variant="subtitle1">
                              {helperText}
                            </Typography>
                          </Box>
                        </Box>
                      )}
                    </>
                  }
                />
              </Box>
            </Box>
            <Box mx={3}>
              <Divider style={{ backgroundColor: '#E9E9E9' }} />
            </Box>
          </React.Fragment>
        )
      })}
    </Grid>
  )
}

export const SELECT_OPTION_VALUE = null

export const NestedCheckboxGroup = ({
  parent,
  name,
  options,
  classes = {}
}) => {
  const { values, setFieldValue } = useFormikContext()
  const {
    name: parentName,
    label: parentLabel,
    disabled: parentDisabled
  } = parent

  const currentValue = _get(values, name)

  // Set parent to unchecked/indeterminate/checked
  useEffect(() => {
    const checkedOptions = options?.filter(option =>
      currentValue?.includes(option.value)
    )
    setFieldValue(
      parentName,
      checkedOptions.length > 0 && checkedOptions.length < options.length
        ? 'indeterminate'
        : !!checkedOptions.length
    )
  }, [currentValue, options, parentName, setFieldValue])

  const handleParentTick = useCallback(
    event => {
      const checked = !_isNil(event.target.checked)
        ? !!event.target.checked
        : !!event.target.value
      const disabledValues = options
        .filter(option => option.disabled)
        .map(option => option.value)

      const values = options.map(option => option.value)
      const valuesToRemove = disabledValues
        ? options
            .filter(option => !disabledValues.includes(option.value))
            .map(option => option.value)
        : options.map(option => option.value)

      setFieldValue(
        name,
        checked
          ? _uniq(currentValue.concat(values))
          : _difference(currentValue, valuesToRemove)
      )
    },
    [currentValue, name, options, setFieldValue]
  )

  return (
    <Grid container direction="column">
      <Grid item>
        <Checkbox
          name={parentName}
          label={
            !classes.title ? (
              parentLabel
            ) : (
              <span className={classes?.title}>{parentLabel}</span>
            )
          }
          className={classes?.title}
          checked={!!_get(values, parentName)}
          indeterminate={_get(values, parentName) === 'indeterminate'}
          disabled={parentDisabled}
          onChange={handleParentTick}
        />
      </Grid>
      <Grid item>
        <Box pl={4} className={classes?.paddingLeft}>
          <CheckboxGroup
            name={name}
            options={options}
            classes={classes?.label}
          />
        </Box>
      </Grid>
    </Grid>
  )
}

// using display="block" in Typography doesn't work due to flexbox on MenuItem
const MultilineMenuItem = styled(MenuItem)`
  display: block;
`

export const SelectGroup = ({
  name,
  'data-testid': dataTestId,
  options,
  value,
  disabled,
  width,
  onChange,
  connect
}) => {
  const { nullableOptions, currentValue } = useNullableOptions(name, options)
  const hasExtra = useMemo(() => !!_find(options, row => !!row.extraLabel), [
    options
  ])
  const Item = hasExtra ? MultilineMenuItem : MenuItem
  const selectClasses = useSelectStyles()
  const menuClasses = useMenuItemStyles()
  return (
    <Select
      id={generateIdFromName(name)}
      data-testid={dataTestId}
      name={name}
      value={value || currentValue}
      disabled={disabled}
      width={width}
      classes={selectClasses}
      isCustomRender={hasExtra}
      customRenderOptions={options}
      {...(!!onChange && { onChange })}
      connect={connect}>
      {nullableOptions.map(({ value, label, extraLabel }) => (
        <Item key={value} value={value} classes={menuClasses}>
          {hasExtra ? (
            <>
              <Typography variant="body1">{label}</Typography>
              <Box
                component={Typography}
                pb={1}
                variant="body2"
                display="block">
                {extraLabel}
              </Box>
            </>
          ) : (
            label
          )}
        </Item>
      ))}
    </Select>
  )
}
