import React, { useCallback, useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'propTypes'
import Box from '@nutrien/uet-react/Box'
import Divider from '@nutrien/uet-react/Divider'
import Dialog from '@nutrien/uet-react/Dialog'
import Button from '@nutrien/uet-react/Button'
import Typography from '@nutrien/uet-react/Typography'
import { AuthContext } from 'components/Auth/authentication-context'
import useAuthorshipSession from 'hooks/useAuthorshipSession'

const YouAreIn = ({ setShowTermsandCondDrawer }) => {
  const { t } = useTranslation()
  const { isAuthenticated, user } = useContext(AuthContext)
  const [showReviewTermsPopup, setShowReviewTermsPopup] = useState(false)
  const { authorshipSessionActive } = useAuthorshipSession()

  const handleReview = () => {
    setShowReviewTermsPopup(false)
    setShowTermsandCondDrawer(true)
  }

  useEffect(() => {
    if (isAuthenticated && user) {
      if (
        !user.profile?.agribleTermsAndConditions &&
        !authorshipSessionActive
      ) {
        setShowReviewTermsPopup(true)
      }
    }
  }, [authorshipSessionActive, isAuthenticated, user])

  const handleClose = useCallback(
    (_event, reason) => {
      /*
       *  https://v4.mui.com/api/dialog/#props
       *
       *  this is the same as setting
       *  disableBackdropClick (deprecated) and disableEscapeKeyDown
       *  props to true.
       */
      if (reason === 'backdropClick' || reason === 'escapeKeyDown') return

      setShowReviewTermsPopup(false)
    },
    [setShowReviewTermsPopup]
  )

  return (
    <Dialog
      actions={
        <>
          <Button onClick={() => handleReview()} variant="contained">
            {t('Review')}
          </Button>
        </>
      }
      open={showReviewTermsPopup}
      onClose={handleClose}
      title={t('You are In')}>
      <Box display="flex" flexDirection="column" style={{ gap: '18px' }}>
        <Typography variant="subtitle1">
          {t('You have created your Agrible account')}
        </Typography>
        <Divider />
        <Typography variant="h4">{t('Before Continuing')}</Typography>
        <Typography variant="subtitle1">
          {t('Please review and agree to the Terms and Conditions')}
        </Typography>
      </Box>
    </Dialog>
  )
}

YouAreIn.propTypes = {
  setShowTermsandCondDrawer: PropTypes.func
}

export default YouAreIn
