import React, { useReducer, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Grid from '@nutrien/uet-react/Grid'
import Button from '@nutrien/uet-react/Button'
import Checkbox from '@nutrien/uet-react/Checkbox'
import Table from '@nutrien/uet-react/Table'
import TableHead from '@nutrien/uet-react/TableHead'
import TableBody from '@nutrien/uet-react/TableBody'
import TableCell from '@nutrien/uet-react/TableCell'
import TableRow from '@nutrien/uet-react/TableRow'
import TableContainer from '@nutrien/uet-react/TableContainer'
import Box from '@nutrien/uet-react/Box'
import AppBar from '@nutrien/uet-react/AppBar'
import Toolbar from '@nutrien/uet-react/Toolbar'
import _get from 'lodash/get'
import _map from 'lodash/map'

import Intercom from 'components/Intercom'
import th from 'theme/helpers'

import reducer, { SET_PERM, SELECT_ALL, CLEAR_ALL, RESET } from './reducer'

const StyledTableCell = styled(TableCell)`
  border-bottom: none;
  padding-left: 0;
  padding-right: 0;
`

const StyledTableRow = styled(TableRow)`
  & > th {
    width: 15%;
    padding-left: ${th.spacing(1.5)};

    &:first-child {
      padding-left: 0;
      width: 40%;
    }

    ${th.breakpoints.down('sm')`
      width: 20% !important;
    `}
  }
`

const StyledAppBar = styled(AppBar)`
  top: auto;
  bottom: 0;
  background-color: ${th.palette('common.white')};
`

const StyledToolbar = styled(Toolbar)`
  justify-content: flex-end;

  & > * {
    margin-left: ${th.spacing(2)};
  }
`

const transformPerms = perms =>
  _map(perms, (perm, slug) => ({
    slug,
    accessLevels: [
      perm.canView && 'view',
      perm.canAdd && 'add',
      perm.canChange && 'change',
      perm.canDelete && 'delete'
    ].filter(Boolean)
  }))

const PermSelector = ({ userPerms, onSave, canEdit }) => {
  const { t } = useTranslation()
  const [state, dispatch] = useReducer(reducer, userPerms)

  const updatePerm = useCallback(
    (slug, perm, value) =>
      dispatch({ type: SET_PERM, payload: { slug, perm, value } }),
    []
  )

  const getValue = useCallback(
    (slug, perm) => _get(state, [slug, perm], false),
    [state]
  )

  const handleChange = (slug, perm) => e =>
    updatePerm(slug, perm, e.target.checked)

  const handleCancel = useCallback(
    () => dispatch({ type: RESET, payload: userPerms }),
    [userPerms]
  )

  const handleSave = useCallback(() => {
    onSave(transformPerms(state))
  }, [onSave, state])

  const handleSelectAll = useCallback(() => dispatch({ type: SELECT_ALL }), [])

  const handleClearAll = useCallback(() => dispatch({ type: CLEAR_ALL }), [])

  const isDirty = userPerms !== state

  return (
    <Box>
      <Grid container justify="space-between">
        <Grid item xs={12} sm={12} md={8}>
          <TableContainer>
            <Table border={0}>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>{t('product')}</StyledTableCell>
                  <StyledTableCell>{t('view')}</StyledTableCell>
                  <StyledTableCell>{t('add')}</StyledTableCell>
                  <StyledTableCell>{t('change')}</StyledTableCell>
                  <StyledTableCell>{t('delete')}</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {_map(userPerms, (perm, slug) => (
                  <TableRow key={slug}>
                    <StyledTableCell>{t(slug)}</StyledTableCell>

                    <StyledTableCell padding="checkbox">
                      {perm.hasView && (
                        <Checkbox
                          disabled={!canEdit}
                          onChange={handleChange(slug, 'canView')}
                          checked={getValue(slug, 'canView')}
                        />
                      )}
                    </StyledTableCell>
                    <StyledTableCell padding="checkbox">
                      {perm.hasAdd && (
                        <Checkbox
                          disabled={!canEdit}
                          onChange={handleChange(slug, 'canAdd')}
                          checked={getValue(slug, 'canAdd')}
                        />
                      )}
                    </StyledTableCell>
                    <StyledTableCell padding="checkbox">
                      {perm.hasChange && (
                        <Checkbox
                          disabled={!canEdit}
                          onChange={handleChange(slug, 'canChange')}
                          checked={getValue(slug, 'canChange')}
                        />
                      )}
                    </StyledTableCell>
                    <StyledTableCell padding="checkbox">
                      {perm.hasDelete && (
                        <Checkbox
                          disabled={!canEdit}
                          onChange={handleChange(slug, 'canDelete')}
                          checked={getValue(slug, 'canDelete')}
                        />
                      )}
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        {canEdit && (
          <Grid item xs={12} sm={12} md={2}>
            <Button onClick={handleSelectAll}>{t('select_all')}</Button>
            <Button onClick={handleClearAll}>{t('clear_all')}</Button>
          </Grid>
        )}
      </Grid>

      <StyledToolbar />
      <StyledAppBar position="fixed" elevation={0} component="div">
        <StyledToolbar>
          <Button variant="outlined" onClick={handleCancel} disabled={!isDirty}>
            {t('cancel')}
          </Button>
          <Button variant="contained" onClick={handleSave} disabled={!isDirty}>
            {t('save')}
          </Button>
          <Intercom />
        </StyledToolbar>
      </StyledAppBar>
    </Box>
  )
}

PermSelector.propTypes = {
  userPerms: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired
}

PermSelector.defaultProps = {}

export default React.memo(PermSelector)
