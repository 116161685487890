import React from 'react'
import { useTranslation } from 'react-i18next'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { pluralizeEntity } from '../../../../../../helpers'

const NoEntity = ({ type }) => {
  const { t } = useTranslation('farm-tree-navigation')

  return (
    <ListItem>
      <ListItemText
        data-testid={'no-entity-label'}
        primary={t('No {{name}}', { name: t(pluralizeEntity(type)) })}
        primaryTypographyProps={{ variant: 'subtitle1' }}
      />
    </ListItem>
  )
}

export default NoEntity
