import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import useMediaQuery from '@nutrien/uet-react/useMediaQuery'
import useTheme from '@nutrien/uet-react/styles/useTheme'

function ResetScroll() {
  const { hash, pathname, search } = useLocation()
  const path = pathname + search

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    // only reset the scroll if there are no hash
    // this prevents a a conflict in components that uses
    // useScrollIntoView or useScrollToHash
    if (!hash) {
      const scrollContainer = document.getElementById('scroll-container')
      if (!isMobile && scrollContainer && scrollContainer.scrollTo)
        setTimeout(() => scrollContainer.scrollTo(0, 0))
      else {
        setTimeout(() => window.scrollTo(0, 0))
      }
    }
  }, [hash, path, isMobile])

  return null
}

export default ResetScroll
