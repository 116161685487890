import React from 'react'

export const PDFIcon = ({ height = 24, width = 24 }) => (
  <svg
    style={{ verticalAlign: 'middle' }}
    width={width}
    height={height}
    viewBox="0 0 350 350"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M280 43.75H83.125C57.9687 43.75 43.75 57.9687 43.75 83.125V280C43.75 292.031 57.9687 306.25 83.125 306.25H280C292.031 306.25 306.25 292.031 306.25 280V83.125C306.25 57.9687 292.031 43.75 280 43.75ZM135.625 161.875C135.625 180.833 124.687 192.5 109.375 188.125H96.25V227.5H70V122.5H109.375C123.594 122.5 134.531 134.167 135.625 148.75V161.875ZM214.375 201.25C214.375 215.833 203.252 227.5 188.125 227.5H148.75V122.5H188.125C203.252 122.5 214.375 134.167 214.375 148.75V201.25ZM280 122.5V148.75H253.75V161.875H280V188.125H253.75V227.5H227.5V122.5H280ZM188.125 148.75V201.25H175V148.75H188.125ZM109.375 148.75V161.875H96.25V148.75H109.375Z"
      fill="#666666"
    />
  </svg>
)
