export default function callApi(authentication, endpoint, options = {}) {
  const token = authentication.accessToken()
  const { authAuthority } = window.uiConf
  const url = `${authAuthority}${endpoint}`

  return fetch(url, {
    ...options,
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      ...options.headers
    }
  })
}
