import reduce from 'lodash/reduce'
import { isArray, isFunction, orderBy, snakeCase } from 'lodash'

export const filterMap = (array, callback) =>
  reduce(
    array,
    (acc, curr) => {
      const result = callback(curr)
      return result === null || result ? acc.concat(result) : acc
    },
    []
  )

/**
 * use with spread operator to conditionally insert elements to arrays/objects
 * @example [{ name: 'staticElement' }, ...insertIf(shouldInsert, [{ name: 'conditionalElement' }])]
 * @example { type: 'objType', ...insertIf(shouldInsert, { subtype: 'conditionalSubtype' })}
 */
export const insertIf = (condition, insert) => (condition ? insert : [])

export const orderByInsensitive = (collection, iteratees, orders) => {
  const normalizedIteratees = isArray(iteratees) ? iteratees : [iteratees]
  return orderBy(
    collection,
    normalizedIteratees.map(iteratee =>
      isFunction(iteratee) ? iteratee : o => String(o[iteratee]).toLowerCase()
    ),
    orders
  )
}

export const generateArrayOf = (num, func) => [...Array(num)].map(func)

export const toLowerSnakeCase = str =>
  typeof str === 'string' ? snakeCase(str.toLowerCase()) : null
