import React from 'react'
import Popover from '@nutrien/uet-react/Popover'

/**
 * this is just a wrapper for the UET Popover.
 * go to https://v4.mui.com/components/popover/
 * to see all the props available for the Popover
 */

const DropdownPopover = ({ ...props }) => {
  return (
    <Popover
      elevation={1}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      {...props}
    />
  )
}

export default DropdownPopover
