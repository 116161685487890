import React from 'react'

export const DOCIcon = ({ height = 24, width = 24 }) => (
  <svg
    style={{ verticalAlign: 'middle' }}
    width={width}
    height={height}
    viewBox="0 0 350 350"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M280 43.75H83.125C58.3333 43.75 43.75 58.3333 43.75 83.125V280C43.75 291.667 58.3333 306.25 83.125 306.25H280C291.667 306.25 306.25 291.667 306.25 280V83.125C306.25 58.3333 291.667 43.75 280 43.75Z"
      fill="#666666"
    />
    <path
      d="M78.75 131.249C83.125 129.791 91.875 129.791 100.625 129.791C113.75 129.791 122.5 132.708 129.792 138.541C138.542 145.833 142.917 158.958 142.917 177.916C142.917 196.874 138.542 211.458 128.333 220.208C121.042 226.041 112.292 228.958 97.7083 228.958C90.4167 228.958 84.5833 228.958 80.2083 227.499V131.249H78.75ZM100.625 209.999C102.083 209.999 102.083 209.999 103.542 209.999C110.833 209.999 118.125 201.249 118.125 176.458C118.125 158.958 113.75 147.291 103.542 147.291C102.083 147.291 100.625 147.291 100.625 147.291V209.999Z"
      fill="white"
    />
    <path
      d="M215.833 176.458C215.833 212.916 202.708 228.958 182.291 228.958C157.5 228.958 150.208 202.708 150.208 177.916C150.208 153.125 160.416 128.333 183.75 128.333C211.458 128.333 215.833 157.5 215.833 176.458ZM173.541 177.916C173.541 201.25 177.916 208.541 183.75 208.541C189.583 208.541 192.5 196.875 192.5 176.458C192.5 158.958 189.583 147.291 183.75 147.291C177.916 147.291 173.541 157.5 173.541 177.916Z"
      fill="white"
    />
    <path
      d="M280 226.041C277.084 227.5 271.25 227.5 265.417 227.5C237.709 227.5 226.042 204.166 226.042 179.375C226.042 142.916 246.459 128.333 268.334 128.333C275.625 128.333 280 129.791 281.459 131.25L277.084 150.208C274.167 148.75 272.709 148.75 268.334 148.75C256.667 148.75 249.375 157.5 249.375 179.375C249.375 199.791 256.667 208.541 268.334 208.541C271.25 208.541 274.167 208.541 277.084 207.083L280 226.041Z"
      fill="white"
    />
  </svg>
)
