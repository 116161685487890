import { createBrowserHistory } from 'history'

const history = createBrowserHistory()

history.listen(location => {
  if (typeof window.gtag === 'function') {
    window.gtag('config', window.uiConf.gaAccount, {
      page_path: `${location.pathname}${location.search}`
    })
  }
})

export default history
