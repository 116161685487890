import { css } from 'styled-components'
import _get from 'lodash/get'

const pxIfNotZero = val => val && `${val}px`

const breakpointHelper = (theme, dir, bp, styles, interpolations) => css`
  ${theme.breakpoints[dir](bp)} {
    ${css(styles, ...interpolations)}
  }
`

export default {
  breakpoints: {
    down: bp => (styles, ...interpolations) => ({ theme }) =>
      breakpointHelper(theme, 'down', bp, styles, interpolations),

    up: bp => (styles, ...interpolations) => ({ theme }) =>
      breakpointHelper(theme, 'up', bp, styles, interpolations)
  },

  palette: value => ({ theme }) =>
    _get(theme.palette, value, theme.palette.common.black),

  spacing: (...nums) => ({ theme }) =>
    nums
      .map(n => (n === 'auto' ? 'auto' : pxIfNotZero(theme?.spacing?.(n))))
      .join(' ')
}
