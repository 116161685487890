import _get from 'lodash/get'
import _isObject from 'lodash/isObject'

const regex = new RegExp(/^DOS API failed[\s\S]*:[\s]*([\S\s]*)/, 'i')
const pruneErrorMessage = error => {
  const message = _isObject(error) ? error.message : error
  // Guarantee string or undefined, do not return an object
  // This makes || blocks easier for consumers
  if (typeof message !== 'string') {
    return
  }
  const match = regex.exec(message)
  return match ? match[1] || match[0] : message
}

export const getGraphQLError = error => {
  const messages = _get(error, 'graphQLErrors', [])
    .map(pruneErrorMessage)
    .filter(Boolean)

  // Assumes all errors are appended with a dot already
  return (messages.length && messages.join(' ')) || pruneErrorMessage(error)
}

export const getDosErrors = error => {
  try {
    if (error.graphQLErrors[0].extensions.messages[0].length === 1) {
      return ['Something went wrong']
    }
    return error.graphQLErrors[0].extensions.messages ?? []
  } catch (err) {
    console.error(err)
    return ['Something went wrong']
  }
}

export const getErrorMessage = (error, customMessage) => {
  if (!error) {
    return false
  }
  if (customMessage) {
    return customMessage
  }
  if (error.graphQLErrors) {
    return getGraphQLError(error)
  }
  if (error.httpError) {
    const { errors } = JSON.parse(error.httpError.body)
    return errors[0].message
  }
}
