import { useRef, useState, useCallback } from 'react'
import _attempt from 'lodash/attempt'
import _isError from 'lodash/isError'

export default (initialValue = '', schema) => {
  const [value, setValue] = useState(initialValue)
  const touched = useRef(false)
  const watcher = useRef(() => {})
  const validation = schema && _attempt(() => schema.validateSync(value))
  const errors = _isError(validation) ? validation.errors : undefined
  const hasError = errors && errors.length > 0

  return {
    value,
    setValue,
    hasError,
    reset: useCallback(() => setValue(''), []),
    watch: useCallback(fn => {
      watcher.current = fn
    }, []),
    bind: {
      value,
      onChange: useCallback(({ target }) => {
        touched.current = true
        setValue(target.value)
        watcher.current(target.value)
      }, []),
      ...(hasError && touched.current
        ? {
            error: true,
            helperText: errors[0]
          }
        : {})
    }
  }
}
