import { parse } from './date'

export const decodeRotationSeasonId = rotationSeasonId => {
  if (!rotationSeasonId || typeof rotationSeasonId !== 'string') return
  if (!rotationSeasonId.match(/\d+-\d+-\d+-\d+/g)) return

  const [planting, harvest, crop, specificCrop] = rotationSeasonId.split('-')

  return {
    planting: parse(planting, 'yyyyMMdd', 0),
    harvest: parse(harvest, 'yyyyMMdd', 0),
    cropId: Number(crop),
    specificCropId: Number(specificCrop)
  }
}
