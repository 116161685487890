import React, { useEffect, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'graphql-hooks'
import styled from 'styled-components'
import Dialog from '@nutrien/uet-react/Dialog'
import Button from '@nutrien/uet-react/Button'
import RadioGroup from '@nutrien/uet-react/RadioGroup'
import Radio from '@nutrien/uet-react/Radio'
import FormControlLabel from '@nutrien/uet-react/FormControlLabel'
import Typography from '@nutrien/uet-react/Typography'
import { useLocation } from 'react-router-dom'

import { goToJohnDeereOrgAuthorization } from 'store/actions/johnDeere'
import Retry from 'components/Retry'
import useInput from 'hooks/useInput'
import th from 'theme/helpers'

const StyledDialog = styled(Dialog)`
  .MuiDialogContent-root {
    padding-top: 0;
  }
`

const DialogContent = styled.div`
  border-top: 1px solid ${th.palette('divider')};
  height: 100%;
  box-sizing: border-box;
  padding: ${th.spacing(3, 0)};
`

const Circle = styled.div`
  width: 8px;
  height: 8px;
  background: ${props => (props.connectionsUri ? '#E5391A' : '#619B2D')};
  border-radius: 50%;
`

const StyledTypography = styled(Typography)`
  margin-bottom: ${th.spacing(2)};
  color: #666666;
  font-size: 16px;
`

const LabelContainer = styled.div`
  padding-left: 32px;
  display: flex;
  align-items: center;
  margin-top: -8px;
`

const AuthorizationStatus = styled(Typography)`
  padding-left: 6px;
  color: #666666;
`

const CircleContainer = styled.div`
  display: flex;
  height: 21px;
  align-items: center;
`

const JD_ORGS_QUERY = `
  query {
    getImportableOrgs {
      id
      name
      connectionsUri
    }
  }
`

const JohnDeereOrgSelector = ({
  onClose,
  onImportFields,
  setRequestPending
}) => {
  const { t } = useTranslation()
  const selectedOrg = useInput()
  const [authorized, setAuthorized] = useState()
  const { data, error, loading, refetch } = useQuery(JD_ORGS_QUERY)
  const location = useLocation()
  const orgs = data && data.getImportableOrgs

  useEffect(() => {
    if (Array.isArray(orgs) && orgs.length > 0) {
      if (!selectedOrg.value) {
        selectedOrg.setValue(orgs[0].id)
        setAuthorized(orgs[0].connectionsUri)
      } else {
        selectedOrg.setValue(selectedOrg.value)
        const org = orgs.find(org => org.id === selectedOrg.value)
        if (org) {
          setAuthorized(org.connectionsUri)
        }
      }
    }
  }, [orgs, selectedOrg])

  const buildRedirectUri = useCallback(() => {
    const domain = window.location.hostname
    if (domain === 'localhost') {
      return 'http://localhost:3000/fieldimport/jdimport-update-token'
    }
    return `https://${domain}/fieldimport/jdimport-update-token`
  }, [])

  const handleImportFields = useCallback(
    () => onImportFields(selectedOrg.value),
    [onImportFields, selectedOrg.value]
  )

  const handleAuthorizeOrg = useCallback(
    () =>
      goToJohnDeereOrgAuthorization(location, authorized, buildRedirectUri()),
    [location, authorized, buildRedirectUri]
  )

  useEffect(() => {
    setRequestPending(loading)
  }, [loading, setRequestPending])

  if (error) return <Retry handleRetry={refetch} />
  if (loading || !orgs) return null

  return (
    <StyledDialog
      title={t('import_fields')}
      open
      onClose={onClose}
      hasCloseIcon
      maxWidth="xs"
      closeIconSize="large"
      actions={
        <>
          <Button variant="outlined" onClick={onClose}>
            {t('cancel')}
          </Button>
          <Button
            variant="contained"
            onClick={authorized ? handleAuthorizeOrg : handleImportFields}>
            {authorized ? t('Authorize') : t('import_fields')}
          </Button>
        </>
      }>
      <DialogContent>
        <StyledTypography>{t('jd_ops_display_orgs_text')}</StyledTypography>

        <RadioGroup aria-label="org" name="org" {...selectedOrg.bind}>
          {orgs.map(org => (
            <>
              <FormControlLabel
                key={org.id}
                value={org.id}
                control={<Radio />}
                label={org.name}
              />
              <LabelContainer>
                <CircleContainer>
                  <Circle connectionsUri={org.connectionsUri} />
                </CircleContainer>
                <AuthorizationStatus>
                  {org.connectionsUri ? 'Unauthorized' : 'Authorized'}
                </AuthorizationStatus>
              </LabelContainer>
            </>
          ))}
        </RadioGroup>
      </DialogContent>
    </StyledDialog>
  )
}

JohnDeereOrgSelector.propTypes = {
  onClose: PropTypes.func.isRequired,
  onImportFields: PropTypes.func.isRequired,
  setRequestPending: PropTypes.func.isRequired,
  setRequestError: PropTypes.func.isRequired
}

export default React.memo(JohnDeereOrgSelector)
