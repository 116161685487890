import React, { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'propTypes'
import Typography from '@nutrien/uet-react/Typography'
import BonsaiDialog from 'components/Bonsai/BonsaiDialog'
import { AuthContext } from 'components/Auth/authentication-context'

const DeclineTermsDialog = ({
  showDeclineTermsDialog,
  setShowDeclineTermsDialog,
  setShowTermsandCondDrawer
}) => {
  const { t } = useTranslation()
  const { logout } = useContext(AuthContext)

  const handleLogout = useCallback(() => {
    setShowTermsandCondDrawer(false)
    logout()
  }, [logout, setShowTermsandCondDrawer])

  const handleClose = useCallback(() => {
    setShowDeclineTermsDialog(false)
  }, [setShowDeclineTermsDialog])

  return (
    <BonsaiDialog
      cancelLabel={t('back')}
      confirmLabel={t('Decline and Log Out')}
      content={
        <Typography>
          {t(
            'You can not use Agrible or the Digital Hub without accepting the terms and conditions'
          )}
        </Typography>
      }
      data-testid="action-button-remove-dialog"
      maxWidth="xs"
      onCancel={handleClose}
      onClose={handleClose}
      onConfirm={handleLogout}
      open={showDeclineTermsDialog}
      title={t('Decline Terms and Conditions')}
      variant="danger"
    />
  )
}

DeclineTermsDialog.propTypes = {
  setShowDeclineTermsDialog: PropTypes.func,
  showDeclineTermsDialog: PropTypes.bool,
  setShowTermsandCondDrawer: PropTypes.func
}

export default DeclineTermsDialog
