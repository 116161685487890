import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@nutrien/uet-react/styles/makeStyles'
import PropTypes from 'prop-types'
import bonsaiTheme from '@nutrien/bonsai-theme/build'
import Box from '@nutrien/uet-react/Box'
import Checkbox from '@nutrien/uet-react/Checkbox'
import Typography from '@nutrien/uet-react/Typography'
import Button from '@nutrien/uet-react/Button'
import Card from '@nutrien/uet-react/Card'
import { useMutation } from 'graphql-hooks'
import useFlags from 'hooks/useFlags'
import BonsaiDrawer from 'components/BonsaiDrawer'
import BonsaiExpandableCard from 'components/Bonsai/BonsaiExpandableCard/BonsaiExpandableCard'
import { AuthContext } from 'components/Auth/authentication-context'
import TermsAndConditionsContent from './TermsAndConditionsContent/'

const useTermsAndCondDrawerStyles = makeStyles(theme => ({
  declineButton: {
    borderColor: bonsaiTheme.colors.alert.danger,
    color: bonsaiTheme.colors.alert.danger
  },
  drawerContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 32px',
    rowGap: 24
  },
  drawerFooter: {
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    bottom: 0,
    display: 'flex',
    justifyContent: 'space-between',
    left: 0,
    padding: '8px 30px',
    position: 'sticky'
  }
}))

const UPDATE_USER_TC_VERSION = `
  mutation updateAgribleTermsAndConditions($version: String!, $accessToken: String){
    updateAgribleTermsAndConditions(version: $version, accessToken: $accessToken)
  }
`

const TermsAndConditionsDrawer = ({
  showTermsandCondDrawer,
  setShowTermsandCondDrawer,
  setShowDeclineTermsDialog
}) => {
  const flags = useFlags()
  const { t } = useTranslation()
  const classes = useTermsAndCondDrawerStyles()
  const [checked, setChecked] = useState(false)
  const { isSSOUser, getSSOAccessToken, user, setUser } = useContext(
    AuthContext
  )
  const [updateTCversion] = useMutation(UPDATE_USER_TC_VERSION)
  const handleCheckboxOnchange = () => {
    setChecked(!checked)
  }

  const handleDecline = () => {
    setShowDeclineTermsDialog(true)
  }

  const { appEnv } = window.uiConf
  const handleAccept = async () => {
    const accessToken = isSSOUser ? await getSSOAccessToken() : ''
    const variables = {
      version: flags.tcVersion[appEnv],
      accessToken: `AccessToken ${accessToken}`
    }
    await updateTCversion({
      variables: variables
    })
    user.profile.agribleTermsAndConditions = flags.tcVersion[appEnv]
    setUser(user)
    setShowTermsandCondDrawer(false)
  }

  return (
    <BonsaiDrawer
      onClose={handleDecline}
      open={showTermsandCondDrawer}
      title={t('Terms and Conditions')}
      width="100vw">
      <Box className={classes.drawerContent}>
        <BonsaiExpandableCard
          cardTitle={t(
            'General Terms and Conditions for the Nutrien Ag Solutions Digital Hub, Nutrien Ag Solutions HUB and Agrible Platforms'
          )}
          collapseButton={false}
          expandOnLoad>
          <Box
            display="flex"
            flexDirection="column"
            style={{ gap: bonsaiTheme.spacing(2) }}>
            <TermsAndConditionsContent />
          </Box>
        </BonsaiExpandableCard>
        <Card>
          <Box alignItems="center" display="flex">
            <Checkbox onChange={handleCheckboxOnchange} />
            <Typography variant="subtitle1">
              {t('I have read and agree to the Terms and Conditions')}
            </Typography>
          </Box>
        </Card>
      </Box>
      <Box className={classes.drawerFooter}>
        <Typography variant="body2">
          {t('Please scroll down to agree')}
        </Typography>
        <Box display="flex" style={{ gap: bonsaiTheme.spacing(1.25) }}>
          <Button
            className={classes.declineButton}
            data-testid="declineButton"
            onClick={handleDecline}
            variant="outlined">
            {t('decline')}
          </Button>
          <Button
            data-testid="acceptButton"
            disabled={!checked}
            onClick={handleAccept}
            variant="contained">
            {t('accept')}
          </Button>
        </Box>
      </Box>
    </BonsaiDrawer>
  )
}

TermsAndConditionsDrawer.propTypes = {
  setShowDeclineTermsDialog: PropTypes.func,
  setShowTermsandCondDrawer: PropTypes.func,
  showTermsandCondDrawer: PropTypes.bool
}

export default TermsAndConditionsDrawer
