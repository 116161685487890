import React from 'react'
import Box from '@nutrien/uet-react/Box'
import Paper from '@nutrien/uet-react/Paper'
import makeStyles from '@nutrien/uet-react/styles/makeStyles'
import useFlags from 'hooks/useFlags'
import BannerMaintenance from 'components/BannerMaintenance'
import SvgIcon from 'components/SvgIcon'
import { NutrienAgribleCoBrandingLogo } from 'components/Icons'
import { SameAgribleBanner } from './SameAgribleBanner'
import { AuthenticationFooter } from './AuthenticationFooter'

function HeaderLogo() {
  return (
    <Box marginY={5}>
      <SvgIcon
        style={{
          width: '262px',
          height: 'auto'
        }}
        viewBox="0 0 262 87"
        component={NutrienAgribleCoBrandingLogo}
      />
    </Box>
  )
}

const useAuthenticationContainerStyles = makeStyles(theme => ({
  pageBox: {
    height: '100%',
    backgroundImage: `url('https://static.nutrienagsolutions.com/backgrounds/farmers_pointing.jpg')`,
    backgroundPositionX: 1100,
    transition: 'background-position 1s',
    overflow: 'hidden',
    backgroundSize: 'cover',

    [theme.breakpoints.up('lg')]: {
      backgroundPositionX: 0
    },
    [theme.breakpoints.up('md')]: {
      backgroundPositionX: 0
    }
  },
  dialogContainer: {
    flex: 1,
    overflow: 'auto',
    width: '100%'
  },
  dialogContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
    minHeight: '100%',
    boxSizing: 'border-box',

    [theme.breakpoints.down('xs')]: {
      alignItems: 'initial'
    }
  },
  paperRoot: {
    [theme.breakpoints.down('xs')]: {
      height: '100%'
    }
  }
}))
export const AuthenticationContainer = ({ children }) => {
  const flags = useFlags()
  const styles = useAuthenticationContainerStyles()
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      className={styles.pageBox}>
      {flags?.maintBannerAndText?.maintenanceActive && (
        <BannerMaintenance message={flags?.maintBannerAndText?.message} />
      )}
      <SameAgribleBanner />

      <Box className={styles.dialogContainer} id="scroll-container">
        <Box className={styles.dialogContent}>
          <Box maxWidth="391px">
            <Paper classes={{ root: styles.paperRoot }} elevation={2}>
              <Box
                padding={3}
                alignItems="center"
                display="flex"
                flexDirection="column"
                boxSizing="border-box"
                height="100%">
                <HeaderLogo />
                {children}
                <AuthenticationFooter></AuthenticationFooter>
              </Box>
            </Paper>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
