import React from 'react'
import PropTypes from 'prop-types'
import Box from '@nutrien/uet-react/Box'
import Typography from '@nutrien/uet-react/Typography'
import Paper from '@nutrien/uet-react/Paper'
import SvgIcon from 'components/SvgIcon'

const StepProgressCard = ({
  children,
  icon,
  iconProps,
  title,
  wrapInPaper,
  ...props
}) => {
  const { styles: iconPropsStyles, ...otherIconProps } = iconProps || {}
  const content = (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100%"
      borderRadius="4px"
      border={wrapInPaper ? undefined : '1px solid'}
      borderColor="grey.300"
      boxSizing="border-box"
      p={3}
      {...props}>
      <Box mb={1}>
        <SvgIcon
          component={icon}
          viewBox="0 0 111 59"
          style={
            iconPropsStyles || {
              height: '59px',
              fontSize: '111px'
            }
          }
          {...otherIconProps}
        />
      </Box>
      <Box>
        <Typography variant="h5">{title}</Typography>
      </Box>

      {children}
    </Box>
  )

  if (wrapInPaper) {
    return <Paper>{content}</Paper>
  }

  return content
}

StepProgressCard.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.elementType,
  iconProps: PropTypes.shape(SvgIcon.PropTypes),
  title: PropTypes.string,
  ...Box.propTypes
}

export default StepProgressCard
