const EVENT_PANEL = 'event_panel'

export const PANELS = Object.freeze({
  EVENT_PANEL
})

const ADD = 'add'
const CREATE = 'create'
const CUSTOM = 'custom'
const EDIT = 'edit'
const EDITANDCOPY = 'editAndCopy'
const SAVE = 'save'

export const FORM_TYPES = Object.freeze({
  ADD,
  CREATE,
  CUSTOM,
  EDIT,
  EDITANDCOPY,
  SAVE
})

export const BANDING = 'Banding'
export const BANDED = 'Banded'
