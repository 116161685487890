import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import Dialog from '@nutrien/uet-react/Dialog'

import th from 'theme/helpers'
import useJohnDeereCreds from 'hooks/useJohnDeereCreds'
import { IntercomAPI } from 'components/Intercom'
import withRequest from 'HOCs/withRequest'
import useToggle from 'hooks/useToggle'

import EntryTypeSelector from './components/EntryTypeSelector'
import MapEntryWizard from './components/MapEntryWizard'
import JohnDeereWizard from './components/JohnDeereWizard'

const titleMap = {
  map: 'map_entry',
  johnDeere: 'home_easy_setup_johnd_deere_title'
}

const StyledDialog = styled(Dialog)`
  .MuiDialogContent-root {
    padding: 0;
    display: flex;
    flex-direction: column;
  }
`

const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  border-top: 1px solid ${th.palette('divider')};
  height: 100%;
  box-sizing: border-box;
`

const AddFieldWizard = ({
  noRedirect,
  onClose,
  setRequestPending,
  setRequestError,
  selectedEntity,
  onAddFieldSuccess
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const jdCreds = useJohnDeereCreds()
  const [entryType, setEntryType] = useState(null)
  const [showDialog, setShowDialog] = useState()
  const [closeOnEscape, toggleCloseOnEscape] = useToggle(true)

  // If we just came back from JD, then automatically set the entryType
  // for the JD wizard to take control of the further steps
  useEffect(() => {
    if (jdCreds) setEntryType('johnDeere')
  }, [history, jdCreds])

  const handleSelectEntry = entry => {
    if (entry === 'shapefile') {
      return IntercomAPI('showNewMessage')
    }
    setEntryType(entry)
  }
  const handleClose = useCallback(() => {
    if (entryType) setEntryType(null)
    else onClose()
  }, [entryType, onClose])

  useEffect(() => {
    setShowDialog(sessionStorage.getItem('orgAuthorization'))
  }, [])

  return (
    <StyledDialog
      title={t(titleMap[entryType] || 'add_fields')}
      open
      fullScreen
      onClose={handleClose}
      disableEscapeKeyDown={!closeOnEscape}
      hasCloseIcon
      closeIconSize="large">
      <DialogContent>
        {(!entryType || entryType === 'johnDeere') && (
          <EntryTypeSelector
            onSelect={handleSelectEntry}
            setRequestPending={setRequestPending}
            setRequestError={setRequestError}
          />
        )}

        {entryType === 'map' && (
          <MapEntryWizard
            onAddFieldSuccess={onAddFieldSuccess}
            selectedEntity={selectedEntity}
            onClose={handleClose}
            onDrawStart={toggleCloseOnEscape}
            onDrawEnd={toggleCloseOnEscape}
          />
        )}
        {showDialog && (
          <JohnDeereWizard
            fromJD
            onCloseAll={onClose}
            setShowDialog={setShowDialog}
            noRedirect={noRedirect}
          />
        )}
        {entryType === 'johnDeere' && (
          <JohnDeereWizard
            onCloseAll={onClose}
            onClose={handleClose}
            noRedirect={noRedirect}
          />
        )}
      </DialogContent>
    </StyledDialog>
  )
}

AddFieldWizard.propTypes = {
  noRedirect: PropTypes.bool,
  onAddFieldSuccess: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  selectedEntity: PropTypes.object,
  setRequestPending: PropTypes.func.isRequired,
  setRequestError: PropTypes.func.isRequired
}

export default withRequest(AddFieldWizard)
