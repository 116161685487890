import callApi from 'helpers/api'

export const gotoJohnDeere = async (authentication, location) => {
  const url = '/jdimport/get_oauth2_url'
  const response = await callApi(authentication, url)
  const data = await response.json()
  const jdUrl = data.url

  const { pathname, search } = location
  sessionStorage.setItem('continue_pathname', pathname)
  sessionStorage.setItem('continue_search', search)
  window.location.assign(jdUrl)
}

export const goToJohnDeereOrgAuthorization = async (
  location,
  jdUrl,
  redirectUrl
) => {
  const url = `${jdUrl}?redirect_uri=${encodeURIComponent(redirectUrl)}`
  const { pathname } = location
  sessionStorage.setItem('continue_pathname', pathname)
  sessionStorage.setItem('continue_search', '?panel=add_field_options')
  sessionStorage.setItem('orgAuthorization', 'true')
  window.location.assign(url)
}
