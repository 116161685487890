import styled from 'styled-components'
import Typography from '@nutrien/uet-react/Typography'

import th from 'theme/helpers'

export const Title = styled(Typography).attrs({
  variant: 'h1',
  align: 'center'
})`
  margin: ${th.spacing(2, 0)};
`
