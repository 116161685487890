import { useFlags as useLDFlags } from 'launchdarkly-react-client-sdk'

import { isOverridable, getOverrides } from 'HOCs/LDOverride'

const useFlags = () => {
  let flagsOverride
  try {
    flagsOverride = getOverrides()
  } catch {
    flagsOverride = {}
  }

  const ldFlags = useLDFlags()

  if (isOverridable) {
    const mergedFlags = { ...ldFlags, ...flagsOverride }
    return mergedFlags
  }

  return ldFlags
}

export default useFlags
