import React, { useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@nutrien/uet-react/Box'
import Typography from '@nutrien/uet-react/Typography'
import Button from '@nutrien/uet-react/Button'
import ExitToApp from '@nutrien/uet-react/icons/ExitToApp'
import { AuthContext } from 'components/Auth/authentication-context'
import SvgIcon from 'components/SvgIcon'
import useAuthorshipSession from 'hooks/useAuthorshipSession'
import useIdle from 'hooks/useIdleTimer'
import ConfirmExitGrowerSession from 'components/ConfirmExitGrowerSession'

const GrowerSessionBanner = () => {
  const { user } = useContext(AuthContext)
  const {
    authorshipSessionActive,
    setAuthorshipSessionActive
  } = useAuthorshipSession()
  const [
    openConfirmExitSessionDialog,
    setOpenConfirmExitSessionDialog
  ] = useState(false)

  const { t } = useTranslation()

  useEffect(() => {
    if (
      sessionStorage.getItem('authorshipSession') &&
      sessionStorage.getItem('authorshipGrower')
    ) {
      setAuthorshipSessionActive(true)
    }
  }, [setAuthorshipSessionActive, user])

  const handleExitSession = async () => {
    sessionStorage.removeItem('authorshipSession')
    sessionStorage.removeItem('authorshipGrower')
    const exitTo = sessionStorage.getItem('currentLocation')
    window.location.href = exitTo
    sessionStorage.removeItem('currentLocation')
  }

  const confirmExitSession = () => {
    setOpenConfirmExitSessionDialog(true)
  }

  // Note:
  // 1)Commented out 'onPrompt' and 'onIdle' to prevent the popup expiring
  // on authership session timout.
  // 2)increased idle time from 10 to 20 minutes.
  const { start: startIdleTimer, reset: resetIdleTimer } = useIdle({
    // onPrompt: confirmExitSession,
    onIdle: confirmExitSession,
    // onIdle: handleExitSession,
    // promptIdleTime: 1,
    idleTime: 20
  })

  const closeDialog = () => {
    setOpenConfirmExitSessionDialog(false)
    resetIdleTimer()
  }

  if (!authorshipSessionActive) return null

  startIdleTimer()

  return (
    <Box
      width="100%"
      display="flex"
      alignItems="center"
      bgcolor="info.main"
      right={0}
      left={0}
      minHeight={80}
      color="info.contrastText">
      <Box
        pl={3}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexGrow={1}>
        <Box color="info.contrastText" style={{ cursor: 'default' }}>
          <Typography variant="body1">
            {t('You are viewing the dashboard of')}
          </Typography>
          <Typography variant="h3">{`${user?.firstName} ${user?.lastName}`}</Typography>
        </Box>
      </Box>
      <Box pr={2} color="info.contrastText">
        <Button
          onClick={handleExitSession}
          style={{ color: '#fff' }}
          aria-label="exit">
          <Typography variant="h3" style={{ paddingRight: 8 }}>
            {t('exit')}
          </Typography>
          <SvgIcon color="info.contrastText" component={ExitToApp} />
        </Button>
      </Box>
      <ConfirmExitGrowerSession
        open={openConfirmExitSessionDialog}
        handleExitSession={handleExitSession}
        closeDialog={closeDialog}
      />
    </Box>
  )
}

export default GrowerSessionBanner
