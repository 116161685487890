import React, { useState, useCallback, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Box from '@nutrien/uet-react/Box'
import EditIcon from '@nutrien/uet-react/icons/Edit'
import Typography from '@nutrien/uet-react/Typography'
import Button from '@nutrien/uet-react/Button'
import Snackbar from '@nutrien/uet-react/Snackbar'
import { ClientContext } from 'graphql-hooks'
import AddIcon from '@nutrien/uet-react/icons/Add'
import withRequest from 'HOCs/withRequest'
import { callQueryWithAccessToken } from 'helpers/callQueryWithAccessToken'
import { AuthContext } from 'components/Auth/authentication-context'
import Loading from 'components/Loading'
import LinkAccountDialog from './LinkAccountDialog'
import EditFinancialAcct from './EditFinancialAcct'

const GET_PAYMENT_ACCOUNT_QUERY = `
query PaymentAccounts{
  paymentAccounts{
    number
    locationCode
    customerType
    nickname
  }
}`

const FinancialAccount = ({ setRequestPending, setRequestError }) => {
  const { t } = useTranslation()
  const [addAccount, setAddAccount] = useState(false)
  const { getSSOAccessToken, isSSOUser, isEmployee } = useContext(AuthContext)
  const graphqlClient = useContext(ClientContext)
  const [paymentAccounts, setPaymentAccounts] = useState([])
  const [paymentAccountsLoading, setPaymentAccountsLoading] = useState(
    isSSOUser && !isEmployee
  )
  const [displaySuccessSnackbar, setDisplaySuccessSnackbar] = useState(false)
  const [accountToEdit, setAccountToEdit] = useState(false)
  const getPaymentAccounts = useCallback(async () => {
    const accessToken = await getSSOAccessToken()
    const { data, loading } = await callQueryWithAccessToken(
      graphqlClient,
      GET_PAYMENT_ACCOUNT_QUERY,
      accessToken
    )
    setPaymentAccountsLoading(loading)
    // reverse the list so that it displays oldest accounts first.
    data?.paymentAccounts?.length &&
      setPaymentAccounts(data.paymentAccounts.reverse())
  }, [getSSOAccessToken, graphqlClient])

  useEffect(() => {
    isSSOUser && !isEmployee && getPaymentAccounts()
  }, [getPaymentAccounts, isSSOUser, isEmployee])

  return (
    <div>
      <Box width="100%" display="flex" justifyContent="space-between" pb={2}>
        <Typography variant="h3">{t('financial_accounts')}</Typography>
      </Box>
      {paymentAccountsLoading ? (
        <Box mt={5}>
          <Loading />
        </Box>
      ) : !paymentAccounts.length ? (
        <Box pt={2} width="45%">
          <Typography variant="body2">
            {t('link_your_financial_account')}
          </Typography>
        </Box>
      ) : (
        <>
          {paymentAccounts.map(paymentAccount => {
            const { number, nickname } = paymentAccount
            return (
              <Box
                width="100%"
                display="flex"
                flexDirection="row"
                py={2}
                borderBottom="solid 1px #cccccc"
                key={paymentAccount.number}>
                <Box
                  width="100%"
                  display="flex"
                  flexDirection="column"
                  style={{ gap: 25 }}>
                  <Box>
                    <Typography variant="h5">{t('account_number')}</Typography>
                    <Typography variant="body2">{number}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="h5">
                      {t('account_nickname')}
                    </Typography>
                    <Typography variant="body2">{nickname}</Typography>
                  </Box>
                </Box>
                <Box pt={3}>
                  <Button
                    style={{ alignSelf: 'end' }}
                    startIcon={<EditIcon />}
                    variant="outlined"
                    onClick={() =>
                      setAccountToEdit({
                        number,
                        nickname
                      })
                    }>
                    {t('edit')}
                  </Button>
                </Box>
              </Box>
            )
          })}
        </>
      )}
      {!paymentAccountsLoading && (
        <Box mt={5}>
          <Button
            startIcon={<AddIcon />}
            variant={paymentAccounts.length ? 'outlined' : 'contained'}
            disabled={!isSSOUser || isEmployee}
            onClick={() => {
              setAddAccount(true)
            }}
            size="large">
            {paymentAccounts.length ? t('link_another') : t('link_account')}
          </Button>
        </Box>
      )}
      <Snackbar
        open={displaySuccessSnackbar}
        autoHideDuration={5000}
        message={displaySuccessSnackbar}
        onClose={() => setDisplaySuccessSnackbar(false)}
      />
      {addAccount && (
        <LinkAccountDialog
          onClose={() => {
            setAddAccount(false)
          }}
          onSuccess={() => {
            isSSOUser && getPaymentAccounts()
            setDisplaySuccessSnackbar(t('Account linked successfully'))
            setAddAccount(false)
          }}
          setRequestPending={setRequestPending}
          setRequestError={setRequestError}
        />
      )}
      <EditFinancialAcct
        onClose={() => {
          setAccountToEdit(false)
        }}
        open={!!accountToEdit}
        accountToEdit={accountToEdit}
        getPaymentAccounts={getPaymentAccounts}
        setRequestPending={setRequestPending}
        setDisplaySuccessSnackbar={setDisplaySuccessSnackbar}
      />
    </div>
  )
}

FinancialAccount.propTypes = {
  setRequestPending: PropTypes.func.isRequired,
  setRequestError: PropTypes.func.isRequired
}

FinancialAccount.defaultProps = {}

export default React.memo(withRequest(FinancialAccount))
