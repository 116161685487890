import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import qs from 'qs'

/**
 * Parses the search params
 *
 * @param {Object} options contains all options for decoding
 * @param {Boolean} options.parseNumbers parses the numbers when set to true
 * @param {Boolean} options.parseBool parses the boolean when set to true
 * @param {Boolean} options.ignoreNull ignore null values when set to true
 * @param {Boolean} options.ignoreEmptySring ignores empty string when set to true
 */
export default ({
  parseNumbers = false,
  parseBool = false,
  ignoreNull = false,
  ignoreEmptyString = false
} = {}) => {
  const location = useLocation()
  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        // based the custom decoder from https://github.com/ljharb/qs/issues/91#issuecomment-522289267
        // this will give us options to parse boolean and numbers if we need to
        decoder(str, decoder, charset) {
          const strWithoutPlus = str.replace(/\+/g, ' ')
          if (charset === 'iso-8859-1') {
            // unescape never throws, no try...catch needed:
            return strWithoutPlus.replace(/%[0-9a-f]{2}/gi, unescape)
          }

          if (parseNumbers && /^[+-]?\d+(\.\d+)?$/.test(str)) {
            return parseFloat(str)
          }

          if (ignoreEmptyString && str.length === 0) {
            return
          }

          const keywords = {
            null: ignoreNull ? undefined : null,
            undefined
          }

          if (str in keywords) {
            return keywords[str]
          }

          const boolKeywords = {
            true: true,
            false: false
          }

          if (parseBool && str in boolKeywords) {
            return boolKeywords[str]
          }

          try {
            return decodeURIComponent(strWithoutPlus)
          } catch (e) {
            return strWithoutPlus
          }
        }
      }),
    [ignoreEmptyString, ignoreNull, location, parseBool, parseNumbers]
  )

  return queryParams
}
