import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Divider from '@nutrien/uet-react/Divider'

import { AuthContext } from 'components/Auth/authentication-context'
import withRequest from 'HOCs/withRequest'
import th from 'theme/helpers'

import UserInfo from '../UserInfo'
import UserSecurity from '../UserSecurity'
import UserPrefs from '../UserPrefs'

const StyledDivider = styled(Divider)`
  margin: ${th.spacing(3, 0)};
`

const Profile = ({ setRequestPending, setRequestError }) => {
  const { user, setUser } = useContext(AuthContext)

  return (
    <div>
      <UserInfo
        user={user}
        onChange={setUser}
        setRequestPending={setRequestPending}
        setRequestError={setRequestError}
      />

      <StyledDivider />

      <UserSecurity
        user={user}
        onChange={setUser}
        setRequestPending={setRequestPending}
        setRequestError={setRequestError}
      />

      <StyledDivider />

      <UserPrefs
        profile={user.profile}
        onChange={setUser}
        setRequestPending={setRequestPending}
        setRequestError={setRequestError}
      />
    </div>
  )
}

Profile.propTypes = {
  setRequestPending: PropTypes.func.isRequired,
  setRequestError: PropTypes.func.isRequired
}

export default React.memo(withRequest(Profile))
