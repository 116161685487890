import React, {
  useContext,
  useState,
  forwardRef,
  useImperativeHandle
} from 'react'
import propTypes from 'propTypes'
import classNames from 'classnames'
import Box from '@nutrien/uet-react/Box'
import { useTranslation } from 'react-i18next'
import TuneIcon from '@nutrien/uet-react/icons/Tune'
import { Formik } from 'formik'
import Fab from '@nutrien/uet-react/Fab'
import makeStyles from '@nutrien/uet-react/styles/makeStyles'
import BonsaiDrawer from 'components/BonsaiDrawer'
import {
  FarmTreeFiltersContext,
  SHOW_ALL
} from 'components/FarmTreeFiltersProvider'
import SortingOptions from './SortingOptions/SortingOptions'

const useStyles = makeStyles(theme => ({
  filterButtonRoot: {
    position: 'absolute',
    bottom: theme.spacing(2),
    left: theme.spacing(2),
    zIndex: 999,
    textTransform: 'capitalize'
  },
  filterButtonMobile: {
    position: 'fixed'
  }
}))

const FarmTreeFilter = forwardRef((props, ref) => {
  const { isMobile } = props
  const { t } = useTranslation()
  const classes = useStyles()
  const {
    selectedFilter,
    setSelectedFilters,
    allCroppingSeasonsQuery,
    filters
  } = useContext(FarmTreeFiltersContext)
  const [isFloatingPanelVisible, setFloatingPanelVisibility] = useState(false)

  // This is for the Adjust Filters button on the StepProgress Card that is rendered in FarmTreeContent if
  // no fields are returned from the applied filter
  useImperativeHandle(ref, () => ({
    isFloatingPanelVisible,
    setFloatingPanelVisibility
  }))

  const handleApplyFilters = values => {
    setSelectedFilters(values)
    setFloatingPanelVisibility(false)
  }

  // this is for if you are already selected on a crop season that was just deleted - reset to show all
  if (
    Object.keys(selectedFilter || {}).length > 0 &&
    selectedFilter?.filterByCropSeason !== SHOW_ALL &&
    !allCroppingSeasonsQuery?.data?.listAllCroppingSeasons?.find(
      s => s.season === selectedFilter?.filterByCropSeason
    )
  ) {
    const filters = {
      ...selectedFilter,
      filterByCropSeason: SHOW_ALL
    }
    handleApplyFilters(filters)
  }

  const resetFilters = resetForm => {
    const initialSelectedFilter = {
      sortOption: filters.sortOption[0].value,
      orderOption: filters.orderOption[0].value,
      filterByCropSeason: filters.filterByCropSeason[0].value,
      filterByProject: filters.filterByProject?.[0].value
    }
    resetForm({
      values: initialSelectedFilter
    })
    handleApplyFilters(initialSelectedFilter)
  }

  const showNumberOfFiltersApplied = () => {
    return selectedFilter.filterByCropSeason !== SHOW_ALL ||
      selectedFilter.filterByProject !== SHOW_ALL
      ? ' (1)'
      : ''
  }

  return (
    <>
      <Fab
        className={classNames(classes.filterButtonRoot, {
          [classes.filterButtonMobile]: isMobile
        })}
        data-testid={'filter-button'}
        color="primary"
        variant="extended"
        aria-label="filters"
        onClick={() => setFloatingPanelVisibility(true)}>
        <Box
          flex={1}
          mx={1}
          display="flex"
          justifyContent="center"
          alignItems="center">
          <TuneIcon />
        </Box>
        {t('filters') + showNumberOfFiltersApplied()}
      </Fab>
      <div>
        <Formik initialValues={selectedFilter} enableReinitialize>
          {({ values, handleChange, resetForm, dirty }) => {
            return (
              <form onSubmit={() => handleApplyFilters(values)}>
                <BonsaiDrawer
                  buttonType="reset"
                  open={isFloatingPanelVisible}
                  onFooterButtonClick={() => handleApplyFilters(values)}
                  title={t('filters')}
                  closeButtonLabel={t('cancel')}
                  onClose={() => setFloatingPanelVisibility(false)}
                  rightHeaderButtonLabel={t('reset')}
                  onRightButtonClick={() => resetFilters(resetForm)}
                  footerButtonLabel={t('apply')}
                  isFooterButtonDisabled={!dirty}>
                  <SortingOptions
                    handleChange={handleChange}
                    selectedFilter={values}
                  />
                </BonsaiDrawer>
              </form>
            )
          }}
        </Formik>
      </div>
    </>
  )
})

FarmTreeFilter.defaultProps = {
  isMobile: false
}

FarmTreeFilter.propTypes = {
  isMobile: propTypes.bool
}

export default FarmTreeFilter
