import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import makeStyles from '@nutrien/uet-react/styles/makeStyles'
import InfoIcon from '@nutrien/uet-react/icons/Info'
import { useTheme } from '@nutrien/uet-react/styles'
import { Box, Button, Paper, Typography, Divider } from '@nutrien/uet-react'
import Alert from '@material-ui/lab/Alert'
import bonsaiTheme from '@nutrien/bonsai-theme'
import { AuthConsumer } from 'components/Auth/authentication-context'
import SvgIcon from 'components/SvgIcon'
import { IntercomAPI } from 'components/Intercom'
import { EmptyStatesIcon } from 'components/Icons'

const useLoginFailedPageStyles = makeStyles(theme => ({
  pageBox: {
    height: '100%',
    backgroundImage: `url('https://static.nutrienagsolutions.com/backgrounds/farmers_pointing.jpg')`,
    backgroundPositionX: '-1100px',
    transition: 'background-position 1s',
    overflow: 'hidden',
    [theme.breakpoints.up('lg')]: {
      backgroundPositionX: 0,
      backgroundSize: 'cover'
    }
  },
  alertInfo: {
    backgroundColor: theme.palette.info.secondary,
    borderRadius: 0,
    padding: theme.spacing(0.75, 3)
  },
  cardTitle: {
    fontSize: 16,
    fontWeight: '700',
    color: bonsaiTheme.colors.grey[600]
  },
  cardText: {
    fontSize: 16,
    fontWeight: '400',
    lineHeight: '1.5',
    color: bonsaiTheme.colors.grey[600]
  },
  contactUsButton: {
    fontSize: 16,
    lineHeight: '1.5',
    width: '84px',
    height: '24px',
    alignItems: 'center'
  },
  loginButton: {
    fontSize: 16,
    lineHeight: '1.5',
    alignItems: 'center',
    width: '120px',
    height: '24px'
  },
  mainContent: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(18),
    minHeight: '100%',
    boxSizing: 'border-box',

    [theme.breakpoints.down('xs')]: {
      alignItems: 'initial'
    }
  },
  paperRoot: {
    [theme.breakpoints.down('xs')]: {
      height: '100%'
    }
  }
}))

const LoginFailed = () => {
  const loginFaildPageStyles = useLoginFailedPageStyles()
  const { t } = useTranslation()
  const { palette } = useTheme()

  const location = useLocation()
  const history = useHistory()

  const email = location.state?.email

  useEffect(() => {
    // calling the page directly from the browser
    if (!email) history.replace('/legacy-login')
  }, [history, email])

  const handleMessageCustomerSupport = event => {
    event.preventDefault()
    IntercomAPI('showNewMessage')
  }

  const handleGoToAuth0 = async logout => {
    await logout()
  }

  return (
    <AuthConsumer>
      {({ logout }) => (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          className={loginFaildPageStyles.pageBox}>
          <Box flex={0} width="100%">
            <Alert
              severity="info"
              classes={{ standardInfo: loginFaildPageStyles.alertInfo }}
              icon={<SvgIcon color="info.text" component={InfoIcon} />}
              action={
                <Button
                  size="small"
                  href="https://nutrienagsolutions.com/agrible">
                  <Typography
                    style={{ color: palette?.info?.text, marginRight: '32px' }}>
                    {t('view')}
                  </Typography>
                </Button>
              }>
              <Typography>{t('authentication_page_banner_msg')}</Typography>
            </Alert>
          </Box>

          <Box className={loginFaildPageStyles.mainContent}>
            <Box maxWidth="391px">
              <Paper
                classes={{ root: loginFaildPageStyles.paperRoot }}
                elevation={2}>
                <Box p={3} display="flex" justifyContent="center">
                  <Box
                    textAlign="center"
                    display="flex"
                    flexDirection="column"
                    style={{ gap: 8 }}
                    alignItems="center">
                    <EmptyStatesIcon />
                    <Typography
                      variant="h4"
                      className={loginFaildPageStyles.cardTitle}>
                      {t('login_failed_msg_header')}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      className={loginFaildPageStyles.cardText}>
                      {t('login_failed_error_msg')}
                    </Typography>
                  </Box>
                </Box>
                <Divider />
                <Box
                  style={{ padding: '8px 16px' }}
                  display="flex"
                  justifyContent="center">
                  <Box
                    textAlign="center"
                    display="flex"
                    flexDirection="row"
                    style={{ gap: 8 }}
                    alignItems="center">
                    <Button
                      variant="outlined"
                      onClick={() => handleGoToAuth0(logout)}>
                      <Typography
                        className={loginFaildPageStyles.loginButton}
                        variant="h5">
                        {t('return_to_login')}
                      </Typography>
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleMessageCustomerSupport}>
                      <Typography
                        className={loginFaildPageStyles.contactUsButton}
                        variant="h5">
                        {t('Contact us')}
                      </Typography>
                    </Button>
                  </Box>
                </Box>
              </Paper>
            </Box>
          </Box>
        </Box>
      )}
    </AuthConsumer>
  )
}
export default LoginFailed
