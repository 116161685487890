import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Box from '@nutrien/uet-react/Box'
import MuiTableCell from '@nutrien/uet-react/TableCell'
import MuiTableRow from '@nutrien/uet-react/TableRow'
import Typography from '@nutrien/uet-react/Typography'
import makeStyles from '@nutrien/uet-react/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  link: {
    color: theme.palette.brand.green,
    textDecoration: 'underline',
    overflowWrap: 'anywhere',

    '&:visited': {
      color: theme.palette.brand.gree
    }
  },
  listRoot: {
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(2)
    }
  },
  ulWithNoDots: {
    listStyle: 'none'
  },
  tableCellRoot: {
    border: `1px solid ${theme.palette.gray[400]}`,
    ...theme.typography.subtitle1,

    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1)
    }
  },
  greenBg: {
    backgroundColor: theme.palette.green[200]
  },
  blueBg: {
    backgroundColor: theme.palette.info.secondary
  },
  grayBg: {
    backgroundColor: theme.palette.grey[50]
  },
  dotNotation: {
    position: 'absolute',
    left: -36
  },
  indentLi: {
    marginLeft: 36
  },
  tableCellRootNoBorders: {
    ...theme.typography.subtitle1,

    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1)
    }
  }
}))

export const A = ({ children, ...props }) => {
  const classes = useStyles()

  return (
    <a className={classes.link} {...props}>
      {children}
    </a>
  )
}

export const UL = ({ noListStyle, ...props }) => {
  const classes = useStyles()

  return (
    <Box my={1.5}>
      <ul
        className={classnames(classes.listRoot, {
          [classes.ulWithNoDots]: noListStyle
        })}
        {...props}
      />
    </Box>
  )
}

export const OL = props => {
  const classes = useStyles()

  return (
    <Box my={1.5}>
      <ol {...props} className={classes.listRoot} />
    </Box>
  )
}

export const LI = ({ indent, ...props }) => {
  const classes = useStyles()
  return (
    <Typography
      {...props}
      className={classnames({ [classes.indentLi]: indent })}
      component="li"
    />
  )
}

export const DotNotation = props => {
  const classes = useStyles()
  return (
    <Typography className={classes.dotNotation} {...props} component="span" />
  )
}

export const SectionTitle = props => {
  return (
    <Box mt={4} mb={2}>
      <Typography {...props} variant="h2" />
    </Box>
  )
}

export const TableCell = ({
  blueBg,
  greenBg,
  removeBorders = false,
  ...props
}) => {
  const classes = useStyles()

  return (
    <MuiTableCell
      className={classnames(
        removeBorders ? classes.tableCellRootNoBorders : classes.tableCellRoot,
        {
          [classes.greenBg]: greenBg,
          [classes.blueBg]: blueBg
        }
      )}
      {...props}
    />
  )
}

export const TableRow = ({ grayBg, ...props }) => {
  const classes = useStyles()

  return (
    <MuiTableRow
      className={classnames({
        [classes.grayBg]: grayBg
      })}
      {...props}
    />
  )
}

LI.propTypes = {
  indent: PropTypes.bool
}
