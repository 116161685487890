import React from 'react'
import PropTypes from 'prop-types'
import UETTypography from '@nutrien/uet-react/Typography'
import Box from '@nutrien/uet-react/Box'
import styled from 'styled-components'

import th from 'theme/helpers'

const StyledTypography = styled(({ gutterBottom, ...rest }) => (
  <UETTypography {...rest} />
))`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: ${({ gutterBottom }) => th.spacing(+gutterBottom)};
`

const Icon = styled(Box)`
  display: flex;
  align-items: center;
`

const Typography = ({ startIcon, endIcon, children, ...rest }) => (
  <StyledTypography {...rest}>
    {startIcon && <Icon mr={1}>{startIcon}</Icon>}
    {children}
    {endIcon && <Icon ml={1}>{endIcon}</Icon>}
  </StyledTypography>
)

Typography.propTypes = {
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  gutterBottom: PropTypes.oneOfType([PropTypes.number, PropTypes.bool])
}

Typography.defaultProps = {
  startIcon: undefined,
  endIcon: undefined,
  gutterBottom: false
}

export default Typography
