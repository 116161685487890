import LanguageDetector from 'i18next-browser-languagedetector'
import { initI18n, addI18nResourceBundle } from '@nutrien/hub-i18n'

import {
  castToUTC,
  isISOString,
  isOffsetISOString,
  isYMDString,
  normalizeYMDString
} from 'helpers/date'

import dryingAndTransportEN from './locales/en/drying-and-transport.json'
import dryingAndTransportES from './locales/es/drying-and-transport.json'
import dryingAndTransportPT from './locales/pt/drying-and-transport.json'
import dryingAndTransportPTBR from './locales/pt-BR/drying-and-transport.json'

import editInvitecodeEN from './locales/en/edit-invitecode.json'
import editInvitecodeES from './locales/es/edit-invitecode.json'
import editInvitecodePT from './locales/pt/edit-invitecode.json'
import editInvitecodePTBR from './locales/pt-BR/edit-invitecode.json'

import editRegionEN from './locales/en/edit-region.json'
import editRegionES from './locales/es/edit-region.json'
import editRegionPT from './locales/pt/edit-region.json'
import editRegionPTBR from './locales/pt-BR/edit-region.json'

import farmTreeNavigationEN from './locales/en/farm-tree-navigation.json'
import farmTreeNavigationES from './locales/es/farm-tree-navigation.json'
import farmTreeNavigationPT from './locales/pt/farm-tree-navigation.json'
import farmTreeNavigationPTBR from './locales/pt-BR/farm-tree-navigation.json'

import fieldCharacteristicsEN from './locales/en/field-characteristics.json'
import fieldCharacteristicsES from './locales/es/field-characteristics.json'
import fieldCharacteristicsPT from './locales/pt/field-characteristics.json'
import fieldCharacteristicsPTBR from './locales/pt-BR/field-characteristics.json'

import geospatialEN from './locales/en/geospatial.json'
import geospatialES from './locales/es/geospatial.json'
import geospatialPT from './locales/pt/geospatial.json'
import geospatialPTBR from './locales/pt-BR/geospatial.json'

import irrigationEN from './locales/en/irrigation.json'
import irrigationES from './locales/es/irrigation.json'
import irrigationPT from './locales/pt/irrigation.json'
import irrigationPTBR from './locales/pt-BR/irrigation.json'

import manageEvidenceEN from './locales/en/manage-evidence.json'
import manageEvidenceES from './locales/es/manage-evidence.json'
import manageEvidencePT from './locales/pt/manage-evidence.json'
import manageEvidencePTBR from './locales/pt-BR/manage-evidence.json'

import translationEN from './locales/en/translation.json'
import translationES from './locales/es/translation.json'
import translationPT from './locales/pt/translation.json'
import translationPTBR from './locales/pt-BR/translation.json'

import weatherStoryEN from './locales/en/weather-story.json'
import weatherStoryES from './locales/es/weather-story.json'
import weatherStoryPT from './locales/pt/weather-story.json'
import weatherStoryPTBR from './locales/pt-BR/weather-story.json'

export { addI18nResourceBundle } from '@nutrien/hub-shared'

const resourceBundles = [
  { lang: 'en', ns: 'drying-and-transport', resources: dryingAndTransportEN },
  { lang: 'es', ns: 'drying-and-transport', resources: dryingAndTransportES },
  { lang: 'pt', ns: 'drying-and-transport', resources: dryingAndTransportPT },
  {
    lang: 'pt-BR',
    ns: 'drying-and-transport',
    resources: dryingAndTransportPTBR
  },
  { lang: 'en', ns: 'edit-invitecode', resources: editInvitecodeEN },
  { lang: 'es', ns: 'edit-invitecode', resources: editInvitecodeES },
  { lang: 'pt', ns: 'edit-invitecode', resources: editInvitecodePT },
  { lang: 'pt-BR', ns: 'edit-invitecode', resources: editInvitecodePTBR },
  { lang: 'en', ns: 'edit-region', resources: editRegionEN },
  { lang: 'es', ns: 'edit-region', resources: editRegionES },
  { lang: 'pt', ns: 'edit-region', resources: editRegionPT },
  { lang: 'pt-BR', ns: 'edit-region', resources: editRegionPTBR },
  { lang: 'en', ns: 'farm-tree-navigation', resources: farmTreeNavigationEN },
  { lang: 'es', ns: 'farm-tree-navigation', resources: farmTreeNavigationES },
  { lang: 'pt', ns: 'farm-tree-navigation', resources: farmTreeNavigationPT },
  {
    lang: 'pt-BR',
    ns: 'farm-tree-navigation',
    resources: farmTreeNavigationPTBR
  },
  {
    lang: 'en',
    ns: 'field-characteristics',
    resources: fieldCharacteristicsEN
  },
  {
    lang: 'es',
    ns: 'field-characteristics',
    resources: fieldCharacteristicsES
  },
  {
    lang: 'pt',
    ns: 'field-characteristics',
    resources: fieldCharacteristicsPT
  },
  {
    lang: 'pt-BR',
    ns: 'field-characteristics',
    resources: fieldCharacteristicsPTBR
  },
  { lang: 'en', ns: 'geospatial', resources: geospatialEN },
  { lang: 'es', ns: 'geospatial', resources: geospatialES },
  { lang: 'pt', ns: 'geospatial', resources: geospatialPT },
  { lang: 'pt-BR', ns: 'geospatial', resources: geospatialPTBR },
  { lang: 'en', ns: 'irrigation', resources: irrigationEN },
  { lang: 'es', ns: 'irrigation', resources: irrigationES },
  { lang: 'pt', ns: 'irrigation', resources: irrigationPT },
  { lang: 'pt-BR', ns: 'irrigation', resources: irrigationPTBR },
  { lang: 'en', ns: 'manage-evidence', resources: manageEvidenceEN },
  { lang: 'es', ns: 'manage-evidence', resources: manageEvidenceES },
  { lang: 'pt', ns: 'manage-evidence', resources: manageEvidencePT },
  {
    lang: 'pt-BR',
    ns: 'manage-evidence',
    resources: manageEvidencePTBR
  },
  { lang: 'en', ns: 'translation', resources: translationEN },
  { lang: 'es', ns: 'translation', resources: translationES },
  { lang: 'pt', ns: 'translation', resources: translationPT },
  { lang: 'pt-BR', ns: 'translation', resources: translationPTBR },
  { lang: 'en', ns: 'weather-story', resources: weatherStoryEN },
  { lang: 'es', ns: 'weather-story', resources: weatherStoryES },
  { lang: 'pt', ns: 'weather-story', resources: weatherStoryPT },
  { lang: 'pt-BR', ns: 'weather-story', resources: weatherStoryPTBR }
]

const dateOpts = {
  // example return:  Tue, 9/4
  dShortMD: {
    weekday: 'short',
    day: 'numeric',
    month: 'numeric',
    timeZone: 'UTC'
  },
  // example return:  Tuesday, 9/4
  dLongMD: {
    weekday: 'long',
    day: 'numeric',
    month: 'numeric',
    timeZone: 'UTC'
  },
  // example return:  9/4
  mDD: { month: 'numeric', day: 'numeric', timeZone: 'UTC' },
  // example return:  Sep 04
  mShortDD: { month: 'short', day: '2-digit', timeZone: 'UTC' },
  // example return:  December 3, 2018
  monthLongDYear: {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    timeZone: 'UTC'
  },
  // example return:  Dec 3, 2018
  monthShortDYear: {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    timeZone: 'UTC'
  },
  // example return:  Dec 3, 2018
  localMonthShortDYear: {
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  },
  // example return:   11:00 AM
  hhMM: { hour: 'numeric', minute: 'numeric', timeZone: 'UTC' },
  // example return:   12:00 AM
  localhhMM: { hour: 'numeric', minute: 'numeric' },
  // example return: 09/04/2018
  mmDDYY: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone: 'UTC'
  },
  // example return:  09/04/2018, 9:24 AM
  fullDateTime: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    timeZone: 'UTC'
  },
  // example return:  09/04/2018, 9:24 AM
  // Uses the users local timezone rather than UTC
  localFullDateTime: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric'
  }
}

function getDateLang(lng) {
  if (['en', 'en-us', 'en-US'].includes(lng)) {
    return 'en'
  }
  if (['pt', 'pt-br', 'pt-BR'].includes(lng)) {
    return 'pt'
  }
  if (['es', 'es-es', 'es-ES'].includes(lng)) {
    return 'es'
  }
  return 'en'
}

// for all options read: https://www.i18next.com/overview/configuration-options
const i18NextConfig = ({ useSuspense }) => ({
  ns: [
    'translation',
    'field-characteristics',
    'geospatial',
    'farm-tree-navigation',
    'weather-story',
    'drying-and-transport',
    'irrigation',
    'edit-invitecode',
    'manage-evidence'
  ],
  defaultNS: 'translation',
  compatibilityJSON: 'v3',
  supportedLngs: ['en', 'en-US', 'es', 'pt', 'pt-BR'],
  fallbackLng: 'en',
  detection: {
    // order and from where user language should be detected
    order: ['querystring', 'navigator'],
    // keys or params to lookup language from
    lookupQuerystring: 'lng'
  },
  ignoreJSONStructure: false,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
    format: function(value, format, lng) {
      // to-do: add a function to throw an error if the value is not a date object
      // throwOnDateObj(value)
      const formatDate = str => {
        const mappedLang = getDateLang(lng)
        const options = dateOpts[format] || dateOpts.fullDateTime
        return window.Intl.DateTimeFormat(mappedLang, options).format(
          new Date(str)
        )
      }
      if (isISOString(value) || isYMDString(value)) {
        const isoString = isYMDString(value) ? normalizeYMDString(value) : value
        return formatDate(isoString)
      }
      if (isOffsetISOString(value)) {
        return formatDate(castToUTC(new Date(value)))
      }

      return value
    },
    skipOnVariables: false
  },
  // key and namespace separators are needed to avoid natural language detection
  // see https://github.com/i18next/i18next/issues/1670
  keySeparator: '.',
  nsSeparator: ':',
  react: {
    useSuspense
  }
})

function setupI18n({ useSuspense }) {
  return initI18n(LanguageDetector, i18NextConfig({ useSuspense })).then(() => {
    resourceBundles.forEach(({ lang, ns, resources }) =>
      addI18nResourceBundle(lang, ns, resources)
    )
  })
}

export default setupI18n
