import React, { useEffect, useState } from 'react'
import { Auth0Provider } from '@auth0/auth0-react'
import LaunchDarkly from 'HOCs/LDOverride'

const LDProviderOverride = ({ children }) => {
  const [isReady, setIsReady] = useState(false)
  const { auth0Domain, auth0ClientID, auth0Audience } = window.uiConf
  useEffect(() => {
    setIsReady(false)
    LaunchDarkly.init().then(() => setIsReady(true))
  }, [])
  return (
    isReady && (
      <LaunchDarkly.Provider>
        <Auth0Provider
          domain={auth0Domain}
          clientId={auth0ClientID}
          audience={auth0Audience}
          redirectUri={`${window.location.origin}/auth`}>
          {children}
        </Auth0Provider>
      </LaunchDarkly.Provider>
    )
  )
}

export default LDProviderOverride
