import React from 'react'

export const CSVIcon = ({ height = 24, width = 24 }) => (
  <svg
    style={{ verticalAlign: 'middle' }}
    width={width}
    height={height}
    viewBox="0 0 350 350"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M280 43.75H83.125C58.3333 43.75 43.75 58.3333 43.75 83.125V280C43.75 291.667 58.3333 306.25 83.125 306.25H280C291.667 306.25 306.25 291.667 306.25 280V83.125C306.25 58.3333 291.667 43.75 280 43.75Z"
      fill="#666666"
    />
    <path
      d="M134.166 226.041C131.25 227.5 125.416 227.5 119.583 227.5C91.8747 227.5 80.208 204.166 80.208 179.375C80.208 142.916 100.625 128.333 122.5 128.333C129.791 128.333 134.166 129.791 135.625 131.25L131.25 150.208C128.333 148.75 126.875 148.75 122.5 148.75C110.833 148.75 103.541 157.5 103.541 179.375C103.541 199.791 110.833 208.541 122.5 208.541C125.416 208.541 128.333 208.541 131.25 207.083L134.166 226.041Z"
      fill="white"
    />
    <path
      d="M147.291 205.625C151.666 208.541 158.958 210 164.791 210C173.541 210 176.458 205.625 176.458 201.25C176.458 195.416 172.083 192.5 163.333 186.666C147.291 177.916 144.375 166.25 144.375 158.958C144.375 142.916 154.583 128.333 177.916 128.333C185.208 128.333 191.041 129.791 193.958 131.25L191.041 150.208C188.125 148.75 183.75 147.291 177.916 147.291C170.625 147.291 166.25 151.666 166.25 156.041C166.25 160.416 167.708 163.333 179.375 169.166C192.5 176.458 196.875 186.666 196.875 196.875C196.875 215.833 182.291 227.5 161.875 227.5C153.125 227.5 145.833 226.041 141.458 223.125L147.291 205.625Z"
      fill="white"
    />
    <path
      d="M227.5 227.5L205.625 129.791H230.417L236.25 170.625C237.708 180.833 239.167 192.5 240.625 202.708C242.083 191.041 243.542 180.833 245 169.166L250.833 128.333H275.625L253.75 226.041H227.5V227.5Z"
      fill="white"
    />
  </svg>
)
