import React from 'react'
import styled from 'styled-components'
import SvgIcon from '@nutrien/uet-react/SvgIcon'

import th from 'theme/helpers'

// TODO: Dropping color prop here, should do it conditionally
const StyledIcon = styled(({ color, ...rest }) => <SvgIcon {...rest} />)`
  ${({ color, theme }) =>
    color ? `color: ${th.palette(color)({ theme })}` : ''};
`

export default StyledIcon
