import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useManualQuery } from 'graphql-hooks'
import styled from 'styled-components'
import Grid from '@nutrien/uet-react/Grid'
import SvgIcon from '@nutrien/uet-react/SvgIcon'
import MapIcon from '@nutrien/uet-react/icons/Map'
import InsertDriveFileIcon from '@nutrien/uet-react/icons/InsertDriveFile'

import Box from '@nutrien/uet-react/Box'
import { JohnDeereIcon } from 'components/Icons'
import th from 'theme/helpers'
import useToggle from 'hooks/useToggle'

import AuthorshipPermissionDisabledOverlay from 'components/AuthorshipPermissionDisabledOverlay'
import EntryCard from '../EntryCard'
import JohnDeereConsentDialog from '../JohnDeereConsentDialog'

const Icon = styled(SvgIcon)`
  color: ${th.palette('grey.600')};
  font-size: 50px;
`

const JohnDeereIconGrey = styled(JohnDeereIcon)`
  & > :first-child {
    fill: inherit;
  }

  & > :last-child {
    fill: ${th.palette('common.white')};
  }
`

const Parent = styled.div`
  padding: ${th.spacing(3)};
`

const JD_ORGS_QUERY = `
  query {
    getImportableOrgs {
      id
      name
    }
  }
`

const isConsentError = error =>
  error.graphQLErrors.some(e => e.message.includes('No consent given'))

const EntryTypeSelector = ({
  onSelect,
  setRequestPending,
  setRequestError
}) => {
  const { t } = useTranslation()
  const [headsUp, toggleHeadsUp] = useToggle(false)

  const [fetchImportableOrgs] = useManualQuery(JD_ORGS_QUERY, {
    useCache: false
  })

  const handleGetConsent = async () => {
    setRequestPending(true)

    const { data, error } = await fetchImportableOrgs()

    // TODO: Handle error if it's other than consent error
    if (error && isConsentError(error)) {
      setRequestPending(false)
      return toggleHeadsUp()
    }

    if (data && data.getImportableOrgs) {
      onSelect('johnDeere')
    }

    setRequestPending(false)
  }

  return (
    <Parent>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <EntryCard
            title={t('map_entry')}
            text={t('use_map_entry')}
            icon={<Icon component={MapIcon} />}
            onClick={() => onSelect('map')}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <Box style={{ position: 'relative' }}>
            <EntryCard
              title={t('jd_ops_import_name')}
              text={t('jd_ops_import_description')}
              icon={<Icon component={JohnDeereIconGrey} fill="blue" />}
              onClick={handleGetConsent}
            />
            <AuthorshipPermissionDisabledOverlay
              tooltipMessage="action_not_available_employees"
              alwaysDisableInAuthorship={true}
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Box style={{ position: 'relative' }}>
            <EntryCard
              title={`${t('shapefile')} ${t('import')}`}
              text={t('import_shapefile_msg')}
              icon={<Icon component={InsertDriveFileIcon} />}
              onClick={() => onSelect('shapefile')}
            />
            <AuthorshipPermissionDisabledOverlay
              tooltipMessage="action_not_available_employees"
              alwaysDisableInAuthorship={true}
            />
          </Box>
        </Grid>
      </Grid>

      {headsUp && (
        <JohnDeereConsentDialog
          onClose={toggleHeadsUp}
          setRequestPending={setRequestPending}
          setRequestError={setRequestError}
        />
      )}
    </Parent>
  )
}

EntryTypeSelector.propTypes = {
  onSelect: PropTypes.func.isRequired,
  setRequestPending: PropTypes.func.isRequired,
  setRequestError: PropTypes.func.isRequired
}

export default React.memo(EntryTypeSelector)
