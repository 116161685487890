import React from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@nutrien/uet-react/styles/makeStyles'
import Box from '@nutrien/uet-react/Box'
import { CONTACT_US_LINK, PRIVACY_LINK, TERMS_AND_CONDITIONS_LINK } from 'const'
import Typography from '@nutrien/uet-react/Typography'
import Divider from '@nutrien/uet-react/Divider'

const useAuthenticationFooterStyles = makeStyles(theme => ({
  divider: {
    margin: '0 10px',
    height: '13px',
    alignSelf: 'center',
    backgroundColor: theme.palette.grey[600]
  }
}))

export function AuthenticationFooter() {
  const { t } = useTranslation()
  const footerClasses = useAuthenticationFooterStyles()
  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Typography
          style={{ fontWeight: 'bold' }}
          variant="caption"
          color="primary"
          component="a"
          target="_blank"
          rel="noopener noreferrer"
          href={TERMS_AND_CONDITIONS_LINK}>
          {t('Terms of Use')}
        </Typography>
        <Divider
          orientation="vertical"
          flexItem
          className={footerClasses.divider}
        />
        <Typography
          style={{ fontWeight: 'bold' }}
          variant="caption"
          color="primary"
          component="a"
          target="_blank"
          rel="noopener noreferrer"
          href={PRIVACY_LINK}>
          {t('privacy_policy')}
        </Typography>
        <Divider
          orientation="vertical"
          flexItem
          className={footerClasses.divider}
        />
        <Typography
          style={{ fontWeight: 'bold' }}
          variant="caption"
          color="primary"
          component="a"
          target="_blank"
          rel="noopener noreferrer"
          href={CONTACT_US_LINK}>
          {t('contact_us')}
        </Typography>
      </Box>
      <Box textAlign="center" marginTop={2}>
        <Typography variant="caption" color="textSecondary">
          &copy; Agrible, Inc. {new Date().getFullYear()}
        </Typography>
      </Box>
    </Box>
  )
}
