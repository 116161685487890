import {
  ENTITY_TYPE_ACCOUNT,
  ENTITY_TYPE_ORGS,
  ENTITY_TYPE_ORG_GROUP,
  ENTITY_TYPE_ENTERPRISE_GROUPS,
  UUID_MY_ACCOUNT,
  UUID_SHARED_ORGS,
  ENTITY_TYPE_FARMS,
  ENTITY_TYPE_FIELDS,
  UUID_MY_ORG,
  NAME_SHARED_ORGS,
  NAME_MY_ORG,
  ENTITY_TYPE_PRETTY_PLURAL,
  ENTITY_TYPE_ROTATION_GROUP
} from 'const'
import { getChildren } from '@nutrien/farm-tree-navigation/dist/selectors/getNestedActiveEntityChildren'
import omitBy from 'lodash/omitBy'
import find from 'lodash/find'
import mapValues from 'lodash/mapValues'
import partition from 'lodash/partition'
import React from 'react'
import HighlightedTerm from 'components/HighlightedTerm'
import { fuzzyMatch } from '../string'

export const extractRealUUIDs = (farmTree, baseUUID) => {
  const entity = farmTree[baseUUID]
  if (!entity) return []
  return [
    ...(entity.isFake
      ? entity.childrenUUIDs.reduce(
          (acc, c) => acc.concat(extractRealUUIDs(farmTree, c)),
          []
        )
      : [entity.uuid])
  ].filter(Boolean)
}

export function sortTreeToListbyStatus(entities, fields) {
  const sortedEntities = {}
  for (const status in fields) {
    sortedEntities[status] = fields[status].map(field => entities[field.uuid])
  }
  sortedEntities.enrolledAcreage =
    sortedEntities.enrolledFields?.length === 0
      ? 0
      : sortedEntities.enrolledFields?.length === 1
      ? sortedEntities.enrolledFields[0]?.acreage
      : sortedEntities.enrolledFields?.reduce((sum, field) => {
          return { acreage: sum?.acreage + field?.acreage }
        }).acreage
  return sortedEntities
}

// Hides group if there is only one in given org
export function pruneGroups(entities) {
  const isGroup = entity =>
    [ENTITY_TYPE_ORG_GROUP, ENTITY_TYPE_ENTERPRISE_GROUPS].includes(entity.type)
  for (const entity of Object.values(entities)) {
    if (entity.type === ENTITY_TYPE_ORGS) {
      const groups = entity.childrenUUIDs
        .map(id => entities[id])
        .filter(isGroup)

      if (groups.length === 1) {
        const group = groups[0]
        entity.childrenUUIDs.splice(
          entity.childrenUUIDs.indexOf(group.uuid),
          1,
          ...group.childrenUUIDs
        )
        entity.group = entities[group.uuid]
      }
    }
  }
  return entities
}

function decorateSharedEntity(entity, entitiesById) {
  entitiesById[entity.uuid].isShared = true
  if (entity.children) {
    entity.children.map(child => decorateSharedEntity(child, entitiesById))
  }
  return entitiesById
}

export function splitOrgs(orgs, entitiesById) {
  // Wrap whole tree in a ghost node: owned-organizations
  // This node won't be visible because it won't be referenced by activeId
  // owned-organizations.childrenUUIDs[0] is expected by Search logic
  entitiesById[UUID_MY_ORG] = {
    type: ENTITY_TYPE_ORG_GROUP,
    name: NAME_MY_ORG,
    childrenUUIDs: [UUID_MY_ACCOUNT],
    isFake: true
  }

  entitiesById[UUID_MY_ACCOUNT] = {
    uuid: UUID_MY_ACCOUNT,
    // NutrienFarmTree shared component is not localized yet!
    // hardcoding this label becomes an acceptable compromise
    name: 'All Fields',
    type: ENTITY_TYPE_ACCOUNT,
    childrenUUIDs: [],
    isFake: true
  }
  // there could be only one own organization
  const myOrg = orgs.find(({ owner }) => !owner)
  if (myOrg) {
    entitiesById[UUID_MY_ACCOUNT].childrenUUIDs.push(myOrg.uuid)
    orgs.splice(orgs.indexOf(myOrg), 1)
  }
  // remaining orgs are shared ones
  if (orgs.length) {
    entitiesById[UUID_SHARED_ORGS] = {
      uuid: UUID_SHARED_ORGS,
      name: NAME_SHARED_ORGS,
      type: ENTITY_TYPE_ORG_GROUP,
      childrenUUIDs: orgs.map(({ uuid }) => uuid),
      isFake: true
    }
    // decorate shared entities to make them easily distinguishable
    entitiesById[UUID_MY_ACCOUNT].childrenUUIDs.push(UUID_SHARED_ORGS)
    const sharedTree = getChildren(entitiesById[UUID_SHARED_ORGS], entitiesById)
    decorateSharedEntity(sharedTree, entitiesById)
  }
  return entitiesById
}

export const entityCheckboxStates = {
  DISABLED: 'DISABLED',
  CHECKED: 'CHECKED',
  WARNING: 'WARNING',
  LOCKED: 'LOCKED'
}

export const pluralizeEntity = type => {
  if (type === ENTITY_TYPE_FARMS) {
    return ENTITY_TYPE_PRETTY_PLURAL[ENTITY_TYPE_FIELDS]
  }

  if (type === ENTITY_TYPE_ENTERPRISE_GROUPS) {
    return ENTITY_TYPE_PRETTY_PLURAL[ENTITY_TYPE_FARMS]
  }

  if (type === ENTITY_TYPE_ORGS) {
    return ENTITY_TYPE_PRETTY_PLURAL[ENTITY_TYPE_ENTERPRISE_GROUPS]
  }

  return ENTITY_TYPE_PRETTY_PLURAL[ENTITY_TYPE_FIELDS]
}

export const cleanFarmTreeData = farmTreeData => {
  const realData = omitBy(farmTreeData, ({ isFake }) => isFake)
  const groups = Object.values(realData)
    .filter(({ group }) => group)
    .map(({ group }) => group)

  const filtered = omitBy(realData, ({ uuid }) =>
    groups.some(groupEntity => groupEntity.uuid === uuid)
  )
  return mapValues(filtered, entity => {
    const parentGroup = groups.find(({ uuid }) => entity.parentUUID === uuid)
    if (parentGroup) {
      return {
        ...entity,
        parentUUID: parentGroup.parentUUID
      }
    }
    return entity
  })
}

export const makeEntityTree = (
  { childrenUUIDs, ...currentEntity },
  entityArray
) => {
  const children = entityArray
    .filter(({ uuid }) => childrenUUIDs.includes(uuid))
    .map(child =>
      child.childrenUUIDs ? makeEntityTree(child, entityArray) : child
    )
  return {
    ...currentEntity,
    children
  }
}

export const makeEntityTrees = entityArray => {
  const allChildrenUUIDs = entityArray.reduce(
    (acc, { childrenUUIDs }) =>
      childrenUUIDs ? acc.concat(childrenUUIDs) : acc,
    []
  )

  if (allChildrenUUIDs.length === 0) {
    return entityArray
  }

  const topLevelEntities = entityArray.filter(
    ({ uuid }) => !allChildrenUUIDs.includes(uuid)
  )

  return topLevelEntities.map(rootEntity =>
    rootEntity.childrenUUIDs
      ? makeEntityTree(rootEntity, entityArray)
      : rootEntity
  )
}

export const filterEntitiesByType = (entityArr, entityTypes) => {
  const [rest, removed] = partition(entityArr, ({ type }) =>
    entityTypes.includes(type)
  )
  const findRemoved = id => removed.find(({ uuid }) => id === uuid)

  return rest.map(entity =>
    entity.childrenUUIDs
      ? {
          ...entity,
          childrenUUIDs: entity.childrenUUIDs.reduce((acc, id) => {
            const found = findRemoved(id)
            return acc.concat(found?.childrenUUIDs || id)
          }, [])
        }
      : entity
  )
}

export const isOrganization = type => type === ENTITY_TYPE_ORGS
export const isEnterpriseGroup = type => type === ENTITY_TYPE_ENTERPRISE_GROUPS
export const isFarm = type => type === ENTITY_TYPE_FARMS
export const isField = type => type === ENTITY_TYPE_FIELDS
export const isRotationGroup = type => type === ENTITY_TYPE_ROTATION_GROUP
export const isFieldOrRotation = type =>
  [ENTITY_TYPE_ROTATION_GROUP, ENTITY_TYPE_FIELDS].includes(type)

export const getFirstFieldInBasicFarmTree = sortedEntities => {
  const firstGroup = find(sortedEntities, {
    type: ENTITY_TYPE_ENTERPRISE_GROUPS
  })
  const firstFarm = find(sortedEntities, { parentUUID: firstGroup?.uuid })

  return find(sortedEntities, { parentUUID: firstFarm?.uuid })
}

export const applyHighlightedText = (entity, searchString) =>
  fuzzyMatch(entity.primaryText, searchString)
    ? {
        ...entity,
        primaryText: (
          <HighlightedTerm
            name={entity.primaryText}
            searchString={searchString}
          />
        )
      }
    : entity

export const getAllCroppingSeasonsForEntities = (
  allCroppingSeasons,
  entities
) => {
  const entityIds = entities.map(({ uuid }) => uuid)
  return allCroppingSeasons.filter(({ fields }) =>
    fields.some(({ fieldUuid }) => entityIds.includes(fieldUuid))
  )
}

export const tagCustomRotations = (farmTree, customRotationId) =>
  customRotationId
    ? farmTree.map(entity =>
        entity.rotationId
          ? { ...entity, isCustom: entity.rotationId === customRotationId }
          : entity
      )
    : farmTree
