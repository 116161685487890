import React, { useCallback, useContext } from 'react'
import PropTypes from 'prop-types'
import Button from '@nutrien/uet-react/Button'
import Dialog from '@nutrien/uet-react/Dialog'
import Box from '@nutrien/uet-react/Box'
import Typography from '@nutrien/uet-react/Typography'
import TextField from '@nutrien/uet-react/TextField'
import { useTranslation } from 'react-i18next'
import { ClientContext } from 'graphql-hooks'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { AuthContext } from 'components/Auth/authentication-context'
import { callQueryWithAccessToken } from 'helpers/callQueryWithAccessToken'
import { EDIT_ACCOUNT_NICKNAME_MUTATION } from '../utils'

const EditFinancialAcct = ({
  onClose,
  open,
  getPaymentAccounts,
  accountToEdit,
  setDisplaySuccessSnackbar,
  setRequestPending
}) => {
  const { t } = useTranslation()
  const { getSSOAccessToken } = useContext(AuthContext)
  const graphqlClient = useContext(ClientContext)

  const validationSchema = Yup.object().shape({
    accountNickname: Yup.string()
      .min(1)
      .required(t('Account Nickname is required'))
  })

  const handleSave = useCallback(
    async values => {
      const accessToken = await getSSOAccessToken()
      setRequestPending(true)
      const variables = {
        accountId: accountToEdit.number,
        nickname: values.accountNickname,
        accountToken: accessToken
      }

      const { data, error } = await callQueryWithAccessToken(
        graphqlClient,
        EDIT_ACCOUNT_NICKNAME_MUTATION,
        accessToken,
        variables
      )

      if (error) {
        setRequestPending(false)
        return setDisplaySuccessSnackbar(t('failed_to_update_account_nickname'))
      }

      if (data?.editAccountNickname) {
        await getPaymentAccounts()
        setRequestPending(false)
        onClose()
        setDisplaySuccessSnackbar(t('nickname_successfully_updated'))
      }
    },
    [
      getSSOAccessToken,
      setRequestPending,
      accountToEdit,
      graphqlClient,
      setDisplaySuccessSnackbar,
      t,
      getPaymentAccounts,
      onClose
    ]
  )

  return (
    <Dialog open={open} onClose={onClose} title={t('edit_account')}>
      <Formik
        initialValues={{ accountNickname: '' }}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          handleSave(values)
          actions.setSubmitting(false)
        }}>
        {({ errors, handleChange, handleBlur, values }) => {
          return (
            <Form>
              <Box
                width="100%"
                display="flex"
                flexDirection="column"
                style={{ gap: 24 }}>
                <Box>
                  <Typography variant="h5">{t('account_number')}</Typography>
                  <Typography variant="body2">
                    {accountToEdit?.number}
                  </Typography>
                </Box>
                <Box>
                  <TextField
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={accountToEdit?.nickname}
                    value={values.accountNickname}
                    name="accountNickname"
                    inputProps={{ 'data-testid': 'accountNickname' }}
                  />
                  <Typography variant="caption">
                    {t(
                      'Give your account a recognizable and easy-to-remember name'
                    )}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  style={{ gap: 8 }}>
                  <Button onClick={onClose} variant="outlined">
                    {t('cancel')}
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={!values.accountNickname || errors.accountNickname}
                    data-testid={'save-nickname'}>
                    {t('save')}
                  </Button>
                </Box>
              </Box>
            </Form>
          )
        }}
      </Formik>
    </Dialog>
  )
}

export default EditFinancialAcct

EditFinancialAcct.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  getPaymentAccounts: PropTypes.func.isRequired,
  accountToEdit: PropTypes.shape({
    number: PropTypes.string,
    nickname: PropTypes.string
  }).isRequired,
  setDisplaySuccessSnackbar: PropTypes.func.isRequired,
  setRequestPending: PropTypes.func.isRequired
}
