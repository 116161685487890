import CardContent from '@nutrien/uet-react/CardContent'
import PropTypes from 'prop-types'
import Popover from '@nutrien/uet-react/Popover'
import React, { useCallback, useState } from 'react'
import Link from '@nutrien/uet-react/Link'
import makeStyles from '@nutrien/uet-react/styles/makeStyles'
import Typography from 'components/Typography'
import ProjectCard from 'components/ProjectCard'

const useStyles = makeStyles({
  infoText: {
    cursor: 'pointer'
  }
})

const InfoPopover = ({
  className,
  setToggleDrawer,
  children,
  linkText,
  infoLabel,
  dataTestId,
  linkTextStyle
}) => {
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = useState(null)

  const open = Boolean(anchorEl)

  const showDetails = useCallback(event => setAnchorEl(event.currentTarget), [
    setAnchorEl
  ])

  return (
    <>
      <Link
        data-testid={dataTestId}
        onClick={showDetails}
        className={className}>
        <Typography
          className={classes.infoText}
          style={linkTextStyle}
          variant="h5">
          {infoLabel}
        </Typography>
      </Link>
      <Popover
        id={'simple-popover'}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}>
        <ProjectCard
          customCardContent={<CardContent children={children} />}
          linkText={linkText}
          noMinHeight
          onClick={setToggleDrawer}
          cardStyles={{
            width: 340
          }}
          customBody
          noHeader
        />
      </Popover>
    </>
  )
}

InfoPopover.propTypes = {
  className: PropTypes.string,
  isLandOwnerRequired: PropTypes.bool,
  setToggleDrawer: PropTypes.func,
  children: PropTypes.any,
  linkText: PropTypes.any,
  infoLabel: PropTypes.node,
  dataTestId: PropTypes.string,
  linkTextStyle: PropTypes.object
}

export default InfoPopover
