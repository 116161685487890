export const planUpgradeTypes = [
  {
    value: 'no-change',
    label: 'no_change'
  },
  {
    value: 'agronomy-planning',
    label: 'bundle_agronomy_drawer'
  },
  { value: 'all-access', label: 'Superman' },
  {
    value: 'international-sustainability',
    label: 'International Sustainability'
  }
]
export const ROUTES = {
  EMPLOYEE_DASHBOARD: 'employee-dashboard',
  ORGANIZATIONS: 'organizations',
  COMMUNITIES: 'communities',
  CAMPAIGNS: 'campaigns',
  REGIONS: 'regions',
  MEMBER_DETAILS: 'member-profile'
}
