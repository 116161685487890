import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@nutrien/uet-react/Grid'
import HomeTabs from './components/HomeTabs'
import ProductTabs from './components/ProductTabs'

const Tabs = ({ handleTabChange, orientation, scrolledToTab, tabType }) => {
  const isHorizontal = orientation === 'horizontal'

  // we might not need this in the future when we get rid of the home page
  const isHomeNav = tabType === 'home'

  return (
    <Grid
      data-testid="tabs-container"
      container
      direction={isHorizontal ? 'row' : 'column'}
      justify={isHorizontal ? 'center' : 'flex-start'}
      style={{ position: 'relative' }}
      alignItems="flex-start">
      {isHomeNav ? (
        <HomeTabs
          data-testid="home-tabs"
          scrolledToTab={scrolledToTab}
          onTabChange={handleTabChange}
        />
      ) : (
        <ProductTabs data-testid="product-tabs" onTabChange={handleTabChange} />
      )}
    </Grid>
  )
}

Tabs.propTypes = {
  handleTabChange: PropTypes.func,
  orientation: PropTypes.oneOf(['vertical', 'horizontal']),
  // legacy prop for the Home page scroll logic
  scrolledToTab: PropTypes.string,
  tabType: PropTypes.oneOf(['account', 'home', 'community'])
}

Tabs.defaultProps = {
  handleTabChange: () => {},
  orientation: 'horizontal',
  scrolledToTab: undefined,
  tabType: undefined
}

export default Tabs
