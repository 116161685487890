import { useState, useEffect } from 'react'

export default (mapRef, initialZoom = 0) => {
  const [zoom, setZoom] = useState(initialZoom)

  useEffect(() => {
    if (!mapRef) return
    const handleMoveEnd = () => setZoom(mapRef.getView().getZoom())

    mapRef.on('moveend', handleMoveEnd)
    return () => mapRef.un('moveend', handleMoveEnd)
  }, [mapRef])

  return zoom
}
