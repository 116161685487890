import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Typography from '@nutrien/uet-react/Typography'
import Grid from '@nutrien/uet-react/Grid'
import TextField from '@nutrien/uet-react/TextField'
import MenuItem from '@nutrien/uet-react/MenuItem'
import Button from '@nutrien/uet-react/Button'
import Label from '@nutrien/uet-react/Label'
import AddIcon from '@nutrien/uet-react/icons/Add'

import useToggle from 'hooks/useToggle'

import InviteUserDialog from '../InviteUserDialog'

const PermUser = ({
  orgs,
  selectedOrg,
  onSelectOrg,
  farms,
  selectedFarm,
  onSelectFarm,
  users,
  selectedUser,
  onSelectUser,
  setRequestPending,
  setRequestError,
  onInviteSuccess,
  canEdit
}) => {
  const { t } = useTranslation()
  const [showInviteUser, toggleInviteUser] = useToggle(false)

  const handleInviteSuccess = useCallback(
    userId => {
      toggleInviteUser()
      onInviteSuccess(userId)
    },
    [onInviteSuccess, toggleInviteUser]
  )

  return (
    <div>
      <Typography variant="body1">{t('org_desc')}</Typography>
      <br />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            select
            label={t('organization')}
            value={selectedOrg}
            onChange={e => onSelectOrg(e.target.value)}>
            {orgs.map(org => (
              <MenuItem key={org.uuid} value={org.uuid}>
                {org.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <TextField
            select
            label={t('farm')}
            value={selectedFarm}
            onChange={e => onSelectFarm(e.target.value)}
            disabled={!farms.length}>
            {farms.length ? (
              farms.map(farm => (
                <MenuItem key={farm.uuid} value={farm.uuid}>
                  {farm.name}
                </MenuItem>
              ))
            ) : (
              <MenuItem />
            )}
          </TextField>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <TextField
            select
            label={t('user')}
            value={selectedUser}
            onChange={e => onSelectUser(e.target.value)}
            disabled={!users.length}>
            {users.length ? (
              users.map(user => (
                <MenuItem key={user.uuid} value={user.uuid}>
                  {user.email}
                </MenuItem>
              ))
            ) : (
              <MenuItem />
            )}
          </TextField>
        </Grid>

        {canEdit && (
          <Grid item xs={12} sm={6} md={3}>
            <Label label="&nbsp;">
              <Button
                startIcon={<AddIcon />}
                variant="outlined"
                color="primary"
                size="large"
                onClick={toggleInviteUser}>
                {t('invite_user')}
              </Button>
            </Label>
          </Grid>
        )}
      </Grid>

      {showInviteUser && (
        <InviteUserDialog
          orgId={selectedOrg}
          onClose={toggleInviteUser}
          onSuccess={handleInviteSuccess}
          setRequestPending={setRequestPending}
          setRequestError={setRequestError}
        />
      )}
    </div>
  )
}

PermUser.propTypes = {
  orgs: PropTypes.array,
  selectedOrg: PropTypes.string,
  onSelectOrg: PropTypes.func.isRequired,
  farms: PropTypes.array,
  selectedFarm: PropTypes.string,
  onSelectFarm: PropTypes.func.isRequired,
  users: PropTypes.array,
  selectedUser: PropTypes.string,
  onSelectUser: PropTypes.func.isRequired,
  setRequestPending: PropTypes.func.isRequired,
  setRequestError: PropTypes.func.isRequired,
  onInviteSuccess: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired
}

PermUser.defaultProps = {}

export default React.memo(PermUser)
