import { useContext } from 'react'
import { ENTITY_TYPE_ORGS } from '@nutrien/farm-tree-navigation/dist/constants/entities'
import useFlags from 'hooks/useFlags'

import { AuthContext } from 'components/Auth/authentication-context'

const useDashboardLink = () => {
  const { user } = useContext(AuthContext)
  const flags = useFlags()
  const canViewStakeholderDash =
    user?.communityDashboardAccess?.canViewStakeholderDashboard
  const canViewEmployeeDashboard =
    user?.communityDashboardAccess?.canViewEmployeeDashboard

  if (canViewEmployeeDashboard) {
    return '/employee-dashboard'
  }

  if (canViewStakeholderDash) {
    return '/stakeholder-dashboard'
  }

  if (flags.sustainabiltityGrowerDashboard) {
    return `/grower-dashboard`
  }

  return `/cards/${ENTITY_TYPE_ORGS}/my-account/sustainable-sourcing`
}

export default useDashboardLink
