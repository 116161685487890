import React, { useCallback, useContext } from 'react'
import Box from '@nutrien/uet-react/Box'
import { useTranslation } from 'react-i18next'
import { ENTITY_TYPE_ORGS } from '@nutrien/farm-tree-navigation/dist/constants/entities'
import Hidden from '@nutrien/uet-react/Hidden'
import Button from '@nutrien/uet-react/Button'
import IconButton from '@nutrien/uet-react/IconButton'
import makeStyles from '@nutrien/uet-react/styles/makeStyles'
import EditIcon from '@nutrien/uet-react/icons/Edit'
import AddCircleIcon from '@nutrien/uet-react/icons/AddCircle'
import CloseIcon from '@nutrien/uet-react/icons/Close'
import Dialog from '@nutrien/uet-react/Dialog'
import { AuthContext } from 'components/Auth/authentication-context'
import useToggle from 'hooks/useToggle/useToggle'
import { FARM_TREE_PRODUCT } from 'helpers/products'
import usePermissions from 'hooks/usePermissions'
import Typography from 'components/Typography'
import useAuthorshipSession from 'hooks/useAuthorshipSession'

const useStyles = makeStyles(() => ({
  button: {
    padding: '2px'
  }
}))

const FarmTreeControls = ({ handleEdit, handleAdd, handleClose, entity }) => {
  const { t } = useTranslation()
  const { button } = useStyles()
  const { user } = useContext(AuthContext)
  const { canAdd, canChange, canDelete } = usePermissions(FARM_TREE_PRODUCT)
  const { authorshipSessionActive } = useAuthorshipSession()
  // it is not possible to edit a shared organization (only its groups/farms/fields)
  const canChangeEntity = useCallback(
    entity =>
      canChange(entity.uuid) &&
      (entity.type !== ENTITY_TYPE_ORGS || entity.isOwner),
    [canChange]
  )
  // organization can not be deleted (despite their group could be)
  const canDeleteEntity = useCallback(
    entity => canDelete(entity.uuid) && entity.type !== ENTITY_TYPE_ORGS,
    [canDelete]
  )

  const [showUnableDialog, toggleUnableDialog] = useToggle(false)
  const handleAddField = event => {
    if (entity && !entity.isFake && canAdd(entity.uuid)) handleAdd(event)
    else toggleUnableDialog()
  }

  return (
    <Box display="flex" justifyContent="space-between">
      <Dialog
        title={t('select_org_first_title')}
        open={showUnableDialog}
        onClose={toggleUnableDialog}
        maxWidth="xs"
        actions={
          <>
            <Button variant="contained" onClick={toggleUnableDialog}>
              {t('okay_no_exclamation')}
            </Button>
          </>
        }>
        <Typography variant="body1">{t('select_org_first_msg')}</Typography>
      </Dialog>
      <Hidden mdUp>
        <IconButton onClick={handleClose} className={button}>
          <CloseIcon />
        </IconButton>
      </Hidden>
      <Box center="y">
        <Typography variant="h2">{t('fields')}</Typography>
      </Box>
      <Box>
        <IconButton
          className={button}
          disabled={
            (authorshipSessionActive &&
              !user?.authorshipPermissions?.canEditGrowerData) ||
            (entity
              ? entity.isFake ||
                (!canChangeEntity(entity) && !canDeleteEntity(entity))
              : false)
          }
          data-testid="ftn-edit-button"
          onClick={handleEdit}>
          <EditIcon />
        </IconButton>
        <IconButton
          onClick={handleAddField}
          className={button}
          disabled={
            authorshipSessionActive &&
            !user?.authorshipPermissions?.canEditGrowerData
          }
          data-testid="ftn-add-button">
          <AddCircleIcon />
        </IconButton>
      </Box>
    </Box>
  )
}

export default FarmTreeControls
