import _find from 'lodash/find'

export const getParent = (farmTree, uuid) => {
  return _find(
    farmTree,
    entity => entity.childrenUUIDs && entity.childrenUUIDs.includes(uuid)
  )
}

export function findRoots(entities) {
  const entityArray = Object.values(entities)
  const allChildrenUUIDs = entityArray.reduce(
    (acc, { childrenUUIDs }) =>
      childrenUUIDs ? acc.concat(childrenUUIDs) : acc,
    []
  )

  return entityArray.filter(
    ({ uuid, parentUUID }) => !parentUUID && !allChildrenUUIDs.includes(uuid)
  )
}

export function getAncestorUuids(flattenedEntities, entityId) {
  const entity = flattenedEntities[entityId]

  if (!entity || !entity.parentUUID) {
    return []
  }

  return [
    entity.parentUUID,
    ...getAncestorUuids(flattenedEntities, entity.parentUUID)
  ]
}

export function getDescendantUuids(
  flattenedEntities,
  entityId,
  nestingLimit = Number.POSITIVE_INFINITY,
  currentNesting = 1
) {
  const entity = flattenedEntities[entityId]

  if (!entity || !entity.childrenUUIDs) {
    return []
  }

  if (currentNesting >= nestingLimit) {
    return entity.childrenUUIDs
  }

  return [
    ...entity.childrenUUIDs,
    ...entity.childrenUUIDs.flatMap(uuid =>
      getDescendantUuids(
        flattenedEntities,
        uuid,
        nestingLimit,
        currentNesting + 1
      )
    )
  ]
}

export const appendEntityAncestors = (flattenedEntities, selectedEntities) => {
  const getAncestors = (acc, { parentUUID }) => {
    if (Object.keys(acc).includes(parentUUID) || !parentUUID) {
      return acc
    }
    const parentEntity = flattenedEntities[parentUUID]
    return parentEntity
      ? getAncestors({ ...acc, [parentUUID]: parentEntity }, parentEntity)
      : acc
  }

  return Object.values(selectedEntities).reduce(getAncestors, selectedEntities)
}

const noChildren = (entity, collapsedIds = []) =>
  !entity.childrenUUIDs || collapsedIds.includes(entity.uuid)

export const appendDescendants = (entity, entityArray, skippedIds) => {
  if (noChildren(entity, skippedIds)) {
    return { ...entity, noChildren: true }
  }

  const children = entityArray
    .filter(({ uuid }) => entity.childrenUUIDs.includes(uuid))
    .flatMap(child => appendDescendants(child, entityArray, skippedIds))

  return [entity, ...children]
}

export const getAllLeafNodes = (entity, entityArray) => {
  const children = entityArray.filter(({ uuid }) =>
    entity.childrenUUIDs.includes(uuid)
  )

  return children.flatMap(child =>
    child.childrenUUIDs ? getAllLeafNodes(child, entityArray) : child
  )
}
