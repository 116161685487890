import React, {
  createContext,
  useCallback,
  useState,
  useMemo,
  useContext
} from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import {
  ASC,
  DESC,
  SHOW_ALL,
  FARM_SORT,
  NAME_SORT,
  ACRES_SORT,
  ENROLLMENT_SORT,
  CROP_ROTATION_SORT,
  CROP_ROTATION_STATUS_SORT,
  defaultFilterValues,
  SUSTAINABILITY_PERMISSIONS
} from 'const'

import { useQuery } from 'graphql-hooks'
import { AuthContext } from 'components/Auth/authentication-context'

const GET_CAMPAIGNS_QUERY = `
  query fetchAllUserCampaignsThroughMemberships(
    $permission: String,
    $campaignOpen: Boolean,
    $profileUuid: String,
    $summary: Boolean
  ) {
    fetchAllUserCampaignsThroughMemberships(
      permission: $permission,
      campaignOpen: $campaignOpen,
      profileUuid: $profileUuid,
      summary: $summary
    ) {
      uuid
      status
      campaign {
        uuid
        name
        shortDescription
      }
    }
  }
`
export const FarmTreeFiltersContext = createContext({})

export const FarmTreeFiltersProvider = ({
  children,
  sortOptions,
  allCroppingSeasonsQuery,
  initSortOption
}) => {
  const { t } = useTranslation()
  const ADD_BY = 5
  const { user } = useContext(AuthContext)
  const profileUuid = user?.id
  const { data } = useQuery(GET_CAMPAIGNS_QUERY, {
    variables: {
      permission: SUSTAINABILITY_PERMISSIONS.GROWER,
      campaignOpen: true,
      profileUuid: profileUuid,
      summary: true
    }
  })

  const croppingSeasons = allCroppingSeasonsQuery?.data?.listAllCroppingSeasons

  const memberships = useMemo(
    () => data?.fetchAllUserCampaignsThroughMemberships,
    [data]
  )
  const [seasonsLength, setSeasonsLength] = useState(ADD_BY)
  const [selectedFilter, setSelectedFilters] = useState(
    initSortOption
      ? { ...defaultFilterValues, sortOption: initSortOption }
      : defaultFilterValues
  )

  const limitedSeasons = croppingSeasons?.slice(0, seasonsLength) || []

  const showMoreOption =
    croppingSeasons?.length > ADD_BY && croppingSeasons?.length > seasonsLength

  const addToLimited = useCallback(() => {
    const newLength = seasonsLength + ADD_BY

    // check if the new length is longer than the croppingSeasons' length
    setSeasonsLength(
      newLength > croppingSeasons.length - 1
        ? croppingSeasons.length
        : newLength
    )
  }, [seasonsLength, croppingSeasons])

  const membershipOptions = useCallback(() => {
    if (!memberships) return
    const membershipOptions = memberships.map(membership => ({
      value: membership.campaign.uuid,
      membershipUuid: membership.uuid,
      label: membership.campaign.name,
      status: membership.status
    }))

    return [{ value: SHOW_ALL, label: `${t('ShowAll')}` }, ...membershipOptions]
  }, [memberships, t])

  // All filter options
  const options = useMemo(
    () => ({
      sortOption: [
        { value: CROP_ROTATION_SORT, label: t('rotation_group') },
        { value: CROP_ROTATION_STATUS_SORT, label: t('status') },
        { value: FARM_SORT, label: t('farm') },
        { value: ENROLLMENT_SORT, label: t('EnrollmentStatus') },
        { value: ACRES_SORT, label: t('Acres') },
        { value: NAME_SORT, label: t('field_name') }
      ].filter(({ value }) => sortOptions.includes(value)),
      orderOption: [
        { value: ASC, label: t('ascending') },
        { value: DESC, label: t('descending') }
      ],
      filterByCropSeason: [
        { value: SHOW_ALL, label: `${t('ShowAll')}` },
        ...limitedSeasons?.map(season => ({
          value: season.season,
          label: season.season
        }))
      ],
      // only show this filter if there are memberships
      ...(memberships
        ? {
            filterByProject: membershipOptions()
          }
        : {})
    }),
    [t, limitedSeasons, memberships, membershipOptions, sortOptions]
  )

  return (
    <FarmTreeFiltersContext.Provider
      value={{
        filters: options,
        showMoreOption,
        handleShowMore: addToLimited,
        selectedFilter,
        membershipOptions,
        setSelectedFilters,
        allCroppingSeasonsQuery
      }}>
      {children}
    </FarmTreeFiltersContext.Provider>
  )
}

FarmTreeFiltersProvider.propTypes = {
  children: PropTypes.object.isRequired,
  sortOptions: PropTypes.array,
  allCroppingSeasonsQuery: PropTypes.any,
  initSortOption: PropTypes.any
}

FarmTreeFiltersProvider.defaultProps = {
  sortOptions: [FARM_SORT, NAME_SORT, ACRES_SORT]
}

export default FarmTreeFiltersProvider
