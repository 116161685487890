import React from 'react'
import T from 'prop-types'
import { useTranslation } from 'react-i18next'
import SearchIcon from '@nutrien/uet-react/icons/Search'
import Box from '@nutrien/uet-react/Box'
import Button from '@nutrien/uet-react/Button'
import Typography from '@nutrien/uet-react/Typography'

const NoResults = ({ clearSearch }) => {
  const { t } = useTranslation('farm-tree-navigation')
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      pt={3}
      data-testid="noFarmTreeResults">
      <SearchIcon fontSize="large" />
      <Typography>{t('No Search Results')}</Typography>
      <Button data-testid="clearSearchButton" onClick={clearSearch}>
        {t('Clear Search')}
      </Button>
    </Box>
  )
}

NoResults.propTypes = {
  clearSearch: T.func.isRequired
}

export default NoResults
