import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from './BonsaiButton'

const BonsaiCalmButton = props => {
  const [executing, setExecuting] = useState(false)

  const { disabled, onClick, ...otherProps } = props

  const onRealClick = async event => {
    setExecuting(true)
    try {
      await onClick()
    } finally {
      setExecuting(false)
    }
  }

  return (
    <Button
      onClick={onRealClick}
      disabled={executing || disabled}
      {...otherProps}
    />
  )
}

export default BonsaiCalmButton

BonsaiCalmButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired
}
