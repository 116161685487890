import React from 'react'
import ExpandLess from '@nutrien/uet-react/icons/ExpandLess'
import ExpandMore from '@nutrien/uet-react/icons/ExpandMore'

const Caret = ({ direction, ...props }) => {
  if (direction === 'up') {
    return <ExpandLess {...props} />
  }
  if (direction === 'down') {
    return <ExpandMore {...props} />
  }
  return null
}

export default Caret
