import React, { lazy } from 'react'
import { Route, Switch } from 'react-router-dom'
import useFlags from 'hooks/useFlags'

import { BasicLayout } from 'components/PageLayouts'
import StepperProvider from 'components/StepperContext'
const AsyncManageProject = lazy(() => import('pages/ManageProject'))
const AsyncEnrollFields = lazy(() => import('pages/EnrollFields'))
const AsyncBuildRotations = lazy(() => import('pages/BuildRotations'))
const AsyncCreatePlan = lazy(() => import('pages/CreatePlan'))
const AsyncDocumentBaseline = lazy(() => import('pages/DocumentBaseline'))
const AsyncLogFieldActivity = lazy(() => import('pages/LogFieldActivity'))
const AsyncPageNotFound = lazy(() => import('pages/NotFoundWithFooter'))

const ManagePropjectDashboard = () => {
  const flags = useFlags()

  return (
    <BasicLayout withFooter={true}>
      <StepperProvider>
        <Switch>
          {flags.sustainabilityManageProject && (
            <Route
              exact
              path="/manage-project/:id"
              render={props => <AsyncManageProject {...props} />}
            />
          )}

          {flags.sustainabilityManageProject && (
            <Route
              path="/manage-project/:id/select-fields/"
              render={props => <AsyncEnrollFields {...props} />}
            />
          )}

          {flags.stepperBuildRotations && (
            <Route
              path="/manage-project/:id/build-rotations/"
              render={props => <AsyncBuildRotations {...props} />}
            />
          )}

          {flags.stepper4CreatePlan && (
            <Route
              path="/manage-project/:id/create-plan/"
              render={props => <AsyncCreatePlan {...props} />}
            />
          )}

          {flags.stepperDocumentBaseline && (
            <Route
              path="/manage-project/:id/document-baseline/"
              render={props => <AsyncDocumentBaseline {...props} />}
            />
          )}

          {flags.manageProjectLogFieldActivityEnabled && (
            <Route
              path="/manage-project/:id/log-field-activity/"
              render={props => <AsyncLogFieldActivity {...props} />}
            />
          )}

          {/* Last route will show the 404 page not found */}
          <Route path="*" render={props => <AsyncPageNotFound {...props} />} />
        </Switch>
      </StepperProvider>
    </BasicLayout>
  )
}

export default ManagePropjectDashboard
