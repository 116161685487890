export default {
  red: {
    normal: '#d33e2a',
    light: '#e7b2aa',
    dark: '#b9372b'
  },
  green: {
    normal: '#8dc63f',
    light: '#c4df9b',
    dark: '#023502'
  }
}
