import React from 'react'
import PropTypes from 'prop-types'
import Hidden from '@nutrien/uet-react/Hidden'

import LinkTab from '../LinkTab'

/**
 * this component is to support the page with different cards in it.
 * eventually we will be moving to the dropdown for nested links
 * this is the latest UX we agreed on as of 09/09/2021
 **/
const NestedLinkTab = ({
  children,
  id,
  isSelected,
  tabLabel,
  ...parentLinkTabprops
}) => {
  /*
   * if the children is an array
   * filter the invalid element from children.
   * this is only used for conditions.
   */
  const validChildren =
    children?.filter?.(child => React.isValidElement(child)) ?? []

  /*
   * this guard clause is to render null
   * when there are no children or if the children are invalid elements.
   * this is a possible case when the user doesn't have any permissions
   * to any of the children or all of the children are feature flagged.
   */
  if (!children || (Array.isArray(children) && !validChildren.length))
    return null

  /*
   * checks if any of the children is selected.
   * we have to cast this as boolean because if isSelected is false,
   * it will pass the child object to the isSelected props
   * of <LinkTab> which expects a boolean.
   */
  const hasChildSelected = !!(Array.isArray(children)
    ? validChildren.find(child => child.props.isSelected)
    : children.props.isSelected)

  /*
   * We could reuse this component just for mobile.
   * Once we migrated all of the tabs to dropdown, we
   * can remove the logic to show a different behavior
   * in desktop and just use this component for mobile nav.
   *
   * We can remove the <Hidden /> component and handle the
   * logic in the <DropDownLinkTab />
   */

  return (
    <>
      <LinkTab
        {...parentLinkTabprops}
        id={id}
        data-testid={`nested-link-tab-${id}`}
        isSelected={isSelected || hasChildSelected}>
        {tabLabel}
      </LinkTab>

      {/* only renders the children links on mobile */}
      <Hidden mdUp>{children}</Hidden>
    </>
  )
}

NestedLinkTab.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  tabLabel: PropTypes.string.isRequired
}

NestedLinkTab.defaultProps = {
  isSelected: false
}

export default NestedLinkTab
