import React, { useState } from 'react'
import TermsAndConditionsDrawer from './TermsAndConditionsDrawer'
import UpdatedTermsAndConditions from './UpdatedTermsAndConditions'
import YouAreIn from './YouAreIn'
import DeclineTermsDialog from './DeclineTermsDialog'

const AcceptTermsAndConditions = () => {
  const [showTermsandCondDrawer, setShowTermsandCondDrawer] = useState(false)
  const [showDeclineTermsDialog, setShowDeclineTermsDialog] = useState(false)
  return (
    <>
      <YouAreIn setShowTermsandCondDrawer={setShowTermsandCondDrawer} />
      <UpdatedTermsAndConditions
        setShowTermsandCondDrawer={setShowTermsandCondDrawer}
      />
      <TermsAndConditionsDrawer
        setShowDeclineTermsDialog={setShowDeclineTermsDialog}
        setShowTermsandCondDrawer={setShowTermsandCondDrawer}
        showTermsandCondDrawer={showTermsandCondDrawer}
      />
      <DeclineTermsDialog
        setShowDeclineTermsDialog={setShowDeclineTermsDialog}
        setShowTermsandCondDrawer={setShowTermsandCondDrawer}
        showDeclineTermsDialog={showDeclineTermsDialog}
      />
    </>
  )
}

AcceptTermsAndConditions.propTypes = {}

export default AcceptTermsAndConditions
