import React from 'react'

export const JPGIcon = ({ height = 24, width = 24 }) => (
  <svg
    style={{ verticalAlign: 'middle' }}
    width={width}
    height={height}
    viewBox="0 0 350 350"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_27327_275872)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M306.25 277.083V72.9167C306.25 56.875 293.125 43.75 277.083 43.75H72.9167C56.875 43.75 43.75 56.875 43.75 72.9167V277.083C43.75 293.125 56.875 306.25 72.9167 306.25H277.083C293.125 306.25 306.25 293.125 306.25 277.083ZM123.958 196.875L160.417 240.771L211.458 175L277.083 262.5H72.9167L123.958 196.875Z"
        fill="#666666"
      />
    </g>
    <defs>
      <clipPath id="clip0_27327_275872">
        <rect width="350" height="350" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
