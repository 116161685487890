import { useContext, useMemo } from 'react'
import _get from 'lodash/get'

import { AuthContext } from 'components/Auth/authentication-context'

export default () => {
  const { user } = useContext(AuthContext)
  const country = useMemo(() => _get(user, 'profile.address.country'), [user])

  return country
}
