import React from 'react'
import _get from 'lodash/get'
import _find from 'lodash/find'

import EntityAncestors from 'components/EntityAncestors'

const createEntity = (farmTree, { uuid, name }) => {
  return {
    uuid,
    name,
    titleInfo: (
      <EntityAncestors
        farmTree={farmTree}
        entityUuid={uuid}
        entityName={name}
      />
    ),
    items: {
      total: {
        value: 0
      },
      average: {
        value: 0
      }
    }
  }
}

const selectedSeasonExists = (selectedSeason, value) =>
  selectedSeason &&
  value.cropName === selectedSeason.name &&
  value.cropYear === selectedSeason.year

export const getEntityTotals = ({
  farmTree,
  farmItemId,
  fieldsData,
  fieldType,
  selectedSeason,
  canView
}) => {
  const entities = []
  const ancestors = {}

  if (!farmTree || !farmItemId) return entities

  const findEntities = entityId => {
    const entity = farmTree[entityId]

    if (!entity) return

    if (entity.type === fieldType) {
      const field = _find(fieldsData, { fieldUuid: entity.uuid })

      if (_get(field, 'values')) {
        field.values.forEach(value => {
          for (const type in ancestors) {
            if (selectedSeasonExists(selectedSeason, value)) {
              ancestors[type].items.total.value +=
                (value.area ? parseFloat(value.area) : 0) *
                (value.actual ? parseFloat(value.actual) : 0)
              ancestors[type].items.average.value += value.area
                ? parseFloat(value.area)
                : 0

              ancestors[type].items.total.units = value.units
                ? _get(value.units.split('/'), '[0]')
                : null
              ancestors[type].items.average.units = value.units
            }
          }
        })
      }
    } else if (entity.childrenUUIDs) {
      ancestors[entity.type] = createEntity(farmTree, entity)
      entities.push(ancestors[entity.type])
      entity.childrenUUIDs.filter(canView).forEach(findEntities)
    }
  }
  findEntities(farmItemId)

  // generate average by diving total with sum of area
  for (const entity of entities) {
    entity.items.average.value =
      entity.items.average.value > 0
        ? entity.items.total.value / entity.items.average.value
        : 0
  }

  return entities
}
