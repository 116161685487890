import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Button from '@nutrien/uet-react/Button'
import Card from '@nutrien/uet-react/Card'
import Box from '@nutrien/uet-react/Box'
import CardActions from '@nutrien/uet-react/CardActions'
import CardContent from '@nutrien/uet-react/CardContent'
import CardHeader from '@nutrien/uet-react/CardHeader'
import ChevronRight from '@nutrien/uet-react/icons/ChevronRight'
import Typography from '@nutrien/uet-react/Typography'
import makeStyles from '@nutrien/uet-react/styles/makeStyles'
import theme from 'theme'
import ChipWithPopover from 'components/ChipWithPopover'

const useStyles = makeStyles(theme => ({
  bodyStyles: {
    ...theme.typography.subtitle1,
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden'
  },
  button: {
    '&:hover': {
      backgroundColor: 'inherit'
    }
  },
  buttonCenter: {
    '&:hover': {
      backgroundColor: 'inherit'
    },
    justifyContent: 'center'
  },
  cardRoot: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '228px'
  },
  cardNoMinHeight: {
    display: 'flex',
    flexDirection: 'column'
  },
  cardHeader: {
    paddingBottom: theme.spacing(0),
    fontSize: 16,
    lineHeight: 24,
    height: 64,
    alignItems: 'start'
  },
  cardTitle: {
    fontSize: '20px',
    fontWeight: '600',
    lineHeight: '1.1',
    marginBottom: theme.spacing(0.5),
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    'text-overflow': 'ellipsis',
    overflow: 'hidden',
    '@media (max-width: 1250px)': {
      fontSize: '18px',
      lineHeight: '1.3'
    }
  },
  cardSubheader: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '122px'
  },
  cardContent: {
    padding: theme.spacing(1, 2, 0, 2),
    flex: 1
  },
  titleChip: {
    backgroundColor: '#0093E5',
    color: '#fff',
    marginLeft: 8,
    paddingLeft: 12,
    paddingRight: 12,
    fontSize: 12,
    fontWeight: 600
  }
}))

const ProjectCard = ({
  bodyText,
  cardStyles = {},
  chipStatus = false,
  customBody = false,
  customCardContent = null,
  customCardHeader = null,
  headerText,
  linkHref,
  linkText,
  noHeader = false,
  noMinHeight,
  onClick,
  reportCard = false,
  subHeaderText
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const renderHeader = () => {
    if (noHeader) return null
    if (customCardHeader) return customCardHeader

    return (
      <CardHeader
        subheader={
          <Box display="flex">
            <Box flex={1}>
              <Typography className={classes.cardSubheader}>
                {subHeaderText}
              </Typography>
            </Box>
          </Box>
        }
        classes={{ root: classes.cardHeader, title: classes.cardTitle }}
        title={
          <Box display="flex">
            <Box flex={1}>
              <Typography className={classes.cardTitle}>
                {headerText}
              </Typography>
            </Box>
            {chipStatus && (
              <ChipWithPopover
                label={t('new')}
                chipColor={theme.palette.info.main}
                chipTextColor={theme.palette.common.white}
                popoverText={t('new_invitation_popover')}
                chipSize="xsmall"
              />
            )}
          </Box>
        }
      />
    )
  }

  return (
    <div style={cardStyles}>
      <Card
        elevation={0}
        variant="outlined"
        classes={{
          root: noMinHeight ? classes.cardNoMinHeight : classes.cardRoot
        }}
        data-testid="project-card">
        <Box mb={0.5}>{renderHeader()}</Box>

        {customBody ? (
          customCardContent
        ) : (
          <CardContent classes={{ root: classes.cardContent }}>
            <Typography className={classes.bodyStyles}>{bodyText}</Typography>
          </CardContent>
        )}
        {reportCard && (
          <CardActions classes={{ root: classes.buttonCenter }}>
            <Button
              classes={{ root: classes.button }}
              to={linkHref}
              disableRipple
              component={Link}>
              {linkText}
            </Button>
          </CardActions>
        )}
        {linkText && !reportCard && (
          <CardActions>
            {linkHref ? (
              <Button
                classes={{ root: classes.button }}
                to={linkHref}
                endIcon={<ChevronRight />}
                disableRipple
                component={Link}>
                {linkText}
              </Button>
            ) : (
              <Button
                classes={{ root: classes.button }}
                onClick={onClick}
                endIcon={<ChevronRight />}
                disableRipple>
                {linkText}
              </Button>
            )}
          </CardActions>
        )}
      </Card>
    </div>
  )
}

ProjectCard.propTypes = {
  bodyText: PropTypes.string,
  cardStyles: PropTypes.shape(),
  chipStatus: PropTypes.bool,
  customBody: PropTypes.bool,
  customCardContent: PropTypes.node,
  customCardHeader: PropTypes.node,
  headerText: PropTypes.string,
  linkHref: PropTypes.string,
  linkText: PropTypes.any,
  noHeader: PropTypes.bool,
  noMinHeight: PropTypes.bool,
  onClick: PropTypes.func,
  reportCard: PropTypes.bool,
  subHeaderText: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default ProjectCard
