import { useState, useCallback, useRef } from 'react'
import { useManualQuery } from 'graphql-hooks'
import _get from 'lodash/get'

const IMPORT_STATUS_QUERY = `
  query {
    getImportStatus {
      status
      pctComplete
      message
      enterpriseGroupUuid
      summary {
          totalFieldsProcessed
          totalFieldsAdded
          totalFieldsUpdated
          totalFieldsRemoved
          totalFieldsNoop
          totalFieldsSkipped
          totalBoundaryErrors
          totalApiErrors
      }
    }
  }
`

const initialProgress = {
  pctComplete: 0,
  isPending: true
}

export default (pollDuration = 500) => {
  const timer = useRef(null)
  const [progress, setProgress] = useState(initialProgress)
  const [getImportStatus] = useManualQuery(IMPORT_STATUS_QUERY, {
    useCache: false
  })

  const poll = useCallback(async () => {
    const resp = await getImportStatus()
    const data = _get(resp, ['data', 'getImportStatus']) || {}
    data.status = data.status || 'error'
    const isPending = data.status === 'queued' || data.status === 'running'
    const isDone = data.status === 'done'
    const isError = data.status === 'error'

    setProgress({
      ...data,
      summary: data.summary || {},
      isPending,
      isDone,
      isError
    })

    if (isPending) {
      timer.current = setTimeout(poll, pollDuration)
    }
  }, [getImportStatus, pollDuration])

  return [poll, progress]
}
