import React from 'react'
import { string, node } from 'prop-types'
import Typography from '@nutrien/uet-react/Typography'
import Box from '@nutrien/uet-react/Box'
import Paper from '@nutrien/uet-react/Paper'
import propTypes from 'propTypes'

const EditSection = ({ name, details, children, noButton }) => {
  return (
    <Paper data-testid="edit_entity">
      <Box mb={2} p={3}>
        <Box mb={2}>
          <Typography variant="h5" id="editSectionName">
            {name}
          </Typography>
        </Box>
        {details && (
          <Box mb={noButton ? 1 : 3}>
            <Typography variant="body1">{details}</Typography>
          </Box>
        )}
        {children}
      </Box>
    </Paper>
  )
}

EditSection.propTypes = {
  children: node.isRequired,
  noButton: propTypes.bool,
  name: string.isRequired,
  details: string
}

export default EditSection
