import { Routes as SustainabilityDashboardRoutes } from '@nutrien/hub-sustainability-dashboard'

/*
 * isEnabled can be a boolean derived from LD flags or an internal one like
 * user?.communityDashboardAccess?.canViewStakeholderDashboard
 * See: https://github.com/Agrium/agrible-platform/pull/1292#discussion_r703664632
 *
 * requiresAuth needs to be true when isEnabled needs the user context
 */
export const getHybridRoutes = user => {
  return [
    {
      isEnabled:
        user?.communityAdminAccess ||
        user?.communityDashboardAccess?.canViewStakeholderDashboard,
      requiresAuth: true,
      routes: SustainabilityDashboardRoutes
    }
  ]
}
