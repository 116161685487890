import { useRef } from 'react'

// performant way of creating a singleton (or whatever) via ref
// because useRef(new Class()) will still call the constructor on every render even though the result is ignored
const useSingleton = callback => {
  const ref = useRef(null)
  if (ref.current === null) {
    ref.current = callback()
  }
  return ref.current
}

export default useSingleton
