const CHEMICAL_APPLICATION = 'chemicalApplication'
const FERTILIZER_APPLICATION = 'fertilizerApplication'
const HARVEST_COMPLETION = 'harvestCompletion'
const HAIL_LOG = 'hailLog'
const IRRIGATION = 'irrigation'
const PLANTING_COMPLETION = 'plantingCompletion'
const SOIL_TEST = 'soilTest'
const TILLAGE = 'tillage'

export const EVENT_MUTATION_TYPES = Object.freeze({
  CHEMICAL_APPLICATION,
  FERTILIZER_APPLICATION,
  HARVEST_COMPLETION,
  HAIL_LOG,
  IRRIGATION,
  PLANTING_COMPLETION,
  SOIL_TEST,
  TILLAGE
})
