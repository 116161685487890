import React, { Suspense, useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import Box from '@nutrien/uet-react/Box'
import Grid from '@nutrien/uet-react/Grid'
import SplitPane from '@nutrien/uet-lab/SplitPane'
import useMediaQuery from '@nutrien/uet-react/useMediaQuery'
import { useQuery } from 'graphql-hooks'
import { ALL_CROPPING_SEASONS_QUERY, ADD_CROPPING_SEASON_MUTATION } from 'const'
import th from 'theme/helpers'
import useQueryParams from 'hooks/useQueryParams'
import { AuthContext } from 'components/Auth/authentication-context'
import Loading from 'components/Loading'
import LinkHeader from 'components/Header/LinkHeader'
import Footer from 'components/Footer'
import FarmTree from 'components/FarmTree'
import { ScrollContext } from 'components/ScrollProvider/ScrollProvider'
import FarmTreeFiltersProvider from 'components/FarmTreeFiltersProvider'
import usePopulateFarmTree from 'hooks/usePopulateFarmTree'
import usePendo from 'hooks/usePendo'
import { gqlHooksCache } from '../../../graphql'

const PageContainer = styled(Grid)`
  height: 100%;
  min-height: 100%;
  background: ${th.palette('common.white')};
  & .Pane.vertical.Pane1 {
    background: #f2f2f2;
    overflow: hidden;
  }
`

const ContentContainer = styled(Grid)`
  ${th.breakpoints.up('md')`
    overflow-y: scroll;
    flex-direction: row;
  `}
`

const RightPane = styled(Grid)`
  ${th.breakpoints.up('md')`
    height: 100%;
    overflow-y: scroll;
  `}
`

const Page = styled(Grid)`
  flex: 1 0 auto;
`

const RightSide = ({ hideFarmTree, children, hideFooter }) => (
  <RightPane
    item
    container
    direction="column"
    wrap="nowrap"
    xs
    id="scroll-container">
    <Page
      item
      container
      direction="column"
      wrap="nowrap"
      xs
      component={Box}
      px={hideFarmTree ? 3 : 2}
      pb={2}>
      <Suspense fallback={<Loading />}>{children}</Suspense>
    </Page>
    {!hideFooter && <Footer />}
  </RightPane>
)

const LeftSide = ({ hideFarmTree }) => {
  const farmTreeData = usePopulateFarmTree()

  return hideFarmTree ? null : (
    <Box bgcolor={'grey.50'}>
      <FarmTree {...farmTreeData} />
    </Box>
  )
}

const FarmTreeLayout = ({ children }) => {
  useEffect(() => {
    initializePendo()
    gqlHooksCache.clear()

    // disabling this rule since we only want this to run on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { viewMode } = useParams()
  const { formType } = useQueryParams()
  const { setContentScroll } = useContext(ScrollContext)
  const hideFooter = viewMode === 'maps'
  const hideFarmTree = formType === 'edit' || formType === 'create'
  const isMobile = useMediaQuery('(max-width:960px)')
  const { user } = useContext(AuthContext)
  const profileUuid = user?.id

  const allCroppingSeasonsQuery = useQuery(ALL_CROPPING_SEASONS_QUERY, {
    variables: {
      profileUuid
    },
    refetchAfterMutations: [ADD_CROPPING_SEASON_MUTATION]
  })

  const { initialize: initializePendo } = usePendo()

  return (
    <FarmTreeFiltersProvider allCroppingSeasonsQuery={allCroppingSeasonsQuery}>
      <PageContainer
        container
        wrap="nowrap"
        direction="column"
        justify="flex-start"
        alignItems="center">
        <LinkHeader tabType="account" />
        <ContentContainer
          xs
          item
          container
          wrap="nowrap"
          onScroll={event => {
            if (event.target.id === 'scroll-container')
              setContentScroll(event.target.scrollTop)
          }}
          direction="column"
          justify="flex-start">
          {hideFarmTree ? (
            <RightSide
              hideFarmTree={hideFarmTree}
              children={children}
              hideFooter={hideFooter}
            />
          ) : isMobile ? (
            <Box>
              <LeftSide hideFarmTree={hideFarmTree} />
              <RightSide
                hideFarmTree={hideFarmTree}
                children={children}
                hideFooter={hideFooter}
              />
            </Box>
          ) : (
            <SplitPane
              defaultSize={466}
              leftContent={<LeftSide hideFarmTree={hideFarmTree} />}
              rightContent={
                <RightSide
                  hideFarmTree={hideFarmTree}
                  children={children}
                  hideFooter={hideFooter}
                />
              }
            />
          )}
        </ContentContainer>
      </PageContainer>
    </FarmTreeFiltersProvider>
  )
}

export default FarmTreeLayout
