import {
  string,
  shape,
  number,
  bool,
  array,
  arrayOf,
  oneOfType
} from 'prop-types'
import { eventType } from './eventsTypes'
import { userType } from './userTypes'

export const croppingSeasonType = shape({
  canDelete: bool,
  canEdit: bool,
  conservationPractices: array,
  crop: oneOfType([
    shape({
      id: number,
      name: string,
      isCoverCrop: bool,
      isOther: bool,
      isFsSupported: bool,
      isSustainabilitySupportedCrop: bool
    }),
    number
  ]),
  drainageStatus: string,
  owner: userType,
  energySource: string,
  endDate: string,
  events: arrayOf(eventType),
  fieldCharacteristics: array,
  id: number,
  irrigation: shape({
    irrigationSystemOn: bool,
    irrigationSystemType: string,
    irrigatedAreaValue: number,
    irrigatedAreaUnit: string,
    type: string
  }),
  isCoverCrop: bool,
  isFallow: bool,
  productionAreaValue: number,
  rotationSeasonId: string,
  soil: shape({
    userSuppliedSlopeSteepness: number,
    userSuppliedSoilOrganicMatter: number,
    userSuppliedSoilPh: number,
    userSuppliedSoilMoisture: string,
    userSuppliedSoilTexture: string
  }),
  specificCrop: oneOfType([
    shape({
      id: number,
      name: string
    }),
    number
  ]),
  startDate: string,
  tillageMethod: string
})
