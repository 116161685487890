import React from 'react'
import PropTypes from 'prop-types'

import LinkTab from '../LinkTab'

/*
 * This component needs to be used with the NestedLinkTab or DropdownLinkTab
 */

const ChildLinkTab = ({ children, ...props }) => (
  <LinkTab {...props} isChild>
    {children}
  </LinkTab>
)

ChildLinkTab.propTypes = {
  children: PropTypes.node,
  external: PropTypes.bool,
  id: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  to: PropTypes.string.isRequired
}

ChildLinkTab.defaultProps = {
  children: null,
  external: false,
  isSelected: false,
  onClick: () => {}
}

export default ChildLinkTab
