import React, { useCallback } from 'react'
import T from 'prop-types'
import { useTranslation } from 'react-i18next'
import { styled } from '@nutrien/uet-react/styles'
import Select from '@nutrien/uet-react/Select'
import MenuItem from '@nutrien/uet-react/MenuItem'
import { useSelector } from 'react-redux'
import useGetProjectStep from 'hooks/useGetProjectStep/useGetProjectStep'
import { selectAllFarms } from 'store/selectors/farmTree'
import EditSection from '../EditSection'

const StyledSelect = styled(Select)(() => ({
  width: '100%',
  height: '2.5rem',
  '& select:focus': {
    backgroundColor: 'transparent'
  }
}))

const MoveSection = ({ name, parentId, setParentId }) => {
  const { t } = useTranslation()
  const projectStep = useGetProjectStep()
  const handleChange = useCallback(
    e => {
      const { value } = e.target
      setParentId(value)
    },
    [setParentId]
  )
  const farmList =
    useSelector(state => selectAllFarms(state, parentId, projectStep)) ?? []
  return (
    <EditSection
      name={t('move_field')}
      details={t('you_can_move_field', {
        name
      })}>
      <StyledSelect id="farm-select" onChange={handleChange} value={parentId}>
        {farmList.map(({ uuid, name }) => (
          <MenuItem key={`farm-${uuid}`} value={uuid}>
            {name}
          </MenuItem>
        ))}
      </StyledSelect>
    </EditSection>
  )
}

MoveSection.propTypes = {
  name: T.string.isRequired,
  setParentId: T.func.isRequired,
  parentId: T.string.isRequired
}

export default MoveSection
