import { isAfter } from 'helpers/date'

export const isPastEnrollmentDeadline = campaignDetails => {
  if (!campaignDetails.enrollmentEndDate) {
    return false
  }
  const { enrollmentEndDate } = campaignDetails
  const date = new Date()
  const formattedDate = date.toISOString().split('T')[0]
  return isAfter(new Date(formattedDate), new Date(enrollmentEndDate))
}

export const isPastSubmissionDeadline = campaignDetails => {
  // Note: planDeadline below refers to the baseline submission deadline
  /*
    If submission deadline has passed and commitment deadline has not passed
    then we would want to use Submission, if set. But if not set, we’ll differ
    to Enrollment Deadline.
  */
  if (!campaignDetails.planDeadline) {
    return false
  }
  const { planDeadline } = campaignDetails
  const date = new Date()
  const formattedDate = date.toISOString().split('T')[0]
  return isAfter(new Date(formattedDate), new Date(planDeadline))
}

export const isPastCampaignEndDate = campaignDetails => {
  if (!campaignDetails.campaignEndDate) {
    return false
  }
  const { campaignEndDate } = campaignDetails
  const date = new Date()
  const formattedDate = date.toISOString().split('T')[0]
  return isAfter(new Date(formattedDate), new Date(campaignEndDate))
}

export const isBeforeEnrollAndCmpgnEndDates = campaignDetails => {
  return !(
    isPastEnrollmentDeadline(campaignDetails) ||
    isPastCampaignEndDate(campaignDetails)
  )
}
