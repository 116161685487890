export const YIELD_DETAILS_QUERY = `
fragment period on YieldPeriod {
    name
    startDate
    endDate
}

fragment range on YieldRange {
    units
    yield
    low
    high
}

query Yield($fieldId: String!, $unit: Units!) {
    getYieldDetails(fieldId: $fieldId, unit: $unit) {
        values { 
            area 
            areaUnits 
            cropYear 
            cropName 
            seasonId 
            isPast
            units
            lowRangeYield
            highRangeYield
            actualYield
            depthUnits
            moistureStatus
            rootDepth
            waterPercent
            water
            minorFlood { ...range }
            moderateFlood { ...range }
            majorFlood { ...range }
            harvestStatus
            harvestTargets { ...period }
            currentStage { ...period }
            previousStage { ...period }
            nextStage { ...period }
        }
        fieldUuid
        fieldName
        county
        state
        country
        updateTime
        createdTime
    }
}
`

export const LIST_YIELD_QUERY = `
  query ListYield(
  $containerIds: [String]!
  $metric: YieldMetric!
  $unit: Units!
) {
  fieldsData: listYield(
    containerIds: $containerIds
    metric: $metric
    unit: $unit
  ) {
    values {
      actual
      units
      area
      areaUnits
      cropYear
      cropName
      seasonId
      isPast
    }
    fieldUuid
    fieldName
    county
    state
    country
    updateTime
    createdTime
  }
}
`
