import { useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'

export default function useIdle({
  onIdle,
  onPrompt,
  promptIdleTime,
  idleTime
}) {
  const [isIdle, setIsIdle] = useState()

  const handleOnIdle = () => {
    setIsIdle(true)
    onIdle()
  }
  const handleOnPrompt = () => {
    onPrompt()
  }
  const { getRemainingTime, start, reset } = useIdleTimer({
    timeout: 1000 * 60 * idleTime,
    promptBeforeIdle: 1000 * 60 * promptIdleTime,
    onIdle: handleOnIdle,
    onPrompt: handleOnPrompt,
    startManually: true,
    stopOnIdle: true,
    throttle: 500
  })

  return {
    getRemainingTime,
    start,
    isIdle,
    reset
  }
}
