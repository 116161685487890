import React, { useRef, useMemo, useEffect } from 'react'
import T from 'prop-types'
import Box from '@nutrien/uet-react/Box'
import TextField from '@nutrien/uet-react/TextField'
import SearchIcon from '@nutrien/uet-react/icons/Search'
import CloseIcon from '@nutrien/uet-react/icons/Close'
import InputAdornment from '@nutrien/uet-react/InputAdornment'
import IconButton from '@nutrien/uet-react/IconButton'
import debounce from 'lodash/debounce'
import CircularProgress from '@nutrien/uet-react/CircularProgress'

const FarmTreeSearch = ({
  setSearchString,
  searchString,
  isSearchLoading,
  isControlledByState
}) => {
  const textFieldRef = useRef()
  const debouncedSetSearchString = useMemo(
    () => debounce(setSearchString, 300),
    [setSearchString]
  )
  const handleSetSearchText = e => {
    debouncedSetSearchString(e.target.value)
  }

  useEffect(() => {
    if (isControlledByState) {
      textFieldRef.current.value = searchString
    }
  }, [isControlledByState, searchString])
  const clearSearch = () => {
    textFieldRef.current.value = ''
    setSearchString('')
  }
  return (
    <Box py={2}>
      <TextField
        inputRef={textFieldRef}
        placeholder="Search"
        inputProps={{ 'data-testid': 'farmTreeSearch' }}
        onChange={handleSetSearchText}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {isSearchLoading ? (
                <CircularProgress size={20} />
              ) : (
                <SearchIcon color="action" />
              )}
            </InputAdornment>
          ),
          endAdornment: searchString && (
            <InputAdornment position="end">
              <IconButton
                data-testid="searchReset"
                edge="end"
                onClick={clearSearch}>
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    </Box>
  )
}

FarmTreeSearch.propTypes = {
  setSearchString: T.func.isRequired,
  searchString: T.string,
  isSearchLoading: T.bool,
  isControlledByState: T.bool
}

FarmTreeSearch.defaultProps = {
  searchString: '',
  isSearchLoading: false,
  isControlledByState: false
}

export default FarmTreeSearch
