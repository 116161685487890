import React from 'react'
import T from 'prop-types'
import {
  BonsaiProvider as OriginalBonsaiProvider,
  useCustomFonts,
  useCustomIcons
} from '@nutrien/bonsai-core'
import bonsaiTheme from '@nutrien/bonsai-theme'

const BonsaiFontFamilyRegular = bonsaiTheme.typography.fontFamily.regular
const BonsaiFontFamilySemiBold = bonsaiTheme.typography.fontFamily.semiBold

function replaceFontFamilies(object) {
  const currentObject = { ...object }

  Object.entries(currentObject).forEach(([key, currentValue]) => {
    if (currentValue === BonsaiFontFamilySemiBold) {
      currentObject[key] = 'Open Sans'
      currentObject.fontWeight = '600'
    } else if (currentValue === BonsaiFontFamilyRegular) {
      currentObject[key] = 'Open Sans'
      currentObject.fontWeight = '400'
    } else if (typeof currentValue === 'object') {
      currentObject[key] = replaceFontFamilies(currentValue)
    }
  })

  return currentObject
}

export default function BonsaiProvider({ children }) {
  const theme = replaceFontFamilies(bonsaiTheme)
  const [customIconsLoaded] = useCustomIcons()
  const [customFontsLoaded] = useCustomFonts()
  const hybridTheme = {
    ...theme,
    colors: {
      ...theme.colors,
      primary: theme.colors.brand.leaf2,
      primaryHover: theme.colors.primaryUI.greenHover
    }
  }

  const isReady = customFontsLoaded && customIconsLoaded

  return isReady ? (
    <OriginalBonsaiProvider theme={hybridTheme}>
      {children}
    </OriginalBonsaiProvider>
  ) : null
}

BonsaiProvider.propTypes = {
  children: T.element.isRequired
}
