import React, { useState, useCallback, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import uuid from 'uuid/v4'

import th from 'theme/helpers'
import useAuthorshipSession from 'hooks/useAuthorshipSession'
import { ChatIcon } from '../Icons'
import { AuthConsumer } from '../Auth/authentication-context'

import ReactIntercom from './ReactIntercom'

const globalIntercomStyle = css`
  position: fixed;
  z-index: 100000;
  bottom: ${th.spacing(7)};
  right: ${th.spacing(1)};

  ${th.breakpoints.down('xs')`
    bottom: ${th.spacing(8)};
    right: ${th.spacing(2)};
  `}
`

const Icon = styled.div`
  ${({ global }) => (global ? globalIntercomStyle : '')}
  width: 48px;
  height: 48px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${th.palette('brand.green')};
  text-align: center;
  cursor: pointer;

  svg {
    width: 34px;
    padding-top: 8px;
    padding-left: 5px;
    path:first-child {
      fill: ${th.palette('common.white')};
    }
  }
`

const IntercomContext = React.createContext()

// Keeps track of local Intercom instances
export const IntercomProvider = ({ children }) => {
  const [localInstances, setLocalInstances] = useState({})
  const setLocalInstance = useCallback(
    id => {
      setLocalInstances({ ...localInstances, [id]: true })
    },
    [localInstances]
  )
  const unsetLocalInstance = useCallback(
    id => {
      const updatedInstances = { ...localInstances }
      delete updatedInstances[id]
      setLocalInstances(updatedInstances)
    },
    [localInstances]
  )

  return (
    <IntercomContext.Provider
      value={[localInstances, setLocalInstance, unsetLocalInstance]}>
      {children}
    </IntercomContext.Provider>
  )
}

// Track local instances to hide the global instance when at least one local instance is present
const useInstanceTracking = global => {
  const [localInstances, setLocalInstance, unsetLocalInstance] = useContext(
    IntercomContext
  )
  const [id] = useState(uuid)
  const localCount = Object.keys(localInstances).length

  useEffect(() => {
    if (!localInstances[id] && !global) {
      setLocalInstance(id)
    }
  }, [global, id, localInstances, setLocalInstance])

  const unmountOnly = []
  useEffect(() => () => unsetLocalInstance(id), unmountOnly) // eslint-disable-line react-hooks/exhaustive-deps

  return { localCount }
}

const Intercom = ({ global = false }) => {
  const { localCount } = useInstanceTracking(global)
  const shouldRender = (global && !localCount) || !global
  const { authorshipSessionActive } = useAuthorshipSession()
  return (
    <AuthConsumer>
      {({ user }) => {
        const intercomUser = user
          ? {
              user_id: authorshipSessionActive ? user.loggedInUserId : user.id,
              email: authorshipSessionActive ? user.loggedInEmail : user.email,
              name: authorshipSessionActive
                ? `${user.loggedInFirstName} ${user.loggedInLastName}`
                : `${user.firstName} ${user.lastName}`,
              user_hash: user.profile.intercomHmac
            }
          : {}
        return (
          <React.Fragment>
            {shouldRender && (
              <Icon global={global}>
                <ChatIcon id="customIntercomIcon" />
              </Icon>
            )}
            {global && (
              <ReactIntercom
                appID={window.uiConf.intercomAppId}
                hide_default_launcher={true}
                custom_launcher_selector="#customIntercomIcon"
                {...intercomUser}
              />
            )}
          </React.Fragment>
        )
      }}
    </AuthConsumer>
  )
}

Intercom.propTypes = {
  /* Instance type - use !global for when nesting Intercom on a page */
  global: PropTypes.bool
}

export default Intercom
