import React, { useCallback, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Typography from '@nutrien/uet-react/Typography'
import Grid from '@nutrien/uet-react/Grid'
import Box from '@nutrien/uet-react/Box'
import Button from '@nutrien/uet-react/Button'
import SvgIcon from '@nutrien/uet-react/SvgIcon'
import SecurityIcon from '@nutrien/uet-react/icons/Lock'
import EditIcon from '@nutrien/uet-react/icons/Edit'
import makeStyles from '@nutrien/uet-react/styles/makeStyles'

import Link from '@nutrien/uet-react/Link'
import { AuthContext } from 'components/Auth/authentication-context'
import useToggle from 'hooks/useToggle'

import { IntercomAPI } from 'components/Intercom'
import ChangeEmailDialog from '../ChangeEmailDialog'
import ChangePasswordDialog from '../ChangePasswordDialog'

const useStyles = makeStyles(theme => ({
  SectionRow: {
    marginBottom: theme.spacing(3)
  },
  icon: {
    color: theme.palette.grey[600],
    marginRight: theme.spacing(1),
    verticalAlign: 'bottom'
  },
  typography: {
    marginBottom: theme.spacing(1)
  },
  restrictionMessage: {
    width: 368,
    fontSize: 12
  },
  customerService: {
    fontSize: 12,
    color: theme.palette.brand.green,
    cursor: 'pointer'
  }
}))

const UserEmail = ({ user, onChange, setRequestPending, setRequestError }) => {
  const { t } = useTranslation()
  const styles = useStyles()
  const { isSSOUser, resetSSOPassword } = useContext(AuthContext)
  const [resetSSOPasswordSent, setResetSSOPasswordSent] = useState(false)
  const [showChangeEmail, toggleChangeEmail] = useToggle(false)
  const [showChangePass, toggleChangePass] = useToggle(false)
  const canChangeEmail = !isSSOUser

  const handleChangeEmailSuccess = useCallback(
    userObj => {
      onChange(userObj)
      toggleChangeEmail()
    },
    [onChange, toggleChangeEmail]
  )

  const handleMessageCustomerSupport = event => {
    IntercomAPI('show')
  }

  return (
    <React.Fragment>
      <Grid
        className={styles.SectionRow}
        container
        alignItems="center"
        justify="space-between">
        <Typography variant="h3">
          <SvgIcon className={styles.icon} component={SecurityIcon} />
          {t('security')}
        </Typography>
      </Grid>

      <Grid
        className={styles.SectionRow}
        container
        alignItems="center"
        justify="space-between">
        <Box>
          <Typography className={styles.typography} variant="h6">
            {t('email_address')}
          </Typography>
          <Typography className={styles.typography} variant="body1">
            {user.email}
          </Typography>
        </Box>
        {canChangeEmail ? (
          <Button
            startIcon={<EditIcon />}
            variant="outlined"
            onClick={toggleChangeEmail}>
            {t('change_email')}
          </Button>
        ) : (
          <Typography className={styles.restrictionMessage}>
            {t('your_account_associated_with_Nutrien_email')}{' '}
            <Link
              onClick={handleMessageCustomerSupport}
              className={styles.customerService}>
              {t('contact_Customer_Support')}
            </Link>{' '}
            {t('to_change_your_email_address')}
          </Typography>
        )}
      </Grid>

      <Grid
        className={styles.SectionRow}
        container
        alignItems="center"
        justify="space-between">
        <Box>
          <Typography className={styles.typography} variant="h6">
            {t('password')}
          </Typography>
          <Typography className={styles.typography} variant="body1">
            {t('security_section_text')}
          </Typography>
        </Box>
        {resetSSOPasswordSent ? (
          <Typography className={styles.restrictionMessage}>
            {t('reset_sso_password_sent')}
          </Typography>
        ) : (
          <Button
            startIcon={<EditIcon />}
            variant="outlined"
            onClick={toggleChangePass}>
            {t('change_password')}
          </Button>
        )}
      </Grid>

      {showChangeEmail && (
        <ChangeEmailDialog
          onClose={toggleChangeEmail}
          onSuccess={handleChangeEmailSuccess}
          currentEmail={user.email}
          setRequestPending={setRequestPending}
          setRequestError={setRequestError}
        />
      )}

      {showChangePass && (
        <ChangePasswordDialog
          onClose={toggleChangePass}
          isSSOUser={isSSOUser}
          resetSSOPassword={resetSSOPassword}
          setRequestPending={setRequestPending}
          setRequestError={setRequestError}
          setResetSSOPasswordSent={setResetSSOPasswordSent}
        />
      )}
    </React.Fragment>
  )
}

UserEmail.propTypes = {
  user: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  setRequestPending: PropTypes.func.isRequired,
  setRequestError: PropTypes.func.isRequired
}

export default React.memo(UserEmail)
