import React from 'react'
import _orderBy from 'lodash/orderBy'
import _findIndex from 'lodash/findIndex'
import _flatMap from 'lodash/flatMap'
import _get from 'lodash/get'
import _find from 'lodash/find'

import { EVENTS } from 'const'
import dayJs from 'dayjs'
import {
  format,
  compareAsc,
  compareDesc,
  isAfter,
  isBefore,
  isValid,
  startOfDay,
  endOfDay,
  subDays,
  MIN_DATE,
  MAX_DATE,
  isYMDString
} from 'helpers/date'
import EntityAncestors from 'components/EntityAncestors'
import {
  getRotationSeasonEndDate,
  getRotationSeasonStartDate
} from './cropRotationSeasons'

export const convertEventDateToFieldStoryFormat = date => {
  if (date && dayJs(date).isValid()) {
    return getCalendarDate(date) + 'T00:00:00Z'
  }
}

// Assumes ISOString format and so implies UTC
// || undefined so that we never return null (causes 1970 bug)
const toDate = arg => {
  return (
    arg?.activityDatetime ||
    convertEventDateToFieldStoryFormat(arg?.date) ||
    arg ||
    undefined
  )
}

export const getEventDate = (season, type) =>
  _get(
    season.events.find(({ __typename }) => __typename === type),
    'activityDatetime'
  )

// Returns the range within which it is safe to assign harvest
// completion. This cannot be earlier than latest event (or season start)
// and cannot be later than day before start of the next season
export const getHarvestCompletionInterval = (seasons, selectedSeason) => {
  const selectedEndDate = getCropEndDate(selectedSeason)
  const nextSeasonStartDate = seasons
    .map(getCropStartDate)
    .filter(Boolean)
    .sort(compareAsc)
    .filter(isValid)
    .filter(date => isAfter(date, selectedEndDate))[0]

  let pcDate = selectedSeason.events.find(
    event => event.__typename === 'PlantingCompletion'
  )
  pcDate = toDate(pcDate)
  pcDate = isValid(pcDate) ? endOfDay(pcDate) : undefined

  // because of bulk tillage applications in baseline, there are tillage events getting added with a date that is later than the harvest date
  // this disregards that season so we can still maintain appropriate validation
  const seasonEvents = selectedSeason.events.filter(({ activityDatetime }) => {
    const eventDate = new Date(activityDatetime)
    const eventHarvestDate = new Date(selectedEndDate)
    return eventDate <= eventHarvestDate
  })

  const latestEventDate = seasonEvents
    .filter(
      event =>
        event.__typename !== 'PlantingCompletion' &&
        event.__typename !== 'HarvestCompletion'
    )
    .map(toDate)
    .filter(isValid)
    .sort(compareDesc)[0]
  const start = [
    pcDate,
    latestEventDate && startOfDay(latestEventDate),
    getCropStartDate(selectedSeason, true)
  ]
    .filter(Boolean)
    .sort(compareDesc)[0]
  const end = nextSeasonStartDate
    ? endOfDay(subDays(nextSeasonStartDate, 1))
    : MAX_DATE
  return { start, end, isPC: start === pcDate }
}

// Returns the range within which it is safe to assign
// all non-hc events. This cannot be earlier than the end
// of the previous season and cannot be later than the end
// of this season
export const getCropEventsInterval = (seasons, selectedSeason) => {
  const selectedStartDate = getCropStartDate(selectedSeason)
  const previousSeasonEndDate = seasons
    .map(getCropEndDate)
    .sort(compareDesc)
    .filter(isValid)
    .filter(date => isBefore(date, selectedStartDate))[0]
  const start = previousSeasonEndDate || MIN_DATE
  const end = getCropEndDate(selectedSeason)
  return { start, end }
}

export const getCropEndDate = season => {
  const date = [
    season.endDate,
    getEventDate(season, 'HarvestCompletion'),
    getRotationSeasonEndDate(season)
  ]
    .filter(Boolean)
    .map(toDate)
    .filter(isValid)
    .sort(compareDesc)[0]
  return date ? endOfDay(date) : undefined
}

export const getCropStartDate = (season, strict) => {
  const earliestEventDate = season.events
    .map(toDate)
    .filter(isValid)
    .sort(compareAsc)[0]
  const date = [
    season.startDate,
    strict === true ? undefined : earliestEventDate
  ]
    .filter(Boolean)
    .map(toDate)
    .filter(isValid)
    .sort(compareAsc)[0]
  return date ? startOfDay(date) : undefined
}

export const getCropDate = season => {
  // || undefined so that we never return null (causes 1970 bug)
  return getCropEndDate(season) || getCropStartDate(season) || undefined
}

export const getSeasonInterval = season => {
  if (!season) {
    return { end: '', start: '' }
  }
  const plantingCompletionDate = season.events.find(
    event => event.__typename === 'PlantingCompletion'
  )?.activityDatetime

  return {
    end: endOfDay(season.endDate) || getCropEndDate(season) || '',
    start: startOfDay(season.startDate) || plantingCompletionDate || ''
  }
}

export const getCropName = (season, t) =>
  season.crop ? t(season.crop.name) : season.isFallow ? t('Fallow') : '--'

export const getSpecificCropName = (season, t) =>
  season.specificCrop
    ? t(season.specificCrop.name)
    : season.isFallow
    ? t('Fallow')
    : '--'

export const formatCropDate = date => (date ? format(date, 'yyyy') : '--')

const createFarm = (farmTree, entity) => {
  return {
    farmUuid: entity.uuid,
    farmName: entity.name,
    titleInfo: (
      <EntityAncestors
        farmTree={farmTree}
        entityUuid={entity.uuid}
        entityName={entity.name}
      />
    ),
    seasons: []
  }
}

const createSeason = (farm, season, field) => {
  const { cropName, cropYear } = season
  const existingSeason = _find(farm.seasons, { cropYear, cropName })

  if (!season.actual) {
    return
  }

  if (existingSeason) {
    existingSeason.fields.push({ ...field, value: season, values: null })
  } else {
    farm.seasons.push({
      cropName,
      cropYear,
      fields: [{ ...field, value: season, values: null }]
    })
  }
}

export const addFieldsDataToFarm = ({
  farmTree,
  fieldsData,
  farmItemId,
  ENTITY_TYPE_FIELDS,
  ENTITY_TYPE_FARMS
}) => {
  const farms = []
  let currentFarm

  const findEntities = entityId => {
    const entity = farmTree[entityId]
    if (entity && entity.type === ENTITY_TYPE_FARMS && entity.childrenUUIDs) {
      currentFarm = createFarm(farmTree, entity)
      farms.push(currentFarm)
    }

    if (entity && entity.type === ENTITY_TYPE_FIELDS) {
      const fieldData = fieldsData.find(
        fieldData => fieldData.fieldUuid === entity.uuid
      )

      if (fieldData && fieldData.values) {
        fieldData.values.forEach(value => {
          createSeason(currentFarm, value, fieldData)
        })
      }
    }
    if (entity && entity.childrenUUIDs) {
      entity.childrenUUIDs.forEach(childUUID => findEntities(childUUID))
    }
  }
  findEntities(farmItemId)

  return farms
}

export const orderSeasons = seasons =>
  _orderBy(seasons, ['cropYear', 'cropName'], ['desc', 'asc'])

export const filterSeasons = (seasons, seasonOption) =>
  seasons.filter(season => {
    return (
      seasonOption === 'All' ||
      seasonOption === `${season.cropName} ${season.cropYear}`
    )
  })

export const combineSeasons = (farms, key) =>
  _flatMap(farms, key).reduce((acc, season) => {
    const { cropYear, cropName } = season
    const existingSeason = _findIndex(acc, { cropYear, cropName })

    if (existingSeason >= 0) {
      acc.splice(existingSeason, 1, {
        ...acc[existingSeason],
        fields: acc[existingSeason].fields
          ? [...acc[existingSeason].fields, ...season.fields]
          : null
      })
      return acc
    }

    return [...acc, season]
  }, [])

export const createDropdownSeasons = seasons =>
  orderSeasons(seasons).map(({ cropName, cropYear, seasonId, isPast }) => ({
    id: seasonId,
    name: cropName,
    year: cropYear,
    isPast
  }))

export const formatDropdownSeasons = (
  seasons,
  { mergedSeasons = false } = {}
) =>
  seasons.map(({ name, year, id }) => ({
    value: mergedSeasons ? `${name} ${year}` : id,
    label: `${name} ${year}`
  }))

export const getSeasonStartDate = season => {
  const date =
    season.plantingDate ||
    season.startDate ||
    getEventDate(season, EVENTS.PLANTING_COMPLETION)

  return date ? getCalendarDate(date) : getRotationSeasonStartDate(season)
}

export const getSeasonCompletionDate = season => {
  const date =
    season.harvestDate ||
    season.endDate ||
    getEventDate(season, EVENTS.HARVEST_COMPLETION)

  return date ? getCalendarDate(date) : getRotationSeasonEndDate(season)
}

export const getSeasonYear = season => {
  if (!season) return

  return getSeasonCompletionDate(season).split('-')[0]
}

export const seasonIsInTargetYear = (season, campaignYears) =>
  campaignYears.map(year => year.toString()).includes(getSeasonYear(season))

/**
 * It seems like the backend choice to make event.activityDateTime an actual time instead of a calendar date
 * was probably an oversight.  This reverses that and shows what the user intended for display purposes.
 * Provides parity with the original Field Story implementation
 * @param {string} activityDateTime
 * @returns {*}
 */
export const dateTimeToCalendarDate = activityDateTime =>
  activityDateTime.split('T')[0]

/**
 * For some reason, the DatePicker component that is used throughout the app converts all dates to local time, which makes things very diffiicult
 * This corrects for that and also handles other potential formats and returns a standardized format
 * @param dateEntity - any kind of entity that could be turned into a javascript, date-fns, or dayjs Date object
 * @returns {string} - a date string in the format YYYY-MM-DD
 */
export const getCalendarDate = dateEntity => {
  const correctedDateEntity =
    typeof dateEntity === 'string' && dateEntity.includes('T')
      ? dateTimeToCalendarDate(dateEntity)
      : dateEntity

  return dayJs.utc(correctedDateEntity).format('YYYY-MM-DD')
}

/**
 * For situations like croppingSeason.endDate coming from the backend which is in format YYYY-MM-DD
 * but implies that it is UTC T00:00:00.000Z
 * This occasionally needs to be normalzied for the datepicker which operates in abstract UTC T00:00:00.000Z
 * @param {string } dateString - a string that sometimes is in the format YYYY-MM-DD
 * @returns {string}
 */
export const calendarDateFromServerToUtc = dateString => {
  if (isYMDString(dateString)) {
    return dateString + 'T00:00:00.000Z'
  }
  return dateString
}

export const getEnrolledCampaignIdsFromEvents = events => {
  return events
    .filter(event => event.eventType === EVENTS.PLAN)
    .map(({ campaign }) => campaign.uuid)
}

export const getSeasonEnrollmentChipStatuses = (events, campaignId) => {
  const enrolledCampaignIds = getEnrolledCampaignIdsFromEvents(events)
  const enrolledInProject = enrolledCampaignIds.includes(campaignId)
  return {
    enrolledInProject,
    enrolledInOtherProject: enrolledCampaignIds.some(id => id !== campaignId)
  }
}

export const addCampaignNameToTypeIfPlan = ({ campaign, eventType }, t) => {
  if (eventType === EVENTS.PLAN) {
    return `${t(eventType)} (${campaign.name})`
  }
  return t(eventType)
}
