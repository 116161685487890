export const calculateWetYield = (
  dryYield,
  harvestMoisture,
  moistureAfterDrying,
  postHavestLoss = 0
) => {
  if (
    !!dryYield &&
    !!harvestMoisture &&
    !!moistureAfterDrying &&
    moistureAfterDrying !== 100 &&
    harvestMoisture !== 100
  ) {
    return Math.round(
      (dryYield + postHavestLoss) /
        ((100 - harvestMoisture) / (100 - moistureAfterDrying))
    )
  } else {
    return 0
  }
}
