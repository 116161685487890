import { useState, useCallback } from 'react'

export default initialValue => {
  const [flag, setFlag] = useState(initialValue)
  const toggleFlag = useCallback(
    val => setFlag(f => (typeof val === 'boolean' ? val : !f)),
    [setFlag]
  )

  return [flag, toggleFlag]
}
