import React, { useState, useMemo } from 'react'
import { Link } from 'react-router-dom'
import makeStyles from '@nutrien/uet-react/styles/makeStyles'
import PropTypes from 'prop-types'
import Box from '@nutrien/uet-react/Box'
import Card from '@nutrien/uet-react/Card'
import Divider from '@nutrien/uet-react/Divider'
import CardHeader from '@nutrien/uet-react/CardHeader'
import CardActions from '@nutrien/uet-react/CardActions'
import CardContent from '@nutrien/uet-react/CardContent'
import Collapse from '@nutrien/uet-react/Collapse'
import IconButton from '@nutrien/uet-react/IconButton'
import CircularProgress from '@nutrien/uet-react/CircularProgress'
import ExpandMoreIcon from '@nutrien/uet-icons/ExpandMore'
import BonsaiButton from '../BonsaiButton'

const useStyles = makeStyles(theme => ({
  iconExpansion: {
    borderRadius: theme.spacing(0.5),
    border: `1px solid ${theme.palette.gray[200]}`,
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    height: theme.spacing(4),
    width: theme.spacing(4)
  },
  iconFlipNormal: {
    transform: 'rotate(0deg)'
  },
  iconFlipUpsideDown: {
    transform: 'rotate(180deg)'
  },
  cardContent: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    justifyContent: 'space-between',
    '@media (min-width: 1250px)': {
      paddingLeft: theme.spacing(3)
    }
  },
  cardContentNoPadding: {
    // there is specific padding on the last child, hence the !important
    padding: '0 !important'
  },
  footer: {
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    '@media (min-width: 1250px)': {
      paddingRight: theme.spacing(3)
    }
  },
  footerNoPadding: {
    padding: '0 8px',
    justifyContent: 'flex-end'
  }
}))

const useTitleStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'space-between',
    '@media (min-width: 1250px)': {
      paddingLeft: theme.spacing(3)
    }
  },
  title: {
    fontSize: '21px',
    '@media (max-width: 1250px)': {
      fontSize: '18px'
    },
    fontWeight: 600,
    color: '#222222',
    whiteSpace: 'pre-wrap'
  }
}))

const BonsaiExpandableCard = ({
  cardTitle,
  shortDescription,
  expandOnLoad,
  children,
  button,
  buttonText,
  buttonHref,
  secondButtonText,
  secondButtonHref,
  hasFooter,
  collapseButton,
  dataTestPrefix,
  loading,
  loaderMinHeight,
  avatar,
  chips,
  ...props
}) => {
  const classes = useStyles()
  const titleClasses = useTitleStyles()

  const [expanded, setExpanded] = useState(!!expandOnLoad)

  const renderDivider = useMemo(() => {
    if (props?.tableFooter && expanded) {
      return null
    }
    return <Divider />
  }, [expanded, props.tableFooter])

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  return (
    <Card data-testid={`${dataTestPrefix}-component`}>
      <CardHeader
        title={cardTitle}
        subheader={shortDescription}
        classes={titleClasses}
        avatar={avatar}
        data-testid={`${dataTestPrefix}-short-desc`}
        action={
          <>
            {chips}
            {collapseButton && (
              <IconButton
                className={classes.iconExpansion}
                data-testid="bonsaiCardIconButton"
                onClick={handleExpandClick}
                expand={expanded.toString()}>
                <ExpandMoreIcon
                  className={
                    expanded
                      ? classes.iconFlipUpsideDown
                      : classes.iconFlipNormal
                  }
                />
              </IconButton>
            )}
          </>
        }
      />
      {!loading && children && (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent
            className={
              props?.noPadding
                ? classes.cardContentNoPadding
                : classes.cardContent
            }>
            {children}
          </CardContent>
        </Collapse>
      )}
      {loading && (
        <Box center="xy" style={{ minHeight: loaderMinHeight }}>
          <CircularProgress size={33} />
        </Box>
      )}
      {hasFooter && (
        <>
          {renderDivider}
          <CardActions
            className={
              props?.tableFooter ? classes.footerNoPadding : classes.footer
            }
            data-testid="bonsaiCardFooter">
            {secondButtonText && (
              <BonsaiButton
                variant="outlined"
                data-testid="bonsaiCardButton"
                component={Link}
                text={secondButtonText}
                to={secondButtonHref}></BonsaiButton>
            )}
            {button}
            {buttonText && !button && (
              <BonsaiButton
                variant="contained"
                data-testid="bonsaiCardButton"
                component={Link}
                text={buttonText}
                to={buttonHref}></BonsaiButton>
            )}
          </CardActions>
        </>
      )}
    </Card>
  )
}

BonsaiExpandableCard.propTypes = {
  hasFooter: PropTypes.bool,
  hasChip: PropTypes.bool,
  expandOnLoad: PropTypes.bool,
  collapseButton: PropTypes.bool,
  hasButton: PropTypes.bool,
  hasSecondButton: PropTypes.bool,
  tableFooter: PropTypes.bool,
  chips: PropTypes.node
}

BonsaiExpandableCard.defaultProps = {
  hasFooter: false,
  expandOnLoad: false,
  collapseButton: true,
  hasButton: true,
  hasSecondButton: false,
  dataTestPrefix: 'bonsaiCard',
  tableFooter: false
}

export default BonsaiExpandableCard
