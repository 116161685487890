import React, { lazy } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import { ColumnLayout } from 'components/PageLayouts'
import useHasCommunityAccess from 'hooks/useHasCommunityAccess'
import { PageTitleProvider } from 'hooks/usePageTitle'

const AsyncHome = lazy(() => import('./components/Home'))
const AsyncOrganization = lazy(() => import('./components/Organization'))
const AsyncCommunity = lazy(() => import('./components/Community'))
const AsyncRegion = lazy(() => import('./components/Region'))
const AsyncMemberships = lazy(() => import('./components/Membership'))
const AsyncCampaign = lazy(() => import('./components/Campaign'))
const AsyncInvites = lazy(() => import('./components/Invites'))

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const hasAccess = useHasCommunityAccess()

  return hasAccess ? <Component {...rest} /> : <Redirect to="/community" />
}

const Community = ({ match }) => (
  <PageTitleProvider>
    {({ pageTitle }) => (
      <ColumnLayout pageTitle={pageTitle}>
        <Switch>
          <Route path={`${match.path}/invites`} component={AsyncInvites} />

          <Route
            path={`${match.path}/campaigns/:campaignId`}
            render={props => (
              <ProtectedRoute component={AsyncCampaign} {...props} />
            )}
          />

          <Route
            path={`${match.path}/memberships/:membershipId`}
            render={props => (
              <ProtectedRoute component={AsyncMemberships} {...props} />
            )}
          />

          <Route
            path={`${match.path}/regions/:regionId`}
            render={props => (
              <ProtectedRoute component={AsyncRegion} {...props} />
            )}
          />

          <Route
            path={`${match.path}/communities/:communityId`}
            render={props => (
              <ProtectedRoute component={AsyncCommunity} {...props} />
            )}
          />

          <Route
            path={`${match.path}/organizations/:orgId`}
            render={props => (
              <ProtectedRoute component={AsyncOrganization} {...props} />
            )}
          />

          <Route exact path={match.path} component={AsyncHome} />
        </Switch>
      </ColumnLayout>
    )}
  </PageTitleProvider>
)

export default Community
