import {
  ADD_CROPPING_SEASON_MUTATION,
  EDIT_CROPPING_SEASON,
  EVENT_DELETE_QUERY,
  FIELD_CHARACTERISTICS_UPDATE_QUERY,
  SAVE_BEST_PRACTICES,
  SEASON_DELETE_QUERY,
  SEASONS_DELETE_QUERY,
  UPDATE_ROTATION_SEASON
} from './gqlQueries'

export const refetchAfterCroppingSeasonChanges = [
  SEASON_DELETE_QUERY,
  SEASONS_DELETE_QUERY,
  EVENT_DELETE_QUERY,
  EDIT_CROPPING_SEASON,
  FIELD_CHARACTERISTICS_UPDATE_QUERY,
  ADD_CROPPING_SEASON_MUTATION,
  SAVE_BEST_PRACTICES,
  UPDATE_ROTATION_SEASON
]
