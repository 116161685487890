import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { FarmTreeFiltersContext } from 'components/FarmTreeFiltersProvider'
import FilterBlock from './components/FilterBlock'

const SortingOptions = ({ ...props }) => {
  const { t } = useTranslation()
  const { filters, showMoreOption, handleShowMore } = useContext(
    FarmTreeFiltersContext
  )
  const { farmItemLevel } = useParams()

  return (
    <div data-testid="sorting-options">
      <FilterBlock
        {...props}
        options={filters?.sortOption}
        name={'sortOption'}
        label={t('sort_by')}
        testid="sort-option-filters"
      />
      <FilterBlock
        {...props}
        options={filters?.orderOption}
        name={'orderOption'}
        label={'Order By'}
        testid="ascending-descending-filters"
      />
      <FilterBlock
        {...props}
        options={filters?.filterByCropSeason}
        name={'filterByCropSeason'}
        label={`${t('filter_by')} ${t('Crop Season')}`}
        showMore={showMoreOption}
        showMoreAction={handleShowMore}
        testid="crop-season-filters"
        disabled={props.selectedFilter.filterByProject !== 'Show All'}
      />
      {filters?.filterByProject && farmItemLevel ? (
        <FilterBlock
          {...props}
          options={filters?.filterByProject}
          name={'filterByProject'}
          label={`${t('filter_by')} ${t('Project')}`}
          testid="project-filters"
          disabled={props.selectedFilter.filterByCropSeason !== 'Show All'}
        />
      ) : null}
    </div>
  )
}

export default SortingOptions
