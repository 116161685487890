import React, { useRef } from 'react'
import makeStyles from '@nutrien/uet-react/styles/makeStyles'
import PropTypes from 'propTypes'

import BonsaiStatusChip from 'components/Bonsai/BonsaiChips/BonsaiStatusChip'
import ProjectCard from 'components/ProjectCard'
import SimplePopover from 'components/Popover'

const useStyles = makeStyles(theme => ({
  chip: props => ({
    backgroundColor: props.chipColor,
    color: props.chipTextColor,
    float: props.float,
    fontWeight: '600',
    '&:hover': {
      backgroundColor: props.chipColor
    },
    '&:focus': {
      backgroundColor: props.chipColor
    },
    marginRight: theme.spacing(1)
  })
}))

/**
 * @description ChipWithPopover is a custom functional component you can use to easily render a chip with a popover
 * to display information over the chip. Any strings passed to the component (i.e. the chip text or the popover text)
 * should be translated. If no popover text is passed nor any custom jsx for the popover body, a popover will not
 * be rendered.
 *
 * @param {Element} [children] - A custom React Element implementation for the body of the popover.
 * @param {string} [chipColor=primary] - The color of the chip. The default is "primary". It can accept hex values.
 * @param {string} [chipTextColor] - The color of the chip's text. It can accept hex values.
 * @param {string} [chipSize=xsmall] - The size of the chip (i.e. 'xsmall', 'small', 'medium').
 * @param {string} [float=right] - Indicate the position of the chip within the element (e.g. 'left', 'right', 'none').
 * @param {string} label - Required - The text displayed within the chip.
 * @param {string} [popoverText] - Text to display in popover. Not required if you use custom jsx for the popover's body. Wrap the text in the translation fn t().
 * @param {string} [testId] - Optional test id
 * @returns {Element} - Clickable chip with a popover to display information
 */
const ChipWithPopover = props => {
  const { children, chipSize, label, popoverText, testId } = props
  const popoverRef = useRef(null)
  const classes = useStyles(props)

  const openPopover = event => {
    if (popoverRef.current) {
      popoverRef.current.handleClick(event)
    }
  }

  return label ? (
    <>
      <BonsaiStatusChip
        className={classes.chip}
        label={label}
        data-testid={testId}
        onClick={event => openPopover(event)}
        clickable={!!(popoverText || children)}
        size={chipSize}
      />
      {popoverText || children ? (
        <SimplePopover ref={popoverRef}>
          {children || (
            <ProjectCard
              bodyText={popoverText}
              cardStyles={{
                width: 340
              }}
              noHeader
              noMinHeight
            />
          )}
        </SimplePopover>
      ) : null}
    </>
  ) : null
}

export default ChipWithPopover

ChipWithPopover.defaultProps = {
  chipColor: 'primary',
  chipSize: 'xsmall',
  float: 'right'
}

ChipWithPopover.propTypes = {
  children: PropTypes.node,
  chipColor: PropTypes.oneOfType([
    PropTypes.string, // any hex value
    PropTypes.oneOf([
      'default',
      'primary',
      'secondary',
      'error',
      'warning',
      'info'
    ])
  ]),
  chipTextColor: PropTypes.string,
  chipSize: PropTypes.oneOf(['xsmall', 'small', 'medium']),
  float: PropTypes.oneOf(['left', 'right', 'none']),
  label: PropTypes.string.isRequired,
  popoverText: PropTypes.string,
  testId: PropTypes.string
}
