import qs from 'qs'
import { pickBy } from 'lodash'

const queryParamsToKeepThroughPageChanges = ['seasonId']

// Urls are created in this helper in order to facilitate the need
// for some query params to be kept though multiple navigation changes
// To remove a query parameter pass it in with a null value.
const createUrl = (
  location,
  {
    viewMode,
    farmItemLevel,
    farmItemId,
    page,
    subPage,
    isDashboardPath = true,
    queryParams = {},
    hash = ''
  } = {},
  // Only use clearTrackedParams to clear params that are tracked through page changes
  // Pass true to both clearParams and clearTrackedParams to clear all params - use with caution!!!
  { clearPageParams = false, clearTrackedParams = false } = {}
) => {
  let queryObject = {
    ...qs.parse(location.search, { ignoreQueryPrefix: true }),
    ...queryParams
  }

  if (clearPageParams) {
    queryObject = pickBy(queryObject, (value, key) =>
      queryParamsToKeepThroughPageChanges.includes(key)
    )
    queryObject = {
      ...queryObject,
      ...queryParams
    }
  }

  if (clearTrackedParams) {
    queryObject = pickBy(
      queryObject,
      (value, key) => !queryParamsToKeepThroughPageChanges.includes(key)
    )
  }

  const queryString = qs.stringify(queryObject, {
    addQueryPrefix: true,
    skipNulls: true
  })

  const urlParts = location.pathname.split('/')

  const path = isDashboardPath
    ? `/${viewMode || urlParts[1]}/${farmItemLevel ||
        urlParts[2]}/${farmItemId || urlParts[3]}/${page || urlParts[4]}${
        subPage ? `/${subPage}` : ''
      }`
    : location.pathname

  return path + queryString + hash
}

export default createUrl
