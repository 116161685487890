export const getMoistureStatusColor = (status, theme) => {
  if (['Surplus', 'Adequate'].includes(status)) {
    return theme.palette.primary.main
  }

  if (!status) {
    return theme.palette.common.white
  }

  return theme.palette.error.main
}
