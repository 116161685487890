import { getGrowthStageImage, getMoistureStatusColor } from './'

export const cardFormatter = (type, _, theme) => ({
  metric: { actual, units, cropName } = {},
  ...rest
}) => {
  return {
    value: isNaN(actual)
      ? actual
      : `${parseInt(actual, 10)}${type === 'waterPercent' ? '%' : ''}`,
    units,
    image: type === 'growthStage' && getGrowthStageImage(cropName, actual),
    ...(type === 'moistureStatus' && {
      color: getMoistureStatusColor(actual, theme)
    }),
    ...rest
  }
}
