import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import useFlags from 'hooks/useFlags'
import { AuthConsumer } from 'components/Auth/authentication-context'

import useDashboardLink from 'hooks/useDashboardLink'
import useAuthorshipSession from 'hooks/useAuthorshipSession'

import { AgribleCombinationMark } from '../Icons'

const StyledLogo = styled(AgribleCombinationMark)`
  width: 156px;
  height: 32px;
`

const LinkedLogo = props => {
  const flags = useFlags()
  const dashboardLink = useDashboardLink()
  const { authorshipSessionActive } = useAuthorshipSession()

  return (
    <AuthConsumer>
      {({ isAuthenticated }) => (
        <Link
          aria-label="home"
          to={
            !authorshipSessionActive &&
            (flags.homePageRedirect && !isAuthenticated ? '/' : dashboardLink)
          }
          style={{ cursor: authorshipSessionActive ? 'default' : 'pointer' }}
          {...props}>
          <StyledLogo />
        </Link>
      )}
    </AuthConsumer>
  )
}

export default LinkedLogo
