import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Breadcrumbs from '@nutrien/uet-react/Breadcrumbs'
import Typography from '@nutrien/uet-react/Typography'
import ArrowRightIcon from '@nutrien/uet-react/icons/ArrowRight'

import { selectAncestors } from 'store/selectors/farmTree'
import th from 'theme/helpers'

const EntityName = styled(Typography)`
  color: ${th.palette('grey.450')};
`

const EntityAncestors = ({ farmTree, entityUuid, entityName }) => {
  const ancestors = selectAncestors(
    { farmTree: { entities: farmTree } },
    entityUuid
  ).reverse()

  return (
    <Breadcrumbs separator={<ArrowRightIcon />}>
      {ancestors.map(e => (
        <EntityName key={e.uuid}>{e.name}</EntityName>
      ))}
      {entityName && <EntityName>{entityName}</EntityName>}
    </Breadcrumbs>
  )
}

EntityAncestors.propTypes = {
  farmTree: PropTypes.object.isRequired,
  entityUuid: PropTypes.string.isRequired,
  entityName: PropTypes.string
}

export default React.memo(EntityAncestors)
