import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Typography from 'components/Typography'
import Dialog from 'components/Form/Dialog'

const TnCsDialog = ({ onClose, modified, version, text }) => {
  const { t } = useTranslation()

  return (
    <Dialog title={t('CommunityTermsStepTitle')} onClose={onClose}>
      <Typography variant="h3">{version}</Typography>
      <Typography variant="body2" gutterBottom={4}>
        {t('date_modified')}{' '}
        {t('_date_monthLongDYear', {
          date: modified
        })}
      </Typography>

      <Typography variant="body1">{text}</Typography>
    </Dialog>
  )
}

TnCsDialog.propTypes = {
  onClose: PropTypes.func,
  modified: PropTypes.string,
  version: PropTypes.string,
  text: PropTypes.string
}

export default TnCsDialog
