import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Typography from '@nutrien/uet-react/Typography'
import Grid from '@nutrien/uet-react/Grid'
import Box from '@nutrien/uet-react/Box'
import makeStyles from '@nutrien/uet-react/styles/makeStyles'
import { withGlobalConfig } from '../GlobalConfig'

const useStyles = makeStyles({
  version: {
    fontSize: '12px'
  }
})

function Footer({ globalConfig }) {
  const { t } = useTranslation()
  const classes = useStyles()
  const currentYear = new Date().getFullYear()
  const footerLinks = [
    {
      title: t('terms_and_cond'),
      url: { pathname: 'https://my.nutrienagsolutions.com/terms-of-use' }
    },
    {
      title: t('privacy_policy'),
      url: { pathname: 'https://www.nutrien.com/nutrien-privacy-policy' }
    },
    {
      title: t('disclaimer'),
      url: '/disclaimer'
    },
    {
      title: t('help_center'),
      url: { pathname: 'http://learn.agrible.com/en/' }
    },
    {
      title: t('contact_us'),
      url: { pathname: 'https://my.nutrienagsolutions.com/contact' }
    }
  ]

  return (
    <Box bgcolor="gray.A900" color="gray.A400" width="100%" pt={2} pb={2}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        width="100%"
        heigth="100%">
        <Grid item>
          <Box mb={1}>
            <Typography align="center" variant="caption" component="p">
              {t('trademarkDisclaimer')}
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box maxWidth="343px" mb={1}>
            <Grid container>
              <Typography align="center" variant="caption">
                {footerLinks.map((link, i) => (
                  <Link
                    key={i}
                    to={link.url}
                    target={typeof link.url === 'string' ? '_self' : '_blank'}>
                    <Box
                      fontWeight="600"
                      lineHeight={2}
                      color="primary.light"
                      component="span">
                      {link.title}

                      {link.url === '/disclaimer' ? (
                        <br />
                      ) : (
                        <Box
                          fontWeight="400"
                          fontSize="16px"
                          mx={0.75}
                          component="span">
                          {i === footerLinks.length - 1 ? '' : ' |'}
                        </Box>
                      )}
                    </Box>
                  </Link>
                ))}
              </Typography>
            </Grid>
          </Box>
        </Grid>
        <Box>
          <Typography align="center" variant="caption" component="p">
            <span role="img" aria-label="copyright logo">
              ©
            </span>{' '}
            Nutrien Ag Solutions
            <span role="img" aria-label="trademark logo">
              ™️{' '}
            </span>{' '}
            {currentYear}
          </Typography>
          <Typography align="center" className={classes.version}>
            {t('version')}: {globalConfig.version}
          </Typography>
        </Box>
      </Grid>
    </Box>
  )
}

const EnhancedFooter = withGlobalConfig(Footer)
EnhancedFooter.displayName = 'Footer'
export default EnhancedFooter
