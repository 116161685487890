import React, { useEffect, lazy } from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch, Redirect } from 'react-router-dom'
import _isEmpty from 'lodash/isEmpty'
import { ENTITY_TYPE_ORGS } from '@nutrien/farm-tree-navigation/dist/constants/entities'
import useFlags from 'hooks/useFlags'
import { selectOwnedOrg, selectFarmTree } from 'store/selectors/farmTree'
import Retry from 'components/Retry'
import Loading from 'components/Loading'
import useLoadFarmTree from 'hooks/useLoadFarmTree'
import useAuthorshipSession from 'hooks/useAuthorshipSession'
import { ColumnLayout } from '../../components/PageLayouts'
import FarmTreeLayout from '../PageLayouts/FarmTreeLayout/FarmTreeLayout'

const AsyncAgronomy = lazy(() => import('pages/Agronomy'))
const AsyncCriticalPeriods = lazy(() => import('pages/CriticalPeriods'))
const AsyncFieldForecast = lazy(() => import('pages/FieldForecast'))
const AsyncFieldStory = lazy(() => import('pages/FieldStory'))
const AsyncHailEvents = lazy(() => import('pages/HailEvents'))
const AsyncMarket = lazy(() => import('pages/Market'))
const AsyncNutrientEngine = lazy(() => import('pages/NutrientEngine'))
const AsyncPageNotFound = lazy(() => import('pages/PageNotFound'))
const AsyncSpraySmart = lazy(() => import('pages/SpraySmart'))
const AsyncSustainability = lazy(() => import('pages/Sustainability'))
const AsyncTractorTime = lazy(() => import('pages/TractorTime'))
const AsyncWeather = lazy(() => import('pages/Weather'))
const AsyncWeatherStory = lazy(() => import('pages/WeatherStory'))
const AsyncYield = lazy(() => import('pages/Yield'))
const AsyncMySummary = lazy(() => import('pages/MySummary'))
const AsyncBarleyQualityEngine = lazy(() => import('pages/BarleyQualityEngine'))

const Dashboard = ({ match }) => {
  // Farm Tree Layout Feature Flag Logic ******************
  const [farmTree, loadFarmTree, { loading, error }] = useLoadFarmTree()
  const ownedOrg = useSelector(selectOwnedOrg)
  const { farmItemId } = match.params
  const field = useSelector(selectFarmTree)[farmItemId]
  const farmId = field?.parentUUID
  const flags = useFlags()
  const { authorshipSessionActive } = useAuthorshipSession()
  useEffect(() => {
    loadFarmTree()
  }, [loadFarmTree])

  if (error) return <Retry handleRetry={loadFarmTree} />
  if (_isEmpty(farmTree) || loading || !ownedOrg) return <Loading />

  if (farmItemId !== 'my-account' && !farmTree[farmItemId]) {
    return <Redirect to="/not-found" />
  }

  const cardPath =
    '/cards/:farmItemLevel(account|orgs|org_group|enterprise_groups|farms|fields)/:farmItemId'

  return (
    <Switch>
      <Route
        path={`/cards/${ENTITY_TYPE_ORGS}/my-account/:service`}
        render={({ match: { params }, location }) => (
          <Redirect
            to={{
              pathname: `/cards/${ENTITY_TYPE_ORGS}/${ownedOrg.uuid}/${params.service}`,
              search: location.search
            }}
          />
        )}
      />
      <Redirect
        from="/maps/:farmItemLevel/:farmItemId/:page(critical-periods|field-story|hail-alert|market|nutrient-engine|sustainable-sourcing|weather-story|barley-quality-engine)"
        to="/cards/:farmItemLevel/:farmItemId/:page"
      />

      <Route
        path={`${cardPath}/field-story`}
        render={props => (
          <FarmTreeLayout>
            <AsyncFieldStory {...props} />
          </FarmTreeLayout>
        )}
      />

      {!authorshipSessionActive && (
        <Route
          path={`${cardPath}/market`}
          render={props => (
            <ColumnLayout>
              <AsyncMarket {...props} />
            </ColumnLayout>
          )}
        />
      )}

      {!authorshipSessionActive && (
        <Route
          path={`${cardPath}/sustainable-sourcing`}
          render={props => (
            <FarmTreeLayout>
              <AsyncSustainability farmId={farmId} {...props} />
            </FarmTreeLayout>
          )}
        />
      )}

      <Route
        path={`${cardPath}/my-summary`}
        render={props => (
          <FarmTreeLayout>
            <AsyncMySummary {...props} />
          </FarmTreeLayout>
        )}
      />

      {flags.weatherTab && !authorshipSessionActive && (
        <Route
          path={`${match.path}/weather`}
          render={props => (
            <ColumnLayout>
              <AsyncWeather {...props} />
            </ColumnLayout>
          )}
        />
      )}

      {flags.weatherTab && !authorshipSessionActive && (
        <Route
          path={`${cardPath}/weather-story`}
          render={props => (
            <ColumnLayout>
              <AsyncWeatherStory {...props} />
            </ColumnLayout>
          )}
        />
      )}

      {flags.weatherTab && !authorshipSessionActive && (
        <Route
          path={`${match.path}/field-forecast`}
          render={props => (
            <FarmTreeLayout>
              <AsyncFieldForecast farmId={farmId} {...props} />
            </FarmTreeLayout>
          )}
        />
      )}

      {flags.weatherTab && !authorshipSessionActive && (
        <Route
          path={`${cardPath}/hail-alert`}
          render={props => (
            <FarmTreeLayout>
              <AsyncHailEvents {...props} />
            </FarmTreeLayout>
          )}
        />
      )}

      {flags.agronomyTab && !authorshipSessionActive && (
        <Route
          path={`${match.path}/agronomy`}
          render={props => (
            <ColumnLayout>
              <AsyncAgronomy {...props} />
            </ColumnLayout>
          )}
        />
      )}

      {flags.agronomyTab && !authorshipSessionActive && (
        <Route
          path={`${match.path}/yield/:subPage?`}
          render={props => (
            <FarmTreeLayout>
              <AsyncYield farmId={farmId} {...props} />
            </FarmTreeLayout>
          )}
        />
      )}

      {flags.agronomyTab && !authorshipSessionActive && (
        <Route
          path={`${cardPath}/nutrient-engine/:subPage?`}
          render={props => (
            <FarmTreeLayout>
              <AsyncNutrientEngine farmId={farmId} {...props} />
            </FarmTreeLayout>
          )}
        />
      )}

      {flags.agronomyTab && !authorshipSessionActive && (
        <Route
          path={`${cardPath}/critical-periods`}
          render={props => (
            <FarmTreeLayout>
              <AsyncCriticalPeriods farmId={farmId} {...props} />
            </FarmTreeLayout>
          )}
        />
      )}

      {flags.agronomyTab && !authorshipSessionActive && (
        <Route
          path={`${match.path}/tractor-time/:subPage?`}
          render={props => (
            <FarmTreeLayout>
              <AsyncTractorTime farmId={farmId} {...props} />
            </FarmTreeLayout>
          )}
        />
      )}

      {flags.agronomyTab && !authorshipSessionActive && (
        <Route
          path={`${match.path}/spray-smart`}
          render={props => (
            <FarmTreeLayout>
              <AsyncSpraySmart farmId={farmId} {...props} />
            </FarmTreeLayout>
          )}
        />
      )}

      <Route
        path={`${cardPath}/barley-quality-engine`}
        render={props => (
          <FarmTreeLayout>
            <AsyncBarleyQualityEngine {...props} />
          </FarmTreeLayout>
        )}
      />

      {/* Last route will show the 404 page not found */}
      <Route
        path="*"
        render={props => (
          <FarmTreeLayout>
            <AsyncPageNotFound {...props} />
          </FarmTreeLayout>
        )}
      />
    </Switch>
  )
}

export default Dashboard
