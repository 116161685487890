import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import _map from 'lodash/map'

import { selectAllOrgs, selectPermissions } from 'store/selectors/farmTree'
import usePermissions from 'hooks/usePermissions'

/**
 * Hook telling whether a product can be accessed or not.
 * Product access is granted if any of the user organization has access to it.
 * This hook is handy to hide Barley Quality engine links, but it must not be used
 * to determine product access on a given node. usePermissions does it.
 *
 * @param {string} product - checked product slug
 * @returns {boolean}
 * @see usePermissions
 */
export default product => {
  const permissions = usePermissions(product)
  const orgs = useSelector(selectAllOrgs)
  const globalPermissions = useSelector(selectPermissions)

  return useMemo(
    () =>
      globalPermissions?.includes(`${product}:view`) ||
      _map(orgs, 'uuid').some(permissions.canView),
    [permissions, orgs, globalPermissions, product]
  )
}
