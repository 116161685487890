import React, { useContext } from 'react'
import { RoutesContext as HubRoutesContext } from '@nutrien/hub-routing'
import { getHybridRoutes } from '../../config/hybridRoutes'
import { AuthContext } from '../Auth/authentication-context'

export const HybridRoutesContext = React.createContext([])

const HybridRoutesProvider = ({ children }) => {
  const { user } = useContext(AuthContext)
  const hybridRoutes = getHybridRoutes(user)

  const flattenedRoutes = hybridRoutes.flatMap(({ isEnabled, routes }) =>
    isEnabled ? routes : []
  )

  return (
    <HybridRoutesContext.Provider value={hybridRoutes}>
      <HubRoutesContext.Provider value={flattenedRoutes}>
        {children}
      </HubRoutesContext.Provider>
    </HybridRoutesContext.Provider>
  )
}

export default HybridRoutesProvider
