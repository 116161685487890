export function getNestedRegions(regions) {
  const mapRegions = {}
  regions.forEach(region => {
    mapRegions[region.uuid] = {
      ...region,
      label: region.name,
      value: region.uuid
    }
  })

  const nestedRegions = []

  regions.forEach(flatRegion => {
    const region = mapRegions[flatRegion.uuid]
    const parentUUID = region.parent.uuid
    const parentKind = region.parent.kind

    if (parentKind === 'Campaign') {
      nestedRegions.push(region)
    } else {
      const parentRegion = mapRegions[parentUUID]
      parentRegion.subRegions = parentRegion.subRegions || []
      parentRegion.subRegions.push(region)
    }
  })

  return [nestedRegions, mapRegions]
}
