import React, { useContext } from 'react'
import Dialog from '@nutrien/uet-react/Dialog'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Button from '@nutrien/uet-react/Button'
import Typography from '@nutrien/uet-react/Typography'
import styled from 'styled-components'
import { AuthContext } from 'components/Auth/authentication-context'

const StyledTypography = styled(Typography).attrs({ variant: 'h5' })`
  font-weight: 400;
`

const EnrollmentSuccessDialog = ({ onClose, name, previouslyEnrolled }) => {
  const { user } = useContext(AuthContext)
  const { t } = useTranslation()

  return (
    <Dialog
      title={
        previouslyEnrolled
          ? t('campaign_enrollment_previously_enrolled', { name: name })
          : t('campaign_enrollment_success', { name: name })
      }
      open={true}
      onClose={onClose}
      actions={
        <>
          <Button variant="outlined" onClick={onClose}>
            {t('not_now')}
          </Button>
          <Button
            variant="contained"
            href={`/cards/orgs/${user?.subscriptions[0]?.organizationUuid}/sustainable-sourcing`}>
            {t('GoToSustainability')}
          </Button>
        </>
      }>
      <StyledTypography>
        {previouslyEnrolled
          ? t('enrollment_success_already_enrolled')
          : t('enrollment_success_text')}
      </StyledTypography>
    </Dialog>
  )
}

EnrollmentSuccessDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  previouslyEnrolled: PropTypes.bool.isRequired
}

EnrollmentSuccessDialog.defaultProps = {}

export default EnrollmentSuccessDialog
