import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Typography from '@nutrien/uet-react/Typography'
import makeStyles from '@nutrien/uet-react/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  base: {
    // links needs to be an h2 on mobile
    ...theme.typography.h2,
    color: theme.palette.text.primary,
    lineHeight: 1.75,

    // links needs to be body1 with gray color on desktop
    [theme.breakpoints.up('md')]: {
      ...theme.typography.body1,
      color: theme.palette.text.secondary
    }
  },
  selected: {
    // links needs to be bold when selected in desktop
    [theme.breakpoints.up('md')]: {
      color: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightBold
    }
  },
  child: {
    ...theme.typography.subtitle1,
    color: theme.palette.text.primary,

    [theme.breakpoints.up('md')]: {
      color: theme.palette.text.secondary
    }
  }
}))

/*
 * I decided to just create the styling differences in makeStyles
 * since there is currently no way to just add bold on styling on
 * a Typography.
 *
 * Using classnames to add classNames based on the prop.
 * We can change it to clsx if that is the standard.
 * as of 20/09/2021, there are no solid decision on which package to use.
 */

const TabTypograhpy = ({ children, isChild, isSelected, ...props }) => {
  const classes = useStyles()

  return (
    <Typography
      data-testid="tab-typography"
      className={classnames(classes.base, {
        [classes.child]: isChild,
        [classes.selected]: isSelected
      })}
      {...props}>
      {children}
    </Typography>
  )
}

TabTypograhpy.propTypes = {
  children: PropTypes.node,
  isChild: PropTypes.bool,
  isSelected: PropTypes.bool
}

TabTypograhpy.defaultProps = {
  isChild: false,
  isSelected: false
}

export default TabTypograhpy
