/* eslint-disable one-var */
import { useMemo, useContext } from 'react'
import {
  ENTITY_TYPE_ENTERPRISE_GROUPS,
  ENTITY_TYPE_ORGS,
  ENTITY_TYPE_FIELDS,
  ENROLLED_STATUS
} from 'const'
import pickBy from 'lodash/pickBy'
import {
  fuzzyMatch,
  appendEntityAncestors,
  filterEntitiesByType,
  isRotationGroup
} from 'helpers'
import partition from 'lodash/partition'
import orderBy from 'lodash/orderBy'
import {
  FarmTreeFiltersContext,
  ASC,
  DESC,
  ACRES_SORT,
  NAME_SORT,
  ENROLLMENT_SORT,
  CROP_ROTATION_SORT,
  SHOW_ALL,
  CROP_ROTATION_STATUS_SORT
} from 'components/FarmTreeFiltersProvider'

const ENROLLED_FIELDS = 'enrolledFields',
  INELIGIBLE_FIELDS = 'ineligibleFields',
  ELIGIBLE_FIELDS = 'eligibleFields'

const useFarmTreeSortFilter = (
  flattenedEntities,
  searchString = '',
  enrolledFieldsInCampaign = []
) => {
  const { allCroppingSeasonsQuery, selectedFilter } = useContext(
    FarmTreeFiltersContext
  )

  return useMemo(
    () =>
      farmTreeSortFilter(
        flattenedEntities,
        selectedFilter,
        allCroppingSeasonsQuery,
        searchString,
        enrolledFieldsInCampaign
      ),
    [
      selectedFilter,
      allCroppingSeasonsQuery,
      flattenedEntities,
      searchString,
      enrolledFieldsInCampaign
    ]
  )
}

const nameSort = ({ name }) => name.toLowerCase()

export const farmTreeSortFilter = (
  entities,
  selectedFilter,
  allCroppingSeasonsQuery,
  searchString,
  enrolledFieldsInCampaign
) => {
  const {
    sortOption,
    orderOption,
    filterByCropSeason,
    filterByProject
  } = selectedFilter
  const skipCropSeason =
    filterByCropSeason === SHOW_ALL && filterByProject === SHOW_ALL

  const getFilteredEntities = () => {
    if (!searchString && skipCropSeason) {
      return entities
    }
    const matches = pickBy(entities, entity => {
      if (filterByProject !== SHOW_ALL) {
        return (
          passesSearch(entity, searchString, entities) &&
          passesProjectFilter(entity.uuid, enrolledFieldsInCampaign)
        )
      }

      return (
        passesSearch(entity, searchString, entities) &&
        passsesCropSeason(
          entity.uuid,
          allCroppingSeasonsQuery,
          filterByCropSeason
        )
      )
    })

    return appendEntityAncestors(entities, matches)
  }

  const entityArray = Object.values(getFilteredEntities())

  if (sortOption === ENROLLMENT_SORT) {
    return getSortedByEnrollment(entityArray, orderOption)
  }

  const [orgGroups, otherEntities] = partition(entityArray, ({ type }) =>
    [ENTITY_TYPE_ORGS, ENTITY_TYPE_ENTERPRISE_GROUPS].includes(type)
  )
  const sortedOrgGroups = orderBy(
    orgGroups,
    ['isShared', nameSort],
    [DESC, ASC]
  )

  if (sortOption === ACRES_SORT) {
    const sortedByAcreage = sortedOrgGroups.concat(
      orderBy(otherEntities, ['acreage', nameSort], orderOption)
    )
    return filterEntitiesByType(sortedByAcreage, [
      ENTITY_TYPE_ENTERPRISE_GROUPS,
      ENTITY_TYPE_FIELDS,
      ENTITY_TYPE_ORGS
    ])
  }

  const sortedByName = sortedOrgGroups.concat(
    orderBy(otherEntities, [nameSort], orderOption)
  )
  if (sortOption === NAME_SORT) {
    return filterEntitiesByType(sortedByName, [
      ENTITY_TYPE_ENTERPRISE_GROUPS,
      ENTITY_TYPE_FIELDS,
      ENTITY_TYPE_ORGS
    ])
  }

  if (sortOption === CROP_ROTATION_SORT) {
    return orderBy(
      entityArray,
      [({ type }) => isRotationGroup(type), nameSort],
      orderOption
    )
  }

  const complete = ({ rotationId }) => !!rotationId
  if (sortOption === CROP_ROTATION_STATUS_SORT) {
    return orderBy(
      entityArray.filter(({ type }) => !isRotationGroup(type)),
      [complete, nameSort],
      orderOption
    )
  }

  return sortedByName
}

const getFieldIdsForStatus = (fields, status) => {
  const filteredFields = fields
    .filter(({ parentUUID }) => {
      const assignToIneligibleFields =
        ENROLLED_STATUS.FIELD_STATUS_INELIGIBLE === status &&
        parentUUID === undefined
      return parentUUID === status || assignToIneligibleFields
    })
    .map(({ uuid }) => uuid)
  return filteredFields
}

const getSortedByEnrollment = (entityArray, orderOption) => {
  const fieldsWithEnrollmentParentId = entityArray
    .filter(({ type }) => type === ENTITY_TYPE_FIELDS)
    .map(entity => ({
      ...entity,
      parentUUID: entity.enrolledStatus
    }))

  const enrollmentTypeContainers = [
    {
      uuid: ENROLLED_FIELDS,
      name: 'Selected Fields',
      childrenUUIDs: getFieldIdsForStatus(
        fieldsWithEnrollmentParentId,
        ENROLLED_FIELDS
      ),
      type: ENTITY_TYPE_ENTERPRISE_GROUPS
    },
    {
      uuid: ELIGIBLE_FIELDS,
      name: 'Eligible Fields',
      childrenUUIDs: getFieldIdsForStatus(
        fieldsWithEnrollmentParentId,
        ELIGIBLE_FIELDS
      ),
      type: ENTITY_TYPE_ENTERPRISE_GROUPS
    },
    {
      uuid: INELIGIBLE_FIELDS,
      name: 'Non-Eligible Fields',
      childrenUUIDs: getFieldIdsForStatus(
        fieldsWithEnrollmentParentId,
        INELIGIBLE_FIELDS
      ),
      type: ENTITY_TYPE_ENTERPRISE_GROUPS
    }
  ]
  return orderBy(fieldsWithEnrollmentParentId, [nameSort], orderOption).concat(
    enrollmentTypeContainers
  )
}

const passsesCropSeason = (
  uuid,
  allCroppingSeasonsQuery,
  filterByCropSeason
) => {
  const allCroppingSeasons =
    allCroppingSeasonsQuery?.data?.listAllCroppingSeasons

  if (filterByCropSeason === SHOW_ALL || !allCroppingSeasons) {
    return true
  }

  const croppingSeasonFields = allCroppingSeasons.find(
    ({ season }) => season === filterByCropSeason
  )?.fields

  return croppingSeasonFields.map(({ fieldUuid }) => fieldUuid).includes(uuid)
}

const passesProjectFilter = (entityUUID, enrolledFields) => {
  return enrolledFields.includes(entityUUID)
}

const passesSearch = (entity, searchString, entities) => {
  if (!searchString || fuzzyMatch(entity.name, searchString)) {
    return true
  }
  const parent = entities[entity.parentUUID]
  return parent ? passesSearch(parent, searchString, entities) : false
}

export default useFarmTreeSortFilter
