import React, { useState } from 'react'
import PropTypes from 'prop-types'
import BonsaiDrawer from './BonsaiDrawer'

const BonsaiCalmDrawer = props => {
  const [executing, setExecuting] = useState(false)

  const { isFooterButtonDisabled, onFooterButtonClick, ...otherProps } = props

  const onRealClick = async event => {
    setExecuting(true)
    try {
      await onFooterButtonClick()
    } finally {
      setExecuting(false)
    }
  }

  return (
    <BonsaiDrawer
      onFooterButtonClick={onRealClick}
      isFooterButtonDisabled={executing || isFooterButtonDisabled}
      {...otherProps}
    />
  )
}

export default BonsaiCalmDrawer

BonsaiCalmDrawer.propTypes = {
  isFooterButtonDisabled: PropTypes.bool,
  onFooterButtonClick: PropTypes.func.isRequired
}
