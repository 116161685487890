/**
 * This HoC is a noop for non-dev environments.
 * It subscribes to LD's flags and shallow overrides them with
 * what's stored in `localStorage`. It allows us to easily toggle
 * flags from console for testing, without having to go to LD Dashboard.
 * Try in console, `_ldOverride.set('flagName', 'value')`
 */

import React, { useEffect, useState } from 'react'
import {
  asyncWithLDProvider,
  withLDConsumer
} from 'launchdarkly-react-client-sdk'

import resolveLaunchDarklyKey from '../../config/resolveLaunchDarklyKey'
import {
  isOverridable,
  getOverrides,
  setOverrides
} from './overridePersistence'

const LDProviderOverride = ({ children, flags, overrides }) => {
  const [overrideFlags, setOverrideFlags] = useState(overrides ?? {})

  const effectiveFlags = { ...flags, ...overrideFlags }

  if (isOverridable) {
    // eslint-disable-next-line no-underscore-dangle
    window._ldOverride = {
      set: (flag, value) => {
        setOverrideFlags(prev => ({ ...prev, [flag]: value }))
      },
      reset: () => {
        setOverrideFlags({})
      },
      get: flag => {
        if (flag) {
          console.log(flag, effectiveFlags?.[flag])
        } else {
          console.log('LD Flags: ', effectiveFlags)
        }
      }
    }
  }

  useEffect(() => {
    if (overrideFlags) {
      setOverrides(overrideFlags)
    }
  }, [overrideFlags])

  return (
    <React.Fragment key={JSON.stringify(overrideFlags)}>
      {children}
    </React.Fragment>
  )
}

const LaunchDarkly = {
  init: async () => {
    const LDProvider = await asyncWithLDProvider({
      clientSideID: resolveLaunchDarklyKey(),
      options: {
        fetchGoals: false
      }
    })
    let overrides
    const LDOverride = withLDConsumer()(LDProviderOverride)

    if (isOverridable) {
      overrides = getOverrides()
    }

    LaunchDarkly.Provider = ({ children }) => (
      <LDProvider>
        <LDOverride overrides={overrides}>{children}</LDOverride>
      </LDProvider>
    )
  },
  Provider: () => null
}

export default LaunchDarkly
