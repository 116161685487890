import React, { useState, useContext } from 'react'

const PageTitleContext = React.createContext()

export const PageTitleProvider = ({ children }) => {
  const [pageTitle, setPageTitle] = useState(<div />)
  const value = { pageTitle, setPageTitle }
  return (
    <PageTitleContext.Provider value={value}>
      {typeof children === 'function' ? children(value) : children}
    </PageTitleContext.Provider>
  )
}

export const usePageTitle = () => useContext(PageTitleContext)
