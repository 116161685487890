import styled from 'styled-components'
import Typography from '@nutrien/uet-react/Typography'

import th from 'theme/helpers'

export const P = styled(Typography).attrs({
  variant: 'subtitle1',
  component: 'p'
})`
  margin-bottom: ${th.spacing(2)};
  position: relative;
`
