import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Dialog from '@nutrien/uet-react/Dialog'
import Button from '@nutrien/uet-react/Button'
import Typography from '@nutrien/uet-react/Typography'
import Grid from '@nutrien/uet-react/Grid'
import MapIcon from '@nutrien/uet-react/icons/Map'
import LoopIcon from '@nutrien/uet-react/icons/Loop'
import DoneIcon from '@nutrien/uet-react/icons/Done'
import Paper from '@nutrien/uet-react/Paper'

import th from 'theme/helpers'

const StyledDialog = styled(Dialog)`
  .MuiDialogContent-root {
    padding: ${th.spacing(0)};
  }
`

const DialogContent = styled.div`
  height: 100%;
  box-sizing: border-box;
  padding: ${th.spacing(0, 3, 3, 3)};
`

const SuccessMessage = styled(Typography)`
  padding: ${th.spacing(0, 0, 4, 0)};
`

const StyledButton = styled(Button)`
  padding: ${th.spacing(0)};
`

const NavItem = styled(Grid)`
  padding: ${th.spacing(0, 0, 2, 0)};
`

const Prompt = styled(Typography)`
  padding: ${th.spacing(2)};
  display: flex;
  justify: flex-start;
  alignitems: flex-start;
`

const DonePrompt = styled(Typography)`
  padding: ${th.spacing(2)};
  display: flex;
  justify: flex-start;
  alignitems: flex-start;
  color: ${th.palette('grey.600')};
`

const StyledMapIcon = styled(MapIcon)`
  color: ${th.palette('grey.600')};
  padding: ${th.spacing(2, 1, 1, 2)};
`

const StyledLoopIcon = styled(LoopIcon)`
  color: ${th.palette('grey.600')};
  padding: ${th.spacing(2, 1, 1, 2)};
`

const StyledDoneIcon = styled(DoneIcon)`
  color: ${th.palette('grey.600')};
  padding: ${th.spacing(2, 1, 1, 2)};
`

const Message = styled(Typography)`
  padding: ${th.spacing(0, 2, 2, 2)};
  text-align: left;
`

const DoneMessage = styled(Typography)`
  padding: ${th.spacing(0, 6, 2, 2)};
  text-align: left;
`

const FieldSuccessDialog = ({ onAddMore, onDone, croppingSeason }) => {
  const { t } = useTranslation()

  return (
    <StyledDialog
      title={t('field_added')}
      open
      onClose={onDone}
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs">
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <SuccessMessage variant="body1">
              {t('you_added_field')}
            </SuccessMessage>
          </Grid>
          <NavItem item xs={12}>
            <StyledButton onClick={onAddMore}>
              <Paper elevation={0} variant="outlined">
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-start">
                  <Grid item xs={10}>
                    <Prompt>
                      <Typography component={'span'} variant="h5">
                        {t('add_another_field')}
                      </Typography>
                    </Prompt>
                  </Grid>
                  <Grid item xs={2}>
                    <StyledMapIcon />
                  </Grid>
                  <Grid item>
                    <Message>
                      <Typography component={'span'} variant="body1">
                        {t('add_another_field_msg')}
                      </Typography>
                    </Message>
                  </Grid>
                </Grid>
              </Paper>
            </StyledButton>
          </NavItem>
          <NavItem item xs={12}>
            <StyledButton onClick={croppingSeason}>
              <Paper elevation={0} variant="outlined">
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-start">
                  <Grid item xs={10}>
                    <Prompt>
                      <Typography component={'span'} variant="h5">
                        {t('create_cropping_season')}
                      </Typography>
                    </Prompt>
                  </Grid>
                  <Grid item xs={2}>
                    <StyledLoopIcon />
                  </Grid>
                  <Grid item>
                    <Message>
                      <Typography component={'span'} variant="body1">
                        {t('create_cropping_season_msg')}
                      </Typography>
                    </Message>
                  </Grid>
                </Grid>
              </Paper>
            </StyledButton>
          </NavItem>
          <NavItem item xs>
            <StyledButton onClick={onDone}>
              <Paper elevation={0} variant="outlined">
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-start">
                  <Grid item xs={10}>
                    <DonePrompt>
                      <Typography component={'span'} variant="h5">
                        {t('done_for_now')}
                      </Typography>
                    </DonePrompt>
                  </Grid>
                  <Grid item xs={2}>
                    <StyledDoneIcon />
                  </Grid>
                  <Grid item>
                    <DoneMessage>
                      <Typography component={'span'} variant="body1">
                        {t('done_for_now_msg')}
                      </Typography>
                    </DoneMessage>
                  </Grid>
                </Grid>
              </Paper>
            </StyledButton>
          </NavItem>
        </Grid>
      </DialogContent>
    </StyledDialog>
  )
}

FieldSuccessDialog.propTypes = {
  onAddMore: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired
}

export default FieldSuccessDialog
