import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { memoize } from 'lodash'
import AppBar from '@nutrien/uet-react/AppBar'
import Toolbar from '@nutrien/uet-react/Toolbar'
import Box from '@nutrien/uet-react/Box'
import Typography from '@nutrien/uet-react/Typography'
import Grid from '@nutrien/uet-react/Grid'
import Button from '@nutrien/uet-react/Button'
import IconButton from '@nutrien/uet-react/IconButton'
import ArrowBack from '@nutrien/uet-react/icons/ArrowBack'
import Close from '@nutrien/uet-react/icons/Close'
import makeStyles from '@nutrien/uet-react/styles/makeStyles'
import LinkedLogo from './LinkedLogo'

const StyledAppBar = styled(AppBar)`
  ${({ boxShadow }) =>
    !boxShadow &&
    css`
      box-shadow: none;
    `}
`

const useStyles = makeStyles(() => ({
  toolbarRoot: {
    minHeight: '64px'
  }
}))

const WizardHeader = ({
  displayLogo = true,
  boxShadow = 4,
  handleBack,
  title,
  titleVariant = 'h4',
  toolbarSpacing = 2,
  handleClose,
  handleSubmit
}) => {
  const classes = useStyles()
  const leftColumnSpace = displayLogo ? 4 : 9
  const rightColumnSpace = displayLogo ? 6 : 3
  return (
    <StyledAppBar
      position="static"
      component={Box}
      color="inherit"
      boxShadow={boxShadow}>
      <Toolbar
        classes={{ root: classes.toolbarRoot }}
        component={Box}
        px={toolbarSpacing}
        pt="1px">
        <Grid container alignItems="center">
          {(handleBack || title) && (
            <Grid
              item
              container
              xs={leftColumnSpace}
              justify="flex-start"
              alignItems="center">
              {handleBack && (
                <IconButton onClick={handleBack} aria-label="back" size="small">
                  <ArrowBack fontSize="large" />
                </IconButton>
              )}
              {title && <Typography variant={titleVariant}>{title}</Typography>}
            </Grid>
          )}
          {displayLogo && (
            <Grid item container xs={6}>
              <LinkedLogo />
            </Grid>
          )}
          <Grid
            item
            container
            xs={rightColumnSpace}
            justify="flex-end"
            alignItems="center">
            {handleSubmit && (
              <Button variant="contained" size="small" onClick={handleSubmit}>
                Done
              </Button>
            )}
            {handleClose && (
              <Box mr="-3px">
                <IconButton
                  onClick={handleClose}
                  aria-label="close"
                  size="small">
                  <Close fontSize="large" />
                </IconButton>
              </Box>
            )}
          </Grid>
        </Grid>
      </Toolbar>
    </StyledAppBar>
  )
}

WizardHeader.propTypes = {
  /** Adds logo in specified location */
  displayLogo: PropTypes.bool,
  /** Adds back button and assigns handler */
  handleBack: PropTypes.func,
  /** Text displayed beside the back button */
  title: PropTypes.string,
  /** Adds close button and assigns handler */
  handleClose: PropTypes.func,
  /** Adds done button and assigns handler */
  handleSubmit: PropTypes.func
}
WizardHeader.defaultProps = {
  displayLogo: true
}
export default WizardHeader
export const defaultHandleBack = memoize(history => () => history.goBack())
export const defaultHandleClose = memoize(history => () => history.push('/'))
