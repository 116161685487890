import { useLocation } from 'react-router-dom'

import createUrl from 'helpers/createUrl'

const useCreateUrl = () => {
  const location = useLocation()
  return (...params) => createUrl(location, ...params)
}

export default useCreateUrl
