import React from 'react'
import PropTypes from 'prop-types'
import Box from '@nutrien/uet-react/Box'
import classnames from 'classnames'
import Divider from '@nutrien/uet-react/Divider'
import Accordion from '@nutrien/uet-react/Accordion'
import AccordionSummary from '@nutrien/uet-react/AccordionSummary'
import AccordionDetails from '@nutrien/uet-react/AccordionDetails'
import ExpandArrow from '@nutrien/uet-react/icons/ExpandMore'
import Typography from '@nutrien/uet-react/Typography'
import makeStyles from '@nutrien/uet-react/styles/makeStyles'

/*
 * This component wraps the UET Accordion component to add some custom styling and funcitonalities
 *
 * PROPS:
 *   title     String    title for the accordion
 *   subtitle  String?   subtitle for the accordion
 *   footer    Node?     renders at the bottom, outside of the Accordion
 *
 * Accordion Props are going to be pass through.
 * https://v4-11-4.material-ui.com/api/accordion/#props
 *
 */

const useAccordionStyles = makeStyles(() => ({
  expanded: {
    margin: props => props.expandedMargin
  },
  root: {
    boxShadow: 'none'
  }
}))

const useAccordionSummaryStyles = makeStyles(theme => ({
  content: {
    margin: props => props.contentMargin
  },
  expandIcon: {
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: '4px',
    marginLeft: theme.spacing(2),
    marginRight: props => props.marginRight ?? theme.spacing(-0.5),
    padding: props => props.padding ?? theme.spacing(0.5)
  },
  expanded: {
    marginBottom: props => props.expandedMargin,
    marginTop: props => props.expandedMargin
  },
  root: {
    minHeight: props => props.minHeight,
    padding: props => theme.spacing(props.paddingY, 3)
  }
}))

const useAccordionDetailsStyles = makeStyles(theme => ({
  root: {
    backgroundColor: props => props.backgroundColor,
    padding: theme.spacing(1, 3, 0),
    paddingLeft: props => props.paddingLeft
  },
  smallVariant: {
    padding: theme.spacing(0, 0, 0)
  }
}))

const AccordionBlock = ({
  title,
  header,
  subtitle,
  complexHeader,
  children,
  label,
  footer,
  smallType,
  accordionDetailsStyles,
  accordianHeaderStyles,
  accordionStyles,
  ...props
}) => {
  const accordionClasses = useAccordionStyles(accordionStyles)
  const accordionSummaryClasses = useAccordionSummaryStyles(
    accordianHeaderStyles
  )
  const accordionDetailsClasses = useAccordionDetailsStyles(
    accordionDetailsStyles
  )
  const subtitleContent =
    typeof subtitle === 'string' || typeof subtitle === 'number' ? (
      <Typography
        color="textSecondary"
        variant={smallType ? 'subtitle2' : 'subtitle1'}>
        {subtitle}
      </Typography>
    ) : (
      subtitle
    )

  return (
    <div data-testid="accordion-block-wrapper">
      {complexHeader ? (
        <div>
          <Accordion
            classes={accordionClasses}
            display="flex"
            {...props}
            square>
            <AccordionSummary
              classes={accordionSummaryClasses}
              expandIcon={<ExpandArrow data-testid={`expand_icon_${label}`} />}>
              <Box width="100%">
                <Box>{title}</Box>
                {!!subtitle && subtitleContent}
                <div>{header}</div>
              </Box>
            </AccordionSummary>
            <Box>
              <AccordionDetails
                classes={{ root: accordionDetailsClasses.root }}>
                {children}
              </AccordionDetails>
            </Box>
          </Accordion>
        </div>
      ) : (
        <Accordion classes={accordionClasses} {...props}>
          <AccordionSummary
            classes={accordionSummaryClasses}
            expandIcon={<ExpandArrow />}>
            <div>
              <Typography
                gutterBottom={smallType}
                variant={smallType ? 'h5' : 'h2'}>
                {title}
              </Typography>
              {!!subtitle && subtitleContent}
            </div>
          </AccordionSummary>
          <AccordionDetails
            className={classnames(accordionDetailsClasses.root, {
              [accordionDetailsClasses.smallVariant]: smallType
            })}>
            {children}
          </AccordionDetails>
        </Accordion>
      )}
      {!!footer && (
        <div data-testid="accordion-block-footer">
          <Divider />
          <Box px={3} py={2}>
            {footer}
          </Box>
        </div>
      )}
    </div>
  )
}

AccordionBlock.propTypes = {
  children: PropTypes.node.isRequired,
  footer: PropTypes.node,
  smallType: PropTypes.bool,
  subtitle: PropTypes.node,
  title: PropTypes.string.isRequired
}

AccordionBlock.defaultProps = {
  footer: null,
  subtitle: null
}

export default AccordionBlock
