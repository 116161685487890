import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'graphql-hooks'
import styled from 'styled-components'
import Dialog from '@nutrien/uet-react/Dialog'
import Button from '@nutrien/uet-react/Button'
import Typography from '@nutrien/uet-react/Typography'
import TextField from '@nutrien/uet-react/TextField'
import SendIcon from '@nutrien/uet-react/icons/Send'
import * as Yup from 'yup'
import _get from 'lodash/get'

import th from 'theme/helpers'
import useInputs from 'hooks/useInputs'

const StyledDialog = styled(Dialog)`
  .MuiDialogContent-root {
    padding: ${th.spacing(0, 2)};
  }
`

const StyledTextField = styled(TextField)`
  margin-bottom: ${th.spacing(2)};

  .MuiFormHelperText-contained {
    margin: 0;
  }
`

const DialogContent = styled.div`
  border-top: 1px solid ${({ theme }) => theme.palette.divider};
  box-sizing: border-box;
  padding: ${th.spacing(1, 0, 2, 0)};
`

const INVITE_USER_MUTATION = `
mutation InviteUser(
  $orgId: String!
  $firstName: String!
  $lastName: String!
  $email: String!
) {
  inviteUser(
    orgId: $orgId
    firstName: $firstName
    lastName: $lastName
    email: $email
  ) {
    uuid
  }
}
`

const InviteUserDialog = ({
  orgId,
  onClose,
  onSuccess,
  setRequestPending,
  setRequestError
}) => {
  const { t } = useTranslation()
  const [inviteUser] = useMutation(INVITE_USER_MUTATION)
  const validationSchema = useMemo(
    () => ({
      firstName: Yup.string(),
      lastName: Yup.string(),
      email: Yup.string()
        .email(t('email_error'))
        .required(t('enter_email'))
    }),
    [t]
  )
  const { inputs, getValues, isValid } = useInputs(
    { firstName: '', lastName: '', email: '' },
    validationSchema
  )
  const { firstName, lastName, email } = inputs

  const handleSend = useCallback(async () => {
    setRequestPending(true)
    const { data, error } = await inviteUser({
      variables: {
        orgId,
        ...getValues()
      }
    })
    setRequestPending(false)

    if (error) return setRequestError(t('api_error_title'))

    const userId = _get(data, 'inviteUser.uuid', null)

    if (userId) onSuccess(userId)
  }, [
    getValues,
    inviteUser,
    onSuccess,
    orgId,
    setRequestError,
    setRequestPending,
    t
  ])

  return (
    <StyledDialog
      title={t('invite_user')}
      open
      onClose={onClose}
      hasCloseIcon
      maxWidth="xs"
      closeIconSize="large"
      actions={
        <>
          <Button variant="outlined" onClick={onClose}>
            {t('cancel')}
          </Button>
          <Button
            startIcon={<SendIcon />}
            variant="contained"
            onClick={handleSend}
            disabled={!isValid()}
            data-testid="submitBtn">
            {t('send')}
          </Button>
        </>
      }>
      <DialogContent>
        <Typography variant="body2">{t('invite_user_message')}</Typography>
        <br />
        <StyledTextField
          inputProps={{
            'data-testid': 'firstName'
          }}
          label={t('first_name')}
          {...firstName.bind}
          autoFocus
        />
        <StyledTextField
          inputProps={{
            'data-testid': 'lastName'
          }}
          label={t('last_name')}
          {...lastName.bind}
        />
        <StyledTextField
          inputProps={{
            'data-testid': 'email'
          }}
          label={t('email')}
          {...email.bind}
        />
      </DialogContent>
    </StyledDialog>
  )
}

InviteUserDialog.propTypes = {
  orgId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  setRequestPending: PropTypes.func.isRequired,
  setRequestError: PropTypes.func.isRequired
}

export default InviteUserDialog
