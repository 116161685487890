import React from 'react'
import UETDialog from '@nutrien/uet-react/Dialog'
import styled from 'styled-components'
import Button from '@nutrien/uet-react/Button'
import Toolbar from '@nutrien/uet-react/Toolbar'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import th from 'theme/helpers'

const StyledDialog = styled(UETDialog)`
  .MuiDialogTitle-root {
    padding: ${th.spacing(2, 3)};
    h6 {
      font-size: ${({ theme }) => theme.typography.h1.fontSize};
    }
  }
  .MuiDialogContent-root {
    padding: ${th.spacing(0, 3)};
  }
  .MuiDialogActions-root {
    padding: ${th.spacing(1, 3, 3, 3)};
  }
`
const DialogContent = styled(({ noDivider, ...rest }) => <div {...rest} />)`
  height: 100%;
  box-sizing: border-box;
  padding: ${th.spacing(3, 0, 2, 0)};
  border-top: ${({ noDivider }) => (noDivider ? 0 : `1px solid #ccc`)};
`

const StyledToolbar = styled(Toolbar)`
  justify-content: flex-end;
  padding: 0;
  & > * {
    margin-left: ${th.spacing(2)};
  }
`

const Dialog = ({ title, onClose, children, open = true, noDivider }) => (
  <StyledDialog
    title={title}
    open={open}
    onClose={onClose}
    hasCloseIcon
    maxWidth="xs"
    closeIconSize="large">
    <DialogContent noDivider={noDivider}>{children}</DialogContent>
  </StyledDialog>
)

Dialog.propTypes = {
  title: PropTypes.string,
  noDivider: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  open: PropTypes.bool
}
export default Dialog

export const DialogActions = ({
  onClose,
  onSubmit,
  cancelText,
  submitText,
  submitDisabled
}) => {
  const { t } = useTranslation()
  return (
    <StyledToolbar variant="dense">
      <Button variant="outlined" onClick={onClose} data-testid="dialog-cancel">
        {cancelText || t('cancel')}
      </Button>
      <Button
        type="submit"
        variant="contained"
        disabled={submitDisabled}
        data-testid="dialog-submit"
        onClick={onSubmit}>
        {submitText || t('save')}
      </Button>
    </StyledToolbar>
  )
}
DialogActions.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  cancelText: PropTypes.string,
  submitText: PropTypes.string,
  submitDisabled: PropTypes.bool.isRequired
}
DialogActions.defaultProps = {
  submitDisabled: false
}
