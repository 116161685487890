import { arrayOf, bool, shape, string } from 'prop-types'
import { addressType, unitType } from './commonTypes'
import { communityDashboardAccessType } from './sustainabilityTypes'
import { subscriptionsType } from './subscriptionTypes'

export const userProfileType = shape({
  agribleTermsAndConditions: string.isRequired,
  address: addressType,
  dailyEmailReport: bool.isRequired,
  intercomHmac: string,
  isSalesUser: bool,
  language: string.isRequired,
  unit: unitType
})

export const documentationLinksType = arrayOf(
  shape({
    key: string,
    value: string
  })
)

export const userType = shape({
  id: string,
  firstName: string,
  lastName: string,
  email: string,
  profile: userProfileType,
  documentationLinks: documentationLinksType,
  subscriptions: subscriptionsType,
  communityAdminAccess: bool,
  communityDashboardAccess: communityDashboardAccessType
})
