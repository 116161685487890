import { FIELD_FORECAST_PRODUCT, HAIL_EVENTS_PRODUCT } from 'helpers/products'

import useCanAccessProduct from '../useCanAccessProduct'

/**
 * returns an object with the list of weather products
 * and whether the current user have access to those products.
 *
 * Current weather products:
 *
 * FIELD_FORECAST_PRODUCT,
 * HAIL_EVENTS_PRODUCT
 *
 * @returns {object}
 */

const useWeatherAccessPermissions = () => {
  const fieldForecast = useCanAccessProduct(FIELD_FORECAST_PRODUCT)
  const hailEvent = useCanAccessProduct(HAIL_EVENTS_PRODUCT)

  return {
    fieldForecast,
    hailEvent
  }
}

export default useWeatherAccessPermissions
