import React, { createContext, useState } from 'react'
import PropTypes from 'prop-types'

export const ScrollContext = createContext({})

export const ScrollProvider = ({ children }) => {
  const [contentScroll, setContentScroll] = useState(0)
  return (
    <ScrollContext.Provider value={{ contentScroll, setContentScroll }}>
      {children}
    </ScrollContext.Provider>
  )
}

ScrollProvider.propTypes = {
  children: PropTypes.object.isRequired
}

export default ScrollProvider
