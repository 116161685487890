import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link as RouterLink } from 'react-router-dom'
import makeStyles from '@nutrien/uet-react/styles/makeStyles'

import TabTypography from '../TabTypography'

const useStyles = makeStyles(theme => ({
  divRoot: {
    margin: theme.spacing(0, 0, 0, 4),
    padding: theme.spacing(1, 1),

    [theme.breakpoints.up('md')]: {
      // these weird spacings are there to make sure
      // the AppBar is ~64px
      // sorry. :(
      margin: theme.spacing(0, 1.5),
      padding: theme.spacing(2.5, 0, '19px')
    }
  },
  divSelected: {
    borderLeft: `solid 8px ${theme.palette.brand.green}`,
    // override the left margin to accommodate the 8px border
    margin: theme.spacing(0, 0, 0, 3),

    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(0, 1.5),
      borderLeft: 'none',
      borderBottom: `solid 4px ${theme.palette.brand.green}`
    }
  },
  divChild: {
    margin: theme.spacing(1, 0, 1, 3),
    paddingLeft: theme.spacing(2),

    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(0),
      padding: theme.spacing(1, 2),

      '&:hover': {
        background: theme.palette.gray[50]
      }
    }
  },
  divChildSelected: {
    [theme.breakpoints.down('sm')]: {
      borderLeft: `solid 8px ${theme.palette.brand.green}`,
      // override the padding when selected on mobile
      // to accommodate the border
      paddingLeft: theme.spacing(1)
    },

    // we're going to assume that a child LinkTab in
    // desktop will be inside of a drop down
    [theme.breakpoints.up('md')]: {
      background: theme.palette.gray[100]
    }
  }
}))

const Link = ({ children, external, id, isChild, isSelected, onClick, to }) => {
  const classes = useStyles()

  const label = (
    <div
      data-testid={`nav-link-label-${id}`}
      className={classnames(classes.divRoot, {
        [classes.divChild]: isChild,
        [classes.divSelected]: isSelected && !isChild,
        [classes.divChildSelected]: isSelected && isChild
      })}>
      <TabTypography isSelected={isSelected} isChild={isChild}>
        {children}
      </TabTypography>
    </div>
  )

  return external ? (
    <a href={to} onClick={onClick} data-testid={`nav-link-${id}-a`}>
      {label}
    </a>
  ) : (
    <RouterLink to={to} onClick={onClick} data-testid={`nav-link-${id}`}>
      {label}
    </RouterLink>
  )
}

Link.propTypes = {
  children: PropTypes.node,
  external: PropTypes.bool,
  id: PropTypes.string.isRequired,
  isChild: PropTypes.bool,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  to: PropTypes.string.isRequired
}

Link.defaultProps = {
  children: null,
  external: false,
  isChild: false,
  isSelected: false,
  onClick: () => {}
}

export default Link
