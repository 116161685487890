import React, { useMemo, lazy } from 'react'
import { useHistory } from 'react-router-dom'
import Loading from 'components/Loading'
import { ROUTES } from './const/index'
const AsyncEmployeeDashboard = lazy(() => import('./EmployeeDashboard'))
const ROUTES_WITHOUT_ROOT = { ...ROUTES }
delete ROUTES_WITHOUT_ROOT.EMPLOYEE_DASHBOARD
const QUALIFIED_ROUTE_KEYS = Object.entries(ROUTES_WITHOUT_ROOT).reduce(
  (set, nextVal) => {
    set.add(nextVal[1])
    return set
  },
  new Set()
)

// Checks current URL path exists within an object of supported routes. Returns an object
// with validity and redirect URL if needed.
export const isValidURLPredicate = currentPath => {
  const urlSplit = currentPath.split('/').filter(url => url.trim())
  if (urlSplit.length === 1 || QUALIFIED_ROUTE_KEYS.has(urlSplit[1])) {
    if (urlSplit.length === 3) return { valid: true, redirect: false }
    if (urlSplit.length > 3)
      return { valid: true, redirect: `/${urlSplit.slice(0, 3).join('/')}` }
    if (urlSplit.length === 2)
      return { valid: true, redirect: `/${urlSplit[0]}` }
    return { valid: true, redirect: false }
  }
  return { valid: false, redirect: false }
}

export default function EmployeeDashboardRoot({
  user,
  canViewEmployeeDashboard,
  isAuthenticated,
  AsyncPageNotFound,
  GotoLogin,
  match,
  location
}) {
  const history = useHistory()

  const isValidURL = useMemo(() => {
    return isValidURLPredicate(location.pathname)
  }, [location.pathname])

  if (isValidURL.redirect) history.push(isValidURL.redirect)

  return user ? (
    canViewEmployeeDashboard && isValidURL.valid ? (
      <AsyncEmployeeDashboard match={match} />
    ) : (
      <AsyncPageNotFound />
    )
  ) : isAuthenticated ? (
    <Loading />
  ) : (
    <GotoLogin location={location} />
  )
}
