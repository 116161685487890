import React from 'react'
import Box from '@nutrien/uet-react/Box'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import makeStyles from '@nutrien/uet-react/styles/makeStyles'
import Divider from '@nutrien/uet-react/Divider'
import Button from '@nutrien/uet-react/Button'
import AccordionBlock from 'components/AccordionBlock'
import { StyledSingleChoice } from 'components/Form'

const useStyles = makeStyles(theme => ({
  buttonSpacing: {
    paddingTop: `${theme.spacing(2)}px`,
    paddingBottom: `${theme.spacing(2)}px`
  }
}))

const FilterBlock = ({
  options,
  label,
  name,
  testid,
  showMore = false,
  showMoreAction,
  disabled
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const showMoreButton = showMore && (
    <Box>
      <Divider />
      <Box center="xy">
        <Button
          className={classes.buttonSpacing}
          onClick={showMoreAction}
          fullWidth>
          {t('show_more')}
        </Button>
      </Box>
    </Box>
  )

  return (
    <Box m={2}>
      <div data-testid="filter-block">
        <AccordionBlock defaultExpanded smallType title={label}>
          <Box width="100%">
            <Box mb={0.2} pl={2}>
              <StyledSingleChoice
                disabled={disabled}
                variant="radio"
                optionTypographyVariant={'subtitle1'}
                name={name}
                options={options}
                // label="Select Option"
                size="x-small"
                alignOptions="flex-start"
                data-testid={testid}
              />
            </Box>
            {showMoreButton}
          </Box>
        </AccordionBlock>
      </div>
    </Box>
  )
}

FilterBlock.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.any.isRequired,
      disabled: PropTypes.bool
    })
  ).isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  testid: PropTypes.string,
  showMore: PropTypes.bool,
  showMoreAction: PropTypes.func
}

export default FilterBlock
