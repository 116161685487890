import React from 'react'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import makeStyles from '@nutrien/uet-react/styles/makeStyles'

const useStyles = makeStyles({
  root: {
    width: '100%',
    justifyContent: 'center',
    boxSizing: 'border-box',
    padding: '0 16px'
  }
})
const BannerMaintenance = ({ message }) => {
  const classes = useStyles()
  return (
    <Alert severity="warning" className={classes.root}>
      <AlertTitle>{message}</AlertTitle>
    </Alert>
  )
}

export default BannerMaintenance
