export const callQueryWithAccessToken = async (
  graphqlClient,
  query,
  accessToken,
  variables = {}
) => {
  const response = await graphqlClient.request(
    {
      query: query,
      variables
    },
    {
      fetchOptionsOverrides: {
        headers: {
          Authorization: `AccessToken ${accessToken}`
        }
      }
    }
  )
  return response
}
