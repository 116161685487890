const FTM = 'ftm'
const BOTH_FTM_CFA = 'both'
const CFA = 'cfa'
const COMET = 'comet'
const CARNMPP = 'carnmpp'
const EUDR = 'eudr'
const NERP = 'nerp'
const OTHER = 'other'

/**
 * A dictionary of sustainability protocols/data providers.
 */
export const SUSTAINABILITY_PROTOCOLS = Object.freeze({
  FTM,
  BOTH_FTM_CFA,
  CFA,
  COMET,
  CARNMPP,
  EUDR,
  NERP,
  OTHER
})

/**
 * A dictionary for sustainability protocol names
 * based from DOS
 *
 * https://github.com/aginfo/morningfarmreport/blob/develop/mfr/apps/community/models/community.py#L375-L395
 */

export const SUSTAINABILITY_PROTOCOL_NAMES = Object.freeze({
  [CARNMPP]: 'Sustainable Nitrogen Outcomes',
  [CFA]: 'Cool Farm Alliance',
  [COMET]: 'COMET',
  [EUDR]: 'Regulation on Deforestation-Free Products (EUDR)',
  [FTM]: 'Field to Market',
  [NERP]: 'NERP'
})
