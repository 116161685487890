import { displayValue } from 'helpers/value'
import emptyValue from 'components/Form/helpers/emptyValue'
import { castToUTC } from 'helpers/date'

import { getGrowthStageImage, getMoistureStatusColor } from '../helpers'

const getName = data => (data.name ? data.name : emptyValue)

const formatDateRange = (data, t) => {
  if (!data.name) return ''

  const startDate = data.startDate ? data.startDate : castToUTC(new Date())
  const endDate = data.endDate ? data.endDate : castToUTC(new Date())

  return `${t('_date_mShortDD', { date: startDate })} - ${t('_date_mShortDD', {
    date: endDate
  })}`
}

const getFlood = flood => `${flood.high} - ${flood.low}`

export const yieldCards = (values, t, opts = {}) => {
  if (!values) return []

  return [
    {
      title: values.isPast ? t('actual') : t('expected'),
      value: displayValue(values.actualYield, 0),
      units: values.units,
      ...opts
    },
    ...(values.isPast
      ? []
      : [
          {
            title: t('low_range'),
            value: displayValue(values.lowRangeYield, 0),
            units: values.units,
            ...opts
          },
          {
            title: t('high_range'),
            value: displayValue(values.highRangeYield, 0),
            units: values.units,
            ...opts
          }
        ])
  ]
}

export const soilMoistureCards = (values, t, theme, opts = {}) => {
  if (!values) return []

  return [
    {
      title: t('root_depth'),
      value: displayValue(values.rootDepth, 0),
      units: values.depthUnits,
      ...opts
    },
    {
      title: t('avail_water'),
      value: `${displayValue(values.waterPercent, 0)}${
        values.waterPercent !== null ? '%' : ''
      }`,
      ...opts
    },
    {
      title: t('avail_water'),
      value: displayValue(values.water),
      units: values.water ? values.depthUnits : '',
      ...opts
    },
    {
      title: t('today_status'),
      value: values.moistureStatus || emptyValue,
      color: getMoistureStatusColor(values.moistureStatus, theme),
      ...opts
    }
  ]
}

export const growthStageCards = (values, t, crop) => {
  if (!values) return []

  return [
    {
      title: t('current_upper'),
      value: getName(values.currentStage),
      units: formatDateRange(values.currentStage, t),
      image: getGrowthStageImage(crop, values.currentStage.name)
    },
    {
      title: t('previous_upper'),
      value: getName(values.previousStage),
      units: formatDateRange(values.previousStage, t)
    },
    {
      title: t('next_upper'),
      value: getName(values.nextStage),
      units: formatDateRange(values.nextStage, t)
    }
  ]
}

export const harvestMoistureCards = (values, t) => {
  if (!values) return []

  return [
    {
      title: t('current_upper'),
      value: values.harvestStatus || emptyValue
    },
    ...values.harvestTargets.map(target => ({
      title: `${target.name}% ${t('target')}`,
      value: formatDateRange(target, t)
    }))
  ]
}

export const floodedYieldCards = (values, t) => {
  if (!values) return []

  return [
    {
      title: t('minor'),
      value: getFlood(values.minorFlood),
      units: values.minorFlood.units
    },
    {
      title: t('moderate'),
      value: getFlood(values.moderateFlood),
      units: values.moderateFlood.units
    },
    {
      title: t('significant'),
      value: getFlood(values.majorFlood),
      units: values.majorFlood.units
    }
  ]
}

export const fieldYieldCards = (values, t) => {
  if (!values) return []

  return [
    {
      title: t('minor'),
      value: displayValue(values.minorFlood.yield, 0),
      units: values.minorFlood.units
    },
    {
      title: t('moderate'),
      value: displayValue(values.moderateFlood.yield, 0),
      units: values.moderateFlood.units
    },
    {
      title: t('significant'),
      value: displayValue(values.majorFlood.yield, 0),
      units: values.majorFlood.units
    }
  ]
}
