import { useCallback, useContext } from 'react'
import useFlags from 'hooks/useFlags'

import { AuthContext } from 'components/Auth/authentication-context'

const usePendo = () => {
  const { user } = useContext(AuthContext)
  const flags = useFlags()

  const initialize = useCallback(
    (options = {}) => {
      // for testing envs that doesn't have Pendo token
      // or if pendo got blocked by an ad blocking software
      // and if the feature flag is off.
      if (!window.pendo || !window.pendo?.initialize || !flags.pendoAnalytics) {
        return console.info('Pendo Agent was not initialized')
      }

      const currentVisitorId = window.pendo?.getVisitorId?.()

      // skip initialization when pendo is already initialized
      // and the user ID is the same as the visitor ID
      if (window.pendo?.isReady?.() && currentVisitorId === user?.id) return

      if (user?.id) {
        const { visitor, ...rest } = options

        const initializeData = {
          visitor: {
            email: user.email,
            // spread the visitor options
            ...visitor,

            // but overwrite the id since id is required
            // and needs to be the same per user.
            id: user.id
          },
          ...rest
        }

        if (window.pendo?.isReady?.()) {
          window.pendo.updateOptions(initializeData)
        } else {
          window.pendo.initialize(initializeData)
        }
      } else {
        // initialize pendo as unknown/anonymous user
        if (window.pendo?.isReady?.()) {
          window.pendo.updateOptions({})
        } else {
          window.pendo.initialize({})
        }
      }
    },
    [flags, user]
  )

  /**
   * We want to override the initialize
   * to include the user id when it is available
   */
  return {
    ...(window.pendo ?? {}),
    initialize
  }
}

export default usePendo
