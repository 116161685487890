import { orderBy } from 'lodash'

export const sortSeasonEvents = (seasons, fieldName) => {
  const sortField = fieldName || 'eventName'
  const sortedSeasons = seasons.map(season => {
    if (season.events) {
      if (season.isFallow) {
        // Fallow seasons are sorted by date as we would probably want to see them in the order they occurred
        // ('startDate' followed by 'endDate')
        // even though they would not be alphabetical
        return {
          ...season,
          events: orderBy([...season.events], ['date'], ['asc'])
        }
      } else {
        return {
          ...season,
          events: orderBy([...season.events], sortField, ['asc'])
        }
      }
    }
    return season
  })
  return sortedSeasons
}
