import React from 'react'
import PropTypes from 'prop-types'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles({
  highlight: {
    backgroundColor: 'rgba(133, 212, 1, 0.4)',
    borderRadius: '2px',
    padding: '1px 0',
    margin: '-1px 0'
  }
})

const HighlightedTerm = ({ name, searchString }) => {
  const { highlight } = useStyles()
  const searchTerm = name.toLowerCase().indexOf(searchString.toLowerCase())
  if (searchTerm > -1) {
    const termEndIndex = searchTerm + searchString.length
    return (
      <span>
        {name.substring(0, searchTerm)}
        <span className={highlight} data-testid="highlight">
          {name.substring(searchTerm, termEndIndex)}
        </span>
        {name.substring(termEndIndex)}
      </span>
    )
  }
  return <span>{name}</span>
}

HighlightedTerm.propTypes = {
  searchString: PropTypes.string,
  name: PropTypes.string.isRequired
}

HighlightedTerm.defaultProps = {
  searchString: ''
}

export default HighlightedTerm
