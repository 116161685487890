import React, { lazy, useContext, Suspense } from 'react'
import T from 'prop-types'
import { Redirect, Route, Switch } from 'react-router-dom'
import useFlags from 'hooks/useFlags'

import { AuthContext } from 'components/Auth/authentication-context'
import BonsaiProvider from 'components/BonsaiProvider'
import { ColumnLayout } from 'components/PageLayouts'
import Loading from 'components/Loading'

const AsyncPageNotFound = lazy(() => import('../../pages/PageNotFound'))

const GotoLogin = ({ location }) => (
  <Redirect
    to={`/login?redirect=${encodeURIComponent(
      location.pathname + location.search
    )}`}
  />
)

const HybridRoutesCreator = ({ routes, routesEnabled }) => {
  const flags = useFlags()
  const { isAuthenticated, user } = useContext(AuthContext)

  return (
    <BonsaiProvider>
      <Switch>
        {routes.map(route => {
          const isEnabled = route.isEnabled ?? true
          // when isEnabled is a callback, it accepts flags to determine enablement
          const isRouteEnabled =
            typeof isEnabled === 'function' ? isEnabled({ flags }) : !!isEnabled

          const isRedirect = !!route.redirectTo
          if (isRedirect) {
            return (
              <Redirect
                exact
                key={route.path}
                from={route.path}
                to={route.redirectTo}
              />
            )
          }

          const isUserLoading = !user && isAuthenticated
          /**
           * route authorization only indicates public/private routes
           * the presence of "allowAnonymous" in "authorizedRoles"
           * indicates a public route and an empty list indicates
           * a route that requires authentication. Authorization based
           * on the user's role should be handled within the page component.
           */
          const routeIsPublic = route.authorizedRoles.includes('allowAnonymous')

          return (
            <Route
              render={({ location }) => {
                if (isUserLoading) {
                  return <Loading />
                }

                // routesEnabled is a flag based on permissions or other agrible feature flags
                if (!routesEnabled || !isRouteEnabled) {
                  return (
                    <Suspense fallback={<Loading />}>
                      <AsyncPageNotFound data-testid="404-page" />
                    </Suspense>
                  )
                }

                if (isAuthenticated || routeIsPublic) {
                  return (
                    <ColumnLayout fullWidth fullHeight hideFooter>
                      <Suspense fallback={<Loading />}>
                        <route.View />
                      </Suspense>
                    </ColumnLayout>
                  )
                }
                return <GotoLogin location={location} />
              }}
              exact
              path={route.path}
              key={route.path}
            />
          )
        })}
        <Route
          render={() => (
            <Suspense fallback={<Loading />}>
              <AsyncPageNotFound />
            </Suspense>
          )}
        />
      </Switch>
    </BonsaiProvider>
  )
}

const BaseRouteDefinition = {
  authorizedRoles: T.arrayOf(T.string).isRequired, // partial implementation of roles limited to identifying public/private routes
  path: T.string.isRequired,
  isEnabled: T.oneOfType([T.bool, T.func]) // bool or callback determining if route is enabled
}

const RedirectRouteDefinition = T.shape({
  ...BaseRouteDefinition,
  redirectTo: T.string.isRequired,
  isEnabled: T.oneOfType([T.bool, T.func])
})

const RenderRouteDefinition = T.shape({
  ...BaseRouteDefinition,
  path: T.string.isRequired,
  View: T.elementType // lazily loaded component
})

HybridRoutesCreator.propTypes = {
  routes: T.arrayOf(
    T.oneOfType([RedirectRouteDefinition, RenderRouteDefinition])
  ).isRequired,
  routesEnabled: T.bool
}

HybridRoutesCreator.defaultProps = {
  routesEnabled: true
}

export default HybridRoutesCreator
