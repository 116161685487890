import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import partition from 'lodash/partition'
import Typography from '@nutrien/uet-react/Typography'
import Box from '@nutrien/uet-react/Box'
import Button from '@nutrien/uet-react/Button'
import { useSelector } from 'react-redux'
import usePermissions from 'hooks/usePermissions'
import { FARM_TREE_PRODUCT } from 'helpers/products'
import { selectChildren } from 'store/selectors/farmTree'
import RenameSection from '../RenameSection'
import EditSection from '../EditSection'

const EditFarm = ({ farm, onClickDelete, setName, nameState }) => {
  const { t } = useTranslation()
  const { canChange, canDelete } = usePermissions(FARM_TREE_PRODUCT)

  const farmChildren = useSelector(state => selectChildren(state, farm.uuid))
  const [deletableChildren, undeletableChildren] = partition(
    farmChildren,
    'canDelete'
  )

  return (
    <>
      {canChange(farm.uuid) && (
        <RenameSection name={nameState} type={t('farm')} setName={setName} />
      )}
      {canDelete(farm.uuid) && (
        <EditSection
          name={t('delete') + ' ' + t('farm')}
          details={t('delete_farm_blurb')}>
          {!!deletableChildren.length && (
            <>
              <Typography variant="h6" data-testid="deletable-children">
                {t('fields_can_be_deleted') + ` (${deletableChildren.length})`}
              </Typography>
              <ul>
                {deletableChildren.map(child => (
                  <Typography key={child.uuid} component="li" variant="body1">
                    {child.name}
                  </Typography>
                ))}
              </ul>
            </>
          )}
          <Box mb={2}>
            <Button
              onClick={onClickDelete}
              variant="outlined"
              data-testid="delete_farm"
              disabled={undeletableChildren.length > 0}>
              {t('delete')}
            </Button>
          </Box>
          {!!undeletableChildren.length && (
            <>
              <Typography variant="h6">
                {t('fields_can_not_be_deleted') +
                  ` (${undeletableChildren.length})`}
              </Typography>
              <Box my={2}>
                <Typography>
                  {t('cant_delete_sustainability_fields_blurb')}
                </Typography>
              </Box>
              <ul>
                {undeletableChildren.map(child => (
                  <Typography key={child.uuid} component="li" variant="body1">
                    {child.name}
                  </Typography>
                ))}
              </ul>
            </>
          )}
        </EditSection>
      )}
    </>
  )
}

export default EditFarm

EditFarm.propTypes = {
  farm: PropTypes.object.isRequired,
  nameState: PropTypes.string.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  setName: PropTypes.func.isRequired
}
