import { arrayOf, bool, shape, string } from 'prop-types'

export const servicesType = arrayOf(
  shape({
    defaultProduct: bool,
    slug: string,
    visible: bool
  })
)

export const subscriptionsType = arrayOf(
  shape({
    uuid: string,
    planName: string,
    services: servicesType,
    organizationUuid: string,
    organizationName: string,
    activatedDate: string,
    expirationDate: string
  })
)
