import React, { useContext, useCallback, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'propTypes'
import Box from '@nutrien/uet-react/Box'
import Dialog from '@nutrien/uet-react/Dialog'
import Button from '@nutrien/uet-react/Button'
import Typography from '@nutrien/uet-react/Typography'
import useFlags from 'hooks/useFlags'
import { AuthContext } from 'components/Auth/authentication-context'
import useAuthorshipSession from 'hooks/useAuthorshipSession'

const UpdatedTermsAndConditions = ({ setShowTermsandCondDrawer }) => {
  const { t } = useTranslation()
  const flags = useFlags()
  const { isAuthenticated, user } = useContext(AuthContext)
  const [showViewUpdatedTermsPopup, setShowViewUpdatedTermsPopup] = useState(
    false
  )
  const { authorshipSessionActive } = useAuthorshipSession()
  const { appEnv } = window.uiConf

  useEffect(() => {
    if (isAuthenticated && user) {
      if (
        !authorshipSessionActive &&
        user.profile?.agribleTermsAndConditions &&
        Number(user.profile?.agribleTermsAndConditions) <
          Number(flags.tcVersion[appEnv])
      ) {
        setShowViewUpdatedTermsPopup(true)
      }
    }
  }, [flags, isAuthenticated, user, appEnv, authorshipSessionActive])

  const handleView = () => {
    setShowViewUpdatedTermsPopup(false)
    setShowTermsandCondDrawer(true)
  }

  const handleClose = useCallback(
    (_event, reason) => {
      /*
       *  https://v4.mui.com/api/dialog/#props
       *
       *  this is the same as setting
       *  disableBackdropClick (deprecated) and disableEscapeKeyDown
       *  props to true.
       */
      if (reason === 'backdropClick' || reason === 'escapeKeyDown') return

      setShowViewUpdatedTermsPopup(false)
    },
    [setShowViewUpdatedTermsPopup]
  )

  return (
    <Dialog
      actions={
        <>
          <Button onClick={() => handleView()} variant="contained">
            {t('View Updated Terms')}
          </Button>
        </>
      }
      open={showViewUpdatedTermsPopup}
      onClose={handleClose}
      title={t('Updated Terms and Conditions')}>
      <Box display="flex" flexDirection="column" style={{ gap: '18px' }}>
        <Typography variant="subtitle1">
          {t(
            'Our Terms and Conditions have been updated Please review and accept or decline these terms'
          )}
        </Typography>
      </Box>
    </Dialog>
  )
}

UpdatedTermsAndConditions.propTypes = {
  setShowTermsandCondDrawer: PropTypes.func
}

export default UpdatedTermsAndConditions
