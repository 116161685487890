export const staticOptions = {
  key: 'ye-org-card',
  options: t => [
    {
      title: t('data_type'),
      name: 'dataType',
      value: 'Yield',
      label: t('yield_label'),
      type: 'select',
      options: [
        { label: t('yield_label'), value: 'Yield' },
        { label: t('low_range'), value: 'LowRangeYield' },
        { label: t('high_range'), value: 'HighRangeYield' },
        { label: t('growth_stage'), value: 'GrowthStage' },
        { label: t('root_depth'), value: 'RootDepth' },
        { label: t('moisture_status'), value: 'MoistureStatus' },
        { label: t('Water'), value: 'Water' },
        { label: t('water_percent'), value: 'WaterPercent' }
      ]
    },
    {
      title: t('Season'),
      name: 'season',
      value: 'All',
      label: t('All'),
      type: 'select',
      entityId: null,
      options: [{ label: t('All'), value: 'All' }]
    },
    {
      title: t('group_by_farm'),
      name: 'groupByFarm',
      value: true,
      label: t('farms'),
      type: 'switch'
    },
    {
      title: t('sort'),
      name: 'sortOrder',
      value: 'desc',
      label: t('descending'),
      type: 'chips',
      options: [
        { label: t('ascending'), value: 'asc' },
        { label: t('descending'), value: 'desc' }
      ]
    }
  ]
}

export const generateDynamicOptions = (
  options,
  { optionIndex, entityId, values = [] },
  currentSeason,
  t
) => {
  if (!options.length) return null

  if (!values.find(opt => opt.value === currentSeason)) {
    options[optionIndex] = {
      ...options[optionIndex],
      label: t('All'),
      value: 'All'
    }
  }

  if (entityId !== options[optionIndex].entityId) {
    options[optionIndex].options = [
      { label: t('All'), value: 'All' },
      ...values
    ]
  }

  return options
}
