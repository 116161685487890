import farmTree from '@nutrien/farm-tree-navigation/dist/redux/reducers'
import {
  RECEIVE_ENTITIES_SUCCESS,
  RECEIVE_ENTITIES_ERROR,
  SET_SELECTED_ID
} from '@nutrien/farm-tree-navigation/dist/redux/actions/constants'
import {
  ENTITY_TYPE_ORGS,
  ENTITY_TYPE_FARMS,
  UUID_MY_ACCOUNT
} from '@nutrien/farm-tree-navigation/dist/constants/entities'
import _find from 'lodash/find'

import keyBy from 'lodash/keyBy'
import sortBy from 'lodash/sortBy'
import { ENTITY_TYPE_FIELDS } from '@nutrien/geospatial'

import { STEPPER_STATUS } from 'const'
import {
  getFirstFieldInBasicFarmTree,
  getParent,
  getAllCroppingSeasonsForEntities,
  insertIf,
  orderByInsensitive,
  isField,
  tagCustomRotations
} from 'helpers'
import {
  FARM_TREE_IS_LOADING,
  RELOAD_ENTITIES_SUCCESS,
  LOAD_BUILD_ROTATIONS_COMPLETE,
  SET_SELECTED_ID_SIMPLE,
  LOAD_CREATE_PLAN_COMPLETE,
  GET_ENROLLED_FIELDS_NAVIGATION_TREE_COMPLETE,
  GET_BUILD_ROTATIONS_FARM_TREE_COMPLETE,
  UPDATE_BUILD_CROP_ROTATIONS_STATUS,
  LOAD_DOCUMENT_BASELINE_COMPLETE,
  UPDATE_ENTITY_BASELINE_STATUS,
  LOAD_LOG_FIELD_ACTIVITY_COMPLETE,
  REFETCH_STEPPER_FARM_TREE_COMPLETE
} from 'store/actions/FarmTree'

import { RENAME_ENTITY, MOVE_ENTITY, DELETE_ENTITY } from '../actions/FarmTree'
import history from '../../history'
import { selectCustomRotationId, selectSelectedId } from '../selectors/farmTree'

function updateEntityBaselineStatus(state, { payload }) {
  const { baselineCroppingSeasons, enrolledFieldsInCampaign } = payload
  const uuid = state.selectedId
  const entityIsField = Object.keys(state.entities).includes(uuid)
  const entity = entityIsField
    ? state.entities[uuid]
    : state.cropRotations.find(({ id }) => id === uuid)

  if (entity.baselineStatusIncomplete === null) {
    return state
  }

  const baselineRotationStatusIncomplete = baselineCroppingSeasons.some(
    ({ events }) =>
      events.some(({ status }) => status === STEPPER_STATUS.INCOMPLETE)
  )

  const newEntities = enrolledFieldsInCampaign.reduce((acc, curr) => {
    acc[curr.uuid] = {
      ...state.entities[curr.uuid],
      baselineStatusIncomplete: !curr.isDocumentBaselineComplete
    }
    return acc
  }, {})

  return {
    ...state,
    entities: {
      ...state.entities,
      ...newEntities
    },
    cropRotations: state.cropRotations.map(rotation => {
      const shouldUpdate = entityIsField
        ? rotation.id === entity.rotationId && !entity.isCustom
        : rotation.id === uuid

      return shouldUpdate
        ? {
            ...rotation,
            baselineStatusIncomplete: baselineRotationStatusIncomplete
          }
        : rotation
    })
  }
}

function updateBuildCropRotationsStatus(
  state,
  { payload: enrolledFieldsInCampaign }
) {
  const uuid = state.selectedId
  const enrolledField = enrolledFieldsInCampaign?.getEnrolledFieldsForCampaign.find(
    field => field.uuid === uuid
  )
  const buildCropRotationStatusIncomplete = !enrolledField?.isBuildCropRotationsComplete

  return {
    ...state,
    entities: {
      ...state.entities,
      [uuid]: {
        ...state.entities[uuid],
        buildCropRotationStatusIncomplete
      }
    }
  }
}

// This override is necessary for multi-org support
function receiveEntitiesSuccess(state, action) {
  return {
    ...state,
    allFarms: action.payload.allFarms,
    entities: action.payload.newFarmTree,
    // activeId should always be root, as it affects visibility
    activeId: UUID_MY_ACCOUNT,
    selectedId: null,
    previousSelectedId: null,
    isLoading: false,
    error: null
  }
}

const getFirstNonCustomRotation = (sortedEntities, sortedRotations) =>
  sortedRotations.find(
    ({ id, seasons }) =>
      seasons.length &&
      sortedEntities.some(({ rotationId }) => id === rotationId)
  )

const getFirstFieldInRotationListId = (sortedEntities, sortedRotations) => {
  const firstWithoutRotation = sortedEntities.find(
    ({ rotationId, type }) => type === ENTITY_TYPE_FIELDS && !rotationId
  )

  if (firstWithoutRotation) {
    return firstWithoutRotation.uuid
  }

  const firstNonCustomRotation = getFirstNonCustomRotation(
    sortedEntities,
    sortedRotations
  )

  const fieldToSelect = firstNonCustomRotation
    ? sortedEntities.find(
        ({ rotationId }) => rotationId === firstNonCustomRotation.id
      )
    : // this assumes there will only ever be one custom rotation, if that is not the case, it is bad data created outside this app
      sortedEntities.find(({ type }) => isField(type))
  return fieldToSelect?.uuid
}

const getFirstRotationInRotationListId = (sortedEntities, sortedRotations) => {
  const firstNonCustomRotation = getFirstNonCustomRotation(
    sortedEntities,
    sortedRotations
  )

  return (
    firstNonCustomRotation?.id ||
    // edge case where the only rotation is the custom rotation
    getFirstFieldInRotationListId(sortedEntities, sortedRotations)
  )
}

function loadRotationFarmTreeComplete(state, { payload }, initSelectRotation) {
  const {
    farmTree,
    allFarms,
    permissions,
    cropRotations,
    croppingSeasons
  } = payload.loadBuildCropRotations
  const sortedEntities = orderByInsensitive(farmTree, 'name')
  const sortedRotations = orderByInsensitive(cropRotations, 'rotationName')

  return {
    ...state,
    entities: keyBy(sortedEntities, 'uuid'),
    allFarms,
    cropRotations: sortedRotations,
    permissions: permissions,
    allCroppingSeasons: croppingSeasons,
    selectedId: initSelectRotation
      ? getFirstRotationInRotationListId(sortedEntities, sortedRotations)
      : getFirstFieldInRotationListId(sortedEntities, sortedRotations),
    isLoading: false,
    error: null,
    campaignData: payload.getCommunityNode,
    ...insertIf(payload.listCrops, { cropsWithSubCrops: payload.listCrops })
  }
}

function getBuildRotationsFarmTreeComplete(state, { payload }) {
  const { farmTree, cropRotations } = payload.getBuildRotationsFarmTree
  const sortedEntities = sortBy(farmTree, 'name')
  const sortedRotations = sortBy(cropRotations, 'rotation_name')

  return {
    ...state,
    ...insertIf(payload.selectedId, { selectedId: payload.selectedId }),
    entities: keyBy(sortedEntities, 'uuid'),
    cropRotations: sortedRotations,
    isLoading: false,
    error: null
  }
}

function loadCreatePlanComplete(state, { payload }) {
  const {
    farmTree,
    permissions,
    allFarms
  } = payload.getEnrolledFieldsNavigationTree
  const sortedEntities = sortBy(farmTree, 'name')

  return {
    ...state,
    allFarms,
    entities: keyBy(sortedEntities, 'uuid'),
    permissions,
    allCroppingSeasons: getAllCroppingSeasonsForEntities(
      payload.listAllCroppingSeasons,
      sortedEntities
    ),
    croppingSeasonsForCleanup: payload.listCroppingSeasonsForCleanup,
    campaignData: payload.getCommunityNode,
    cropsWithSubCrops: payload.listCropsWithSubCrops,
    selectedId: getFirstFieldInBasicFarmTree(sortedEntities)?.uuid,
    isLoading: false,
    error: null
  }
}

function getEnrolledFieldsNavigationTreeComplete(state, { payload }) {
  const { selectFirstField } = payload
  // When removing a selected field from a Campaign, we default to select the first field on return to Create Plan.
  const sortedEntities = sortBy(
    payload.data.getEnrolledFieldsNavigationTree.farmTree,
    'name'
  )

  const { allFarms } =
    payload.getEnrolledFieldsNavigationTree ||
    payload.data.getEnrolledFieldsNavigationTree

  return {
    ...state,
    allFarms,
    entities: keyBy(sortedEntities, 'uuid'),
    isLoading: false,
    error: null,
    ...(selectFirstField && {
      selectedId: getFirstFieldInBasicFarmTree(sortedEntities)?.uuid
    })
  }
}

function refetchStepperFarmTreeComplete(
  state,
  {
    payload: {
      getEnrolledFieldsNavigationTree: { farmTree, permissions, allFarms }
    }
  }
) {
  const customRotationId = selectCustomRotationId({ farmTree: state })
  const selectedId = selectSelectedId({ farmTree: state })

  const sortedEntities = sortBy(farmTree, 'name')
  const entities = keyBy(
    tagCustomRotations(sortedEntities, customRotationId),
    'uuid'
  )
  const selectedEntityWasDeleted = !Object.keys(entities).includes(selectedId)
  return {
    ...state,
    entities,
    allFarms,
    allCroppingSeasons: getAllCroppingSeasonsForEntities(
      state.allCroppingSeasons,
      sortedEntities
    ),
    permissions,
    isLoading: false,
    error: null,
    selectedId: selectedEntityWasDeleted
      ? getFirstFieldInBasicFarmTree(sortedEntities)?.uuid
      : selectedId
  }
}

// TODO: Move history ops into actions
// This override is necessary for url sync
function setSelectedId(state, action) {
  const [, , entityType, entityId] = history.location.pathname.split('/')

  // Intercept first call and assign entity from url instead of root
  if (!state.selectedId && action.payload === state.activeId) {
    if (entityId) {
      return farmTree(state, { ...action, payload: entityId })
    }
    return farmTree(state, action)
  }

  // Update url on every select
  if (state.selectedId && action.payload && action.payload !== entityId) {
    const url = history.location.pathname
      .replace(state.selectedId, action.payload)
      .replace(entityType, state.entities[action.payload].type)
    history.push(url)
  }
  return farmTree(state, action)
}

function renameEntity(state, action) {
  const { uuid, name } = action.payload
  const parent = getParent(state.entities, uuid)
  const getEntityName = entityId =>
    entityId === uuid ? name : state.entities[entityId].name
  const entities = {
    ...state.entities,
    [uuid]: {
      ...state.entities[uuid],
      name
    },
    [parent.uuid]: {
      ...parent,
      childrenUUIDs: parent.childrenUUIDs
        .concat([])
        .sort((a, b) => getEntityName(a).localeCompare(getEntityName(b)))
    }
  }
  return {
    ...state,
    entities
  }
}

function moveEntity(state, action) {
  const { uuid, parentUUID } = action.payload
  const newFarm = _find(state.allFarms, {
    uuid: state.entities[uuid].parentUUID
  })
  const previousParent = getParent(state.entities, uuid) || newFarm
  const newParent = state.entities[parentUUID] || newFarm
  const entities = {
    ...state.entities,
    [uuid]: {
      ...state.entities[uuid],
      parentUUID
    },
    [previousParent.uuid]: {
      ...previousParent,
      childrenUUIDs: previousParent.childrenUUIDs?.filter(
        childUUID => childUUID !== uuid
      )
    },
    [newParent.uuid]: {
      ...newParent,
      childrenUUIDs: newParent.childrenUUIDs
        ?.concat([uuid])
        .sort((a, b) =>
          state.entities[a].name.localeCompare(state.entities[b].name)
        )
    }
  }

  return {
    ...state,
    entities
  }
}

function deleteSubtree(uuid, entities) {
  const entity = entities[uuid]
  const children = entity.childrenUUIDs || []
  children.forEach(childUUID => deleteSubtree(childUUID, entities))
  const parent = getParent(entities, uuid)
  if (parent) {
    entities[parent.uuid] = {
      ...parent,
      childrenUUIDs: parent.childrenUUIDs.filter(
        childUUID => childUUID !== uuid
      )
    }
  }
  delete entities[uuid]
}

function getMyOrg(entities) {
  return _find(
    entities,
    e => e.type === ENTITY_TYPE_ORGS && !e.isShared && !e.owner
  )
}

function deleteEntity(state, action) {
  const { uuid, manuallySetSelectedField } = action.payload
  const parent = getParent(state.entities, uuid)
  const entities = { ...state.entities }
  if (parent.type === ENTITY_TYPE_FARMS && parent.childrenUUIDs.length === 1) {
    deleteSubtree(parent.uuid, entities)
  } else {
    deleteSubtree(uuid, entities)
    // If there's single (pruned) group, deleting a farm from
    // entities should also reflect the group in org.group
    const org = getMyOrg(entities)
    if (org.group) {
      entities[org.group.uuid] = entities[org.uuid] = {
        ...org,
        group: {
          ...org.group,
          childrenUUIDs: org.childrenUUIDs
        }
      }
    }
  }
  const sortedEntities = sortBy(entities, 'name')

  return {
    ...state,
    entities,
    // in the context of manage projects, activeId is not utilized, and selectedId is removed
    selectedId: manuallySetSelectedField
      ? getFirstFieldInBasicFarmTree(sortedEntities)?.uuid
      : entities[state.selectedId]
      ? state.selectedId
      : state.activeId
  }
}

function farmTreeOverrides(state, action) {
  switch (action.type) {
    case RECEIVE_ENTITIES_SUCCESS:
      return receiveEntitiesSuccess(state, action)
    case LOAD_BUILD_ROTATIONS_COMPLETE:
      return loadRotationFarmTreeComplete(state, action)
    case LOAD_DOCUMENT_BASELINE_COMPLETE:
      return loadRotationFarmTreeComplete(state, action, true)
    case LOAD_LOG_FIELD_ACTIVITY_COMPLETE:
      return loadRotationFarmTreeComplete(state, action, false)
    case LOAD_CREATE_PLAN_COMPLETE:
      return loadCreatePlanComplete(state, action)
    case GET_ENROLLED_FIELDS_NAVIGATION_TREE_COMPLETE:
      return getEnrolledFieldsNavigationTreeComplete(state, action)
    case GET_BUILD_ROTATIONS_FARM_TREE_COMPLETE:
      return getBuildRotationsFarmTreeComplete(state, action)
    case UPDATE_BUILD_CROP_ROTATIONS_STATUS:
      return updateBuildCropRotationsStatus(state, action)
    case UPDATE_ENTITY_BASELINE_STATUS:
      return updateEntityBaselineStatus(state, action)
    case REFETCH_STEPPER_FARM_TREE_COMPLETE:
      return refetchStepperFarmTreeComplete(state, action)

    case RECEIVE_ENTITIES_ERROR:
      return {
        ...state,
        error: action.payload
      }

    // TODO(Optional): Could strip down the entities that didn't change.
    // i.e. For add field, we could only supply the changed tree here and
    // update entities instead of swap
    case RELOAD_ENTITIES_SUCCESS:
      return {
        ...state,
        error: null,
        entities: action.payload.newFarmTree,
        allFarms: action.payload.allFarms,
        isLoading: false
      }

    case SET_SELECTED_ID:
      return {
        ...setSelectedId(state, action),
        previousSelectedId: state.selectedId
      }
    case SET_SELECTED_ID_SIMPLE:
      return {
        ...state,
        selectedId: action.payload
      }
    case RENAME_ENTITY:
      return renameEntity(state, action)
    case MOVE_ENTITY:
      return moveEntity(state, action)
    case DELETE_ENTITY:
      return deleteEntity(state, action)
    case FARM_TREE_IS_LOADING:
      return {
        ...state,
        error: null,
        isLoading: true
      }
    default:
      return farmTree(state, action)
  }
}

export default farmTreeOverrides
