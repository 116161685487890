import { useLocation } from 'react-router-dom'
import { PROJECT_STEP_ROUTES } from 'const'

const useGetProjectStep = () => {
  const { pathname } = useLocation()
  // loop through the PROJECT_STEP_ROUTES
  // if the value is in the pathname,
  // it is safe to assume that the key (based on PROJECT_STEP)
  // is the current project step the user is in.
  for (const [key, value] of Object.entries(PROJECT_STEP_ROUTES)) {
    if (pathname.includes(value)) return key
  }

  return undefined
}

export default useGetProjectStep
