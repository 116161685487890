import React from 'react'
import T from 'prop-types'
import List from '@nutrien/uet-react/List'
import Entity from '../Entity'
import NoEntity from '../NoEntity'

const Entities = ({ entities, searchString, selectedId, type }) => {
  if (!entities || !entities.length) {
    return <NoEntity type={type} />
  }

  const items = (
    <List disablePadding>
      {entities.map(entity => (
        <Entity
          data-testid="entity-box"
          key={entity.uuid}
          selectedId={selectedId}
          searchString={searchString}
          entity={entity}
        />
      ))}
    </List>
  )

  return items
}

Entities.propTypes = {
  entities: T.arrayOf(
    T.shape({
      name: T.string,
      uuid: T.string,
      type: T.string,
      children: T.arrayOf(T.shape())
    })
  ).isRequired,
  searchString: T.string,
  selectedId: T.string,
  type: T.string
}

Entities.defaultProps = {
  entities: [],
  selectedId: '',
  searchString: null
}

export default Entities
