const c = '°C'
const f = '°F'

export const unitMap = {
  inches: 'in',
  millimeters: 'mm',
  Fahrenheit: f,
  fahrenheit: f,
  'GDD [F]': f,
  celsius: c,
  Celsius: c,
  'GDD [C]': c
}
