export const tractorTimeColors = {
  noOps: '#E5391A',
  ltOps: '#F68029',
  mdOps: '#F2B200',
  allOps: '#619B2D',
  ltFzn: '#41B6C4',
  hvFzn: '#2A65AC'
}

export const nutrientEngineColors = {
  deficit: '#763B08',
  surplus: '#0B972F'
}

export const cardColors = {
  rainfall: {
    low: '#41B6C4',
    medium_low: '#3391BC',
    medium: '#2A65AC',
    medium_high: '#253394',
    high: '#0A1565'
  },
  growingDegreeDays: {
    low: '#67CF08',
    medium_low: '#5FAC0E',
    medium: '#0B972F',
    medium_high: '#136535',
    high: '#1A4F0E'
  },
  temperature: {
    low: '#253394',
    medium_low: '#3391BC',
    medium: '#F5B93A',
    medium_high: '#F68029',
    high: '#9A0000'
  },
  maxTemperature: {
    low: '#253394',
    medium_low: '#3391BC',
    medium: '#F5B93A',
    medium_high: '#F68029',
    high: '#9A0000'
  },
  relativeHumidity: {
    low: '#A082F3',
    medium_low: '#9549DD',
    medium: '#6C24AF',
    medium_high: '#451573',
    high: '#39104F'
  },
  primary: tractorTimeColors,
  wet: tractorTimeColors,
  pollinator: {
    warning: '#E5391A',
    watch: '#F2B200',
    spray: '#619B2D'
  },
  nitrogen: nutrientEngineColors,
  phosphorus: nutrientEngineColors,
  default: '#A0A0A0',
  markets: {
    gain: '#619B2D',
    loss: '#E5391A',
    noChange: '#A0A0A0'
  },
  hailEvents: {
    high: '#E5391A',
    medium: '#F68029',
    low: '#F5B93A',
    slight: '#F2B200',
    default: '#A0A0A0'
  },
  barleyQuality: {
    red: '#E5391A',
    yellow: '#F2B200',
    green: '#619B2D',
    neutral: '#A0A0A0'
  },
  sustainability: {
    down: '#8C5730'
  }
}
