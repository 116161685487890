import { useRef, useState, useCallback } from 'react'

export default (initialValue = false) => {
  const watcher = useRef(() => {})
  const [checked, setChecked] = useState(initialValue)

  return {
    checked,
    setChecked,
    watch: useCallback(fn => {
      watcher.current = fn
    }, []),
    bind: {
      checked,
      onChange: useCallback(({ target }) => {
        setChecked(target.checked)
        watcher.current(target.checked)
      }, [])
    }
  }
}
