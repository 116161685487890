import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Card from '@nutrien/uet-react/Card'
import Grid from '@nutrien/uet-react/Grid'
import Typography from '@nutrien/uet-react/Typography'
import ChevronRight from '@nutrien/uet-react/icons/ChevronRight'

import th from 'theme/helpers'

const StyledCard = styled(Card)`
  height: 100%;
`

const CardHeader = styled(Grid)`
  padding: ${th.spacing(2, 0)};
`

const CardText = styled(Typography)`
  padding: ${th.spacing(2, 0)};
`

const CardContent = styled.div`
  height: 100%;
  padding: ${th.spacing(2)};
`

const EntryCard = ({ title, text, icon, onClick }) => {
  return (
    <StyledCard clickable onClick={onClick}>
      <CardContent>
        <CardHeader container alignItems="center" justify="space-between">
          {icon}
          <ChevronRight />
        </CardHeader>
        <Typography variant="h2">{title}</Typography>
        <CardText>{text}</CardText>
      </CardContent>
    </StyledCard>
  )
}

EntryCard.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired
}

export default EntryCard
