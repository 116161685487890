import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import useCreateUrl from 'hooks/useCreateUrl'
import useQueryParams from 'hooks/useQueryParams'

export default name => {
  const history = useHistory()
  const queryParams = useQueryParams()
  const isOpen = queryParams.panel === name
  const createUrl = useCreateUrl()

  const setParams = useCallback(
    params => {
      // panel is used on the query string to represent pages that are not panels.
      // We need to keep track of them when a panel is open
      // to ensure that these pages are intact when the panel is closed
      if (
        !isOpen &&
        params.panel &&
        queryParams.panel &&
        !queryParams.hiddenPanel
      )
        params.hiddenPanel = queryParams.panel
      if (params.panel === null && queryParams.hiddenPanel) {
        params.panel = queryParams.hiddenPanel
        params.hiddenPanel = null
      }

      history.push(
        createUrl({
          isDashboardPath: false,
          queryParams: params
        })
      )
    },
    [createUrl, history, queryParams, isOpen]
  )

  const togglePanel = useCallback(
    (event, params) => setParams({ panel: isOpen ? null : name, ...params }),
    [isOpen, setParams, name]
  )

  return { isOpen, togglePanel, setParams }
}
