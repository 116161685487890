import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'graphql-hooks'
import styled from 'styled-components'
import Dialog from '@nutrien/uet-react/Dialog'
import Button from '@nutrien/uet-react/Button'
import LinearProgress from '@nutrien/uet-react/LinearProgress'
import Typography from '@nutrien/uet-react/Typography'
import Grid from '@nutrien/uet-react/Grid'
import Divider from '@nutrien/uet-react/Divider'
import CircularProgress from '@nutrien/uet-react/CircularProgress'
import AddIcon from '@nutrien/uet-react/icons/Add'
import IncompatibleIcon from '@nutrien/uet-react/icons/Block'
import UpdateIcon from '@nutrien/uet-react/icons/Sync'
import UnchangedIcon from '@nutrien/uet-react/icons/SyncDisabled'
import SuccessIcon from '@nutrien/uet-react/icons/CheckCircle'
import ErrorIcon from '@nutrien/uet-react/icons/Error'
import TotalIcon from '@nutrien/uet-react/icons/Image'
import SvgIcon from '@nutrien/uet-react/SvgIcon'

import th from 'theme/helpers'
import useJDImportStatus from 'hooks/useJDImportStatus'
import useLoadFarmTree from 'hooks/useLoadFarmTree'
import useToggle from 'hooks/useToggle'

const StyledDialog = styled(Dialog)`
  .MuiDialogContent-root {
    padding-top: 0;
  }
`

const DialogContent = styled.div`
  border-top: 1px solid ${th.palette('divider')};
  height: 100%;
  box-sizing: border-box;
  padding: ${th.spacing(3, 0)};
`

const StyledGrid = styled(({ faint, ...rest }) => <Grid {...rest} />)`
  margin-bottom: ${th.spacing(2)};
  color: ${({ faint, theme }) =>
    faint ? theme.palette.grey[400] : theme.palette.text.primary};
`

const ProgressText = styled(Typography)`
  color: inherit;
  margin-left: ${th.spacing(1)};
`

const StyledDivider = styled(Divider)`
  margin: ${th.spacing(2, 0)};
`

const ProgressBar = styled(LinearProgress)`
  margin: ${th.spacing(2, 0, 3, 0)};
`

const StyledSuccessIcon = styled(SvgIcon).attrs({ component: SuccessIcon })`
  color: ${th.palette('primary.main')};
`

const StyledErrorIcon = styled(SvgIcon).attrs({ component: ErrorIcon })`
  color: ${th.palette('error.main')};
`

const StatusRow = ({ faint, icon, text, status }) => (
  <StyledGrid
    container
    alignItems="center"
    justify="space-between"
    wrap="nowrap"
    faint={faint ?? !status}>
    <Grid item container alignItems="center" wrap="nowrap" xs>
      {icon}
      <ProgressText variant="body1">{text}</ProgressText>
    </Grid>
    <Grid item>
      <Typography variant="body1">{status}</Typography>
    </Grid>
  </StyledGrid>
)

const IMPORT_FROM_JD = `
  mutation ImportFromJD($orgId: String!) {
    importFromJohnDeere(orgId: $orgId)
  }
`

const iconMap = {
  queued: <CircularProgress size={20} />,
  running: <CircularProgress size={20} />,
  done: <StyledSuccessIcon />,
  error: <StyledErrorIcon />
}

const JohnDeereImport = ({ orgId, onDone, setRequestPending }) => {
  const { t } = useTranslation()
  const [allowClose, toggleAllowClose] = useToggle(false)
  const [importFromJD] = useMutation(IMPORT_FROM_JD)
  const [startPolling, progress] = useJDImportStatus()
  const [, loadFarmTree] = useLoadFarmTree()

  useEffect(() => {
    importFromJD({ variables: { orgId } })
    startPolling()
  }, [importFromJD, orgId, startPolling])

  const handleClose = useCallback(async () => {
    if (!allowClose) return // Do not allow closing if still importing

    // rehydrate farm tree
    if (progress.isDone) {
      setRequestPending(true)
      await loadFarmTree()
      setRequestPending(false)
    }

    onDone(progress.enterpriseGroupUuid)
  }, [allowClose, onDone, progress, loadFarmTree, setRequestPending])

  useEffect(() => {
    if (progress.isError || progress.isDone) toggleAllowClose()
  }, [progress, toggleAllowClose])

  const { summary } = progress
  const progressIcon = iconMap[progress.status] || iconMap.running

  return (
    <StyledDialog
      title={t('import_fields')}
      open
      onClose={handleClose}
      disableBackdropClick={!allowClose}
      disableEscapeKeyDown={!allowClose}
      hasCloseIcon
      maxWidth="xs"
      closeIconSize="large"
      actions={
        <>
          <Button
            variant="contained"
            onClick={handleClose}
            disabled={!allowClose}>
            {t('done')}
          </Button>
        </>
      }>
      <DialogContent>
        <StatusRow
          icon={progressIcon}
          text={t(progress.status || 'queued')}
          status={`${progress.pctComplete || 0}%`}
        />
        <ProgressBar variant="determinate" value={progress.pctComplete} />

        {!progress.isPending && (
          <React.Fragment>
            <StatusRow
              icon={<AddIcon />}
              text={t('added')}
              status={summary.totalFieldsAdded || 0}
            />
            <StatusRow
              icon={<UpdateIcon />}
              text={t('updated')}
              status={summary.totalFieldsUpdated || 0}
            />
            <StatusRow
              icon={<UnchangedIcon />}
              text={t('no_change')}
              status={summary.totalFieldsNoop || 0}
            />
            <StatusRow
              icon={<IncompatibleIcon />}
              text={t('incompatible')}
              status={summary.totalFieldsSkipped || 0}
            />

            <StyledDivider />

            <StatusRow
              icon={<TotalIcon />}
              text={t('total_fields_processed')}
              status={summary.totalFieldsProcessed || 0}
            />

            <StatusRow
              icon={<SvgIcon />}
              text={t('agrible_and_johndeere')}
              faint
            />
            <br />

            <StatusRow
              icon={
                progress.isError ? <StyledErrorIcon /> : <StyledSuccessIcon />
              }
              text={progress.message || t('all_fields_processed')}
              faint={false}
            />
          </React.Fragment>
        )}
      </DialogContent>
    </StyledDialog>
  )
}

JohnDeereImport.propTypes = {
  onDone: PropTypes.func.isRequired,
  orgId: PropTypes.string.isRequired,
  setRequestPending: PropTypes.func.isRequired
}

export default React.memo(JohnDeereImport)
