import { hot } from 'react-hot-loader/root'
import React, { Suspense } from 'react'
import { Router } from 'react-router-dom'
import { Provider as ReduxProvider } from 'react-redux'
import { ClientContext } from 'graphql-hooks'
import { ThemeProvider } from 'styled-components'
import DateFnsUtils from '@date-io/date-fns'
import MuiPickersUtilsProvider from '@nutrien/uet-react/MuiPickersUtilsProvider'
import {
  StylesProvider,
  ThemeProvider as MuiThemeProvider
} from '@nutrien/uet-react/styles'

import useFlags from 'hooks/useFlags'
import ResetScroll from 'components/ResetScroll'
import TopBannerProvider from 'components/TopBannerProvider'
import ScrollProvider from 'components/ScrollProvider/ScrollProvider'
import ChunkErrorBoundary from 'components/ChunkErrorBoundary'
import Intercom, { IntercomProvider } from 'components/Intercom'
import Loading from 'components/Loading'
import Auth from 'components/Auth'
import AuthorshipSessionProvider from 'components/AuthorshipSessionProvider'
import AcceptTermsAndConditions from 'components/AcceptTermsAndConditions'
import CookieWarning from 'components/CookieWarning'
import Authentication from 'components/auth-providers/AgribleBackend'
import theme from './theme'
import GlobalStyle from './theme/GlobalStyle'
import history from './history'
import GraphqlClient, { gqlHooksCache } from './graphql'
import HybridRoutesProvider from './components/HybridRoutesProvider/HybridRoutesProvider'
import AgribleRoutes from './routes'
import useSingleton from './hooks/useSingleton'

const App = ({ store }) => {
  const flags = useFlags()
  const graphqlClient = useSingleton(
    () => new GraphqlClient(flags.gqlCache ? { cache: gqlHooksCache } : {})
  )
  const authentication = useSingleton(() => new Authentication(graphqlClient))
  return (
    <ReduxProvider store={store}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ClientContext.Provider value={graphqlClient}>
          <StylesProvider injectFirst>
            <MuiThemeProvider theme={theme}>
              <ThemeProvider theme={theme}>
                <GlobalStyle />
                <Suspense fallback={<Loading />}>
                  <CookieWarning>
                    <Auth authentication={authentication}>
                      {({ user, isAuthenticated }) => (
                        <HybridRoutesProvider>
                          <AuthorshipSessionProvider>
                            <TopBannerProvider>
                              <ScrollProvider>
                                <IntercomProvider>
                                  <Intercom global />
                                  <Router history={history}>
                                    <ResetScroll />
                                    <ChunkErrorBoundary>
                                      <AgribleRoutes
                                        user={user}
                                        isAuthenticated={isAuthenticated}
                                      />
                                    </ChunkErrorBoundary>
                                    <AcceptTermsAndConditions />
                                  </Router>
                                </IntercomProvider>
                              </ScrollProvider>
                            </TopBannerProvider>
                          </AuthorshipSessionProvider>
                        </HybridRoutesProvider>
                      )}
                    </Auth>
                  </CookieWarning>
                </Suspense>
              </ThemeProvider>
            </MuiThemeProvider>
          </StylesProvider>
        </ClientContext.Provider>
      </MuiPickersUtilsProvider>
    </ReduxProvider>
  )
}

export default process.env.NODE_ENV === 'development' ? hot(App) : App
