import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'

const ExternalRedirectComponent = ({ replace, to }) => {
  useEffect(() => {
    if (replace) {
      window.location.replace(encodeURI(to))
    } else {
      window.location.href = encodeURI(to)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

ExternalRedirectComponent.propTypes = {
  replace: PropTypes.bool,
  to: PropTypes.string.isRequired
}

const ExternalRedirect = ({ from, replace, to, ...routeProps }) => {
  return (
    <Route
      {...routeProps}
      path={from}
      render={() => <ExternalRedirectComponent to={to} replace={replace} />}
    />
  )
}

ExternalRedirect.propTypes = {
  from: PropTypes.string.isRequired,
  replace: PropTypes.bool,
  to: PropTypes.string.isRequired
}

export default ExternalRedirect
