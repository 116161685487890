import React from 'react'
import PropTypes from 'prop-types'
import bonsaiTheme from '@nutrien/bonsai-theme/build'
import makeStyles from '@nutrien/uet-react/styles/makeStyles'
import Dialog from '@nutrien/uet-react/Dialog'
import CircularProgress from '@nutrien/uet-react/CircularProgress'
import Button from '@nutrien/uet-react/Button'

const dangerColor = bonsaiTheme.colors.alert.danger

const useStyles = makeStyles(theme => ({
  danagerButton: {
    color: 'white',
    backgroundColor: dangerColor,
    outlineColor: dangerColor,
    borderColor: dangerColor,
    '&:hover': {
      backgroundColor: bonsaiTheme.colors.red[700]
    }
  },
  dangerCancelButton: {
    color: dangerColor,
    outlineColor: dangerColor,
    borderColor: dangerColor
  },
  circularProgress: {
    color: 'white'
  }
}))
const BonsaiDialog = ({
  variant,
  confirmLabel,
  confirmVariant,
  cancelLabel,
  showLoading,
  isLoading,
  confirmButtonDisabled,
  cancelButtonDisabled,
  onConfirm,
  onCancel,
  content,
  ...props
}) => {
  const classes = useStyles()

  const isDanger = () => {
    if (variant === 'danger') return true
    return false
  }

  const confirmDisabled = isLoading || confirmButtonDisabled
  return (
    <Dialog
      {...props}
      actions={
        <>
          {onCancel && (
            <Button
              variant="outlined"
              disabled={cancelButtonDisabled}
              onClick={onCancel}
              data-testid="bonsai-dialog-cancel-button"
              className={isDanger() ? classes.dangerCancelButton : undefined}>
              {cancelLabel}
            </Button>
          )}
          <Button
            className={
              isDanger() && !confirmDisabled ? classes.danagerButton : undefined
            }
            variant={confirmVariant}
            color={isDanger() ? 'default' : 'primary'}
            onClick={onConfirm}
            disabled={confirmDisabled}
            startIcon={
              showLoading && isLoading ? (
                <CircularProgress
                  circleSize="small"
                  className={classes.circularProgress}
                />
              ) : null
            }
            data-testid="bonsai-dialog-confirm-button">
            {confirmLabel}
          </Button>
        </>
      }>
      {content}
    </Dialog>
  )
}

BonsaiDialog.propTypes = {
  variant: PropTypes.oneOf(['default', 'danger']),
  confirmLabel: PropTypes.string,
  confirmVariant: PropTypes.oneOf(['outlined', 'contained']),
  cancelLabel: PropTypes.string,
  showLoading: PropTypes.bool,
  isLoading: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  content: PropTypes.node.isRequired,
  confirmButtonDisabled: PropTypes.bool,
  cancelButtonDisabled: PropTypes.bool
}

BonsaiDialog.defaultProps = {
  variant: 'default',
  confirmLabel: 'Ok',
  confirmVariant: 'contained',
  cancelLabel: 'Cancel',
  showLoading: false,
  isLoading: false,
  onConfirm: undefined,
  onCancel: undefined,
  content: undefined
}
export default BonsaiDialog
