import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Dialog from '@nutrien/uet-react/Dialog'
import Button from '@nutrien/uet-react/Button'
import Typography from '@nutrien/uet-react/Typography'
import TextField from '@nutrien/uet-react/TextField'
import * as Yup from 'yup'
import { useMutation } from 'graphql-hooks'

import th from 'theme/helpers'
import useInput from 'hooks/useInput'
import { USER_OBJECT } from 'components/auth-providers/AgribleBackend/AgribleBackend'

const StyledDialog = styled(Dialog)`
  .MuiDialogContent-root {
    padding: ${th.spacing(0, 2)};
  }
`

const StyledTextField = styled(TextField)`
  margin-bottom: ${th.spacing(2)};

  .MuiFormHelperText-contained {
    margin: 0;
  }
`

const DialogContent = styled.div`
  border-top: 1px solid ${({ theme }) => theme.palette.divider};
  max-height: 300px;
  box-sizing: border-box;
  padding: ${th.spacing(1, 0, 2, 0)};
`

const USER_EMAIL_MUTATION = `
mutation EditUser(
  $email: String!
) {
  editUser(
    email: $email
  ) ${USER_OBJECT}
}
`

const ChangeEmailDialog = ({
  onClose,
  currentEmail,
  onSuccess,
  setRequestPending,
  setRequestError
}) => {
  const { t } = useTranslation()
  const [updateUserEmail] = useMutation(USER_EMAIL_MUTATION)
  const emailSchema = useMemo(
    () =>
      Yup.string()
        .email(t('email_error'))
        .label(t('email')),
    [t]
  )
  const email = useInput(currentEmail, emailSchema)

  const handleSubmit = useCallback(async () => {
    setRequestPending(true)
    const { data, error } = await updateUserEmail({
      variables: { email: email.value }
    })
    setRequestPending(false)

    if (error) return setRequestError(t('api_error_title'))

    if (data && data.editUser) onSuccess(data.editUser)
  }, [email, onSuccess, setRequestError, setRequestPending, t, updateUserEmail])

  return (
    <StyledDialog
      title={t('change_email')}
      open
      onClose={onClose}
      hasCloseIcon
      maxWidth="xs"
      closeIconSize="large"
      actions={
        <>
          <Button variant="outlined" onClick={onClose}>
            {t('cancel')}
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={!email.value || email.hasError}
            data-testid="submitBtn">
            {t('save')}
          </Button>
        </>
      }>
      <DialogContent>
        <Typography variant="body1">{t('change_email_msg')}</Typography>
        <br />
        <StyledTextField
          inputProps={{
            'data-testid': 'newEmail'
          }}
          label={t('email')}
          {...email.bind}
          autoFocus
        />
      </DialogContent>
    </StyledDialog>
  )
}

ChangeEmailDialog.propTypes = {
  currentEmail: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  setRequestPending: PropTypes.func.isRequired,
  setRequestError: PropTypes.func.isRequired
}

export default ChangeEmailDialog
