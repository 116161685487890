import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Dialog from '@nutrien/uet-react/Dialog'
import Button from '@nutrien/uet-react/Button'
import Typography from '@nutrien/uet-react/Typography'
import TextField from '@nutrien/uet-react/TextField'
import Checkbox from '@nutrien/uet-react/Checkbox'
import FormControlLabel from '@nutrien/uet-react/FormControlLabel'
import * as Yup from 'yup'
import { useMutation } from 'graphql-hooks'

import th from 'theme/helpers'
import useInput from 'hooks/useInput'
import useToggle from 'hooks/useToggle'

const StyledDialog = styled(Dialog)`
  .MuiDialogContent-root {
    padding: ${th.spacing(0, 2)};
  }
`

const StyledTextField = styled(TextField)`
  margin-bottom: ${th.spacing(2)};

  .MuiFormHelperText-contained {
    margin: 0;
  }
`

const DialogContent = styled.div`
  border-top: 1px solid ${({ theme }) => theme.palette.divider};
  max-height: 300px;
  box-sizing: border-box;
  padding: ${th.spacing(1, 0, 2, 0)};
`

const allowEmpty = (cv, ov) => (ov === '' ? undefined : cv)

const CHANGE_PASSWORD_MUTATION = `
mutation ChangePassword(
  $password: String!
) {
  changePassword(value: $password)
}
`

const ChangePasswordDialog = ({
  onClose,
  isSSOUser,
  resetSSOPassword,
  setRequestPending,
  setRequestError,
  setResetSSOPasswordSent
}) => {
  const [showPass, toggleShowPass] = useToggle(false)
  const { t } = useTranslation()
  const [changePassword] = useMutation(CHANGE_PASSWORD_MUTATION)

  const passwordSchema = useMemo(
    () =>
      Yup.string()
        .min(6, t('password1_error'))
        .transform(allowEmpty),
    [t]
  )
  const password = useInput('', passwordSchema)

  const confirmPasswordSchema = useMemo(
    () => Yup.string().oneOf([password.value, ''], t('password2_error')),
    [password.value, t]
  )
  const confirmPassword = useInput('', confirmPasswordSchema)

  const handleSave = useCallback(async () => {
    setRequestPending(true)
    let error = ''

    if (isSSOUser) {
      await resetSSOPassword()
        .then(res => {
          if (!res.ok) throw Error('an_unexpected_error_occurred')
          setResetSSOPasswordSent(true)
        })
        .catch(e => (error = e))
    } else {
      const { error: localStoreError } = await changePassword({
        variables: {
          password: password.value
        }
      })
      error = localStoreError
    }

    setRequestPending(false)

    if (error) return setRequestError(t('api_error_title'))

    onClose()
  }, [
    isSSOUser,
    resetSSOPassword,
    setResetSSOPasswordSent,
    changePassword,
    onClose,
    password.value,
    setRequestError,
    setRequestPending,
    t
  ])

  const canSave =
    isSSOUser ||
    (password.value &&
      confirmPassword.value &&
      !password.hasError &&
      !confirmPassword.hasError)

  return (
    <StyledDialog
      title={t('change_password')}
      open
      onClose={onClose}
      hasCloseIcon
      maxWidth="xs"
      closeIconSize="large"
      actions={
        <Button
          variant="contained"
          onClick={handleSave}
          disabled={!canSave}
          data-testid="submitBtn">
          {isSSOUser ? t('send') : t('change_password')}
        </Button>
      }>
      <DialogContent>
        <Typography variant="body1">
          {isSSOUser
            ? t('reset_sso_password_message')
            : t('change_password_form_text')}
        </Typography>
        {!isSSOUser && (
          <>
            <br />
            <StyledTextField
              autoFocus
              autoComplete="new-password"
              name="new-password"
              type={showPass ? 'text' : 'password'}
              label={t('new_password')}
              inputProps={{ 'data-testid': 'newPass' }}
              {...password.bind}
            />
            <StyledTextField
              type={showPass ? 'text' : 'password'}
              label={t('confirm_password')}
              inputProps={{ 'data-testid': 'confPass' }}
              {...confirmPassword.bind}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={showPass}
                  onChange={toggleShowPass}
                  color="primary"
                />
              }
              label={t('show_password')}
            />
          </>
        )}
      </DialogContent>
    </StyledDialog>
  )
}

ChangePasswordDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  setRequestPending: PropTypes.func.isRequired,
  setRequestError: PropTypes.func.isRequired
}

export default ChangePasswordDialog
