import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import LinkTab from '../LinkTab'

const HomeTabs = ({ onTabChange, scrolledToTab }) => {
  const { t } = useTranslation()
  const isSelected = id => scrolledToTab === id

  return (
    <>
      <LinkTab
        id="home"
        data-testid="link-tab-home"
        to="/#intro_header"
        isSelected={isSelected('home')}
        onClick={onTabChange}>
        {t('home')}
      </LinkTab>

      <LinkTab
        id="agsupplychain"
        data-testid="link-tab-agsupplychain"
        to="/#agsupplychain"
        isSelected={isSelected('agsupplychain')}
        onClick={onTabChange}>
        {t('ag_supply_chain')}
      </LinkTab>

      <LinkTab
        id="sustainability"
        data-testid="link-tab-sustainability"
        to="/#sustainabilityngo"
        isSelected={isSelected('sustainability')}
        onClick={onTabChange}>
        {t('sustainability')}
      </LinkTab>

      <LinkTab
        id="growers"
        data-testid="link-tab-growers"
        to="/#growers"
        isSelected={isSelected('growers')}
        onClick={onTabChange}>
        {t('growers')}
      </LinkTab>

      <LinkTab
        id="about"
        data-testid="link-tab-about"
        to="https://www.nutrienagsolutions.com/agrible"
        external
        isSelected={isSelected('about')}
        onClick={onTabChange}>
        {t('about')}
      </LinkTab>
    </>
  )
}

HomeTabs.propTypes = {
  onTabChange: PropTypes.func,
  scrolledToTab: PropTypes.string
}

export default HomeTabs
