import { useRef, useCallback, useContext } from 'react'
import { useManualQuery } from 'graphql-hooks'
import { useDispatch, useSelector } from 'react-redux'
import _get from 'lodash/get'
import { receiveEntitiesSuccess as loadEntitiesSuccess } from '@nutrien/farm-tree-navigation/dist/redux/actions/actions'

import { ENTITY_TYPES } from 'const'
import { selectFarmTree } from 'store/selectors/farmTree'
import { AuthContext } from 'components/Auth/authentication-context'
import {
  processTree,
  reloadEntitiesSuccess,
  setFarmTreeIsLoading
} from 'store/actions/FarmTree'

const NAVIGATION_TREE_QUERY = `
  query NavigationTree($userId: String!) {
    getNavigationTree(userId: $userId) {
      type
      uuid
      name
      extent
      parentUUID
      canDelete
      permissions
      ... on Container {
        isOwner
        sharedBy { uuid name }
        childrenUUIDs
        country
        owner { uuid name }
      }
      ... on Field {
        acreage
        enrolledAcres
        county
        poly
        centroid { type coordinates }
        canDelete
      }
    }
  }
`

export default () => {
  const actionFn = useRef(loadEntitiesSuccess)
  const dispatch = useDispatch()
  const { user } = useContext(AuthContext)
  const farmTree = useSelector(selectFarmTree)

  const [getNavigationTree, status] = useManualQuery(NAVIGATION_TREE_QUERY, {
    useCache: false
  })

  const load = useCallback(async () => {
    /*
     * there's a chance that the user is undefined
     * since this hook is being called in the nav
     * and it might be in the logged out state.
     */
    if (!user?.id) return

    dispatch(setFarmTreeIsLoading())
    const resp = await getNavigationTree({
      variables: { userId: user?.id },
      useCache: false
    })
    const data = _get(resp, 'data.getNavigationTree')

    if (data) {
      const newFarmTree = processTree(data)

      const allFarms = []
      for (const entity in newFarmTree) {
        if (newFarmTree[entity].type === ENTITY_TYPES.FARMS) {
          const { type, uuid, name, parentUUID } = newFarmTree[entity]
          allFarms.push({ type, uuid, name, parentUUID })
        }
      }

      dispatch(actionFn.current({ newFarmTree, allFarms }))
      actionFn.current = reloadEntitiesSuccess // only reload this point forward
    }

    return resp
  }, [dispatch, getNavigationTree, user])

  return [farmTree, load, status]
}
