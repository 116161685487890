import resolveEnv, { DEV, SIT } from 'config/resolveEnv'

const LD_FLAG_KEY = 'agrible-flag-override'

export const getOverrides = () => {
  const data = localStorage.getItem(LD_FLAG_KEY)

  return data ? JSON.parse(data) : {}
}

export const setOverrides = overrideFlags =>
  localStorage.setItem(LD_FLAG_KEY, JSON.stringify(overrideFlags))

const env = resolveEnv()
export const isOverridable = [DEV, SIT].includes(env)
