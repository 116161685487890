import { useContext } from 'react'

import { AuthContext } from 'components/Auth/authentication-context'

/**
 * @returns {object} list of community dashboard access
 */

const useHasCommunityDashboardAccess = () => {
  const { user } = useContext(AuthContext)
  return user?.communityDashboardAccess
}

export default useHasCommunityDashboardAccess
