import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import Backdrop from '@nutrien/uet-react/Backdrop'
import CircularProgress from '@nutrien/uet-react/CircularProgress'
import Snackbar from '@nutrien/uet-react/Snackbar'
import Portal from '@nutrien/uet-react/Portal'

const LightBackdrop = styled(Backdrop)`
  z-index: 10000;
  background-color: rgba(255, 255, 255, 0.75);
`

const withRequest = Component => props => {
  const [isRequestPending, setRequestPending] = useState(false)
  const [requestError, setRequestError] = useState(null)
  const dismissError = useCallback(() => setRequestError(null), [
    setRequestError
  ])
  const handleError = useCallback(
    error => {
      setRequestError(error)
      setRequestPending(false)
    },
    [setRequestError, setRequestPending]
  )

  return (
    <React.Fragment>
      <Portal>
        <LightBackdrop open={isRequestPending}>
          <CircularProgress size={50} />
        </LightBackdrop>
        <Snackbar
          open={!!requestError}
          hasCloseIcon
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          style={{ zIndex: 10000 }}
          message={requestError}
          onClose={dismissError}
        />
      </Portal>
      <Component
        setRequestPending={setRequestPending}
        setRequestError={handleError}
        {...props}
      />
    </React.Fragment>
  )
}

export default withRequest
