import React, { createContext, useState } from 'react'
import PropTypes from 'prop-types'

export const AuthorshipSessionContext = createContext({})

export const AuthorshipSessionProvider = ({ children }) => {
  const isAuthorshipSessionActive = sessionStorage.getItem('authorshipGrower')

  // this is the main state that will determine if the
  // on behalf session is active  or not.
  const [authorshipSessionActive, setAuthorshipSessionActive] = useState(
    !!isAuthorshipSessionActive
  )
  return (
    <AuthorshipSessionContext.Provider
      value={{
        authorshipSessionActive,
        setAuthorshipSessionActive
      }}>
      {children}
    </AuthorshipSessionContext.Provider>
  )
}

AuthorshipSessionProvider.propTypes = {
  children: PropTypes.object.isRequired
}

export default AuthorshipSessionProvider
