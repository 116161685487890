import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  html {
    /* Adjust font size */
    font-size: 100%;
    -webkit-text-size-adjust: 100%;
    /* Font varient */
    font-variant-ligatures: none;
    -webkit-font-variant-ligatures: none;
    /* Smoothing */
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
  }

  html, body {
    height: 100%;
    margin: 0;
    padding: 0;
  }

  #agrible-root {
    min-height: 100%;
    height: 100%;
  }

  * {
    scroll-behavior: smooth;
  }

  a, a:hover {
    text-decoration: none;
  }

  h1, h2, h3, h4, h5, h6 {
    color: inherit;
  }
`

export default GlobalStyle
