import { createMuiTheme } from '@nutrien/uet-react/styles'
import uetTheme from '@nutrien/uet-theme'

import { cardColors } from './cardColors'
import donutColors from './donutColors'

const customTheme = {
  palette: {
    cardColors,
    donutColors
  },
  overrides: {
    MuiInputBase: {
      root: {
        height: '40px'
      }
    },
    MuiButton: {
      containedSizeLarge: {
        height: '40px'
      }
    },
    MuiFormHelperText: {
      root: {
        marginLeft: 0
      },
      contained: {
        marginLeft: 0
      }
    }
  },
  props: {
    MuiModal: {
      disableEnforceFocus: true
    }
  }
}

export default createMuiTheme(uetTheme, customTheme)
