import React from 'react'
import { useTranslation } from 'react-i18next'
import Typography from '@nutrien/uet-react/Typography'
import propTypes from 'propTypes'
import BonsaiDialog from 'components/Bonsai/BonsaiDialog'

const ConfirmExitGrowerSession = ({ open, handleExitSession, closeDialog }) => {
  const { t } = useTranslation()

  return (
    <BonsaiDialog
      title={t('Still there')}
      open={open}
      data-testid="confirm-exit-grower-session-dialog"
      content={
        <Typography variant="subtitle1">
          {t('Confirm_Leave_Grower_Dashboard')}
        </Typography>
      }
      confirmLabel={t('Stay on Grower Dashboard')}
      confirmVariant="contained"
      cancelLabel={t('Leave')}
      onCancel={handleExitSession}
      onConfirm={closeDialog}
    />
  )
}
ConfirmExitGrowerSession.propTypes = {
  open: propTypes.bool,
  closeDialog: propTypes.func,
  handleExitSession: propTypes.func
}
export default ConfirmExitGrowerSession
