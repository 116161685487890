import React, { useState, useCallback, useEffect } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import AppBar from '@nutrien/uet-react/AppBar'
import Toolbar from '@nutrien/uet-react/Toolbar'
import Box from '@nutrien/uet-react/Box'
import Hidden from '@nutrien/uet-react/Hidden'
import Grid from '@nutrien/uet-react/Grid'
import Typography from '@nutrien/uet-react/Typography'
import IconButton from '@nutrien/uet-react/IconButton'
import Menu from '@nutrien/uet-react/icons/Menu'
import Drawer from '@nutrien/uet-react/Drawer'
import makeStyles from '@nutrien/uet-react/styles/makeStyles'
import isEmpty from 'lodash/isEmpty'
import { useSelector } from 'react-redux'
import useFlags from 'hooks/useFlags'
import BannerMaintenance from 'components/BannerMaintenance'
import th from 'theme/helpers'
import useLoadFarmTree from 'hooks/useLoadFarmTree'
import useDashboardLink from 'hooks/useDashboardLink'
import { AgribleLogomark } from 'components/Icons'
import AuthPanel from 'components/AuthPanel'
import { AuthConsumer } from 'components/Auth/authentication-context'

import GrowerSessionBanner from 'components/GrowerSessionBanner'
import { selectFarmTreeLoading } from 'store/selectors/farmTree'
import LinkedLogo from '../LinkedLogo'
import WizardHeader from '../WizardHeader'
import Tabs from './components/Tabs'

const StyledAccountAppBar = styled(({ children, ...other }) => (
  <AppBar component={Box} color="inherit" elevation={0} {...other}>
    {children}
  </AppBar>
))`
  border-bottom: solid 1px ${th.palette('grey.200')};
  position: relative;
`

const StyledIcon = styled(AgribleLogomark)`
  width: 32px;
  height: 32px;
`

const StyledCommunityAppBar = styled(({ children, ...other }) => (
  <AppBar component={Box} color="inherit" elevation={0} {...other}>
    {children}
  </AppBar>
))`
  border-bottom: solid 1px ${th.palette('grey.200')};
`

const StyledMarketingAppBar = styled(({ children, ...other }) => (
  <AppBar component={Box} color="inherit" {...other}>
    {children}
  </AppBar>
))``

const IconWrapper = styled(Grid)`
  margin-left: ${th.spacing(-1.5)};
`

const useStyles = makeStyles(() => ({
  toolbarRoot: {
    minHeight: '64px'
  }
}))

const LinkHeader = ({
  tabType,
  scrolledToTab,
  pageTitle,
  loadFarmTree,
  position
}) => {
  const [farmTree, loadDefaultFarmTree] = useLoadFarmTree()

  const loadFarmTreeSwitch = loadFarmTree || loadDefaultFarmTree
  const farmTreeLoading = useSelector(selectFarmTreeLoading)
  useEffect(() => {
    if (isEmpty(farmTree) && !farmTreeLoading) {
      loadFarmTreeSwitch()
    }
  }, [farmTree, farmTreeLoading, loadFarmTreeSwitch])

  const classes = useStyles()
  const flags = useFlags()
  const { t } = useTranslation()
  const [isOpen, setOpen] = useState(false)
  const burgerMenu = {
    handleExpanded: useCallback(() => setOpen(true), []),
    handleDismissed: useCallback(() => setOpen(false), []),
    handleItemPressed: useCallback(() => setOpen(false), [])
  }
  const dashboardLink = useDashboardLink()

  const StyledAppBar =
    tabType === 'account'
      ? StyledAccountAppBar
      : tabType === 'community'
      ? StyledCommunityAppBar
      : StyledMarketingAppBar

  return (
    <AuthConsumer>
      {({ isAuthenticated }) => (
        <React.Fragment>
          <Hidden mdUp>
            {/* The display: 'block' is necessary for iPhone safari. */}
            <Drawer
              anchor="bottom"
              open={isOpen}
              transitionDuration={500}
              PaperProps={{ style: { display: 'block', minHeight: '100%' } }}>
              <WizardHeader
                title={t('menu')}
                displayLogo={false}
                handleClose={burgerMenu.handleDismissed}
                boxShadow={0}
              />
              {flags?.maintBannerAndText?.maintenanceActive && (
                <BannerMaintenance
                  message={flags?.maintBannerAndText?.message}
                />
              )}
              <Tabs
                orientation="vertical"
                handleTabChange={burgerMenu.handleItemPressed}
                tabType={tabType}
                scrolledToTab={scrolledToTab}
              />
              {!isAuthenticated && (
                <Box my={3} mx={4}>
                  <AuthPanel />
                </Box>
              )}
            </Drawer>
          </Hidden>
          <StyledAppBar position={position}>
            {flags.enableAuthorshipOfData && <GrowerSessionBanner />}

            {flags?.maintBannerAndText?.maintenanceActive && (
              <BannerMaintenance message={flags?.maintBannerAndText?.message} />
            )}
            <Toolbar
              classes={{ root: classes.toolbarRoot }}
              component={Box}
              justifyContent="space-between">
              <Hidden mdUp>
                <Grid container alignItems="center">
                  <Grid item>
                    <Box
                      display="flex"
                      justify="center"
                      alignItems="center"
                      height={48}
                      mr={1}
                      ml={-1.5}
                      pr={1}
                      color="grey.200"
                      borderRight={1}>
                      <Link
                        to={
                          flags.homePageRedirect && !isAuthenticated
                            ? '/'
                            : dashboardLink
                        }>
                        <StyledIcon data-testid="new-logoMark" />
                      </Link>
                    </Box>
                  </Grid>
                  <Grid item>
                    <IconWrapper item>
                      <IconButton
                        onClick={burgerMenu.handleExpanded}
                        aria-label="menu">
                        <Menu fontSize="large" />
                      </IconButton>
                    </IconWrapper>
                  </Grid>
                  <Grid item>
                    <Box color="grey.600">
                      <Typography
                        onClick={burgerMenu.handleExpanded}
                        variant="h5">
                        {t('menu').toUpperCase()}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs />
                  {isAuthenticated ? (
                    <Grid item>
                      <AuthPanel />
                    </Grid>
                  ) : (
                    <Grid item />
                  )}
                </Grid>
              </Hidden>
              <Hidden smDown>
                <LinkedLogo data-testid="new-logo" />
                {pageTitle || (
                  <Tabs tabType={tabType} scrolledToTab={scrolledToTab} />
                )}
                <AuthPanel />
              </Hidden>
            </Toolbar>

            {!isAuthenticated && (
              <Hidden mdUp>
                <Toolbar classes={{ root: classes.toolbarRoot }}>
                  <Grid
                    container
                    alignItems="center"
                    style={{ justifyContent: 'center' }}
                    smDown>
                    <AuthPanel />
                  </Grid>
                </Toolbar>
              </Hidden>
            )}
          </StyledAppBar>
        </React.Fragment>
      )}
    </AuthConsumer>
  )
}

LinkHeader.propTypes = {
  tabType: PropTypes.string.isRequired,
  pageTitle: PropTypes.node,
  loadFarmTree: PropTypes.func,
  scrolledToTab: PropTypes.any,
  position: PropTypes.string
}

LinkHeader.defaultProps = {
  pageTitle: undefined
}

export default LinkHeader
