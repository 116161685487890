import { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Feature } from 'ol'
import { MultiPolygon } from 'ol/geom'
import VectorSource from 'ol/source/Vector'
import VectorLayer from 'ol/layer/Vector'
import Text from 'ol/style/Text'
import Fill from 'ol/style/Fill'
import Stroke from 'ol/style/Stroke'
import {
  MAP_PROPTYPE,
  fieldPolygonStyle as fieldPolygonStyleGeospatial
} from '@nutrien/geospatial'
import useTheme from '@nutrien/uet-react/styles/useTheme'

import { geometryTo3857 } from 'helpers/mapHelpers'

const defaultStyle = {
  text: '#FFFFFF', // TODO: May be try giving instances instead
  textStroke: [0, 0, 0, 0.6],
  fill: '#DDDDDD',
  stroke: '#FFF',
  width: 3
}

const fieldPolygonStyle = theme => feature => {
  const { label, style: fieldStyle } = feature.getProperties()
  const polygonStyle = fieldPolygonStyleGeospatial(feature)[0]
  const style = { ...defaultStyle, ...fieldStyle }

  polygonStyle.setText(
    new Text({
      text: label,
      overflow: true,
      font: `15px ${theme.typography.fontFamily}`,
      fill: new Fill({ color: style.text }),
      stroke: new Stroke({ color: defaultStyle.textStroke, width: 6 })
    })
  )
  polygonStyle.setFill(new Fill({ color: style.fill }))
  polygonStyle.setStroke(
    new Stroke({ color: style.stroke, width: style.width })
  )

  return [polygonStyle]
}

export const formatFeatures = fields =>
  fields.map(field => {
    return new Feature({
      id: field.id,
      label: field.label,
      style: field.style,
      geometry: geometryTo3857(new MultiPolygon(field.poly.coordinates))
    })
  })

const FieldsLayerPolygon = props => {
  const { map, fields, onClick, maxResolution, minResolution } = props
  const theme = useTheme()
  const fieldPolygonTileLayer = useRef(null)
  const vectorTilePolygonSource = useRef(null)
  const onClickRef = useRef(onClick)

  useEffect(() => {
    onClickRef.current = onClick
  }, [onClick])

  useEffect(() => {
    map.removeLayer(fieldPolygonTileLayer.current)

    vectorTilePolygonSource.current = new VectorSource({
      features: formatFeatures(fields)
    })

    fieldPolygonTileLayer.current = new VectorLayer({
      source: vectorTilePolygonSource.current,
      maxResolution,
      minResolution,
      style: fieldPolygonStyle(theme)
    })

    map.addLayer(fieldPolygonTileLayer.current)
  }, [map, fields, maxResolution, theme]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    map.on('click', e => {
      const pixel = map.getEventPixel(e.originalEvent)
      const clickedFields = map.getFeaturesAtPixel(pixel, {
        layerFilter: layer => layer === fieldPolygonTileLayer.current
      })

      if (clickedFields.length) {
        onClickRef.current(clickedFields)
      }
    })
    // No point executing any time after initial load
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return null
}

FieldsLayerPolygon.propTypes = {
  map: PropTypes.shape(MAP_PROPTYPE).isRequired,
  fields: PropTypes.array,
  onClick: PropTypes.func,
  maxResolution: PropTypes.number
}

FieldsLayerPolygon.defaultProps = {
  fields: [],
  onClick: () => {},
  maxResolution: 10,
  minResolution: 0
}

export default FieldsLayerPolygon
