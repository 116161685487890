import {
  BARLEY_QUALITY_ENGINE_PRODUCT,
  CRITICAL_PERIODS_PRODUCT,
  NUTRIENT_ENGINE_PRODUCT,
  SPRAY_SMART_PRODUCT,
  TRACTOR_TIME_HOURLY_PRODUCT,
  TRACTOR_TIME_PRODUCT,
  YIELD_ENGINE_PRODUCT
} from 'helpers/products'

import useCanAccessProduct from '../useCanAccessProduct'

/**
 * returns an object with the list of agronomy products
 * and whether the current user have access to those products.
 *
 * Current agronomy products:
 *
 * BARLEY_QUALITY_ENGINE_PRODUCT,
 * CRITICAL_PERIODS_PRODUCT,
 * NUTRIENT_ENGINE_PRODUCT,
 * SPRAY_SMART_PRODUCT,
 * TRACTOR_TIME_HOURLY_PRODUCT,
 * TRACTOR_TIME_PRODUCT,
 * YIELD_ENGINE_PRODUCT
 *
 * @returns {object}
 */

const useAgronomyAccessPermissions = () => {
  const bqe = useCanAccessProduct(BARLEY_QUALITY_ENGINE_PRODUCT)
  const criticalPeriods = useCanAccessProduct(CRITICAL_PERIODS_PRODUCT)
  const nutrientEngine = useCanAccessProduct(NUTRIENT_ENGINE_PRODUCT)
  const spraySmart = useCanAccessProduct(SPRAY_SMART_PRODUCT)
  const tractorTime = useCanAccessProduct(TRACTOR_TIME_PRODUCT)
  const tractorTimeHourly = useCanAccessProduct(TRACTOR_TIME_HOURLY_PRODUCT)
  const yieldEngine = useCanAccessProduct(YIELD_ENGINE_PRODUCT)

  return {
    bqe,
    criticalPeriods,
    nutrientEngine,
    spraySmart,
    tractorTime,
    tractorTimeHourly,
    yieldEngine
  }
}

export default useAgronomyAccessPermissions
