export const calculateDryYield = (
  wetYield,
  harvestMoisture,
  moistureAfterDrying,
  postHavestLoss
) => {
  if (
    !!wetYield &&
    !!harvestMoisture &&
    !!moistureAfterDrying &&
    moistureAfterDrying !== 100
  ) {
    return Math.round(
      wetYield * ((100 - harvestMoisture) / (100 - moistureAfterDrying)) -
        postHavestLoss
    )
  } else {
    return 0
  }
}
