const AGRONOMY = 'agronomy'
const BARLEY_QUALITY_ENGINE = 'barley-quality-engine'
const CRITICAL_PERIODS = 'critical-periods'
const FIELD_FORECAST = 'field-forecast'
const FIELD_STORY = 'field-story'
const HAIL_ALERT = 'hail-alert'
const MARKET = 'market'
const NUTRIEN_ENGINE = 'nutrien-engine'
const SPRAY_SMART = 'spray-smart'
const SUSTAINABILITY_SOURCING = 'sustainability-sourcing'
const TRACTOR_TIME = 'tractor-time'
const WEATHER_STORY = 'weather-story'
const YIELD = 'yield'

export const PRODUCTS = Object.freeze({
  AGRONOMY,
  BARLEY_QUALITY_ENGINE,
  CRITICAL_PERIODS,
  FIELD_FORECAST,
  FIELD_STORY,
  HAIL_ALERT,
  MARKET,
  NUTRIEN_ENGINE,
  SPRAY_SMART,
  SUSTAINABILITY_SOURCING,
  TRACTOR_TIME,
  WEATHER_STORY,
  YIELD
})
