import useCanAccessProduct from 'hooks/useCanAccessProduct'
import { FIELD_STORY_PRODUCT } from 'helpers/products'

/**
 * @returns {boolean} true when current user has access to field-story products
 */
const useCanAccessFieldStoryProducts = () => {
  const canViewFieldStoryProduct = useCanAccessProduct(FIELD_STORY_PRODUCT)
  return canViewFieldStoryProduct
}
export default useCanAccessFieldStoryProducts
