import { combineReducers } from 'redux'
import { geospatialRootReducer as geospatial } from '@nutrien/geospatial'
import { WeatherStoryReducer as weatherStory } from '@nutrien/weather-story'

import farmTree from './farmTree'

const rootReducer = combineReducers({
  farmTree,
  geospatial,
  weatherStory
})

export default rootReducer
