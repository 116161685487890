import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Box from '@nutrien/uet-react/Box'
import Button from '@nutrien/uet-react/Button'
import IconButton from '@nutrien/uet-react/IconButton'
import CloseIcon from '@nutrien/uet-react/icons/Close'
import BackIcon from '@nutrien/uet-react/icons/ArrowBackIos'
import Typography from '@nutrien/uet-react/Typography'
import Drawer from '@nutrien/uet-react/Drawer'
import { DRAWER_TRANSITION_DURATION } from 'const'

export const BonsaiDrawerContent = ({
  children,
  title,
  subtitle,
  onClose,
  buttonType,
  rightHeaderButtonLabel,
  onRightButtonClick,
  footerButtonLabel,
  onFooterButtonClick,
  isFooterButtonDisabled,
  scrollContainerRef,
  enableBackBtn,
  onBackButtonClick,
  enableFooterBackBtn,
  singleLineHeader,
  onFooterBackButtonClick,
  width = '573px'
}) => {
  const { t } = useTranslation()
  const Header = (
    <Box
      alignItems="flex-end"
      bgcolor="brand.denim"
      boxSizing="border-box"
      color="grey.0"
      display="flex"
      flex="0 88px"
      px={1}
      pt={1}
      pb={singleLineHeader ? 2 : 1}
      position="relative">
      <Box flex="1 1 0%">
        {enableBackBtn ? (
          <Box mb={singleLineHeader ? -1.5 : 0}>
            <IconButton
              color="inherit"
              data-testid="bonsai-drawer-back-button"
              onClick={onBackButtonClick || onClose}>
              <BackIcon />
            </IconButton>
          </Box>
        ) : (
          <Box mb={singleLineHeader ? -1.5 : 0}>
            <IconButton
              color="inherit"
              data-testid="bonsai-drawer-close-button"
              onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        )}
      </Box>
      <Box flex="3 1 0%" textAlign="center">
        <Typography style={{ textTransform: 'capitalize' }} variant="h4">
          {title}
        </Typography>
        <Typography variant="caption">{subtitle}</Typography>
      </Box>
      <Box flex="1 1 0%">
        {!!rightHeaderButtonLabel && (
          <Button
            color="inherit"
            data-testid="bonsai-drawer-right-header-button"
            onClick={onRightButtonClick}
            type={buttonType}
            variant="text">
            <Typography variant="h5">{rightHeaderButtonLabel}</Typography>
          </Button>
        )}
      </Box>
    </Box>
  )

  const footerButton = (
    <Box bgcolor="theme.palette.common.white" display="flex" flex={0} p={2}>
      {enableFooterBackBtn && (
        <Box mr={1} width="100%">
          <Button
            color="primary"
            data-testid="bonsai-drawer-footer-back-button"
            disableRipple
            fullWidth
            onClick={onFooterBackButtonClick}
            size="large"
            variant="outlined">
            {t('back')}
          </Button>
        </Box>
      )}

      <Button
        color="primary"
        data-testid="bonsai-drawer-footer-button"
        disableRipple
        disabled={isFooterButtonDisabled}
        fullWidth
        onClick={onFooterButtonClick}
        size="large"
        variant="contained">
        {footerButtonLabel}
      </Button>
    </Box>
  )

  return (
    <>
      <Box
        display="flex"
        flex={1}
        flexDirection="column"
        overflow="hidden"
        // eslint-disable-next-line
        width={{ xs: '100vw', md: width }}>
        {Header}
        <Box
          bgcolor="grey.50"
          boxSizing="border-box"
          display="flex"
          flex={1}
          overflow="auto"
          position="relative"
          ref={scrollContainerRef}>
          <Box display="flex" flex={1} flexDirection="column" overflow="auto">
            {children}
          </Box>
        </Box>
        {!!footerButtonLabel && footerButton}
      </Box>
    </>
  )
}

const BonsaiDrawer = ({
  children,
  title,
  subtitle,
  onClose,
  buttonType,
  rightHeaderButtonLabel,
  onRightButtonClick,
  footerButtonLabel,
  onFooterButtonClick,
  isFooterButtonDisabled,
  scrollContainerRef,
  enableBackBtn,
  onBackButtonClick,
  enableFooterBackBtn,
  onFooterBackButtonClick,
  width,
  singleLineHeader = false,
  closeButtonLabel,
  nestedDrawer,
  ModalProps,
  ...props
}) => (
  <Drawer
    anchor="right"
    data-testid="bonsai-drawer-wrapper"
    transitionDuration={DRAWER_TRANSITION_DURATION}
    // Hides the backdrop if the drawer is nested
    ModalProps={{ hideBackdrop: nestedDrawer, ...ModalProps }}
    {...props}>
    <BonsaiDrawerContent
      buttonType={buttonType}
      enableBackBtn={enableBackBtn}
      enableFooterBackBtn={enableFooterBackBtn}
      footerButtonLabel={footerButtonLabel}
      isFooterButtonDisabled={isFooterButtonDisabled}
      onBackButtonClick={onBackButtonClick}
      onClose={onClose}
      singleLineHeader={singleLineHeader}
      onFooterBackButtonClick={onFooterBackButtonClick}
      onFooterButtonClick={onFooterButtonClick}
      onRightButtonClick={onRightButtonClick}
      rightHeaderButtonLabel={rightHeaderButtonLabel}
      scrollContainerRef={scrollContainerRef}
      subtitle={subtitle}
      title={title}
      width={width}>
      {children}
    </BonsaiDrawerContent>
  </Drawer>
)

BonsaiDrawer.propTypes = {
  ...Drawer.propTypes,
  buttonType: PropTypes.string,
  children: PropTypes.node.isRequired,
  closeButtonLabel: PropTypes.string,
  enableBackBtn: PropTypes.bool,
  enableFooterBackBtn: PropTypes.bool,
  footerButtonLabel: PropTypes.node,
  isFooterButtonDisabled: PropTypes.bool,
  nestedDrawer: PropTypes.bool,
  onBackButtonClick: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  onFooterBackButtonClick: PropTypes.func,
  onFooterButtonClick: PropTypes.func,
  onRightButtonClick: PropTypes.func,
  rightHeaderButtonLabel: PropTypes.string,
  scrollContainerRef: PropTypes.any,
  singleLineHeader: PropTypes.bool,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  width: PropTypes.any
}

BonsaiDrawerContent.propTypes = {
  buttonType: PropTypes.string,
  children: PropTypes.node.isRequired,
  closeButtonLabel: PropTypes.string,
  enableBackBtn: PropTypes.bool,
  enableFooterBackBtn: PropTypes.bool,
  footerButtonLabel: PropTypes.node,
  isFooterButtonDisabled: PropTypes.bool,
  onBackButtonClick: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  onFooterBackButtonClick: PropTypes.func,
  onFooterButtonClick: PropTypes.func,
  onRightButtonClick: PropTypes.func,
  rightHeaderButtonLabel: PropTypes.string,
  scrollContainerRef: PropTypes.any,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  width: PropTypes.any
}

BonsaiDrawerContent.defaultProps = {
  enableBackBtn: false,
  isFooterButtonDisabled: false
}

export default BonsaiDrawer
