import React from 'react'
import PropTypes from 'prop-types'
import makeStyles from '@nutrien/uet-react/styles/makeStyles'
import Button from '@nutrien/uet-react/Button'

const useStyles = makeStyles(theme => ({
  largeButton: {
    fontSize: '16px',
    fontWeight: '600',
    padding: theme.spacing(1, 2, 1, 2),
    height: theme.spacing(4)
  },
  smallButton: {
    padding: theme.spacing(0, 2, 0, 2)
  }
}))

const BonsaiButton = ({ text, size, ...props }) => {
  const classes = useStyles()
  /// Need to add props and styling for: solid, outline, clear; large, fullwidth... etc. See Bonsai docs for options.
  return (
    <Button
      className={size === 'small' ? classes.smallButton : classes.largeButton}
      {...props}>
      {text}
    </Button>
  )
}
// TODO: Add proptypes to this and all other components
BonsaiButton.propTypes = {
  text: PropTypes.string.isRequired,
  size: PropTypes.string
}

BonsaiButton.defaultProps = {
  text: 'Button',
  size: 'large'
}

export default BonsaiButton
