const images = [
  {
    names: [
      'Field_Corn_NP',
      'Cotton_NP',
      'Soybeans_NP',
      'Peanuts_NP',
      'Wheat_NP',
      'Barley_NP'
    ],
    image: 'Not_Planted.png'
  },
  {
    names: [
      'Field_Corn_NA',
      'Cotton_NA',
      'Soybeans_NA',
      'Peanuts_NA',
      'Wheat_NA',
      'Barley_NA'
    ],
    image: 'Generic_Crop.png'
  },
  {
    names: ['Field_Corn_VE', 'Field_Corn_EM'],
    image: 'Corn_EM.png'
  },
  {
    names: ['Field_Corn_Not_Emerged'],
    image: 'Field_Corn_Not_Emerged.png'
  },
  {
    names: ['Field_Corn_R4', 'Field_Corn_R5'],
    image: 'Corn_R4.png'
  },
  {
    names: ['Field_Corn_R6'],
    image: 'Corn_R6.png'
  },
  {
    names: ['Field_Corn_V3', 'Field_Corn_V4'],
    image: 'Corn_V3.png'
  },
  {
    names: ['Field_Corn_V6', 'Field_Corn_V7'],
    image: 'Corn_V6.png'
  },
  {
    names: ['Field_Corn_V8', 'Field_Corn_V9', 'Field_Corn_V10'],
    image: 'Corn_V8.png'
  },
  {
    names: [
      'Field_Corn_V11',
      'Field_Corn_V12',
      'Field_Corn_V13',
      'Field_Corn_V14',
      'Field_Corn_V15',
      'Field_Corn_V16',
      'Field_Corn_V17',
      'Field_Corn_V18'
    ],
    image: 'Corn_V12.png'
  },
  {
    names: ['Cotton_Emergence'],
    image: 'Cotton_VC.png'
  },
  {
    names: ['Cotton_Vegetative'],
    image: 'Cotton_V8.png'
  },
  {
    names: ['Cotton_Square'],
    image: 'Cotton_Square.png'
  },
  {
    names: ['Cotton_Bloom_Early'],
    image: 'Cotton_Bloom.png'
  },
  {
    names: ['Cotton_Bloom_Peak'],
    image: 'Cotton_Boll.png'
  },
  {
    names: [
      'Cotton_Boll_Open',
      'Cotton_Boll_20_Open',
      'Cotton_Boll_40_Open',
      'Cotton_Boll_60_Open',
      'Cotton_Boll_80_Open'
    ],
    image: 'Cotton_Open_Boll.png'
  },
  {
    names: ['Cotton_Mature'],
    image: 'Cotton_Maturity.png'
  },
  {
    names: ['Soybeans_VE', 'Soybeans_EM'],
    image: 'Soybeans_EM.png'
  },
  {
    names: ['Soybeans_Not_Emerged'],
    image: 'Soybeans_Not_Emerged.png'
  },
  {
    names: [
      'Soybeans_V5',
      'Soybeans_V6',
      'Soybeans_V7',
      'Soybeans_V8',
      'Soybeans_V9',
      'Soybeans_V10',
      'Soybeans_V11',
      'Soybeans_V12',
      'Soybeans_V13',
      'Soybeans_V14',
      'Soybeans_V15'
    ],
    image: 'Soybeans_V5.png'
  },
  {
    names: ['Soybeans_R1', 'Soybeans_R2'],
    image: 'Soybeans_R1.png'
  },
  {
    names: ['Soybeans_R5', 'Soybeans_R6'],
    image: 'Soybeans_R5.png'
  },
  {
    names: ['Peanuts_VE', 'Peanuts_EM'],
    image: 'Peanuts_EM.png'
  },
  {
    names: ['Peanuts_Not_Emerged'],
    image: 'Peanuts_Not_Emerged.png'
  },
  {
    names: [
      'Peanuts_V7',
      'Peanuts_V8',
      'Peanuts_V9',
      'Peanuts_V10',
      'Peanuts_V11',
      'Peanuts_V12'
    ],
    image: 'Peanuts_V8.png'
  },
  {
    names: ['Peanuts_R3', 'Peanuts_R4'],
    image: 'Peanuts_R3.png'
  },
  {
    names: ['Peanuts_R7'],
    image: 'Peanuts_Maturity.png'
  },
  {
    names: ['Peanuts_R8'],
    image: 'Peanuts_Harvest.png'
  },
  {
    names: [
      'Wheat_Z1_3',
      'Wheat_Z1_4',
      'Wheat_Z1_5',
      'Wheat_Z1_6',
      'Wheat_Z1_7',
      'Wheat_Z1_8',
      'Wheat_Z1_9'
    ],
    image: 'Wheat_Z1_3.png'
  },
  {
    names: ['Wheat_Not_Emerged'],
    image: 'Wheat_Not_Emerged.png'
  },
  {
    names: ['Wheat_Z2_0', 'Wheat_Z2_1', 'Wheat_Z2_2'],
    image: 'Wheat_Z2_1.png'
  },
  {
    names: ['Wheat_Z2_3', 'Wheat_Z2_4'],
    image: 'Wheat_Z2_3.png'
  },
  {
    names: [
      'Wheat_Z2_5',
      'Wheat_Z2_6',
      'Wheat_Z2_7',
      'Wheat_Z2_8',
      'Wheat_Z2_9'
    ],
    image: 'Wheat_Z2_4.png'
  },
  {
    names: ['Wheat_Z3_0', 'Wheat_Z3_1'],
    image: 'Wheat_Z3_1.png'
  },
  {
    names: ['Wheat_Z3_2', 'Wheat_Z3_3', 'Wheat_Z3_4', 'Wheat_Z3_5'],
    image: 'Wheat_Z3_2.png'
  },
  {
    names: ['Wheat_Z3_6', 'Wheat_Z3_7', 'Wheat_Z3_8'],
    image: 'Wheat_Z3_7.png'
  },
  {
    names: ['Wheat_Z3_9', 'Wheat_Z4_0', 'Wheat_Z4_1'],
    image: 'Wheat_Z3_9.png'
  },
  {
    names: [
      'Wheat_Z4_2',
      'Wheat_Z4_3',
      'Wheat_Z4_4',
      'Wheat_Z4_5',
      'Wheat_Z4_6',
      'Wheat_Z4_7',
      'Wheat_Z4_8',
      'Wheat_Z4_9'
    ],
    image: 'Wheat_Z4_5.png'
  },
  {
    names: [
      'Wheat_Z5_0',
      'Wheat_Z5_1',
      'Wheat_Z5_2',
      'Wheat_Z5_3',
      'Wheat_Z5_4',
      'Wheat_Z5_5',
      'Wheat_Z5_6',
      'Wheat_Z5_7'
    ],
    image: 'Wheat_Z5_2.png'
  },
  {
    names: [
      'Wheat_Z5_8',
      'Wheat_Z5_9',
      'Wheat_Z6_0',
      'Wheat_Z6_1',
      'Wheat_Z6_2',
      'Wheat_Z6_3',
      'Wheat_Z6_4',
      'Wheat_Z6_5',
      'Wheat_Z6_6',
      'Wheat_Z6_7',
      'Wheat_Z6_8',
      'Wheat_Z6_9',
      'Wheat_Z7_0',
      'Wheat_Z7_1',
      'Wheat_Z7_2',
      'Wheat_Z7_3',
      'Wheat_Z7_4',
      'Wheat_Z7_5',
      'Wheat_Z7_6',
      'Wheat_Z7_7',
      'Wheat_Z7_8',
      'Wheat_Z7_9'
    ],
    image: 'Wheat_Z5_8.png'
  },
  {
    names: [
      'Wheat_Z8_0',
      'Wheat_Z8_1',
      'Wheat_Z8_2',
      'Wheat_Z8_3',
      'Wheat_Z8_4',
      'Wheat_Z8_5',
      'Wheat_Z8_6',
      'Wheat_Z8_7',
      'Wheat_Z8_8',
      'Wheat_Z8_9',
      'Wheat_Z9_0',
      'Wheat_Z9_1',
      'Wheat_Z9_2'
    ],
    image: 'Wheat_Z9_2.png'
  },
  {
    names: [
      'Barley_1_3',
      'Barley_Z1_4',
      'Barley_Z1_5',
      'Barley_Z1_6',
      'Barley_Z1_7',
      'Barley_Z1_8',
      'Barley_Z1_9'
    ],
    image: 'Barley_Z1_3.png'
  },
  {
    names: ['Barley_Not_Emerged'],
    image: 'Barley_Not_Emerged.png'
  },
  {
    names: ['Barley_Z2_0', 'Barley_Z2_1', 'Barley_Z2_2'],
    image: 'Barley_Z2_1.png'
  },
  {
    names: [
      'Barley_Z2_4',
      'Barley_Z2_5',
      'Barley_Z2_6',
      'Barley_Z2_7',
      'Barley_Z2_8',
      'Barley_Z2_9'
    ],
    image: 'Barley_Z2_4.png'
  },
  {
    names: ['Barley_Z3_0', 'Barley_Z3_1'],
    image: 'Barley_Z3_1.png'
  },
  {
    names: ['Barley_Z3_2', 'Barley_Z3_3', 'Barley_Z3_4', 'Barley_Z3_5'],
    image: 'Barley_Z3_2.png'
  },
  {
    names: ['Barley_Z3_6', 'Barley_Z3_7', 'Barley_Z3_8'],
    image: 'Barley_Z3_7.png'
  },
  {
    names: ['Barley_Z3_9', 'Barley_Z4_0', 'Barley_Z4_1'],
    image: 'Barley_Z3_9.png'
  },
  {
    names: [
      'Barley_Z4_2',
      'Barley_Z4_3',
      'Barley_Z4_4',
      'Barley_Z4_5',
      'Barley_Z4_6',
      'Barley_Z4_7',
      'Barley_Z4_8',
      'Barley_Z4_9'
    ],
    image: 'Barley_Z4_5.png'
  },
  {
    names: [
      'Barley_Z5_0',
      'Barley_Z5_1',
      'Barley_Z5_2',
      'Barley_Z5_3',
      'Barley_Z5_4',
      'Barley_Z5_5',
      'Barley_Z5_6',
      'Barley_Z5_7'
    ],
    image: 'Barley_Z5_2.png'
  },
  {
    names: [
      'Barley_Z5_8',
      'Barley_Z5_9',
      'Barley_Z6_0',
      'Barley_Z6_1',
      'Barley_Z6_2',
      'Barley_Z6_3',
      'Barley_Z6_4',
      'Barley_Z6_5',
      'Barley_Z6_6',
      'Barley_Z6_7',
      'Barley_Z6_8',
      'Barley_Z6_9',
      'Barley_Z7_0',
      'Barley_Z7_1',
      'Barley_Z7_2',
      'Barley_Z7_3',
      'Barley_Z7_4',
      'Barley_Z7_5',
      'Barley_Z7_6',
      'Barley_Z7_7',
      'Barley_Z7_8',
      'Barley_Z7_9'
    ],
    image: 'Barley_Z6_0.png'
  },
  {
    names: [
      'Barley_Z8_0',
      'Barley_Z8_1',
      'Barley_Z8_2',
      'Barley_Z8_3',
      'Barley_Z8_4',
      'Barley_Z8_5',
      'Barley_Z8_6',
      'Barley_Z8_7',
      'Barley_Z8_8',
      'Barley_Z8_9',
      'Barley_Z9_0',
      'Barley_Z9_1',
      'Barley_Z9_2'
    ],
    image: 'Barley_Z9_2.png'
  }
]

export const getGrowthStageImage = (crop, stage) => {
  if (!stage) {
    return '/img/growthstage/Not_Planted.png'
  }

  const concatedName = `${crop.replace(/ /g, '_')}_${stage.replace(/ /g, '_')}`

  const foundImage = images.find(i => i.names.includes(concatedName))

  if (!foundImage) {
    return '/img/growthstage/Not_Planted.png'
  }

  return `/img/growthstage/${foundImage.image}`
}
