import { EVENTS } from '../const'
import {
  getCalendarDate,
  getSeasonCompletionDate,
  getSeasonStartDate
} from './croppingSeasons'

export const getRotationSeasonName = ({
  isFallow,
  specificCropKind,
  cropKind
}) => {
  return isFallow ? 'Fallow' : specificCropKind?.name ?? cropKind.name
}

export const normalizeRotationSeason = season => {
  const {
    id,
    isFallow,
    isSelected,
    isCoverCrop,
    specificCrop,
    specificCropKind,
    crop,
    cropKind,
    specificCropName,
    cropName
  } = season

  const normalizedValues = {
    id,
    plantingDate: getSeasonStartDate(season),
    harvestDate: getSeasonCompletionDate(season),
    isCoverCrop,
    isFallow,
    isSelected
  }

  const isStepperCroppingSeason = Boolean(cropName || specificCropName)
  const cropKindValues = isStepperCroppingSeason
    ? {
        cropKind: { id: crop, name: cropName },
        specificCropKind: specificCrop
          ? { id: specificCrop, name: specificCropName }
          : null
      }
    : {
        cropKind: crop ?? cropKind,
        specificCropKind: specificCrop || specificCropKind
      }

  return { ...normalizedValues, ...cropKindValues }
}

export const getSeasonCropId = ({ specificCropKind, cropKind }) =>
  specificCropKind ? specificCropKind.id : cropKind?.id

export const seasonsMatch = (seasonA, seasonB) => {
  return (
    getSeasonCompletionDate(seasonA) === getSeasonCompletionDate(seasonB) &&
    getSeasonCropId(seasonA) === getSeasonCropId(seasonB)
  )
}

export const getRotationSeasonStartDate = season => {
  const plantingDate = season.events.find(
    ({ eventType }) =>
      eventType === EVENTS.PLANTING_COMPLETION ||
      eventType === EVENTS.START_DATE
  )?.date

  return plantingDate && getCalendarDate(plantingDate)
}

export const getRotationSeasonEndDate = season => {
  const harvestDate = season.events.find(
    ({ eventType }) =>
      eventType === EVENTS.HARVEST_COMPLETION || eventType === EVENTS.END_DATE
  )?.date

  return harvestDate && getCalendarDate(harvestDate)
}
