import { useContext } from 'react'
import { AuthorshipSessionContext } from 'components/AuthorshipSessionProvider'

export default () => {
  const { authorshipSessionActive, setAuthorshipSessionActive } = useContext(
    AuthorshipSessionContext
  )

  return { authorshipSessionActive, setAuthorshipSessionActive }
}
