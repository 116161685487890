import resolveEnv, { DEV, SIT, PRE, PROD } from './resolveEnv'

/**
 * Returns resolveLaunchDarklyKey for env
 * @return {string} launchDarklyKey;
 */
const resolveLaunchDarklyKey = () => {
  const env = resolveEnv()
  const keys = {
    [DEV]: '5f6291d90523380b17d9a260',
    [SIT]: '5f6292c20d216e0aa453c812',
    [PRE]: '5f62928dc068a10a791c6cff',
    [PROD]: '5f6291d90523380b17d9a261'
  }
  return keys[env]
}

export default resolveLaunchDarklyKey
