import { arrayOf, bool, number, shape, string } from 'prop-types'

export const StepType = shape({
  id: string,
  label: string,
  step: number,
  isCompleted: bool
})

export const CategoryStepType = shape({
  description: string,
  label: string,
  steps: arrayOf(StepType)
})

export const StepStatusType = shape({
  fieldsCompletedArea: number,
  fieldsCompletedCount: number,
  id: string,
  isCompleted: bool,
  label: string,
  step: number
})
