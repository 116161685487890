import styled from 'styled-components'
import Typography from '@nutrien/uet-react/Typography'

import th from 'theme/helpers'

export const SubTitle = styled(Typography).attrs({
  variant: 'h2',
  align: 'left'
})`
  margin: ${th.spacing(4, 0, 2, 0)};
  font-weight: 600;
`
