import { GraphQLClient } from 'graphql-hooks'
import _get from 'lodash/get'
import _some from 'lodash/some'
import memCache from 'graphql-hooks-memcache'

const url = window.uiConf ? window.uiConf.graphqlUrl : '/graphql'

const isUnauthorized = result => {
  const errors = _get(result, 'error.graphQLErrors', [])
  // fastify-gql will return status code as strings and not as numbers
  return _some(errors, { extensions: { code: '401' } })
}

export const gqlHooksCache = memCache()

class GraphQLClientWithAuthRenewal extends GraphQLClient {
  constructor(config) {
    super({
      url,
      ...config
    })

    this.enableAuthRenewal = this.enableAuthRenewal.bind(this)
    this.mutationsEmitter.setMaxListeners(20)
  }

  enableAuthRenewal(renewAuth) {
    // client state used to pause all failing operations while renewing token
    let authRenewal = null
    const originalRequest = this.request.bind(this)

    // graphql-client has no concept of response interceptors
    // Documentation suggest to provide custom fetch implementation, such implementation will not
    // benefit from response parsing.
    // Instead, we override `request()` method so it could pause initial operation until renewal is complete
    this.request = async function(operation, ...args) {
      const runQuery = () => originalRequest(operation, ...args)
      const result = await runQuery()
      if (isUnauthorized(result)) {
        if (!authRenewal) {
          // delegate to caller authentication token renewal, to keep the GraphQL client independent
          // renewAuth() must return a promise, so we could pause current GraphQL query and re-run it
          // when auth will be available.
          // All this is transparent to the component issuing the query
          authRenewal = renewAuth()
        }
        try {
          await authRenewal
          // finally, run original operation in case of success
          return runQuery()
        } finally {
          authRenewal = null
        }
        // in case of renewal error, let it go.
      }
      return result
    }
  }
}

export default GraphQLClientWithAuthRenewal
