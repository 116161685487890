import PropTypes from 'prop-types'

const TabPanel = ({ isActive, children }) => {
  return isActive ? children : null
}

TabPanel.propTypes = {
  isActive: PropTypes.bool,
  children: PropTypes.node
}

TabPanel.defaultProps = {
  isActive: false
}

export default TabPanel
