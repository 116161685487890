export const fuzzyMatch = (subject, search) =>
  search && subject?.toUpperCase().includes(search.toUpperCase())

/**
 * Joins an array of words by commas and an "and" before the last word
 * @param wordArr
 * @returns {string|*|string}
 */
export const joinWithAnd = wordArr =>
  wordArr.length > 1
    ? wordArr.slice(0, -1).join(', ') + ' and ' + wordArr.slice(-1)
    : wordArr[0] || ''

export const joinWithOr = wordArr =>
  wordArr.length > 1
    ? wordArr.slice(0, -1).join(', ') + ' or ' + wordArr.slice(-1)
    : wordArr[0] || ''
