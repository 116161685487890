import React from 'react'
import styled, { css } from 'styled-components'
import SvgIcon from '@nutrien/uet-react/SvgIcon'
import CheckCircle from '@nutrien/uet-react/icons/CheckCircle'
import Warning from '@nutrien/uet-react/icons/Warning'
import Error from '@nutrien/uet-react/icons/Error'
import RemoveCircle from '@nutrien/uet-react/icons/RemoveCircle'
import ClearNight from '@nutrien/uet-react/icons/ClearNight'
import Cloudy from '@nutrien/uet-react/icons/Cloudy'
import PartlyCloudy from '@nutrien/uet-react/icons/PartlyCloudy'
import PartlyCloudyNight from '@nutrien/uet-react/icons/PartlyCloudyNight'
import Rain from '@nutrien/uet-react/icons/Rain'
import Sunny from '@nutrien/uet-react/icons/Sunny'
import SubdirectoryArrowRight from '@nutrien/uet-react/icons/SubdirectoryArrowRight'
import Refresh from '@nutrien/uet-react/icons/Refresh'
import ArrowUpward from '@nutrien/uet-react/icons/ArrowUpward'

import th from 'theme/helpers'
import { tractorTimeColors } from 'theme/cardColors'

const Circle = styled(CheckCircle)`
  color: ${th.palette('brand.green')};
`

const Warn = styled(({ mid, light, ...props }) => <Warning {...props} />)`
  color: ${({ theme, mid, light }) =>
    (mid && theme.palette.warning.main) ||
    (light && theme.palette.error.light)};
`

const Alert = styled(Error)`
  color: ${th.palette('error.main')};
`

const Stop = styled(({ heavy, light, ...props }) => (
  <RemoveCircle {...props} />
))`
  color: ${({ heavy, light }) =>
    (heavy && tractorTimeColors.hvFzn) || (light && tractorTimeColors.ltFzn)};
`

const Condition = styled(SvgIcon)`
  color: ${th.palette('grey.600')};
`

const RotatedIcon = styled(({ rotation, ...props }) => <SvgIcon {...props} />)`
  color: ${th.palette('grey.600')};
  transform: ${({ rotation }) => css`
    rotate(${rotation}deg)
  `};
`

export const getIcon = (value, options = {}) => {
  if (options.rotation) {
    return <RotatedIcon component={ArrowUpward} rotation={value} />
  }

  const iconLookup = {
    allOps: <Circle />,
    mdOps: <Warn mid />,
    ltOps: <Warn light />,
    noOps: <Alert />,
    ltFzn: <Stop light />,
    hvFzn: <Stop heavy />,
    clearNight: <Condition component={ClearNight} />,
    cloudy: <Condition component={Cloudy} />,
    partlyCloudy: <Condition component={PartlyCloudy} />,
    partlyCloudyNight: <Condition component={PartlyCloudyNight} />,
    rainy: <Condition component={Rain} />,
    sunny: <Condition component={Sunny} />,
    spray: <Circle />,
    warning: <Alert />,
    watch: <Warn mid />,
    inversion: <Condition component={Refresh} />,
    noInversion: <Condition component={SubdirectoryArrowRight} />
  }

  return iconLookup[value] || null
}
