import React, { Suspense, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Grid from '@nutrien/uet-react/Grid'

import useFlags from 'hooks/useFlags'
import th from 'theme/helpers'
import Loading from 'components/Loading'
import LinkHeader from 'components/Header/LinkHeader'
import Footer from 'components/Footer'
import usePendo from 'hooks/usePendo'
import useAuthorshipSession from 'hooks/useAuthorshipSession'
import { gqlHooksCache } from '../../../graphql'
const PageContainer = styled(Grid)`
  height: 100%;
  min-height: 100%;
  background: ${th.palette('common.white')};
`

// children rule is needed for IE11: https://stackoverflow.com/a/35113633
const partialWidthCSS = css`
  padding: ${th.spacing(0, 2)};
  max-width: ${({ theme }) => theme.breakpoints.values.md}px;
  > * {
    max-width: 100%;
  }
`

// TODO: Can switch to MUI Containers after upgrading to MUI@4.8
const Page = styled(
  ({
    fullWidth,
    fullHeight,
    isTopBannerVisible,
    isGrowerSessionActive,
    ...rest
  }) => <Grid {...rest} />
)`
  ${({ fullWidth }) => !fullWidth && partialWidthCSS}
  flex: 1 0 auto;
  ${({ fullHeight, isTopBannerVisible, isGrowerSessionActive }) => {
    const activeGrowerSessionPadding = isGrowerSessionActive ? 10 : 0
    const maintenanceBannerPadding = isTopBannerVisible.maintenanceActive
      ? 5
      : 0
    let paddingTopSpacing =
      8 + activeGrowerSessionPadding + maintenanceBannerPadding // header height in theme spacing units

    let paddingBottomSpacing = 0
    if (!fullHeight) {
      paddingTopSpacing += 2
      paddingBottomSpacing += 2
    }
    return css`
      height: ${fullHeight ? '100%' : 'auto'};
      padding-top: ${th.spacing(paddingTopSpacing)};
      padding-bottom: ${th.spacing(paddingBottomSpacing)};
    `
  }}
`

const ContentContainer = styled(Grid)`
  ${({ bgcolor, fullheight }) => css`
    height: ${fullheight ? '100%' : 'auto'};
    background: ${th.palette(bgcolor ?? 'common.white')};
  `}
`

const FooterWrapper = styled(Grid)`
  width: 100%;
`

const PageContent = ({
  children,
  fullHeight,
  fullWidth,
  headerType,
  pageTitle,
  hideFooter,
  bgcolor,
  ...otherProps
}) => {
  const flags = useFlags()
  const { authorshipSessionActive } = useAuthorshipSession()
  return (
    <React.Fragment>
      <LinkHeader tabType="community" pageTitle={pageTitle} />
      <ContentContainer
        xs
        item
        container
        wrap="nowrap"
        direction="column"
        justify="flex-start"
        alignItems="center"
        fullheight={+fullHeight}
        bgcolor={bgcolor}>
        <Page
          isTopBannerVisible={flags?.maintBannerAndText}
          isGrowerSessionActive={authorshipSessionActive}
          xs
          item
          container
          direction="column"
          wrap="nowrap"
          fullWidth={fullWidth}
          fullHeight={fullHeight}>
          {children}
        </Page>
        {!hideFooter && (
          <FooterWrapper item>
            <Footer />
          </FooterWrapper>
        )}
      </ContentContainer>
    </React.Fragment>
  )
}

const ColumnLayout = props => {
  const { initialize: initializePendo } = usePendo()

  useEffect(() => {
    initializePendo()
    gqlHooksCache.clear()

    // disabling this rule since we only want this to run on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PageContainer
      container
      wrap="nowrap"
      direction="column"
      justify="flex-start"
      alignItems="center">
      <Suspense fallback={<Loading isFloating />}>
        <PageContent {...props} />
      </Suspense>
    </PageContainer>
  )
}

ColumnLayout.propTypes = {
  pageTitle: PropTypes.node,
  fullWidth: PropTypes.bool.isRequired,
  fullHeight: PropTypes.bool.isRequired,
  hideFooter: PropTypes.bool
}

ColumnLayout.defaultProps = {
  pageTitle: undefined,
  fullHeight: false,
  fullWidth: false,
  hideFooter: false
}

export default ColumnLayout
