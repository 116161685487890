import {
  string,
  shape,
  oneOf,
  oneOfType,
  number,
  bool,
  arrayOf
} from 'prop-types'

import {
  ADDITIVES,
  CARRIERS,
  FERTILIZER_KIND,
  FERTILIZER_TYPES,
  SAMPLE_DEPTH_TYPES
} from 'const'

import { varietyType } from './commonTypes'
import { userType } from './userTypes'

const basicEventType = Object.freeze({
  activityDatetime: string,
  canDelete: bool,
  canEdit: bool,
  eventNote: string,
  id: number,
  owner: userType
})

export const applicatorType = shape({
  certificationNumber: string,
  templateId: number
})

// Chemical Application
export const chemicalApplicationMethodType = shape({
  carrier: oneOf(CARRIERS),
  method: string,
  templateId: number,
  waterAddetives: oneOf(ADDITIVES)
})

export const chemicalComponentType = shape({
  componentActiveIngredientPercent: number,
  componentRateUnit: string,
  componentRateValue: number,
  id: number,
  productId: number,
  productName: string,
  type: string
})

export const chemicalMixType = shape({
  carrier: oneOf(CARRIERS),
  components: arrayOf(chemicalComponentType),
  templateId: number,
  waterAddetives: oneOf(ADDITIVES)
})

export const chemicalApplicationType = shape({
  ...basicEventType,
  applicationAreaPercentage: number,
  applicationMethod: chemicalApplicationMethodType,
  applicator: applicatorType,
  chemicalMix: chemicalMixType
})

// Fertilizer
export const fertilizerApplicationMethod = shape({
  depthUnit: string,
  depthValue: number,
  isVariableRate: bool,
  methodId: number,
  methodName: string,
  templateId: number
})

export const fertilizerComponentType = shape({
  applicationRateUnit: string,
  applicationRateValue: number,
  brandId: number,
  brandName: string,
  entryMethod: string,
  fertilizerId: number,
  id: number,
  intendedApplicationDuration: number,
  kind: oneOf(Object.values(FERTILIZER_KIND)),
  productId: number,
  productName: string,
  stabilizerId: number,
  stabilizerName: string,
  type: oneOf(Object.values(FERTILIZER_TYPES)),
  userAmountUnit: string,
  userAmountValue: number
})

export const fertilizerBlendType = shape({
  components: arrayOf(fertilizerComponentType),
  templateId: number
})

export const fertilizerType = shape({
  ...basicEventType,
  applicationAreaPercentage: number,
  applicationMethod: fertilizerApplicationMethod,
  applicator: applicatorType,
  fertilizerBlend: fertilizerBlendType,
  intendedApplicationDuration: number,
  isVariableRate: bool
})

// Hail Log
export const hailLogtype = shape({
  ...basicEventType,
  cropAndGrowthStage: string,
  density: string,
  durationUnit: string,
  durationValue: number,
  fieldDamaged: bool,
  fieldInspected: bool,
  percentDamaged: number,
  probability: number,
  sizeUnit: string,
  sizeValue: number
})

// Harvest Completion
export const harvestCompletionType = shape({
  ...basicEventType,
  abandonedAcresReason: string,
  actualYieldUnit: string,
  actualYieldValue: number,
  postHarvestLossValue: number,
  postHarvestLossUnit: string,
  canEditYear: bool,
  distanceToSaleUnit: string,
  distanceToSaleValue: number,
  dryingEnergySource: string,
  dryingFuelType: string,
  dryingMethod: string,
  dryingSystem: string,
  dryingTranspoEventNote: string,
  editDryingTranspoProfile: userType,
  finalMoisture: number,
  harvestMoisture: number,
  proteinPercentage: number,
  residueManagement: string,
  targetYieldUnit: string,
  targetYieldValue: number,
  totalHarvestedArea: number,
  transportationDate: string,
  transportationMode: string
})

// Irrigation
export const irrigationtype = shape({
  ...basicEventType,
  durationOfWateringUnit: string,
  durationOfWateringValue: number,
  endDate: string,
  irrigatedAreaUnit: string,
  irrigatedAreaValue: number,
  irrigationAmountUnit: string,
  irrigationAmountValue: number,
  irrigationAreaPercentage: number,
  irrigationScheduleType: string,
  irrigationTotalUnit: string,
  irrigationTotalValue: number,
  startDate: string
})

// Planting Completion
export const plantingCompletionType = shape({
  ...basicEventType,
  productionAreaValue: number,
  varieties: arrayOf(varietyType)
})

// Soil Test
export const soilTestType = shape({
  ...basicEventType,
  aluminumUnit: string,
  aluminumValue: number,
  ammoniumUnit: string,
  ammoniumValue: number,
  baseSatCaUnit: string,
  baseSatCaValue: number,
  baseSatHUnit: string,
  baseSatHValue: number,
  baseSatKUnit: string,
  baseSatKValue: number,
  baseSatMgUnit: string,
  baseSatMgValue: number,
  boronUnit: string,
  boronValue: number,
  bufferPHUnit: string,
  bufferPHValue: number,
  cadmiumUnit: string,
  cadmiumValue: number,
  calciumUnit: string,
  calciumValue: number,
  cecUnit: string,
  cecValue: number,
  chlorineUnit: string,
  chlorineValue: number,
  cobaltUnit: string,
  cobaltValue: number,
  copperUnit: string,
  copperValue: number,
  ironUnit: string,
  ironValue: number,
  isCoverCropRemoval: bool,
  isFiftyPercentPhosphorusRemovalRate: bool,
  isntUnit: string,
  isntValue: number,
  leadUnit: string,
  leadValue: number,
  magnesiumUnit: string,
  magnesiumValue: number,
  manganeseUnit: string,
  manganeseValue: number,
  molybdenumUnit: string,
  molybdenumValue: number,
  nickelUnit: string,
  nickelValue: number,
  nitrateUnit: string,
  nitrateValue: number,
  organicMatterUnit: string,
  organicMatterValue: number,
  phosphorusUnit: string,
  phosphorusValue: number,
  pHUnit: string,
  pHValue: number,
  potassiumUnit: string,
  potassiumValue: number,
  ppntUnit: string,
  ppntValue: number,
  psntUnit: string,
  psntValue: number,
  salinityUnit: string,
  salinityValue: number,
  sampleDepthType: oneOf(Object.values(SAMPLE_DEPTH_TYPES)),
  silverUnit: string,
  silverValue: number,
  sodiumUnit: string,
  sodiumValue: number,
  sulfurUnit: string,
  sulfurValue: number,
  totalCopperUnit: string,
  totalCopperValue: number,
  totalIronUnit: string,
  totalIronValue: number,
  totalMagnesiumUnit: string,
  totalMagnesiumValue: number,
  totalManganeseUnit: string,
  totalManganeseValue: number,
  totalNitrogenUnit: string,
  totalNitrogenValue: number,
  totalPhosphorusUnit: string,
  totalPhosphorusValue: number,
  totalPotassiumUnit: string,
  totalPotassiumValue: number,
  totalSulfurUnit: string,
  totalSulfurValue: number,
  zincUnit: string,
  zincValue: number
})

// Tillage
export const tillageEquipmentType = shape({
  equipmentType: string,
  id: number,
  type: string
})

export const tillageType = shape({
  equipment: tillageEquipmentType,
  otherTillageDepthUnit: string,
  otherTillageDepthValue: number,
  residueCoverage: string,
  tillageAreaPercentage: number
})

export const eventType = oneOfType([
  chemicalApplicationType,
  fertilizerType,
  hailLogtype,
  harvestCompletionType,
  irrigationtype,
  plantingCompletionType,
  soilTestType,
  tillageType
])
