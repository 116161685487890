import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import Box from '@nutrien/uet-react/Box'
import useFlags from 'hooks/useFlags'
import AuthorshipPermissionDisabledOverlay from 'components/AuthorshipPermissionDisabledOverlay'
import useAgronomyAccessPermissions from 'hooks/useAgronomyAccessPermissions'
import useFieldStoryAccessPermissions from 'hooks/useCanAccessFieldStoryProducts'
import useCreateUrl from 'hooks/useCreateUrl'
import useHasCommunityDashboardAccess from 'hooks/useHasCommunityDashboardAccess'
import useWeatherAccessPermissions from 'hooks/useWeatherAccessPermissions'

import { SUSTAINABILITY_PRODUCT } from 'helpers/products'
import useCanAccessProduct from 'hooks/useCanAccessProduct'
import ChildLinkTab from '../ChildLinkTab'
import DropdownLinkTab from '../DropdownLinkTab'
import LinkTab from '../LinkTab'
import NestedLinkTab from '../NestedLinkTab'

const ProductTabs = ({ onTabChange }) => {
  const { farmItemLevel = 'orgs', farmItemId = 'my-account' } = useParams()
  const { pathname } = useLocation()
  const { t } = useTranslation()
  const flags = useFlags()
  const createUrl = useCreateUrl()
  const agronomyAccess = useAgronomyAccessPermissions()
  const fieldStoryAccess = useFieldStoryAccessPermissions()
  const weatherAccess = useWeatherAccessPermissions()
  const communityDashboardAccess = useHasCommunityDashboardAccess()
  const canAccessSustainability = useCanAccessProduct(SUSTAINABILITY_PRODUCT)

  const urlBuilder = page =>
    createUrl(
      {
        farmItemId,
        farmItemLevel,
        page,
        viewMode: 'cards'
      },
      { clearPageParams: true }
    )

  const isSelected = page => pathname.includes(page)

  return (
    <>
      {flags.weatherTab && (
        <Box style={{ position: 'relative' }}>
          <NestedLinkTab
            id="weather"
            data-testid="nested-link-tab-weather"
            to={urlBuilder('weather')}
            isSelected={isSelected('weather')}
            tabLabel={t('weather')}
            onClick={onTabChange}>
            {weatherAccess.fieldForecast && (
              <ChildLinkTab
                id="field-forecast"
                data-testid="child-link-tab-field-forecast"
                to={urlBuilder('field-forecast')}
                isSelected={isSelected('field-forecast')}
                onClick={onTabChange}>
                {t('field_forecast')}
              </ChildLinkTab>
            )}
            <ChildLinkTab
              id="weather-story"
              data-testid="child-link-tab-weather-story"
              to={urlBuilder('weather-story')}
              isSelected={isSelected('weather-story')}
              onClick={onTabChange}>
              {t('weather-story')}
            </ChildLinkTab>
            {weatherAccess.hailEvent && (
              <ChildLinkTab
                id="hail-alert"
                data-testid="child-link-tab-hail-alert"
                to={urlBuilder('hail-alert')}
                isSelected={isSelected('hail-alert')}
                onClick={onTabChange}>
                {t('hail_events')}
              </ChildLinkTab>
            )}
          </NestedLinkTab>
          <AuthorshipPermissionDisabledOverlay
            alwaysDisableInAuthorship={true}
          />
        </Box>
      )}

      <Box style={{ position: 'relative' }}>
        <LinkTab
          id="market"
          data-testid="link-tab-market"
          to={urlBuilder('market')}
          isSelected={isSelected('market')}
          onClick={onTabChange}>
          {t('market')}
        </LinkTab>
        <AuthorshipPermissionDisabledOverlay alwaysDisableInAuthorship={true} />
      </Box>

      {fieldStoryAccess && (
        <LinkTab
          id="field-story"
          data-testid="link-tab-field-story"
          to={urlBuilder('field-story')}
          isSelected={isSelected('field-story')}
          onClick={onTabChange}>
          {t('field_story')}
        </LinkTab>
      )}
      {flags.susDropdownMenu ? (
        <Box style={{ position: 'relative' }}>
          <DropdownLinkTab
            id="sustainability"
            data-testid="dropdown-link-tab-sustainability"
            tabLabel={t('sustainability')}>
            {communityDashboardAccess?.canViewStakeholderDashboard && (
              <ChildLinkTab
                id="stakeholder-dashboard"
                data-testid="child-link-tab-stakeholder-dashboard"
                to="/stakeholder-dashboard"
                isSelected={isSelected('stakeholder-dashboard')}
                onClick={onTabChange}>
                {t('Stakeholder_Dashboard')}
              </ChildLinkTab>
            )}
            {communityDashboardAccess?.canViewEmployeeDashboard && (
              <ChildLinkTab
                id="employee-dashboard"
                to="/employee-dashboard"
                isSelected={isSelected('employee-dashboard')}
                onClick={onTabChange}>
                {t('Employee_Dashboard')}
              </ChildLinkTab>
            )}
            {flags.sustainabiltityGrowerDashboard && (
              <ChildLinkTab
                id="grower-dashboard"
                data-testid="child-link-tab-grower-dashboard"
                to="/grower-dashboard"
                isSelected={
                  isSelected('grower-dashboard') ||
                  isSelected('manage-project') ||
                  isSelected('view-project')
                }
                onClick={onTabChange}>
                {t('Grower_Dashboard')}
              </ChildLinkTab>
            )}
            {canAccessSustainability && (
              <Box style={{ position: 'relative' }}>
                <ChildLinkTab
                  id="sustainable-sourcing"
                  data-testid="child-link-tab-sustainable-sourcing"
                  to={urlBuilder('sustainable-sourcing')}
                  isSelected={isSelected('sustainable-sourcing')}
                  onClick={onTabChange}>
                  {t('sustainability')}
                </ChildLinkTab>
                <AuthorshipPermissionDisabledOverlay
                  alwaysDisableInAuthorship={true}
                />
              </Box>
            )}
          </DropdownLinkTab>
          {!flags.nerpAuthorshipForGrowerDashboard && (
            <AuthorshipPermissionDisabledOverlay
              alwaysDisableInAuthorship={true}
            />
          )}
        </Box>
      ) : (
        canAccessSustainability && (
          <Box style={{ position: 'relative' }}>
            <LinkTab
              id="sustainable-sourcing"
              data-testid="link-tab-sustainable-sourcing"
              to={urlBuilder('sustainable-sourcing')}
              isSelected={isSelected('sustainable-sourcing')}
              onClick={onTabChange}>
              {t('sustainability')}
            </LinkTab>
            {!flags.nerpAuthorshipForGrowerDashboard && (
              <AuthorshipPermissionDisabledOverlay
                alwaysDisableInAuthorship={true}
              />
            )}
          </Box>
        )
      )}

      {flags.agronomyTab && (
        <Box style={{ position: 'relative' }}>
          <NestedLinkTab
            id="agronomy"
            data-testid="nested-link-tab-agronomy"
            to={urlBuilder('agronomy')}
            isSelected={isSelected('agronomy')}
            tabLabel={t('agronomy')}
            onClick={onTabChange}>
            {agronomyAccess.yieldEngine && (
              <ChildLinkTab
                id="yield"
                data-testid="child-link-tab-yield"
                to={urlBuilder('yield')}
                isSelected={isSelected('yield')}
                onClick={onTabChange}>
                {t('yield')}
              </ChildLinkTab>
            )}
            {agronomyAccess.nutrientEngine && (
              <ChildLinkTab
                id="nutrient-engine"
                data-testid="child-link-tab-nutrient-engine"
                to={urlBuilder('nutrient-engine')}
                isSelected={isSelected('nutrient-engine')}
                onClick={onTabChange}>
                {t('nutrient-engine')}
              </ChildLinkTab>
            )}
            {agronomyAccess.criticalPeriods && (
              <ChildLinkTab
                id="critical-periods"
                data-testid="child-link-tab-critical-periods"
                to={urlBuilder('critical-periods')}
                isSelected={isSelected('critical-periods')}
                onClick={onTabChange}>
                {t('critical-periods')}
              </ChildLinkTab>
            )}
            {agronomyAccess.tractorTimeHourly && (
              <ChildLinkTab
                id="tractor-time"
                data-testid="child-link-tab-tractor-time"
                to={urlBuilder('tractor-time')}
                isSelected={isSelected('tractor-time')}
                onClick={onTabChange}>
                {t('tractor-time')}
              </ChildLinkTab>
            )}
            {agronomyAccess.spraySmart && (
              <ChildLinkTab
                id="spray-smart"
                data-testid="child-link-tab-spray-smart"
                to={urlBuilder('spray-smart')}
                isSelected={isSelected('spray-smart')}
                onClick={onTabChange}>
                {t('spray-smart')}
              </ChildLinkTab>
            )}
            {agronomyAccess.bqe && (
              <ChildLinkTab
                id="barley-quality-engine"
                data-testid="child-link-tab-barley-quality-engine"
                to={urlBuilder('barley-quality-engine')}
                isSelected={isSelected('barley-quality-engine')}
                onClick={onTabChange}>
                {t('barley-quality-engine')}
              </ChildLinkTab>
            )}
          </NestedLinkTab>
          <AuthorshipPermissionDisabledOverlay
            alwaysDisableInAuthorship={true}
          />
        </Box>
      )}
    </>
  )
}

ProductTabs.propTypes = {
  onTabChange: PropTypes.func
}

ProductTabs.defaultProps = {
  onTabChange: () => {}
}

export default ProductTabs
