import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import Dialog from '@nutrien/uet-react/Dialog'
import Button from '@nutrien/uet-react/Button'
import Typography from '@nutrien/uet-react/Typography'

import { gotoJohnDeere } from 'store/actions/johnDeere'
import th from 'theme/helpers'
import { useAuthContext } from 'pages/Authentication/Authentication'

const StyledTypography = styled(Typography).attrs({ variant: 'body1' })`
  margin-bottom: ${th.spacing(3)};
`

const JohnDeereConsentDialog = ({
  setRequestPending,
  setRequestError,
  onClose
}) => {
  const { t } = useTranslation()
  const { authentication } = useAuthContext()
  const location = useLocation()

  const handlePositiveConsent = () => {
    setRequestPending(true)

    return gotoJohnDeere(authentication, location).catch(e => {
      setRequestPending(false)
      setRequestError(e.message || t('api_error_title'))
    })
  }

  return (
    <Dialog
      title={t('jd_ops_import_leaving_warning_title')}
      open
      onClose={onClose}
      hasCloseIcon
      maxWidth="xs"
      closeIconSize="large"
      actions={
        <>
          <Button variant="outlined" onClick={onClose}>
            {t('cancel')}
          </Button>
          <Button variant="contained" onClick={handlePositiveConsent}>
            {t('add_fields')}
          </Button>
        </>
      }>
      <StyledTypography>{t('jd_heads_up_line_1')}</StyledTypography>
      <StyledTypography>{t('jd_heads_up_line_2')}</StyledTypography>
      <StyledTypography>{t('jd_heads_up_line_3')}</StyledTypography>
    </Dialog>
  )
}

JohnDeereConsentDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  setRequestPending: PropTypes.func.isRequired,
  setRequestError: PropTypes.func.isRequired
}

export default React.memo(JohnDeereConsentDialog)
