import React, { useCallback, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { ClientContext } from 'graphql-hooks'
import styled from 'styled-components'
import Dialog from '@nutrien/uet-react/Dialog'
import * as Yup from 'yup'

import Box from '@nutrien/uet-react/Box'
import FieldThumbnail from 'components/FieldThumbnail'
import {
  StyledForm,
  StyledTextInput,
  StyledSingleChoice,
  FloatingControls
} from 'components/Form'
import Typography from 'components/Typography'
import Divider from 'components/Divider'
import th from 'theme/helpers'
import {
  selectFarmsFor,
  selectGroupsFor,
  selectOrgFor
} from 'store/selectors/farmTree'
import { addField } from 'store/actions/FarmTree'
import useToggle from 'hooks/useToggle'
import usePermissions from 'hooks/usePermissions'
import { geometryTo4326 } from 'helpers/mapHelpers'
import { FARM_TREE_PRODUCT } from 'helpers/products'
import { AuthContext } from 'components/Auth/authentication-context'

const StyledDialog = styled(Dialog)`
  .MuiDialogContent-root {
    padding: ${th.spacing(0, 2)};
  }
`

const DialogContent = styled.div`
  border-top: 1px solid ${th.palette('divider')};
  height: 100%;
  box-sizing: border-box;
  padding: ${th.spacing(3, 0, 2, 0)};
`

const FieldDetailsDialog = ({
  entity,
  onSuccess,
  onClose,
  selectedEntity,
  setRequestError,
  setRequestPending
}) => {
  const { user } = useContext(AuthContext)
  const profileUuid = user?.id
  const { t } = useTranslation()
  const { canAdd } = usePermissions(FARM_TREE_PRODUCT)
  const [allowClose, toggleAllowClose] = useToggle(true)
  const isInvalidPolygon =
    entity.getGeometry().getCoordinates()[0][0].length < 4 &&
    entity.getGeometry().getCoordinates()[0].length < 4
  const myOrg = useSelector(
    state => selectedEntity && selectOrgFor(state, selectedEntity.uuid)
  )
  const groups =
    useSelector(state => myOrg && selectGroupsFor(state, myOrg.uuid)) || []
  const myGroups = useMemo(() => groups.filter(({ uuid }) => canAdd(uuid)), [
    canAdd,
    groups
  ])

  const client = useContext(ClientContext)
  const dispatch = useDispatch()

  const handleSubmit = useCallback(
    async values => {
      toggleAllowClose()
      setRequestPending(true)
      const coordinates = geometryTo4326(entity.getGeometry()).getCoordinates()
      const fieldData = {
        groupUuid: values.groupUuid,
        farmUuid: values.farmUuid,
        farmName: values.farmName,
        landOwner: values.landRights === 'yes' ? { uuid: myOrg.uuid } : null,
        fieldName: values.fieldName,
        poly: {
          type: 'MultiPolygon',
          coordinates:
            entity.getGeometry().getType() === 'MultiPolygon'
              ? coordinates
              : [coordinates]
        }
      }

      const fieldUuid = await dispatch(addField(client, fieldData, profileUuid))
      toggleAllowClose()

      if (!fieldUuid) return setRequestError(t('an_unexpected_error_occurred'))

      setRequestPending(false)
      const savedDate = {
        fieldName: values.fieldName
      }
      onSuccess(fieldUuid, savedDate)
    },
    [
      client,
      dispatch,
      entity,
      myOrg.uuid,
      onSuccess,
      setRequestError,
      setRequestPending,
      profileUuid,
      t,
      toggleAllowClose
    ]
  )

  const initialValues = useMemo(
    () => ({
      fieldName: '',
      farmUuid: '',
      farmName: '',
      landRights: '',
      groupUuid: myGroups.length === 1 ? myGroups[0].uuid : ''
    }),
    [myGroups]
  )

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        fieldName: Yup.string().required(t('form_cannot_empty')),
        farmUuid: Yup.string(),
        farmName: Yup.string().when('farmUuid', {
          is: f => !f,
          then: s => s.required(t('form_cannot_empty'))
        }),
        groupUuid: Yup.string().required(t('form_cannot_empty'))
      }),
    [t]
  )
  return (
    <StyledDialog
      title={isInvalidPolygon ? t('field_error') : t('field_details')}
      open
      onClose={onClose}
      disableBackdropClick={!allowClose}
      disableEscapeKeyDown={!allowClose}
      hasCloseIcon
      maxWidth="xs"
      closeIconSize="large">
      <DialogContent>
        {isInvalidPolygon ? (
          <Typography>{t('invalid_shape')}</Typography>
        ) : (
          <>
            <Typography variant="h4" gutterBottom={2}>
              {t('field_boundary')}
            </Typography>
            <FieldThumbnail entity={entity} />
            <Divider mt={3} />

            <StyledForm
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}>
              {function FormBody({ values, setFieldValue }) {
                const { t } = useTranslation()
                const { canAdd } = usePermissions(FARM_TREE_PRODUCT)
                const farms = useSelector(state =>
                  selectFarmsFor(state, values.groupUuid)
                )
                const myFarms = useMemo(
                  () => farms.filter(({ uuid }) => canAdd(uuid)),
                  [canAdd, farms]
                )

                const farmOptions = useMemo(
                  () =>
                    myFarms.map(f => ({
                      value: f.uuid,
                      label: f.name
                    })),
                  [myFarms]
                )

                const groupOptions = useMemo(
                  () =>
                    groups.map(g => ({
                      value: g.uuid,
                      label: g.name
                    })),
                  []
                )

                return (
                  <>
                    <StyledTextInput
                      name="fieldName"
                      label={
                        <Typography variant="h4">{t('field_name')}</Typography>
                      }
                      width="auto"
                      placeholder={t('input_field_name')}
                    />
                    <Divider mb={2} />
                    <Box mb={2}>
                      <Typography variant="h4">{t('land_rights')}</Typography>
                      <StyledSingleChoice
                        name="landRights"
                        label={t('land_rights_question')}
                        size="small"
                        width="100%"
                        options={[
                          { value: 'yes', label: t('yes') },
                          { value: 'no', label: t('No') }
                        ]}
                        variant="radio"
                      />
                    </Box>
                    <Divider mb={2} />
                    {groupOptions.length > 1 && (
                      <>
                        <StyledSingleChoice
                          name="groupUuid"
                          label={t('group_name')}
                          size="small"
                          width="100%"
                          options={groupOptions}
                          variant="dropdown"
                          onChange={e => {
                            setFieldValue('farmUuid', '')
                            setFieldValue('groupUuid', e.target.value)
                          }}
                        />
                        <Divider mb={2} />
                      </>
                    )}

                    <Typography variant="h4" gutterBottom={2}>
                      {t('farm')}
                    </Typography>
                    {!!farmOptions.length && (
                      <StyledSingleChoice
                        name="farmUuid"
                        label={t('select_existing')}
                        size="small"
                        width="100%"
                        options={farmOptions}
                        variant="dropdown"
                        disabled={!values.groupUuid}
                        onChange={e => {
                          setFieldValue('farmName', '')
                          setFieldValue('farmUuid', e.target.value)
                        }}
                      />
                    )}
                    {myOrg.isOwner && (
                      <>
                        {!!farmOptions.length && (
                          <Typography variant="body2" gutterBottom={2}>
                            {t('or')}
                          </Typography>
                        )}
                        <StyledTextInput
                          name="farmName"
                          label={t('create_farm')}
                          size="small"
                          width="auto"
                          placeholder={t('farm_name')}
                          disabled={!values.groupUuid}
                          onChange={e => {
                            setFieldValue('farmUuid', '')
                            setFieldValue('farmName', e.target.value)
                          }}
                        />
                      </>
                    )}

                    <FloatingControls handleCancel={onClose} hideIntercom />
                  </>
                )
              }}
            </StyledForm>
          </>
        )}
      </DialogContent>
    </StyledDialog>
  )
}

FieldDetailsDialog.propTypes = {
  entity: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedEntity: PropTypes.object,
  setRequestError: PropTypes.func.isRequired,
  setRequestPending: PropTypes.func.isRequired
}

export default FieldDetailsDialog
