export const DEV = 'dev'
export const SIT = 'sit'
export const PRE = 'pre'
export const PROD = 'prd'

const resolveEnv = () => {
  const { appEnv } = window.uiConf

  const host = window.location.hostname

  const urls = {
    localhost: DEV,
    'dev.agrible.com': DEV,
    'sit.agrible.com': SIT,
    'pre.agrible.com': PRE,
    'www.agrible.com': PROD
  }

  // fallback to url based env if APP_ENV in env variable is not available.
  return appEnv ?? urls[host]
}

export default resolveEnv
