import { any, bool, func, object, number } from 'prop-types'

/**
 * Formik doesn't expose its injected props prop-types, so this file declares
 * them manually so we can re-use them easily.
 */
export const formikInjectedPropsType = {
  dirty: bool.isRequired,
  errors: object.isRequired,
  handleBlur: func.isRequired,
  handleChange: func.isRequired,
  handleReset: func.isRequired,
  handleSubmit: func.isRequired,
  isSubmitting: bool.isRequired,
  isValid: bool.isRequired,
  isValidating: bool.isRequired,
  resetForm: func.isRequired,
  setErrors: func.isRequired,
  setFieldError: func.isRequired,
  setFieldTouched: func.isRequired,
  submitForm: func.isRequired,
  submitCount: number.isRequired,
  setFieldValue: func.isRequired,
  setStatus: func.isRequired,
  setSubmitting: func.isRequired,
  setTouched: func.isRequired,
  setValues: func.isRequired,
  status: any,
  touched: object.isRequired,
  values: object.isRequired,
  validateForm: func.isRequired,
  validateField: func.isRequired
}
