import React, { useState } from 'react'
import { useMutation } from 'graphql-hooks'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Dialog from '@nutrien/uet-react/Dialog'
import Button from '@nutrien/uet-react/Button'
import Typography from '@nutrien/uet-react/Typography'
import Notification from '@nutrien/uet-react/Notification'
import styled from 'styled-components'
import Checkbox from '@nutrien/uet-react/Checkbox'
import FormControlLabel from '@nutrien/uet-react/FormControlLabel'
import { getDosErrors } from 'helpers'
import th from 'theme/helpers'
import TnCsDialog from 'pages/Community/components/common/TnCsDialog'
import Loading from 'components/Loading'

const TCLink = styled(Link)`
  color: ${th.palette('green.800')};
  font-weight: 600;
`
const StyledTypography = styled(Typography).attrs({ variant: 'h5' })`
  font-weight: 400;
  margin-bottom: ${th.spacing(4)};
`

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: ${th.spacing(2.5)};
  margin-bottom: ${th.spacing(2)};
`

const Error = styled(Notification)`
  margin-bottom: ${th.spacing(1)};
  font-family: 'Open Sans', sans-serif;
`

const ENROLL_IN_CAMPAIGN = `
  mutation EnrollInCampaign(
    $code: String!
  ) {
    enrollInCampaign(
      code: $code
    ){
      isEnrolledInCampaign
      campaignId
    }
  }
`

const CampaignAcceptanceDialog = ({
  onClose,
  open = true,
  communityDetails,
  campaignDetails,
  campaignCode,
  setEnrollmentSuccess,
  setPreviouslyEnrolled
}) => {
  const { t } = useTranslation()
  const [communityTerms, setCommunityTerms] = useState(false)
  const [campaignTerms, setCampaignTerms] = useState(false)
  const [viewCommunityTerms, setViewCommunityTerms] = useState(false)
  const [viewCampaignTerms, setViewCampaignTerms] = useState(false)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [enrollInCampaign] = useMutation(ENROLL_IN_CAMPAIGN)

  const handleSubmit = async () => {
    setLoading(true)
    const { data, error } = await enrollInCampaign({
      variables: {
        code: campaignCode
      }
    })
    setLoading(false)
    if (data?.enrollInCampaign?.isEnrolledInCampaign) {
      onClose()
      setEnrollmentSuccess()
    }

    if (error) {
      // error codes aren't distinct, need to reference message to determine if the user is already enrolled
      const errorMessage = getDosErrors(error)?.[1]?.message
      if (
        errorMessage?.includes('Member is already a part of this Community')
      ) {
        onClose()
        setPreviouslyEnrolled()
      } else {
        setError(true)
      }
    }
  }

  return (
    <Dialog
      title={t('valid_campaign_code')}
      open={open}
      hasCloseIcon
      closeIconSize="large"
      onClose={onClose}
      actions={
        <StyledButton
          variant="contained"
          onClick={() => handleSubmit()}
          disabled={!(communityTerms && campaignTerms)}>
          {t('join')}
        </StyledButton>
      }>
      {loading && <Loading />}
      {error && (
        <Error variant="error">{t('failed_to_enroll_in_campaign')}</Error>
      )}
      <StyledTypography>
        {t('campaign_acceptance', { campaign: campaignDetails.name })}
      </StyledTypography>
      <FormControlLabel
        control={
          <Checkbox
            checked={communityTerms}
            onChange={() => setCommunityTerms(!communityTerms)}
            color="primary"
          />
        }
        label={
          <div>
            <span>
              {t('dynamic_agree_terms_label', { name: communityDetails.name })}
            </span>
            &nbsp;
            <TCLink
              to=""
              onClick={e => {
                e.preventDefault()
                setViewCommunityTerms(true)
              }}>
              {t('terms_and_conditions')}
            </TCLink>
          </div>
        }
      />
      <br />
      <FormControlLabel
        control={
          <Checkbox
            checked={campaignTerms}
            onChange={() => setCampaignTerms(!campaignTerms)}
            color="primary"
          />
        }
        label={
          <div>
            <span>
              {t('dynamic_agree_terms_label', { name: campaignDetails.name })}
            </span>
            &nbsp;
            <TCLink
              to=""
              onClick={e => {
                e.preventDefault()
                setViewCampaignTerms(true)
              }}>
              {t('terms_and_conditions')}
            </TCLink>
          </div>
        }
      />
      {viewCommunityTerms && (
        <TnCsDialog
          {...communityDetails.termsAndConditions}
          onClose={() => setViewCommunityTerms(false)}
        />
      )}
      {viewCampaignTerms && (
        <TnCsDialog
          {...campaignDetails.termsAndConditions}
          onClose={() => setViewCampaignTerms(false)}
        />
      )}
    </Dialog>
  )
}

CampaignAcceptanceDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  communityDetails: PropTypes.shape({
    name: PropTypes.string.isRequired,
    termsAndConditions: PropTypes.shape({
      modified: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      version: PropTypes.string.isRequired
    })
  }),
  campaignDetails: PropTypes.shape({
    name: PropTypes.string.isRequired,
    termsAndConditions: PropTypes.shape({
      modified: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      version: PropTypes.string.isRequired
    })
  }),
  campaignCode: PropTypes.string.isRequired,
  setEnrollmentSuccess: PropTypes.func.isRequired
}

CampaignAcceptanceDialog.defaultProps = {}

export default CampaignAcceptanceDialog
