/* eslint-disable one-var */

export const FARM_SORT = 'Farm',
  ENROLLMENT_SORT = 'Enrollment Status',
  ACRES_SORT = 'Acres',
  NAME_SORT = 'Field Name',
  ASC = 'asc',
  DESC = 'desc',
  SHOW_ALL = 'Show All',
  CROP_ROTATION_SORT = 'Rotation Group',
  CROP_ROTATION_STATUS_SORT = 'Status'

export const defaultFilterValues = Object.freeze({
  sortOption: FARM_SORT,
  orderOption: ASC,
  filterByCropSeason: SHOW_ALL,
  filterByProject: SHOW_ALL
})
