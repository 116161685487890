import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
// https://github.com/zloirock/core-js/blob/master/docs/2019-03-19-core-js-3-babel-and-a-look-into-the-future.md#babel
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'whatwg-fetch'
import 'react-hot-loader' // Has to be before react and react-dom
import React from 'react'
import { render } from 'react-dom'

import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import utc from 'dayjs/plugin/utc'
import Sentry from 'components/Sentry'

import createStore from './store'
import setupI18n from './i18n'
import LDProviderOverride from './LDProviderOverride'
import App from './app'

setupI18n({ useSuspense: true })
dayjs.extend(isBetween)
dayjs.extend(utc)
;(() => {
  const store = createStore()

  render(
    <Sentry>
      <LDProviderOverride store={store}>
        <App store={store} />
      </LDProviderOverride>
    </Sentry>,
    document.getElementById('agrible-root')
  )
})()
