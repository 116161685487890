import React, { useMemo, useCallback, useContext } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import _get from 'lodash/get'
import Dialog from '@nutrien/uet-react/Dialog'

import th from 'theme/helpers'
import useQueryParams from 'hooks/useQueryParams'
import usePanel from 'hooks/usePanel'
import usePermissions from 'hooks/usePermissions'
import InPageTabs from 'components/InPageTabs'
import { ORG_MANAGEMENT_PRODUCT } from 'helpers/products'
import { selectOwnedOrg } from 'store/selectors/farmTree'
import { AuthContext } from 'components/Auth/authentication-context'

import TabPanel from './components/TabPanel'
import Profile from './components/Profile'
import Permissions from './components/Permissions'
import Subscriptions from './components/Subscriptions'
import FinancialAccount from './components/FinancialAccount'

const DialogContent = styled.div`
  padding: ${th.spacing(3, 0)};
`

const MyAccount = () => {
  const { isSSOUser, isEmployee } = useContext(AuthContext)
  const { t } = useTranslation()
  const queryParams = useQueryParams()
  const currentTab = queryParams.accounttab
  const { setParams: changeMyAccountPanel } = usePanel('my_account')
  const { canView } = usePermissions(ORG_MANAGEMENT_PRODUCT)
  const ownedOrg = useSelector(selectOwnedOrg)
  const hasPermissionAccess = useMemo(() => canView(_get(ownedOrg, 'uuid')), [
    ownedOrg,
    canView
  ])

  const handleTabChange = useCallback(
    (_, tab) => changeMyAccountPanel({ panel: 'my_account', accounttab: tab }),
    [changeMyAccountPanel]
  )

  const handleClose = useCallback(() => {
    changeMyAccountPanel({ panel: null, accounttab: null })
  }, [changeMyAccountPanel])

  const tabs = useMemo(
    () =>
      [
        { label: t('profile'), value: 'profile' },
        hasPermissionAccess && {
          label: t('permissions'),
          value: 'permissions'
        },
        { label: t('subscriptions'), value: 'subscriptions' },
        isSSOUser &&
          !isEmployee && {
            label: t('financial_accounts'),
            value: 'financialAccount'
          }
      ].filter(Boolean),
    [t, hasPermissionAccess, isSSOUser, isEmployee]
  )

  return (
    <Dialog
      title={t('my_account')}
      open
      fullScreen
      onClose={handleClose}
      hasCloseIcon
      closeIconSize="large">
      <InPageTabs
        tabs={tabs}
        selected={currentTab}
        onChange={handleTabChange}
      />

      <DialogContent>
        <TabPanel isActive={currentTab === 'profile'}>
          <Profile />
        </TabPanel>
        {hasPermissionAccess && (
          <TabPanel isActive={currentTab === 'permissions'}>
            <Permissions />
          </TabPanel>
        )}
        <TabPanel isActive={currentTab === 'subscriptions'}>
          <Subscriptions />
        </TabPanel>
        {isSSOUser && !isEmployee && (
          <TabPanel isActive={currentTab === 'financialAccount'}>
            <FinancialAccount />
          </TabPanel>
        )}
      </DialogContent>
    </Dialog>
  )
}

MyAccount.propTypes = {
  onClose: PropTypes.func
}

export default MyAccount
