export const EVIDENCE_QUERY = `
query getEvidence($seasonId: Int!, $eventId: Int!, $profileUuid: String) {
  getEvidence(seasonId: $seasonId, eventId: $eventId, profileUuid: $profileUuid) {
      evidenceType
      evidenceKey
      eventType
      episode {
        id
      }
      author {
        uuid
        email
        firstName
        lastName
      }
      evidenceRecord {
        uuid
        fileName
        documentType
        uploadStatus
        s3DownloadUrl
        created
        author {
          uuid
          email
          firstName
          lastName
        }
      }
      uuid
      eventId
  }
}
`
export const S3_BUCKET_URL_MUTATION = `
  mutation FetchS3BucketUrl($evidenceRecordInput: EvidenceRecordInput!, $profileUuid: String!) {
    fetchS3BucketUrl(evidenceRecordInput: $evidenceRecordInput, profileUuid: $profileUuid) {
        uuid
        fileName
        documentType
        s3DownloadUrl
        s3UploadUrl
        created
        author {
          uuid
          email
          firstName
          lastName
        }
    }
  }
`

export const POLL_UPLOAD_STATUS_QUERY = `
  query PollEvidenceUploadStatus($evidenceIds: EvidenceUuidsInput!) {
    pollEvidenceUploadStatus(evidenceIds: $evidenceIds) {
      uuid
      uploadStatus
      s3DownloadUrl
    }
  }
`

export const EVIDENCE_COUNT = `
  query getEvidenceFileCount($seasonId: Int!, $eventId: Int!, $profileUuid: String) {
    getEvidenceFileCount(seasonId: $seasonId, eventId: $eventId, profileUuid: $profileUuid) {
      recordCounts {
        key
        value
        hasVirus
      }
    }
  }
`

export const EVIDENCE_DELETE_MUTATION = `
mutation DeleteEvidence(
  $uuid: String!, $profileUuid,: String
) {
  deleteEvidence(
    uuid: $uuid, profileUuid: $profileUuid
  )
}
`

export const S3_DOWNLOAD_URL_QUERY = `
  query PollEvidenceUploadStatus($evidenceIds: EvidenceUuidsInput!) {
    pollEvidenceUploadStatus(evidenceIds: $evidenceIds) {
      s3DownloadUrl
    }
  }
`
