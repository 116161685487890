import React from 'react'
import PropTypes from 'prop-types'

import { AuthProvider, AuthConsumer } from './authentication-context'

export const Auth = ({ children, ...rest }) => (
  <AuthProvider {...rest}>
    <AuthConsumer>{context => children(context)}</AuthConsumer>
  </AuthProvider>
)

Auth.propTypes = {
  children: PropTypes.func.isRequired
}

export default Auth
